import { handleApiErrors } from 'lib/api-error';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './actionType';

import {
  advStockListDataError,
  advStockListDataSuccess,
  advStockPinnedError,
  advStockPinnedSuccess,
  advStockSingleError,
  advStockSingleSuccess,
  allOrdRequestError,
  allOrdRequestSuccess,
  allStockRequestError,
  allStockRequestSuccess,
  allStockRightRequestError,
  allStockRightRequestSuccess,
  cwListRequestError,
  cwListRequestSuccess,
  dmNganhRequestError,
  dmNganhRequestSuccess,
  getStockByIdRequestError,
  getStockByIdRequestSuccess,
  indexRequestError,
  indexRequestSuccess,
  indSumRequestError,
  indSumRequestSuccess,
  list30RequestError,
  list30RequestSuccess,
  listEtfRequestError,
  listEtfRequestSuccess,
  listMessageRequestError,
  listMessageRequestSuccess,
  listNganhRequestError,
  listNganhRequestSuccess,
  loleHSXSnapshotRequestError,
  loleHSXSnapshotRequestSuccess,
  loleSnapshotRequestError,
  loleSnapshotRequestSuccess,
  ndataSnapshotRequestError,
  ndataSnapshotRequestSuccess,
  psIndexRequestError,
  psIndexRequestSuccess,
  psListRequestError,
  psListRequestSuccess,
  psSnapshotRequestError,
  psSnapshotRequestSuccess,
  ptListRequestError,
  ptListRequestSuccess,
  snapshotPartRequestError,
  snapshotPartRequestSuccess,
  snapshotRequestError,
  snapshotRequestSuccess,
  snapshotUnderlyingCWError,
  snapshotUnderlyingCWSuccess,
  snpStockPinnedError,
  snpStockPinnedSuccess,
  stockInfoRequestError,
  stockInfoRequestSuccess,
  topInterestRequestError,
  topInterestRequestSuccess,
  tradeHisPsRequestError,
  tradeHisPsRequestSuccess,
  worldIndexRequestError,
  worldIndexRequestSuccess,
} from './actions';

import { checkInvalidSession, getMsgByErrorCode } from 'utils';

import { processMapData } from 'utils/processMapData';

import axios from 'axios';
import { storages } from 'lib/storages';
import { sortBy } from 'lodash';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrl = `${process.env.REACT_APP_API_URL}`;
const priceUrl = `${process.env.REACT_APP_PRICE_URL}`;
const algoUrl = `${process.env.REACT_APP_ALGO_API_URL}`;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function handleRequestText(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.text())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function allStockRequestApi() {
  const url = `${priceUrl}/getlistallstock`;
  const request = fetch(url);

  return handleRequest(request);
}

function allStockRightRequestApi() {
  const url = `${algoUrl}getRightsList`;
  const request = fetch(url);

  return handleRequest(request);
}

function ptListRequestApi() {
  const url = `${priceUrl}/getPTList`;
  const request = fetch(url);

  return handleRequest(request);
}

function psListRequestApi() {
  const url = `${priceUrl}/pslistdata`;
  const request = fetch(url);

  return handleRequest(request);
}

function cwListRequestApi() {
  const url = `https://info.sbsi.vn/listCW.pt`;
  const request = fetch(url);

  return handleRequest(request);
}

function list30RequestApi(data: any) {
  const url = `${algoUrl}adv_getliststockbyid/${data}`;
  const request = fetch(url);

  return request
    .then((response: any) => response.text())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function listNganhRequestApi(data: any) {
  const request = fetch(algoUrl + 'getListByIndustry/' + data);
  return handleRequestText(request);
}

function listEtfRequestApi(data: any) {
  const url = `https://info.sbsi.vn/listETF.pt?market=${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function advStockListDataRequestApi(data: string) {
  const url = `${appUrl}/adv_getliststockdata/${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function listMessageRequestApi() {
  return null;
  const url = `${appUrl}/listMessage`;
  const request = fetch(url);

  return handleRequest(request);
}

function indSumRequestApi() {
  const url = `${priceUrl}/getSummary`;
  const request = fetch(url);

  return handleRequest(request);
}

function snapshotRequestApi(data: any) {
  const url = `https://board.upstock.com.vn/getliststockdata/${data.toUpperCase()}`;
  const request = fetch(url);

  return handleRequest(request);
}

function loleSnapshotRequestApi(data: any) {
  const url = `${priceUrl}/getliststockdataoddlot/${data.toUpperCase()}`;
  const request = fetch(url);

  return handleRequest(request);
}

function loleHSXSnapshotRequestApi() {
  const url = `${priceUrl}/getOddLotData/HSX`;
  const request = fetch(url);

  return handleRequest(request);
}

function psSnapshotRequestApi(data: any) {
  const url = `${priceUrl}/getpsalldatalsnapshot/${data.toUpperCase()}`;
  const request = fetch(url);

  return handleRequest(request);
}

function stockByIdRequestApi(data: any) {
  const url = `${priceUrl}/getliststockById/${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function worldIndexRequestApi() {
  const url = `${priceUrl}/worldIndex`;
  const request = fetch(url);

  return handleRequest(request);
}

function indexRequestApi(data: any) {
  const url = `${priceUrl}/getlistindexdetail/${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function ndataRequestApi(data: any) {
  const url = `https://bgapidatafeed.vps.com.vn/getps10pricesnapshot/${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function tradeHisPsRequestApi(data: any) {
  const url = `https://bddatafeed.vps.com.vn/getpschartintraday/${data}`;
  const request = fetch(url);

  return handleRequest(request);
}

function dmNganhRequestApi() {
  const url = `${algoUrl}getIndustryList`;
  const request = fetch(url);

  return handleRequest(request);
}

function topInterestRequestApi() {
  const url = `${priceUrl}/topStockInterested?count=10`;
  const request = fetch(url);

  return handleRequest(request);
}

async function allOrderRequestApi(data: any) {
  const url = `${appUrl}/TraditionalService`;
  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* allStockRequestFlow(): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation
    //   priceUrl = config.config.bs
    // }
    const dataList: any = yield call(allStockRequestApi);

    yield put(allStockRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(allStockRequestError(error));
  }
}

function* allStockRightRequestFlow(): any {
  try {
    const dataList: any = yield call(allStockRightRequestApi);

    yield put(allStockRightRequestSuccess(dataList));
  } catch (error) {
    yield put(allStockRightRequestError(error));
  }
}

function* snapshotRequestFlow(action: actions.SnapshotRequestAction): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    // log(config)
    const { data } = action;
    const dataList: any = yield call(snapshotRequestApi, data);
    let csData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(snapshotRequestSuccess(csData));
  } catch (error) {
    yield put(snapshotRequestError(error));
  }
}

function* snapshotUnderlyingRequestFlow(
  action: actions.SnapshotUnderlyingCWRequestAction,
): any {
  try {
    const { data } = action;
    const dataList: any = yield call(snapshotRequestApi, data);
    let csData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(snapshotUnderlyingCWSuccess(csData));
  } catch (error) {
    yield put(snapshotUnderlyingCWError(error));
  }
}

function* psSnapshotRequestFlow(action: actions.PsSnapshotRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(psSnapshotRequestApi, data);
    let psData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataPS(item);
      psData.push(symbol);
    });

    yield put(psSnapshotRequestSuccess(psData));
  } catch (error) {
    yield put(psSnapshotRequestError(error));
  }
}

function* psIndexRequestFlow(action: actions.PsIndexRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(psSnapshotRequestApi, data);
    let psData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataPS(item);
      psData.push(symbol);
    });

    yield put(psIndexRequestSuccess(psData));
  } catch (error) {
    yield put(psIndexRequestError(error));
  }
}

function* snapshotPartRequestFlow(
  action: actions.SnapshotPartRequestAction,
): any {
  try {
    const { data } = action;
    // log(data)
    const dataList: any = yield call(snapshotRequestApi, data);
    let csData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(snapshotPartRequestSuccess(csData));
  } catch (error) {
    yield put(snapshotPartRequestError(error));
  }
}

function* loleSnapshotRequestFlow(
  action: actions.LoleSnapshotRequestAction,
): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    // log(data)
    const { data } = action;
    const dataList: any = yield call(loleSnapshotRequestApi, data);

    yield put(loleSnapshotRequestSuccess(dataList));
  } catch (error) {
    yield put(loleSnapshotRequestError(error));
  }
}

function* loleHSXSnapshotRequestFlow(): any {
  try {
    const dataList: any = yield call(loleHSXSnapshotRequestApi);

    let arrData: any = [];
    dataList.forEach((element: any) => {
      arrData.push(element.data);
    });

    yield put(loleHSXSnapshotRequestSuccess(sortBy(arrData, 'sym')));
  } catch (error) {
    yield put(loleHSXSnapshotRequestError(error));
  }
}

function* stockInfoRequestFlow(action: actions.StockInfoRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(snapshotRequestApi, data);

    let csData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(stockInfoRequestSuccess(csData));
  } catch (error) {
    yield put(stockInfoRequestError(error));
  }
}

function* allOrderRequestFlow(action: actions.AllOrdRequestAction): any {
  try {
    const { data } = action;
    const resData: any = yield call(allOrderRequestApi, data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(allOrdRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(allOrdRequestError(error));
  }
}

function* ptListRequestFlow(): any {
  try {
    const dataList: any = yield call(ptListRequestApi);

    yield put(ptListRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(ptListRequestError(error));
  }
}

function* psListRequestFlow(): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    const dataList: any = yield call(psListRequestApi);

    yield put(psListRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(psListRequestError(error));
  }
}

function* cwListRequestFlow(): any {
  try {
    const dataList: any = yield call(cwListRequestApi);

    yield put(cwListRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(cwListRequestError(error));
  }
}

function* list30RequestFlow(action: actions.List30RequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(list30RequestApi, data);
    // log(data, dataList)
    if (dataList && dataList?.length > 10) {
      const _data = { list: dataList, time: new Date().getTime() };
      // có data mới lưu local
      storages.saveState(data, _data);
      yield put(list30RequestSuccess(_data));
    }
    throw Error('Không lấy được dữ liệu');
  } catch (error) {
    // log(error)
    yield put(list30RequestError(error));
  }
}

function* listNganhRequestFlow(action: actions.ListNganhRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(listNganhRequestApi, data);
    console.log(data, dataList);
    yield put(listNganhRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(listNganhRequestError(error));
  }
}

function* listEtfRequestFlow(action: actions.ListEtfRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(listEtfRequestApi, data);

    yield put(listEtfRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(listEtfRequestError(error));
  }
}

function* listMessageRequestFlow(): any {
  try {
    const dataList: any = yield call(listMessageRequestApi);

    yield put(listMessageRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(listMessageRequestError(error));
  }
}

function* indSumRequestFlow(): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    const dataList: any = yield call(indSumRequestApi);

    yield put(indSumRequestSuccess(dataList));
  } catch (error) {
    // log(error)
    yield put(indSumRequestError(error));
  }
}

function* stockByIdRequestFlow(action: actions.GetStockByIdRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(stockByIdRequestApi, data);

    let csData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(getStockByIdRequestSuccess(csData));
  } catch (error) {
    yield put(getStockByIdRequestError(error));
  }
}

function* worldIndexRequestFlow(): any {
  try {
    const worldList: any = yield call(worldIndexRequestApi);
    yield put(worldIndexRequestSuccess(worldList.data));
  } catch (error) {
    yield put(worldIndexRequestError(error));
  }
}

function* indexRequestFlow(action: actions.IndexRequestAction): any {
  try {
    const { data } = action;
    const indexs: any = yield call(indexRequestApi, data);
    let indexList: any = [];
    indexs &&
      !!indexs.length &&
      indexs.forEach((element: any) => {
        indexList.push(processMapData._processMapDataIndex(element));
      });
    yield put(indexRequestSuccess(indexList));
  } catch (error) {
    yield put(indexRequestError(error));
  }
}

function* ndataSnapshotRequestFlow(
  action: actions.NdataSnapshotRequestAction,
): any {
  try {
    // grab the data from our action
    const { data } = action;

    const ndata: any = yield call(ndataRequestApi, data);
    yield put(ndataSnapshotRequestSuccess(ndata));
  } catch (error) {
    yield put(ndataSnapshotRequestError(error));
  }
}

function* tradeHisPsRequestFlow(action: actions.TradeHisPsRequestAction): any {
  try {
    // grab the data from our action
    const { data } = action;

    const ndata: any = yield call(tradeHisPsRequestApi, data);
    yield put(tradeHisPsRequestSuccess(ndata));
  } catch (error) {
    yield put(tradeHisPsRequestError(error));
  }
}

function* dmNganhRequestFlow(): any {
  try {
    const dataList: any = yield call(dmNganhRequestApi);
    let _dmNganh: any[] = [];
    dataList.forEach((element: any, index: number) => {
      _dmNganh.push({
        id: element.industry_code,
        sequence: index,
        name: element.industry_name_vn,
        path: '/price/bang-gia/nganh-' + element.industry_code,
      });
    });
    yield put(dmNganhRequestSuccess(_dmNganh));
  } catch (error) {
    yield put(dmNganhRequestError(error));
  }
}

function* topInterestRequestFlow(): any {
  try {
    const dataList: any = yield call(topInterestRequestApi);
    // log(dataList);
    yield put(topInterestRequestSuccess(dataList.data));
  } catch (error) {
    yield put(topInterestRequestError(error));
  }
}

function* topStockTradeFlow(action: actions.TopStockTradeRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(topInterestRequestApi);
    // log(dataList);
    yield put(topInterestRequestSuccess(dataList.data));
  } catch (error) {
    yield put(topInterestRequestError(error));
  }
}

function* snpStockPinnedFlow(action: actions.SnpStockPinnedRequestAction): any {
  try {
    const { data } = action;
    const dataList: any = yield call(snapshotRequestApi, data);
    let csData: any = [];
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item);
      csData.push(symbol);
    });

    yield put(snpStockPinnedSuccess(csData));
  } catch (error) {
    yield put(snpStockPinnedError(error));
  }
}

function* advStockListDataFlow(
  action: actions.AdvStockListDataRequestAction,
): any {
  try {
    const { data } = action;
    const dataList: any = yield call(function* snpStockPinnedFlow(
      action: actions.SnpStockPinnedRequestAction,
    ): any {
      try {
        const { data } = action;
        const dataList: any = yield call(snapshotRequestApi, data);
        let csData: any = [];
        dataList.forEach((item: any) => {
          let symbol = processMapData._processMapDataCS(item);
          csData.push(symbol);
        });

        yield put(snpStockPinnedSuccess(csData));
      } catch (error) {
        yield put(snpStockPinnedError(error));
      }
    }, data);
    // log(dataList);
    yield put(advStockListDataSuccess(dataList));
  } catch (error) {
    yield put(advStockListDataError(error));
  }
}

function* advStockPinnedFlow(action: actions.AdvStockPinnedRequestAction): any {
  try {
    const { data } = action;
    const res: any = yield call(advStockListDataRequestApi, data);
    yield put(advStockPinnedSuccess(res));
  } catch (error) {
    yield put(advStockPinnedError(error));
  }
}

function* advStockSingleFlow(action: actions.AdvStockSingleRequestAction): any {
  try {
    const { data } = action;
    const res: any = yield call(advStockListDataRequestApi, data);
    yield put(advStockSingleSuccess(res));
  } catch (error) {
    yield put(advStockSingleError(error));
  }
}

function* priceBoardWatcher() {
  yield all([
    takeLatest(actions.ALL_STOCK_REQUESTING, allStockRequestFlow),
    takeLatest(actions.ALL_STOCK_RIGHT_REQUESTING, allStockRightRequestFlow),
    takeLatest(actions.SNAPSHOT_REQUESTING, snapshotRequestFlow),
    takeLatest(
      actions.SNAPSHOT_UNDERLYING_REQUESTING,
      snapshotUnderlyingRequestFlow,
    ),
    takeEvery(actions.SNAPSHOT_PART_REQUESTING, snapshotPartRequestFlow),
    takeLatest(actions.STOCK_INFO_REQUESTING, stockInfoRequestFlow),
    takeLatest(actions.ALL_ORD_REQUESTING, allOrderRequestFlow),
    takeLatest(actions.PT_LIST_REQUESTING, ptListRequestFlow),
    takeLatest(actions.PS_LIST_REQUESTING, psListRequestFlow),
    takeLatest(actions.PS_SNAPSHOT_REQUESTING, psSnapshotRequestFlow),
    takeLatest(actions.PS_INDEX_REQUESTING, psIndexRequestFlow),
    takeLatest(actions.CW_LIST_REQUESTING, cwListRequestFlow),
    takeLatest(actions.LIST_30_REQUESTING, list30RequestFlow),
    takeLatest(actions.LIST_NGANH_REQUESTING, listNganhRequestFlow),
    takeLatest(actions.LIST_ETF_REQUESTING, listEtfRequestFlow),
    takeLatest(actions.LIST_MESSAGE_REQUESTING, listMessageRequestFlow),
    takeLatest(actions.SUMMARY_REQUESTING, indSumRequestFlow),
    takeLatest(actions.GET_STOCK_BY_ID_REQUESTING, stockByIdRequestFlow),
    takeLatest(actions.WORLD_INDEX_REQUESTING, worldIndexRequestFlow),
    takeLatest(actions.INDEX_REQUESTING, indexRequestFlow),
    takeEvery(actions.NDATA_SNAPSHOT_REQUESTING, ndataSnapshotRequestFlow),
    takeLatest(actions.TRADE_HIS_PS_REQUESTING, tradeHisPsRequestFlow),
    takeLatest(actions.DM_NGANH_REQUESTING, dmNganhRequestFlow),
    takeLatest(actions.LOLE_SNAPSHOT_REQUESTING, loleSnapshotRequestFlow),
    takeLatest(
      actions.LOLE_HSX_SNAPSHOT_REQUESTING,
      loleHSXSnapshotRequestFlow,
    ),
    takeLatest(actions.TOP_INTEREST_REQUESTING, topInterestRequestFlow),
    takeLatest(actions.TOP_STOCK_TRADE_REQUESTING, topStockTradeFlow),

    takeLatest(actions.SNP_STOCK_PINNED_REQUESTING, snpStockPinnedFlow),
    takeLatest(actions.ADV_STOCK_LIST_DATA_REQUESTING, advStockListDataFlow),
    takeLatest(actions.ADV_STOCK_PINNED_REQUESTING, advStockPinnedFlow),
    takeLatest(actions.ADV_STOCK_SINGLE_REQUESTING, advStockSingleFlow),
  ]);
}

export default priceBoardWatcher;
