import * as actions from './actionType';

export interface UserState {
  userList: any;
  userDetail: any;
  userUpd: any;
  userDel: any;
  userRegister: any;
  userRegisCheck: any;

  userRightByAcc: any;

  userAssetDetail: any;
  userProfitClose: any;
  userAssetChart: any;
  userProfitSymbol: any;
}

const initialState = {
  userList: null,
  userDetail: null,
  userUpd: null,
  userDel: null,
  userRegister: null,
  userRegisCheck: null,

  userRightByAcc: null,
  userAssetDetail: null,
  userProfitClose: null,
  userAssetChart: null,
  userProfitSymbol: null,
};

export default function categoryReducer(
  state: UserState = initialState,
  action: actions.UserAction,
): UserState {
  switch (action.type) {
    // case actions.USER_SEARCH_REQUESTING:
    case actions.USER_SEARCH_ERROR:
      return {
        ...state,
        userList: null,
      };

    case actions.USER_SEARCH_SUCCESS:
      return {
        ...state,
        userList: action.resData,
      };

    case actions.USER_DETAIL_REQUESTING:
    case actions.USER_DETAIL_ERROR:
      return {
        ...state,
        userDetail: null,
      };

    case actions.USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.resData,
      };

    case actions.USER_UPD_REQUESTING:
    case actions.USER_UPD_ERROR:
      return {
        ...state,
        userUpd: null,
      };

    case actions.USER_UPD_SUCCESS:
      return {
        ...state,
        userUpd: action.resData,
      };

    case actions.USER_DEL_REQUESTING:
    case actions.USER_DEL_ERROR:
      return {
        ...state,
        userDel: null,
      };

    case actions.USER_DEL_SUCCESS:
      return {
        ...state,
        userDel: action.resData,
      };

    case actions.USER_RIGHT_BY_ACC_REQUESTING:
    case actions.USER_RIGHT_BY_ACC_ERROR:
      return {
        ...state,
        userRightByAcc: null,
      };

    case actions.USER_RIGHT_BY_ACC_SUCCESS:
      return {
        ...state,
        userRightByAcc: action.resData,
      };

    case actions.USER_REGIS_CHECK_REQUESTING:
    case actions.USER_REGIS_CHECK_ERROR:
      return {
        ...state,
        userRegisCheck: null,
      };

    case actions.USER_REGIS_CHECK_SUCCESS:
      return {
        ...state,
        userRegisCheck: action.resData,
      };

    case actions.USER_REGISTER_REQUESTING:
    case actions.USER_REGISTER_ERROR:
      return {
        ...state,
        userRegister: null,
      };

    case actions.USER_REGISTER_SUCCESS:
      return {
        ...state,
        userRegister: action.resData,
      };

    case actions.USER_ASSET_DETAIL_REQUESTING:
    case actions.USER_ASSET_DETAIL_ERROR:
      return {
        ...state,
        userAssetDetail: null,
      };

    case actions.USER_ASSET_DETAIL_SUCCESS:
      return {
        ...state,
        userAssetDetail: action.resData,
      };

    case actions.USER_PROFIT_CLOSE_REQUESTING:
    case actions.USER_PROFIT_CLOSE_ERROR:
      return {
        ...state,
        userProfitClose: null,
      };

    case actions.USER_PROFIT_CLOSE_SUCCESS:
      return {
        ...state,
        userProfitClose: action.resData,
      };

    case actions.USER_ASSET_CHART_REQUESTING:
    case actions.USER_ASSET_CHART_ERROR:
      return {
        ...state,
        userAssetChart: null,
      };

    case actions.USER_ASSET_CHART_SUCCESS:
      return {
        ...state,
        userAssetChart: action.resData,
      };

    case actions.USER_PROFIT_SYMBOL_REQUESTING:
    case actions.USER_PROFIT_SYMBOL_ERROR:
      return {
        ...state,
        userProfitSymbol: null,
      };

    case actions.USER_PROFIT_SYMBOL_SUCCESS:
      return {
        ...state,
        userProfitSymbol: action.resData,
      };

    default:
      return state;
  }
}
