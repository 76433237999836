import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';

import * as actions from './actionType';
import axios from 'axios';
import {
  funcDelError,
  funcDelSuccess,
  funcDetailError,
  funcDetailSuccess,
  funcSearchError,
  funcSearchSuccess,
  funcUpdError,
  funcUpdSuccess,
  setParamDelError,
  setParamDelSuccess,
  setParamDetailError,
  setParamDetailSuccess,
  setParamSearchError,
  setParamSearchSuccess,
  setParamUpdError,
  setParamUpdSuccess,
  userGrDelError,
  userGrDelSuccess,
  userGrDetailError,
  userGrDetailSuccess,
  userGrFuncError,
  userGrFuncSuccess,
  userGrSearchError,
  userGrSearchSuccess,
  userGrUpdError,
  userGrUpdSuccess,
} from './actions';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrl = `${process.env.REACT_APP_API_URL}`;

async function postRequestApi(data: Object) {
  const url = `${appUrl}/traditional-service`;

  try {
    const response = axios.post(url, JSON.stringify(data));
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* setParamSearchRequestFlow(
  action: actions.SetParamSearchRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(setParamSearchSuccess(_res.data));
  } catch (error: any) {
    yield put(setParamSearchError(error));
  }
}

function* setParamDetailRequestFlow(
  action: actions.SetParamSingleRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(setParamDetailSuccess(_res.data[0]));
  } catch (error: any) {
    yield put(setParamDetailError(error));
  }
}

function* setParamUpdRequestFlow(
  action: actions.SetParamUpdRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(setParamUpdSuccess(Math.random()));
  } catch (error: any) {
    yield put(setParamUpdError(error));
  }
}

function* setParamDelRequestFlow(
  action: actions.SetParamDelRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(setParamDelSuccess(Math.random()));
  } catch (error: any) {
    yield put(setParamDelError(error));
  }
}

function* setFuncSearchRequestFlow(
  action: actions.FuncSearchRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(funcSearchSuccess(_res.data));
  } catch (error: any) {
    yield put(funcSearchError(error));
  }
}

function* setFuncDetailRequestFlow(
  action: actions.FuncDetailRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(funcDetailSuccess(_res.data[0]));
  } catch (error: any) {
    yield put(funcDetailError(error));
  }
}

function* setFuncUpdRequestFlow(action: actions.FuncUpdRequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(funcUpdSuccess(Math.random()));
  } catch (error: any) {
    yield put(funcUpdError(error));
  }
}

function* setFuncDelRequestFlow(action: actions.FuncDelRequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(funcDelSuccess(Math.random()));
  } catch (error: any) {
    yield put(funcDelError(error));
  }
}

function* setUserGrSearchRequestFlow(
  action: actions.UserGrSearchRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(userGrSearchSuccess(_res.data));
  } catch (error: any) {
    yield put(userGrSearchError(error));
  }
}

function* setUserGrDetailRequestFlow(
  action: actions.UserGrDetailRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(userGrDetailSuccess(_res.data[0]));
  } catch (error: any) {
    yield put(userGrDetailError(error));
  }
}

function* setUserGrUpdRequestFlow(
  action: actions.UserGrUpdRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(userGrUpdSuccess(Math.random()));
  } catch (error: any) {
    yield put(userGrUpdError(error));
  }
}

function* setUserGrDelRequestFlow(
  action: actions.UserGrDelRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(userGrDelSuccess(Math.random()));
  } catch (error: any) {
    yield put(userGrDelError(error));
  }
}

function* setUserGrFuncRequestFlow(
  action: actions.UserGrFuncRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (
        _res.rs.includes('Invalid session') ||
        _res.rs.includes('InvalidSessionException') ||
        _res.rs.includes('NotLoginException')
      ) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.re);
    }
    yield put(userGrFuncSuccess(_res.data));
  } catch (error: any) {
    yield put(userGrFuncError(error));
  }
}

function* settingWatcher() {
  yield all([
    takeLatest(actions.SET_PARAM_SEARCH_REQUESTING, setParamSearchRequestFlow),
    takeLatest(actions.SET_PARAM_SINGLE_REQUESTING, setParamDetailRequestFlow),
    takeLatest(actions.SET_PARAM_UPD_REQUESTING, setParamUpdRequestFlow),
    takeLatest(actions.SET_PARAM_DEL_REQUESTING, setParamDelRequestFlow),

    takeLatest(actions.FUNC_SEARCH_REQUESTING, setFuncSearchRequestFlow),
    takeLatest(actions.FUNC_DETAIL_REQUESTING, setFuncDetailRequestFlow),
    takeLatest(actions.FUNC_UPD_REQUESTING, setFuncUpdRequestFlow),
    takeLatest(actions.FUNC_DEL_REQUESTING, setFuncDelRequestFlow),

    takeLatest(actions.USER_GR_SEARCH_REQUESTING, setUserGrSearchRequestFlow),
    takeLatest(actions.USER_GR_DETAIL_REQUESTING, setUserGrDetailRequestFlow),
    takeLatest(actions.USER_GR_UPD_REQUESTING, setUserGrUpdRequestFlow),
    takeLatest(actions.USER_GR_DEL_REQUESTING, setUserGrDelRequestFlow),
    takeLatest(actions.USER_GR_FUNC_REQUESTING, setUserGrFuncRequestFlow),
  ]);
}

export default settingWatcher;
