/* eslint-disable jsx-a11y/anchor-is-valid */
import { setLogin } from 'containers/client/actions';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppState } from 'reducers';
import FormForgotPassStep1 from './layout/forgotPass/formForgotPassStep1';
import FormForgotPassStep2 from './layout/forgotPass/formForgotPassStep2';
import { useHistory } from 'react-router';
import { clearLogin } from 'containers/login/actions';
import Notice from 'shared/notice';
import { INoticeMsg } from 'interface';
import { BtnSubmit } from 'utils/styledUtils';
import { useTranslation } from 'react-i18next';
interface Props {

}
function ForgotPassComponent(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [step, setStep] = React.useState<number>(1);
  const [account, setAccount] = React.useState<any>(null);
  const noticeMsg: INoticeMsg = {
    title: t('notice.changepass-success'),
    msg: t('notice.pass-changed-success'),
    buttonType: 'CHANGE_NEW_PASS',
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearLogin());
    };
  }, []);
  function handleCloseStep2() {
    setStep(1)
    setAccount(null)
  }

  function handleReturnLogin() {
    history.push('/price/bang-gia/vn30');
    dispatch(setLogin());
  }

  return (
    <div className="mt-[70px] 2xl:mt-[154px] w-[608px]">
      {step === 1 && (
        <FormForgotPassStep1 setStep={setStep} setAccount={setAccount} />
      )}
      {step === 2 && (
        <FormForgotPassStep2 setStep={setStep} account={account} onClose={handleCloseStep2} />
      )}
      {
        step === 3 && (
          <Notice notice={noticeMsg} onClose={handleReturnLogin}>
            <BtnSubmit className="success w-full" onClick={handleReturnLogin}>
              {t('button.understand')}
            </BtnSubmit>
            <button
              // onClick={handleReCreate}
              type="button"
              className="mt-2 w-full h-12 rounded-full py-2 px-6 font-semibold border border-skin-weak"
            >
              {t('button.logout-device')}
            </button>
          </Notice>
        )
      }
    </div>
  );
}

const makeMapStateToProps = () => {

  const mapStateToProps = (state: AppState) => {
    return {
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(ForgotPassComponent);
