import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';

import * as actions from './actionType';
import axios from 'axios';
import {
  cashTransferInError,
  cashTransferInSuccess,
  cashTransferOutError,
  cashTransferOutSuccess,
} from './actions';
import { checkInvalidSession } from 'utils';
import { storages } from 'lib/storages';

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrl = `${process.env.REACT_APP_API_URL}`;

async function postRequestApi(data: Object) {
  const url = `${appUrl}/TraditionalService`;

  try {
    const response = axios.post(url, JSON.stringify(data));
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* cashTransferInRequestFlow(
  action: actions.CashTransferInRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(cashTransferInSuccess(Math.random()));
  } catch (error: any) {
    yield put(cashTransferInError(error));
  }
}

function* cashTransferOutRequestFlow(
  action: actions.CashTransferOutRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(cashTransferOutSuccess(Math.random()));
  } catch (error: any) {
    yield put(cashTransferOutError(error));
  }
}

function* pawnWatcher() {
  yield all([
    takeLatest(actions.CASH_TRANSFER_IN_REQUESTING, cashTransferInRequestFlow),
    takeLatest(
      actions.CASH_TRANSFER_OUT_REQUESTING,
      cashTransferOutRequestFlow,
    ),
  ]);
}

export default pawnWatcher;
