import { PlacesType, Tooltip } from 'react-tooltip';

interface Props {
  id: string;
  placement?: PlacesType;
}

function Tooltiped(props: Props): JSX.Element {
  const { placement, id } = props;
  return (
    <Tooltip
      id={id}
      place={placement}
      style={{
        backgroundColor: 'var(--color-bg-tooltip)',
        borderRadius: '8px',
        maxWidth: '350px',
      }}
    />
  );
}

export default Tooltiped;
