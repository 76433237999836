import * as actions from './actionType';

// TODO: Giải ngân thu nợ
export function report001Request(data: any): actions.Report001RequestingAction {
  return {
    type: actions.REPORT_001_REQUESTING,
    data,
  };
}

export function report001Success(resData: any): actions.Report001SuccessAction {
  return {
    type: actions.REPORT_001_SUCCESS,
    resData,
  };
}

export function report001Error(
  error: Error | string,
): actions.Report001ErrorAction {
  return {
    type: actions.REPORT_001_ERROR,
    error,
  };
}

// TODO: Dư nợ theo tài khoản
export function report002Request(data: any): actions.Report002RequestingAction {
  return {
    type: actions.REPORT_002_REQUESTING,
    data,
  };
}

export function report002Success(resData: any): actions.Report002SuccessAction {
  return {
    type: actions.REPORT_002_SUCCESS,
    resData,
  };
}

export function report002Error(
  error: Error | string,
): actions.Report002ErrorAction {
  return {
    type: actions.REPORT_002_ERROR,
    error,
  };
}

// TODO: Chi tiết dư nợ
export function report003Request(data: any): actions.Report003RequestingAction {
  return {
    type: actions.REPORT_003_REQUESTING,
    data,
  };
}

export function report003Success(resData: any): actions.Report003SuccessAction {
  return {
    type: actions.REPORT_003_SUCCESS,
    resData,
  };
}

export function report003Error(
  error: Error | string,
): actions.Report003ErrorAction {
  return {
    type: actions.REPORT_003_ERROR,
    error,
  };
}

// TODO: Dư nợ đến hạn
export function report004Request(data: any): actions.Report004RequestingAction {
  return {
    type: actions.REPORT_004_REQUESTING,
    data,
  };
}

export function report004Success(resData: any): actions.Report004SuccessAction {
  return {
    type: actions.REPORT_004_SUCCESS,
    resData,
  };
}

export function report004Error(
  error: Error | string,
): actions.Report004ErrorAction {
  return {
    type: actions.REPORT_004_ERROR,
    error,
  };
}

// TODO: Sao kê nợ
export function report005Request(data: any): actions.Report005RequestingAction {
  return {
    type: actions.REPORT_005_REQUESTING,
    data,
  };
}

export function report005Success(resData: any): actions.Report005SuccessAction {
  return {
    type: actions.REPORT_005_SUCCESS,
    resData,
  };
}

export function report005Error(
  error: Error | string,
): actions.Report005ErrorAction {
  return {
    type: actions.REPORT_005_ERROR,
    error,
  };
}

// TODO: Lịch sử đăng ký gói
export function report006Request(data: any): actions.Report006RequestingAction {
  return {
    type: actions.REPORT_006_REQUESTING,
    data,
  };
}

export function report006Success(resData: any): actions.Report006SuccessAction {
  return {
    type: actions.REPORT_006_SUCCESS,
    resData,
  };
}

export function report006Error(
  error: Error | string,
): actions.Report006ErrorAction {
  return {
    type: actions.REPORT_006_ERROR,
    error,
  };
}

// TODO: lịch sử khớp lệnh
export function report007Request(data: any): actions.Report007RequestingAction {
  return {
    type: actions.REPORT_007_REQUESTING,
    data,
  };
}

export function report007Success(resData: any): actions.Report007SuccessAction {
  return {
    type: actions.REPORT_007_SUCCESS,
    resData,
  };
}

export function report007Error(
  error: Error | string,
): actions.Report007ErrorAction {
  return {
    type: actions.REPORT_007_ERROR,
    error,
  };
}

// TODO: báo cáo phí hợp tác
export function report008Request(data: any): actions.Report008RequestingAction {
  return {
    type: actions.REPORT_008_REQUESTING,
    data,
  };
}

export function report008Success(resData: any): actions.Report008SuccessAction {
  return {
    type: actions.REPORT_008_SUCCESS,
    resData,
  };
}

export function report008Error(
  error: Error | string,
): actions.Report008ErrorAction {
  return {
    type: actions.REPORT_008_ERROR,
    error,
  };
}

// TODO: báo cáo điện tương tác
export function report009Request(data: any): actions.Report009RequestingAction {
  return {
    type: actions.REPORT_009_REQUESTING,
    data,
  };
}

export function report009Success(resData: any): actions.Report009SuccessAction {
  return {
    type: actions.REPORT_009_SUCCESS,
    resData,
  };
}

export function report009Error(
  error: Error | string,
): actions.Report009ErrorAction {
  return {
    type: actions.REPORT_009_ERROR,
    error,
  };
}

// TODO: báo cáo phí phụ thu
export function report010Request(data: any): actions.Report010RequestingAction {
  return {
    type: actions.REPORT_010_REQUESTING,
    data,
  };
}

export function report010Success(resData: any): actions.Report010SuccessAction {
  return {
    type: actions.REPORT_010_SUCCESS,
    resData,
  };
}

export function report010Error(
  error: Error | string,
): actions.Report010ErrorAction {
  return {
    type: actions.REPORT_010_ERROR,
    error,
  };
}

// TODO: báo cáo thống kê tài sản
export function report011Request(data: any): actions.Report011RequestingAction {
  return {
    type: actions.REPORT_011_REQUESTING,
    data,
  };
}

export function report011Success(resData: any): actions.Report011SuccessAction {
  return {
    type: actions.REPORT_011_SUCCESS,
    resData,
  };
}

export function report011Error(
  error: Error | string,
): actions.Report011ErrorAction {
  return {
    type: actions.REPORT_011_ERROR,
    error,
  };
}

// TODO: báo cáo thống kê doanh số
export function report012Request(data: any): actions.Report012RequestingAction {
  return {
    type: actions.REPORT_012_REQUESTING,
    data,
  };
}

export function report012Success(resData: any): actions.Report012SuccessAction {
  return {
    type: actions.REPORT_012_SUCCESS,
    resData,
  };
}

export function report012Error(
  error: Error | string,
): actions.Report012ErrorAction {
  return {
    type: actions.REPORT_012_ERROR,
    error,
  };
}

// TODO: báo cáo sao kê tiền
export function reportCashRequest(
  data: any,
): actions.ReportCashRequestingAction {
  return {
    type: actions.REPORT_CASH_REQUESTING,
    data,
  };
}

export function reportCashSuccess(
  resData: any,
): actions.ReportCashSuccessAction {
  return {
    type: actions.REPORT_CASH_SUCCESS,
    resData,
  };
}

export function reportCashError(
  error: Error | string,
): actions.ReportCashErrorAction {
  return {
    type: actions.REPORT_CASH_ERROR,
    error,
  };
}

// TODO: báo cáo sao kê chứng khoán
export function reportStockRequest(
  data: any,
): actions.ReportStockRequestingAction {
  return {
    type: actions.REPORT_STOCK_REQUESTING,
    data,
  };
}

export function reportStockSuccess(
  resData: any,
): actions.ReportStockSuccessAction {
  return {
    type: actions.REPORT_STOCK_SUCCESS,
    resData,
  };
}

export function reportStockError(
  error: Error | string,
): actions.ReportStockErrorAction {
  return {
    type: actions.REPORT_STOCK_ERROR,
    error,
  };
}

// TODO: tra cứu nợ --Báo cáo dư nợ
export function reportBalDebtRequest(
  data: any,
): actions.ReportBalDebtRequestingAction {
  return {
    type: actions.REPORT_BALANCE_DEBT_REQUESTING,
    data,
  };
}

export function reportBalDebtSuccess(
  resData: any,
): actions.ReportBalDebtSuccessAction {
  return {
    type: actions.REPORT_BALANCE_DEBT_SUCCESS,
    resData,
  };
}

export function reportBalDebtError(
  error: Error | string,
): actions.ReportBalDebtErrorAction {
  return {
    type: actions.REPORT_BALANCE_DEBT_ERROR,
    error,
  };
}

// TODO: tra cứu nợ --Báo cáo ứng trước
export function reportAdvRequest(data: any): actions.ReportAdvRequestingAction {
  return {
    type: actions.REPORT_ADVANCE_REQUESTING,
    data,
  };
}

export function reportAdvSuccess(resData: any): actions.ReportAdvSuccessAction {
  return {
    type: actions.REPORT_ADVANCE_SUCCESS,
    resData,
  };
}

export function reportAdvError(
  error: Error | string,
): actions.ReportAdvErrorAction {
  return {
    type: actions.REPORT_ADVANCE_ERROR,
    error,
  };
}

// TODO: tra cứu nợ --Sao kê nợ
export function reportDebtRequest(
  data: any,
): actions.ReportDebtRequestingAction {
  return {
    type: actions.REPORT_DEBT_REQUESTING,
    data,
  };
}

export function reportDebtSuccess(
  resData: any,
): actions.ReportDebtSuccessAction {
  return {
    type: actions.REPORT_DEBT_SUCCESS,
    resData,
  };
}

export function reportDebtError(
  error: Error | string,
): actions.ReportDebtErrorAction {
  return {
    type: actions.REPORT_DEBT_ERROR,
    error,
  };
}
// TODO: lịch sử gdcp
export function reportStockTradHisRequest(
  data: any,
): actions.ReportStockTradHisRequestingAction {
  return {
    type: actions.REPORT_STOCK_TRAD_HIS_REQUESTING,
    data,
  };
}

export function reportStockTradHisSuccess(
  resData: any,
): actions.ReportStockTradHisSuccessAction {
  return {
    type: actions.REPORT_STOCK_TRAD_HIS_SUCCESS,
    resData,
  };
}

export function reportStockTradHisError(
  error: Error | string,
): actions.ReportStockTradHisErrorAction {
  return {
    type: actions.REPORT_STOCK_TRAD_HIS_ERROR,
    error,
  };
}
// TODO: Hợp đồng nguồn
export function reportLoanContRequest(
  data: any,
): actions.ReportLoanContRequestingAction {
  return {
    type: actions.REPORT_LOAN_CONTRACT_REQUESTING,
    data,
  };
}

export function reportLoanContSuccess(
  resData: any,
): actions.ReportLoanContSuccessAction {
  return {
    type: actions.REPORT_LOAN_CONTRACT_SUCCESS,
    resData,
  };
}

export function reportLoanContError(
  error: Error | string,
): actions.ReportLoanContErrorAction {
  return {
    type: actions.REPORT_LOAN_CONTRACT_ERROR,
    error,
  };
}
// TODO: Loại giao dịch
export function reportCashBusRequest(
  data: any,
): actions.ReportCashBusRequestingAction {
  return {
    type: actions.REPORT_CASH_BUSINESS_REQUESTING,
    data,
  };
}

export function reportCashBusSuccess(
  resData: any,
): actions.ReportCashBusSuccessAction {
  return {
    type: actions.REPORT_CASH_BUSINESS_SUCCESS,
    resData,
  };
}

export function reportCashBusError(
  error: Error | string,
): actions.ReportCashBusErrorAction {
  return {
    type: actions.REPORT_CASH_BUSINESS_ERROR,
    error,
  };
}

// TODO: Các khoản nợ
export function reportDebtsRequest(
  data: any,
): actions.ReportDebtsRequestingAction {
  return {
    type: actions.REPORT_DEBTS_REQUESTING,
    data,
  };
}

export function reportDebtsSuccess(
  resData: any,
): actions.ReportDebtsSuccessAction {
  return {
    type: actions.REPORT_DEBTS_SUCCESS,
    resData,
  };
}

export function reportDebtsError(
  error: Error | string,
): actions.ReportDebtsErrorAction {
  return {
    type: actions.REPORT_DEBTS_ERROR,
    error,
  };
}

// TODO: Lịch sử trả nợ
export function reportDebtsHisRequest(
  data: any,
): actions.ReportDebtsHisRequestingAction {
  return {
    type: actions.REPORT_DEBTS_HIS_REQUESTING,
    data,
  };
}

export function reportDebtsHisSuccess(
  resData: any,
): actions.ReportDebtsHisSuccessAction {
  return {
    type: actions.REPORT_DEBTS_HIS_SUCCESS,
    resData,
  };
}

export function reportDebtsHisError(
  error: Error | string,
): actions.ReportDebtsHisErrorAction {
  return {
    type: actions.REPORT_DEBTS_HIS_ERROR,
    error,
  };
}
// TODO: Chi tiết lịch sử
export function reportDebtsHisDetailRequest(
  data: any,
): actions.ReportDebtsHisDetailRequestingAction {
  return {
    type: actions.REPORT_DEBTS_HIS_DETAIL_REQUESTING,
    data,
  };
}

export function reportDebtsHisDetailSuccess(
  resData: any,
): actions.ReportDebtsHisDetailSuccessAction {
  return {
    type: actions.REPORT_DEBTS_HIS_DETAIL_SUCCESS,
    resData,
  };
}

export function reportDebtsHisDetailError(
  error: Error | string,
): actions.ReportDebtsHisDetailErrorAction {
  return {
    type: actions.REPORT_DEBTS_HIS_DETAIL_ERROR,
    error,
  };
}

// export bao cao
export function exportGlobalRequest(
  data: any,
): actions.ExportGlobalRequestingAction {
  return {
    type: actions.EXPORT_GLOBAL_REQUESTING,
    data,
  };
}

export function exportGlobalSuccess(
  resData: any,
): actions.ExportGlobalSuccessAction {
  return {
    type: actions.EXPORT_GLOBAL_SUCCESS,
    resData,
  };
}

export function exportGlobalError(
  error: Error | string,
): actions.ExportGlobalErrorAction {
  return {
    type: actions.EXPORT_GLOBAL_ERROR,
    error,
  };
}
