import DefaultLayout from 'containers/defaultLayout';
import DefaultAuthentication from 'containers/defaultLayout/DefaultAuthentication';
import React from 'react';
import { Redirect, Route, HashRouter as Router } from 'react-router-dom';
import Loading from 'shared/loading';
import { replaceAll } from 'utils';
interface Props {
  store?: any;
}

const App: React.FunctionComponent<Props> = (props) => {
  const { store } = props;
  const path = replaceAll(window.location.hash, '#', '').replace('/.', '');

  const mode = localStorage.getItem('mode') || 'STANDARD';

  const _newPath =
    !path || path === '/' ? (!mode ? '/dashboard' : '/price') : path;

  console.log('_newPath ===> ', _newPath);

  return (
    <Router>
      <React.Suspense fallback={<Loading />}>
        <Route
          path={['/forgot']}
          component={() => <DefaultAuthentication store={store} />}
        />
        <Route
          path={[
            '/price',
            '/account',
            '/analysis',
            '/trading',
            '/search',
            '/ho-tro',
            '/khao-sat',
            '/extension',
            '/asset',
            '/invest',
            '/cash',
            '/sms',
            '/help',
            '/contact',
          ]}
          component={() => <DefaultLayout store={store} />}
        />
        <Redirect from="/" to={_newPath} />
      </React.Suspense>
    </Router>
  );
};

export default App;
