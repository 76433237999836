import * as actions from './actionType';

export interface AnalState {
  analStReal: any;
  analStMatchPrice: any;
  analStTopMatch: any;
  analStMBActive: any;
  analBasicInfo: any;

  analIndReal: any;
  analNNInday: any;
  analNN10day: any;
  analPBDongTien: any;
  analLichSuGia: any;
  analFSRatio: any;
  analRankFSRatio: any;
  analSecOverview: any;

  analComIntroduction: any;
  analComBasic: any;
  analComSameInd: any;
  analComLeader: any;
  analComHolder: any;
  analComEvents: any;
  analComBigTrans: any;
  analComRelated: any;

  analFilterRange: any;
  analFilterAct: any;
  analFilterActLoading: boolean;
  analUserFilter: any;

  analUserSignal: any;
  secListAll: any;
  analDelFilter: any;
}

const initialState = {
  analStReal: null,
  analStMatchPrice: null,
  analStTopMatch: null,
  analStMBActive: null,
  analBasicInfo: null,

  analIndReal: null,
  analNNInday: null,
  analNN10day: null,
  analPBDongTien: null,
  analLichSuGia: null,
  analFSRatio: null,
  analRankFSRatio: null,
  analSecOverview: null,

  analComIntroduction: null,
  analComBasic: null,
  analComSameInd: null,
  analComLeader: null,
  analComHolder: null,
  analComEvents: null,
  analComBigTrans: null,
  analComRelated: null,

  analFilterRange: null,
  analFilterAct: null,
  analFilterActLoading: false,
  analUserFilter: null,

  analUserSignal: null,
  secListAll: null,
  analDelFilter: null,
};

export default function AnalReducer(
  state: AnalState = initialState,
  action: actions.AnalAction,
): AnalState {
  switch (action.type) {
    // case ANAL_STOCK_REAL_REQUESTING:
    case actions.ANAL_STOCK_REAL_ERROR:
      return {
        ...state,
        analStReal: null,
      };

    case actions.ANAL_STOCK_REAL_SUCCESS:
      return {
        ...state,
        analStReal: action.resData,
      };

    case actions.ANAL_SEC_LIST_ALL_ERROR:
      return {
        ...state,
        secListAll: null,
      };

    case actions.ANAL_SEC_LIST_ALL_SUCCESS:
      return {
        ...state,
        secListAll: action.resData,
      };

    // case ANAL_STOCK_MATCH_PRICE_REQUESTING:
    case actions.ANAL_STOCK_MATCH_PRICE_ERROR:
      return {
        ...state,
        analStMatchPrice: null,
      };

    case actions.ANAL_STOCK_MATCH_PRICE_SUCCESS:
      return {
        ...state,
        analStMatchPrice: action.resData,
      };

    case actions.ANAL_STOCK_TOP_MATCH_ERROR:
      return {
        ...state,
        analStTopMatch: null,
      };

    case actions.ANAL_STOCK_TOP_MATCH_SUCCESS:
      return {
        ...state,
        analStTopMatch: action.resData,
      };

    // case ANAL_STOCK_MB_ACTIVE_REQUESTING:
    case actions.ANAL_STOCK_MB_ACTIVE_ERROR:
      return {
        ...state,
        analStMBActive: null,
      };

    case actions.ANAL_STOCK_MB_ACTIVE_SUCCESS:
      return {
        ...state,
        analStMBActive: action.resData,
      };

    case actions.ANAL_BASIC_INFO_ERROR:
      return {
        ...state,
        analBasicInfo: null,
      };

    case actions.ANAL_BASIC_INFO_SUCCESS:
      return {
        ...state,
        analBasicInfo: action.resData,
      };

    // case ANAL_INDEX_REAL_REQUESTING:
    case actions.ANAL_INDEX_REAL_ERROR:
      return {
        ...state,
        analIndReal: null,
      };

    case actions.ANAL_INDEX_REAL_SUCCESS:
      return {
        ...state,
        analIndReal: action.resData,
      };

    // case ANAL_NN_INDAY_REQUESTING:
    case actions.ANAL_NN_INDAY_ERROR:
      return {
        ...state,
        analNNInday: null,
      };

    case actions.ANAL_NN_INDAY_SUCCESS:
      return {
        ...state,
        analNNInday: action.resData,
      };

    // case ANAL_NN_10DAY_REQUESTING:
    case actions.ANAL_NN_10DAY_ERROR:
      return {
        ...state,
        analNN10day: null,
      };

    case actions.ANAL_NN_10DAY_SUCCESS:
      return {
        ...state,
        analNN10day: action.resData,
      };

    // case ANAL_PB_DONG_TIEN_REQUESTING:
    case actions.ANAL_PB_DONG_TIEN_ERROR:
      return {
        ...state,
        analPBDongTien: null,
      };

    case actions.ANAL_PB_DONG_TIEN_SUCCESS:
      return {
        ...state,
        analPBDongTien: action.resData,
      };

    // case ANAL_LICH_SU_GIA_REQUESTING:
    case actions.ANAL_LICH_SU_GIA_ERROR:
      return {
        ...state,
        analLichSuGia: null,
      };

    case actions.ANAL_LICH_SU_GIA_SUCCESS:
      return {
        ...state,
        analLichSuGia: action.resData,
      };

    // case ANAL_FS_RATIO_REQUESTING:
    case actions.ANAL_FS_RATIO_ERROR:
      return {
        ...state,
        analFSRatio: null,
      };

    case actions.ANAL_FS_RATIO_SUCCESS:
      return {
        ...state,
        analFSRatio: action.resData,
      };

    // case ANAL_RANK_FS_RATIO_REQUESTING:
    case actions.ANAL_RANK_FS_RATIO_ERROR:
      return {
        ...state,
        analRankFSRatio: null,
      };

    case actions.ANAL_RANK_FS_RATIO_SUCCESS:
      return {
        ...state,
        analRankFSRatio: action.resData,
      };

    // case ANAL_SEC_OVERVIEW_REQUESTING:
    case actions.ANAL_SEC_OVERVIEW_ERROR:
      return {
        ...state,
        analSecOverview: null,
      };

    case actions.ANAL_SEC_OVERVIEW_SUCCESS:
      return {
        ...state,
        analSecOverview: action.resData,
      };

    // case ANAL_COM_INTRO_REQUESTING:
    case actions.ANAL_COM_INTRO_ERROR:
      return {
        ...state,
        analComIntroduction: null,
      };

    case actions.ANAL_COM_INTRO_SUCCESS:
      return {
        ...state,
        analComIntroduction: action.resData,
      };

    // case ANAL_COM_BASIC_REQUESTING:
    case actions.ANAL_COM_BASIC_ERROR:
      return {
        ...state,
        analComBasic: null,
      };

    case actions.ANAL_COM_BASIC_SUCCESS:
      return {
        ...state,
        analComBasic: action.resData,
      };

    // case ANAL_COM_SAME_IND_REQUESTING:
    case actions.ANAL_COM_SAME_IND_ERROR:
      return {
        ...state,
        analComSameInd: null,
      };

    case actions.ANAL_COM_SAME_IND_SUCCESS:
      return {
        ...state,
        analComSameInd: action.resData,
      };

    // case ANAL_COM_LEADER_REQUESTING:
    case actions.ANAL_COM_LEADER_ERROR:
      return {
        ...state,
        analComLeader: null,
      };

    case actions.ANAL_COM_LEADER_SUCCESS:
      return {
        ...state,
        analComLeader: action.resData,
      };

    // case ANAL_COM_HOLDER_REQUESTING:
    case actions.ANAL_COM_HOLDER_ERROR:
      return {
        ...state,
        analComHolder: null,
      };

    case actions.ANAL_COM_HOLDER_SUCCESS:
      return {
        ...state,
        analComHolder: action.resData,
      };

    // case ANAL_COM_EVENTS_REQUESTING:
    case actions.ANAL_COM_EVENTS_ERROR:
      return {
        ...state,
        analComEvents: null,
      };

    case actions.ANAL_COM_EVENTS_SUCCESS:
      return {
        ...state,
        analComEvents: action.resData,
      };

    // case ANAL_COM_BIGTRANS_REQUESTING:
    case actions.ANAL_COM_BIGTRANS_ERROR:
      return {
        ...state,
        analComBigTrans: null,
      };

    case actions.ANAL_COM_BIGTRANS_SUCCESS:
      return {
        ...state,
        analComBigTrans: action.resData,
      };

    // case ANAL_COM_RELATED_REQUESTING:
    case actions.ANAL_COM_RELATED_ERROR:
      return {
        ...state,
        analComRelated: null,
      };

    case actions.ANAL_COM_RELATED_SUCCESS:
      return {
        ...state,
        analComRelated: action.resData,
      };

    // case ANAL_FILTER_RANGE_REQUESTING:
    case actions.ANAL_FILTER_RANGE_ERROR:
      return {
        ...state,
        analFilterRange: null,
      };

    case actions.ANAL_FILTER_RANGE_SUCCESS:
      return {
        ...state,
        analFilterRange: action.resData,
      };

    case actions.ANAL_FILTER_ACT_REQUESTING:
      return {
        ...state,
        analFilterAct: null,
        analFilterActLoading: true,
      };

    case actions.ANAL_FILTER_ACT_ERROR:
      return {
        ...state,
        analFilterAct: null,
        analFilterActLoading: false,
      };

    case actions.ANAL_FILTER_ACT_SUCCESS:
      return {
        ...state,
        analFilterAct: action.resData,
        analFilterActLoading: false,
      };

    // case ANAL_USER_FILTER_REQUESTING:
    case actions.ANAL_USER_FILTER_ERROR:
      return {
        ...state,
        analUserFilter: null,
      };

    case actions.ANAL_USER_FILTER_SUCCESS:
      return {
        ...state,
        analUserFilter: action.resData,
      };

    case actions.ANAL_USER_SIGNAL_REQUESTING:
    case actions.ANAL_USER_SIGNAL_ERROR:
      return {
        ...state,
        analUserSignal: null,
      };

    case actions.ANAL_USER_SIGNAL_SUCCESS:
      return {
        ...state,
        analUserSignal: action.resData,
      };

    case actions.ANAL_DEL_FILTER_REQUESTING:
    case actions.ANAL_DEL_FILTER_ERROR:
      return {
        ...state,
        analDelFilter: null,
      };

    case actions.ANAL_DEL_FILTER_SUCCESS:
      return {
        ...state,
        analDelFilter: action.resData,
      };

    default:
      return state;
  }
}
