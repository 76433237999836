import * as actions from './actionType';

export function startSocketChanel() {
  return {
    type: actions.START_CHANNEL,
  };
}

export function socketReady() {
  return {
    type: actions.SOCKET_READY,
  };
}

export function serverOn() {
  return {
    type: actions.SERVER_ON,
  };
}

export function serverOff() {
  return {
    type: actions.SERVER_OFF,
  };
}

export function stopSocketChanel() {
  return {
    type: actions.STOP_CHANNEL,
  };
}

export function setRegSymbol(symbol: string) {
  return {
    type: actions.SET_REG_SYMBOL,
    symbol,
  };
}

export function unsetRegSymbol() {
  return {
    type: actions.UNSET_REG_SYMBOL,
  };
}

export function regisSocketData(params: any) {
  return {
    type: actions.REGISTER_SOCKET_DATA,
    params,
  };
}

export function leaveSocketData(params: any) {
  return {
    type: actions.LEAVE_SOCKET_DATA,
    params,
  };
}

export function receiveReregister(resData: any) {
  return {
    type: actions.RE_REGISTER,
    resData,
  };
}

export function receive1101(resData: any) {
  return {
    type: actions.RECEIVE_1101,
    resData,
  };
}

// export function receive3211(resData: any) {
//   return {
//     type: RECEIVE_3211,
//     resData,
//   };
// }

export function receive3220(resData: any) {
  return {
    type: actions.RECEIVE_3220,
    resData,
  };
}

export function receive3210(resData: any) {
  return {
    type: actions.RECEIVE_3210,
    resData,
  };
}
