import * as actions from './actionType';

/** get list user */
export function userSearchRequest(
  data: any,
): actions.UserSearchRequestingAction {
  return {
    type: actions.USER_SEARCH_REQUESTING,
    data,
  };
}

export function userSearchSuccess(
  resData: any,
): actions.UserSearchSuccessAction {
  return {
    type: actions.USER_SEARCH_SUCCESS,
    resData,
  };
}

export function userSearchError(
  error: Error | string,
): actions.UserSearchErrorAction {
  return {
    type: actions.USER_SEARCH_ERROR,
    error,
  };
}

/** detail user */
export function userDetailRequest(
  data: any,
): actions.UserDetailRequestingAction {
  return {
    type: actions.USER_DETAIL_REQUESTING,
    data,
  };
}

export function userDetailSuccess(
  resData: any,
): actions.UserDetailSuccessAction {
  return {
    type: actions.USER_DETAIL_SUCCESS,
    resData,
  };
}

export function userDetailError(
  error: Error | string,
): actions.UserDetailErrorAction {
  return {
    type: actions.USER_DETAIL_ERROR,
    error,
  };
}

/** update user */
export function userUpdRequest(data: any): actions.UserUpdRequestingAction {
  return {
    type: actions.USER_UPD_REQUESTING,
    data,
  };
}

export function userUpdSuccess(resData: any): actions.UserUpdSuccessAction {
  return {
    type: actions.USER_UPD_SUCCESS,
    resData,
  };
}

export function userUpdError(
  error: Error | string,
): actions.UserUpdErrorAction {
  return {
    type: actions.USER_UPD_ERROR,
    error,
  };
}

/** delete user */
export function userDelRequest(data: any): actions.UserDelRequestingAction {
  return {
    type: actions.USER_DEL_REQUESTING,
    data,
  };
}

export function userDelSuccess(resData: any): actions.UserDelSuccessAction {
  return {
    type: actions.USER_DEL_SUCCESS,
    resData,
  };
}

export function userDelError(
  error: Error | string,
): actions.UserDelErrorAction {
  return {
    type: actions.USER_DEL_ERROR,
    error,
  };
}

/** get list quyền xem cập nhập dl / xem báo cáo */

export function userRightByAccRequest(
  data: any,
): actions.UserRightByAccRequestingAction {
  return {
    type: actions.USER_RIGHT_BY_ACC_REQUESTING,
    data,
  };
}

export function userRightByAccSuccess(
  resData: any,
): actions.UserRightByAccSuccessAction {
  return {
    type: actions.USER_RIGHT_BY_ACC_SUCCESS,
    resData,
  };
}

export function userRightByAccError(
  error: Error | string,
): actions.UserRightByAccErrorAction {
  return {
    type: actions.USER_RIGHT_BY_ACC_ERROR,
    error,
  };
}

/** đăng ký tài khoản */
export function userRegisterRequest(
  data: any,
): actions.UserRegisterRequestingAction {
  return {
    type: actions.USER_REGISTER_REQUESTING,
    data,
  };
}

export function userRegisterSuccess(
  resData: any,
): actions.UserRegisterSuccessAction {
  return {
    type: actions.USER_REGISTER_SUCCESS,
    resData,
  };
}

export function userRegisterError(
  error: Error | string,
): actions.UserRegisterErrorAction {
  return {
    type: actions.USER_REGISTER_ERROR,
    error,
  };
}

/** check thông tin tài khoản */
export function userRegisCheckRequest(
  data: any,
): actions.UserRegisCheckRequestingAction {
  return {
    type: actions.USER_REGIS_CHECK_REQUESTING,
    data,
  };
}

export function userRegisCheckSuccess(
  resData: any,
): actions.UserRegisCheckSuccessAction {
  return {
    type: actions.USER_REGIS_CHECK_SUCCESS,
    resData,
  };
}

export function userRegisCheckError(
  error: Error | string,
): actions.UserRegisCheckErrorAction {
  return {
    type: actions.USER_REGIS_CHECK_ERROR,
    error,
  };
}

/** chi tiết tài sản */
export function userAssetDetailRequest(
  data: any,
): actions.UserAssetDetailRequestingAction {
  return {
    type: actions.USER_ASSET_DETAIL_REQUESTING,
    data,
  };
}

export function userAssetDetailSuccess(
  resData: any,
): actions.UserAssetDetailSuccessAction {
  return {
    type: actions.USER_ASSET_DETAIL_SUCCESS,
    resData,
  };
}

export function userAssetDetailError(
  error: Error | string,
): actions.UserAssetDetailErrorAction {
  return {
    type: actions.USER_ASSET_DETAIL_ERROR,
    error,
  };
}

/** chi tiết tài sản */
export function userProfitCloseRequest(
  data: any,
): actions.UserProfitCloseRequestingAction {
  return {
    type: actions.USER_PROFIT_CLOSE_REQUESTING,
    data,
  };
}

export function userProfitCloseSuccess(
  resData: any,
): actions.UserProfitCloseSuccessAction {
  return {
    type: actions.USER_PROFIT_CLOSE_SUCCESS,
    resData,
  };
}

export function userProfitCloseError(
  error: Error | string,
): actions.UserProfitCloseErrorAction {
  return {
    type: actions.USER_PROFIT_CLOSE_ERROR,
    error,
  };
}

/** Biến động tài sản */
export function userAssetChartRequest(
  data: any,
): actions.UserAssetChartRequestingAction {
  return {
    type: actions.USER_ASSET_CHART_REQUESTING,
    data,
  };
}

export function userAssetChartSuccess(
  resData: any,
): actions.UserAssetChartSuccessAction {
  return {
    type: actions.USER_ASSET_CHART_SUCCESS,
    resData,
  };
}

export function userAssetChartError(
  error: Error | string,
): actions.UserAssetChartErrorAction {
  return {
    type: actions.USER_ASSET_CHART_ERROR,
    error,
  };
}

/** lợi nhuận theo mã */
export function userProfitSymbolRequest(
  data: any,
): actions.UserProfitSymbolRequestingAction {
  return {
    type: actions.USER_PROFIT_SYMBOL_REQUESTING,
    data,
  };
}

export function userProfitSymbolSuccess(
  resData: any,
): actions.UserProfitSymbolSuccessAction {
  return {
    type: actions.USER_PROFIT_SYMBOL_SUCCESS,
    resData,
  };
}

export function userProfitSymbolError(
  error: Error | string,
): actions.UserProfitSymbolErrorAction {
  return {
    type: actions.USER_PROFIT_SYMBOL_ERROR,
    error,
  };
}
