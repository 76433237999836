import React from 'react';

const WindowContext = React.createContext<any>(null);

export { WindowContext };

export const WindowContextProvider: React.FC<any> = ({ children }) => {
  const [windowIsActive, setWindowIsActive] = React.useState<boolean>(true);

  function handleActivity(forcedFlag: any) {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false);
    }

    return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
  }

  React.useEffect(() => {
    const handleActivityFalse = () => handleActivity(false);
    const handleActivityTrue = () => handleActivity(true);

    document.addEventListener('visibilitychange', handleActivity);
    document.addEventListener('blur', handleActivityFalse);
    window.addEventListener('blur', handleActivityFalse);
    window.addEventListener('focus', handleActivityTrue);
    document.addEventListener('focus', handleActivityTrue);

    return () => {
      window.removeEventListener('blur', handleActivity);
      document.removeEventListener('blur', handleActivityFalse);
      window.removeEventListener('focus', handleActivityFalse);
      document.removeEventListener('focus', handleActivityTrue);
      document.removeEventListener('visibilitychange', handleActivity);

      setWindowIsActive(false);
    };
  }, []);

  return (
    <WindowContext.Provider value={{ windowIsActive }}>
      {children}
    </WindowContext.Provider>
  );
};
