import * as actions from './actionType';
export function rightUnExecRequest(
  params: any,
): actions.RightUnExecRequestAction {
  return {
    type: actions.RIGHT_UN_EXEC_REQUESTING,
    params,
  };
}

export function rightUnExecRequestSuccess(
  rightUnExec: any,
): actions.RightUnExecRequestSuccessAction {
  return {
    type: actions.RIGHT_UN_EXEC_REQUEST_SUCCESS,
    rightUnExec,
  };
}

export function rightUnExecRequestError(
  error: any,
): actions.RightUnExecRequestErrorAction {
  return {
    type: actions.RIGHT_UN_EXEC_REQUEST_ERROR,
    error,
  };
}

export function updateUnExecRequest(
  params: any,
): actions.UpdateUnExecRequestAction {
  return {
    type: actions.UPDATE_UN_EXEC_REQUESTING,
    params,
  };
}

export function updateUnExecRequestSuccess(
  updUnExec: any,
): actions.UpdateUnExecRequestSuccessAction {
  return {
    type: actions.UPDATE_UN_EXEC_REQUEST_SUCCESS,
    updUnExec,
  };
}

export function updateUnExecRequestError(
  error: any,
): actions.UpdateUnExecRequestErrorAction {
  return {
    type: actions.UPDATE_UN_EXEC_REQUEST_ERROR,
    error,
  };
}

export function listRightBuyRequest(
  params: any,
): actions.ListRightBuyRequestAction {
  return {
    type: actions.RIGHT_BUY_STT_REQUESTING,
    params,
  };
}

export const listRightBuyRequestSuccess = function listRightBuyRequestSuccess(
  listRightBuy: any,
): actions.ListRightBuyRequestSuccessAction {
  return {
    type: actions.RIGHT_BUY_STT_REQUEST_SUCCESS,
    listRightBuy,
  };
};

export const listRightBuyRequestError = function listRightBuyRequestError(
  error: any,
): actions.ListRightBuyRequestErrorAction {
  return {
    type: actions.RIGHT_BUY_STT_REQUEST_ERROR,
    error,
  };
};

export function rightBuyHistoryRequest(
  params: any,
): actions.RightBuyHistoryRequestAction {
  return {
    type: actions.RIGHT_BUY_HIS_REQUESTING,
    params,
  };
}

export function rightBuyHistoryRequestSuccess(
  rightBuyHis: any,
): actions.RightBuyHistoryRequestSuccessAction {
  return {
    type: actions.RIGHT_BUY_HIS_REQUEST_SUCCESS,
    rightBuyHis,
  };
}

export function rightBuyHistoryRequestError(
  error: any,
): actions.RightBuyHistoryRequestErrorAction {
  return {
    type: actions.RIGHT_BUY_HIS_REQUEST_ERROR,
    error,
  };
}

export function rightAllStatusRequest(
  params: any,
): actions.RightAllStatusRequestAction {
  return {
    type: actions.RIGHT_ALL_STATUS_REQUESTING,
    params,
  };
}

export function rightAllStatusSuccess(
  resData: any,
): actions.RightAllStatusSuccessAction {
  return {
    type: actions.RIGHT_ALL_STATUS_SUCCESS,
    resData,
  };
}

export function rightAllStatusError(
  error: any,
): actions.RightAllStatusErrorAction {
  return {
    type: actions.RIGHT_ALL_STATUS_ERROR,
    error,
  };
}

export function rightAllTypeRequest(
  params: any,
): actions.RightAllTypeRequestAction {
  return {
    type: actions.RIGHT_ALL_TYPE_REQUESTING,
    params,
  };
}

export function rightAllTypeSuccess(
  resData: any,
): actions.RightAllTypeSuccessAction {
  return {
    type: actions.RIGHT_ALL_TYPE_SUCCESS,
    resData,
  };
}

export function rightAllTypeError(error: any): actions.RightAllTypeErrorAction {
  return {
    type: actions.RIGHT_ALL_TYPE_ERROR,
    error,
  };
}
