import { TFunction } from 'i18next';
import React from 'react';

function FooterAuthentication(props: { t: TFunction }) {
  const { t } = props;
  return (
    <div className="h-[44px] flex justify-between items-center text-skin-subdued text-sm bg-skin-body !z-999">
      <div className="flex items-center">
        <span>{t('foot.contact')}</span>
        <span className="h-3 w-[1px] border-l border-skin-weak mx-6" />
        <span>{t('foot.help')}</span>
      </div>
      <div className="flex items-center">
        <div>© UPS Securities 2024</div>
        <span className="h-3 w-[1px] border-l border-skin-weak mx-1" />
        <div>{t('foot.version')} 1.2.2</div>
      </div>
      <div className="flex">
        <span className="mr-6">
          Hotline: <span className="text-skin-base">(+84.4) 3 944 6666</span>
        </span>
        <span>
          {t('foot.email-support')}:{' '}
          <span className="text-skin-base">cskh@upstock.com.vn</span>
        </span>
      </div>
    </div>
  );
}

export default React.memo(FooterAuthentication);
