/** cash */
export const CASH_TRANSFER_IN = 'cash/TRANSFER_IN';
export interface CashTransferInAction {
  type: typeof CASH_TRANSFER_IN;
}

export const CASH_TRANSFER_IN_REQUESTING = 'cash/TRANSFER_IN_REQUESTING';
export interface CashTransferInRequestingAction {
  type: typeof CASH_TRANSFER_IN_REQUESTING;
  data: any;
}

export const CASH_TRANSFER_IN_SUCCESS = 'cash/TRANSFER_IN_SUCCESS';
export interface CashTransferInSuccessAction {
  type: typeof CASH_TRANSFER_IN_SUCCESS;
  resData: any;
}

export const CASH_TRANSFER_IN_ERROR = 'cash/TRANSFER_IN_ERROR';
export interface CashTransferInErrorAction {
  type: typeof CASH_TRANSFER_IN_ERROR;
  error: any;
}

export const CASH_TRANSFER_OUT = 'cash/TRANSFER_OUT';
export interface CashTransferOutAction {
  type: typeof CASH_TRANSFER_OUT;
}

export const CASH_TRANSFER_OUT_REQUESTING = 'cash/TRANSFER_OUT_REQUESTING';
export interface CashTransferOutRequestingAction {
  type: typeof CASH_TRANSFER_OUT_REQUESTING;
  data: any;
}

export const CASH_TRANSFER_OUT_SUCCESS = 'cash/TRANSFER_OUT_SUCCESS';
export interface CashTransferOutSuccessAction {
  type: typeof CASH_TRANSFER_OUT_SUCCESS;
  resData: any;
}

export const CASH_TRANSFER_OUT_ERROR = 'cash/TRANSFER_OUT_ERROR';
export interface CashTransferOutErrorAction {
  type: typeof CASH_TRANSFER_OUT_ERROR;
  error: any;
}

export type CashAction =
  | CashTransferInAction
  | CashTransferInRequestingAction
  | CashTransferInSuccessAction
  | CashTransferInErrorAction
  | CashTransferOutAction
  | CashTransferOutRequestingAction
  | CashTransferOutSuccessAction
  | CashTransferOutErrorAction;
