import { NavItems } from 'interface';

export const navItems: NavItems[] = [
  {
    name: 'Tổng quan tài sản',
    labelCode: 'nav.txt-asset',
    url: '/asset',
    icon: 'asset',
    right: ['ALL'],
    group: '1',
    token: true,
  },
  {
    name: 'Hiệu suất đầu tư',
    labelCode: 'nav.txt-invest',
    url: '/invest',
    icon: 'invest',
    right: ['ALL'],
    group: '1',
    token: true,
  },
  {
    name: 'Tiền',
    labelCode: 'nav.txt-cash',
    url: '/cash',
    icon: 'cash',
    right: ['ALL'],
    group: '1',
    children: [
      {
        name: 'Nộp tiền',
        labelCode: 'nav.txt-payment',
        url: '/cash/payment',
        right: ['ALL'],
        token: true,
      },
      {
        name: 'Chuyển tiền',
        labelCode: 'nav.txt-transfer-money',
        url: '/cash/transfer',
        right: ['ALL'],
        token: true,
      },
    ],
  },
  {
    name: 'Cổ phiếu',
    labelCode: 'nav.txt-stock',
    url: '/price',
    icon: 'stock',
    right: ['ALL'],
    group: '2',
    children: [
      {
        name: 'Bảng giá cổ phiếu',
        labelCode: 'nav.txt-priceboard',
        url: '/price/bang-gia',
        right: ['ALL'],
      },
      {
        name: 'Đặt lệnh cổ phiếu',
        labelCode: 'nav.txt-order',
        url: '/price/order',
        token: true,
        right: ['ALL'],
      },
      {
        name: 'Xác nhận lệnh online',
        labelCode: 'nav.txt-confirm',
        url: '/price/confirm',
        right: ['ALL'],
      },
      {
        name: 'Danh mục đầu tư',
        labelCode: 'nav.txt-investment-portfolio',
        url: '/price/portfolio',
        right: ['ALL'],
        token: true,
      },
      {
        name: 'Quản lý nợ',
        labelCode: 'nav.txt-debt',
        url: '/price/debt',
        right: ['ALL'],
        token: true,
      },
      {
        name: 'Quyền',
        labelCode: 'nav.txt-right',
        url: '/price/right',
        right: ['ALL'],
        token: true,
      },
      // {
      //   name: 'Chuyển khoản CK',
      //   labelCode: 'nav.txt-transfer',
      //   url: '/price/transfer',
      //   right: ['ALL'],
      // },
    ],
  },
  // {
  //   name: 'Trái phiếu',
  //   labelCode: 'nav.txt-bond',
  //   url: '/bond',
  //   icon: 'bond',
  //   right: ['NoALL'],
  //   group: '2',
  // },
  // {
  //   name: 'Phái sinh',
  //   labelCode: 'nav.txt-derivative',
  //   url: '/derivative',
  //   icon: 'derivative',
  //   right: ['NoALL'],
  //   group: '2',
  // },
  // {
  //   name: 'Sản phẩm khác',
  //   labelCode: 'nav.txt-product',
  //   url: '/product',
  //   icon: 'product',
  //   right: ['NoALL'],
  //   group: '2',
  // },
  {
    name: 'Tiện ích',
    labelCode: 'nav.txt-extension',
    url: '/extension',
    icon: 'extension',
    right: ['ALL'],
    group: '3',
    children: [
      {
        name: 'Tra cứu và sao kê',
        labelCode: 'nav.lookup-statements',
        url: '/extension/lookup-statement',
        right: ['ALL'],
        token: true,
      },
      {
        name: 'Danh sách CK GDKQ',
        labelCode: 'nav.list-ck-gdkq',
        url: '/extension/list-ck-gdkq',
        right: ['ALL'],
        token: true,
      },
      // {
      //   name: 'Giới thiệu bạn bè',
      //   labelCode: 'nav.introduce-friend',
      //   url: '/extension/introduce-friend',
      //   right: ['ALL'],
      // },
      // {
      //   name: 'Điểm thưởng UPS',
      //   labelCode: 'nav.reward-points',
      //   url: '/extension/reward-points',
      //   right: ['ALL'],
      // },
    ],
  },
  // {
  //   name: 'Khám phá',
  //   labelCode: 'nav.txt-explore',
  //   url: '/explore',
  //   icon: 'explore',
  //   right: ['ALL'],
  //   group: '3',
  // },
  // {
  //   name: 'Dịch vụ SMS',
  //   labelCode: 'nav.txt-sms',
  //   url: '/sms',
  //   icon: 'sms',
  //   right: ['ALL'],
  //   group: '3',
  // },
  {
    name: 'Hướng dẫn',
    labelCode: 'nav.txt-help',
    url: '/help',
    icon: 'help',
    right: ['ALL'],
    group: '3',
  },
  // {
  //   name: 'Liên hệ',
  //   labelCode: 'nav.txt-contact',
  //   url: '/contact',
  //   icon: 'contact',
  //   right: ['ALL'],
  //   group: '3',
  // },
];
