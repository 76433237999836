import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';
import { saveAs } from 'file-saver';

import * as actions from './actionType';
import axios from 'axios';
import {
  report001Error,
  report001Success,
  report002Error,
  report002Success,
  report003Error,
  report003Success,
  report004Error,
  report004Success,
  report005Error,
  report005Success,
  report006Error,
  report006Success,
  report007Error,
  report007Success,
  report008Error,
  report008Success,
  report009Error,
  report009Success,
  report010Error,
  report010Success,
  report011Error,
  report011Success,
  report012Error,
  report012Success,
  reportCashSuccess,
  reportCashError,
  reportStockSuccess,
  reportStockError,
  reportBalDebtSuccess,
  reportBalDebtError,
  reportAdvSuccess,
  reportAdvError,
  reportDebtError,
  reportDebtSuccess,
  reportStockTradHisError,
  reportStockTradHisSuccess,
  reportLoanContError,
  reportLoanContSuccess,
  reportCashBusError,
  reportCashBusSuccess,
  reportDebtsError,
  reportDebtsSuccess,
  reportDebtsHisError,
  reportDebtsHisSuccess,
  reportDebtsHisDetailSuccess,
  reportDebtsHisDetailError,
  exportGlobalSuccess,
  exportGlobalError,
} from './actions';
import { checkInvalidSession } from 'utils';
import { storages } from 'lib/storages';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrl = `${process.env.REACT_APP_API_URL}`;

async function postRequestApi(data: Object) {
  const url = `${appUrl}/TraditionalService`;

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    // check success
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function handleRequestExport(request: any, filename: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => {
      response.blob().then((blob: any) => {
        saveAs(blob, filename + '.xlsx');
      });
    })
    .then((json: any) => json)
    .catch((error: any) => {
      throw Error(
        'Có lỗi trong quá trình thực hiện, Vui lòng kiểm tra đường truyền mạng!',
      );
    });
}

function postRequestApiExportFile(params: any) {
  const { data } = params;
  const filename = data['TEMPLATE_CODE'] || 'File Export';

  const url = `${appUrl}/BackDownload`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(params),
  });
  return handleRequestExport(request, filename);
}

function* report001RequestFlow(action: actions.Report001RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report001Success(_res.data));
  } catch (error: any) {
    yield put(report001Error(error));
  }
}

function* report002RequestFlow(action: actions.Report002RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report002Success(_res.data));
  } catch (error: any) {
    yield put(report002Error(error));
  }
}

function* report003RequestFlow(action: actions.Report003RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report003Success(_res.data));
  } catch (error: any) {
    yield put(report003Error(error));
  }
}

function* report004RequestFlow(action: actions.Report004RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report004Success(_res.data));
  } catch (error: any) {
    yield put(report004Error(error));
  }
}

function* report005RequestFlow(action: actions.Report005RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report005Success(_res.data));
  } catch (error: any) {
    yield put(report005Error(error));
  }
}

function* report006RequestFlow(action: actions.Report006RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report006Success(_res.data));
  } catch (error: any) {
    yield put(report006Error(error));
  }
}

function* report007RequestFlow(action: actions.Report007RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report007Success(_res.data));
  } catch (error: any) {
    yield put(report007Error(error));
  }
}

function* report008RequestFlow(action: actions.Report008RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report008Success(_res.data));
  } catch (error: any) {
    yield put(report008Error(error));
  }
}

function* report009RequestFlow(action: actions.Report009RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report009Success(_res.data));
  } catch (error: any) {
    yield put(report009Error(error));
  }
}

function* report010RequestFlow(action: actions.Report010RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report010Success(_res.data));
  } catch (error: any) {
    yield put(report010Error(error));
  }
}

function* report011RequestFlow(action: actions.Report011RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report011Success(_res.data));
  } catch (error: any) {
    yield put(report011Error(error));
  }
}

function* report012RequestFlow(action: actions.Report012RequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(report012Success(_res.data));
  } catch (error: any) {
    yield put(report012Error(error));
  }
}

function* reportCashRequestFlow(
  action: actions.ReportCashRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportCashSuccess(_res.data));
  } catch (error: any) {
    yield put(reportCashError(error));
  }
}

function* reportStockRequestFlow(
  action: actions.ReportStockRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportStockSuccess(_res.data));
  } catch (error: any) {
    yield put(reportStockError(error));
  }
}

function* reportBalDebtRequestFlow(
  action: actions.ReportBalDebtRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportBalDebtSuccess(_res.data));
  } catch (error: any) {
    yield put(reportBalDebtError(error));
  }
}

function* reportAdvRequestFlow(action: actions.ReportAdvRequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportAdvSuccess(_res.data));
  } catch (error: any) {
    yield put(reportAdvError(error));
  }
}

function* reportDebtRequestFlow(
  action: actions.ReportDebtRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportDebtSuccess(_res.data));
  } catch (error: any) {
    yield put(reportDebtError(error));
  }
}

function* reportStockTradHisRequestFlow(
  action: actions.ReportStockTradHisRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportStockTradHisSuccess(_res.data));
  } catch (error: any) {
    yield put(reportStockTradHisError(error));
  }
}

function* reportLoanContRequestFlow(
  action: actions.ReportLoanContRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportLoanContSuccess(_res.data));
  } catch (error: any) {
    yield put(reportLoanContError(error));
  }
}

function* reportCashBusRequestFlow(
  action: actions.ReportCashBusRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportCashBusSuccess(_res.data));
  } catch (error: any) {
    yield put(reportCashBusError(error));
  }
}

function* reportDebtsRequestFlow(
  action: actions.ReportDebtsRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportDebtsSuccess(_res.data));
  } catch (error: any) {
    yield put(reportDebtsError(error));
  }
}

function* reportDebtsHisRequestFlow(
  action: actions.ReportDebtsHisRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportDebtsHisSuccess(_res.data));
  } catch (error: any) {
    yield put(reportDebtsHisError(error));
  }
}

function* reportDebtsHisDetailRequestFlow(
  action: actions.ReportDebtsHisDetailRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(reportDebtsHisDetailSuccess(_res.data));
  } catch (error: any) {
    yield put(reportDebtsHisDetailError(error));
  }
}

function* exportGlobalRequestFlow(
  action: actions.ExportGlobalRequestingAction,
): any {
  try {
    const { data } = action;

    const _res: any = yield call(postRequestApiExportFile, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(exportGlobalSuccess(Math.random()));
  } catch (error: any) {
    yield put(exportGlobalError(error));
  }
}

function* reportWatcher() {
  yield all([
    takeLatest(actions.REPORT_001_REQUESTING, report001RequestFlow),
    takeLatest(actions.REPORT_002_REQUESTING, report002RequestFlow),
    takeLatest(actions.REPORT_003_REQUESTING, report003RequestFlow),
    takeLatest(actions.REPORT_004_REQUESTING, report004RequestFlow),
    takeLatest(actions.REPORT_005_REQUESTING, report005RequestFlow),
    takeLatest(actions.REPORT_006_REQUESTING, report006RequestFlow),
    takeLatest(actions.REPORT_007_REQUESTING, report007RequestFlow),
    takeLatest(actions.REPORT_008_REQUESTING, report008RequestFlow),
    takeLatest(actions.REPORT_009_REQUESTING, report009RequestFlow),
    takeLatest(actions.REPORT_010_REQUESTING, report010RequestFlow),
    takeLatest(actions.REPORT_011_REQUESTING, report011RequestFlow),
    takeLatest(actions.REPORT_012_REQUESTING, report012RequestFlow),
    takeLatest(actions.REPORT_CASH_REQUESTING, reportCashRequestFlow),
    takeLatest(actions.REPORT_STOCK_REQUESTING, reportStockRequestFlow),
    takeLatest(
      actions.REPORT_BALANCE_DEBT_REQUESTING,
      reportBalDebtRequestFlow,
    ),
    takeLatest(actions.REPORT_ADVANCE_REQUESTING, reportAdvRequestFlow),
    takeLatest(actions.REPORT_DEBT_REQUESTING, reportDebtRequestFlow),
    takeLatest(
      actions.REPORT_STOCK_TRAD_HIS_REQUESTING,
      reportStockTradHisRequestFlow,
    ),
    takeLatest(
      actions.REPORT_LOAN_CONTRACT_REQUESTING,
      reportLoanContRequestFlow,
    ),
    takeLatest(
      actions.REPORT_CASH_BUSINESS_REQUESTING,
      reportCashBusRequestFlow,
    ),
    takeLatest(actions.REPORT_DEBTS_REQUESTING, reportDebtsRequestFlow),
    takeLatest(actions.REPORT_DEBTS_HIS_REQUESTING, reportDebtsHisRequestFlow),
    takeLatest(
      actions.REPORT_DEBTS_HIS_DETAIL_REQUESTING,
      reportDebtsHisDetailRequestFlow,
    ),
    takeLatest(actions.EXPORT_GLOBAL_REQUESTING, exportGlobalRequestFlow),
  ]);
}

export default reportWatcher;
