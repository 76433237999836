import settingCol from 'components/panel/settingCol';
import * as actions from './actionType';

export interface ClientState {
  token: any;
  dataCCCD: any;
  toast: any;
  config: any;
  setting: any;
  typeNav: string;
  reqLogin: boolean;
  dataSearch: any;
  showNav: boolean;
  showTour: boolean;
  showSlide: boolean;
  footActive: string;
  modePrice: string;

  reqChangePass: boolean;
  reqChangePin: boolean;
  reqLostSession: boolean;
  reqTrading: boolean;
  objTrading: any;
  symbolActive: any; // chi tiết chứng khoán
  symbolDel: any; // symbol xóa
  symbolAdd: any; // symbol thêm mới
  symbolScroll: any; // scroll đến mã trên bảng giá
  symbolPin: any; // pin mã chứng khoán lên đầu table
  pin: any;
  tempPin: any;
  ordCond: boolean; // lệnh điều kiện
  dblPri: any; // click double từ bảng giá
  dblSellAll: any; // click bán tất cả

  category: any;
  categoryNganh: any;
  categoryPortfolio: any;
  categoryRequesting: boolean;
  categorySuccessful: boolean;
  categoryErrors: any;

  accPortfolio: any;
  accPortfolioRequesting: boolean;
  updPortfolio: any;
  delPortfolio: any;

  preSec: string;
  listAccount: any;
  defAccount: any;
  defAccountLayout: any;
  allBank: any;
  bankBranch: any;
  allProvince: any;
  allDistrict: any;
  allVillage: any;

  checkPin: any;
  confirmOrder: any;
  customGroup: any;
  settingCol: boolean;
  firstLogin: boolean;
}

const initialState = {
  token: null,
  dataCCCD: null,
  toast: null,
  config: null,
  setting: null,
  typeNav: 'full',
  reqLogin: false,
  dataSearch: null,
  showNav: false,
  showTour: false,
  showSlide: false,
  footActive: '',
  modePrice: '',
  reqChangePass: false,
  reqChangePin: false,
  reqLostSession: false,
  reqTrading: false,
  objTrading: null,
  symbolActive: null,
  symbolDel: null,
  symbolAdd: null,
  symbolScroll: null,
  symbolPin: null,
  pin: null,
  tempPin: null,
  ordCond: false,
  dblPri: null,
  dblSellAll: null,

  category: null,
  categoryNganh: null,
  categoryPortfolio: null,
  categoryRequesting: false,
  categorySuccessful: false,
  categoryErrors: [],

  accPortfolio: null,
  accPortfolioRequesting: false,
  updPortfolio: null,
  delPortfolio: null,

  preSec: '',
  listAccount: [],
  defAccount: null,
  defAccountLayout: null,
  allBank: null,
  bankBranch: null,
  allProvince: null,
  allDistrict: null,
  allVillage: null,

  checkPin: null,
  confirmOrder: null,
  customGroup: null,
  settingCol: false,
  firstLogin: false,
};

export default function clientReducer(
  state: ClientState = initialState,
  action: actions.ClientAction | any,
): ClientState {
  // console.log(action)
  switch (action.type) {
    case 'INVALID_SESSION':
      return {
        ...state,
        reqLogin: true,
        token: null,
      };
    case 'INVALID_SESSION_CLEAR':
      return {
        ...state,
        reqLogin: false,
      };

    case actions.CLIENT_UNSET:
      return {
        ...state,
        token: null,
      };

    case 'REQUEST_PRIVATE_FALSE':
    case 'API_ERR':
      return {
        ...state,
        toast: {
          msg: action.msg,
          title: 'Thông báo',
          type: 'error',
        },
      };

    case 'CLEAR_TOAST':
      return {
        ...state,
        toast: null,
      };

    case actions.CLIENT_SET:
      return {
        ...state,
        token: action.data,
      };

    case actions.CLIENT_TOAST_SET:
      return {
        ...state,
        toast: action.data,
      };

    case actions.CLIENT_TOAST_CLEAR:
      return {
        ...state,
        toast: null,
      };

    case actions.CLIENT_CONFIG_SET:
      return {
        ...state,
        config: action.data,
      };

    case actions.CLIENT_TYPENAV_SET:
      return {
        ...state,
        typeNav: action.data,
      };

    case actions.DATA_SEARCH_SET:
      return {
        ...state,
        dataSearch: action.data,
      };

    case actions.SHOW_NAV_SET:
      return {
        ...state,
        showNav: action.data,
      };

    case actions.SHOW_TOUR_SET:
      return {
        ...state,
        showTour: action.data,
      };

    case actions.SHOW_SLIDE_SET:
      return {
        ...state,
        showSlide: action.data,
      };

    case actions.CLIENT_SETTING_SET:
      return {
        ...state,
        setting: action.data,
      };

    case actions.FOOTER_ACTIVE_SET:
      return {
        ...state,
        footActive: action.data,
      };

    case actions.SHOW_TOAST:
      return {
        ...state,
        toast: action.resData,
      };

    case actions.SYMBOL_DEL:
      return {
        ...state,
        symbolDel: action.symbol,
      };

    case actions.SYMBOL_ADD:
      return {
        ...state,
        symbolAdd: action.symbol,
      };

    case actions.SYMBOL_SCROLL:
      return {
        ...state,
        symbolScroll: action.symbol,
      };

    case actions.SYMBOL_PIN:
      return {
        ...state,
        symbolPin: action.symbol,
      };

    case actions.CATEGORY_SET:
      return {
        ...state,
        category: action.category,
      };

    case actions.CATEGORY_NGANH_SET:
      return {
        ...state,
        categoryNganh: action.category,
      };

    case actions.CATEGORY_PORTFOLIO_SET:
      return {
        ...state,
        categoryPortfolio: action.category,
      };

    case actions.SETTING_SET:
      return {
        ...state,
        setting: action.setting,
      };

    case actions.LOGIN_SET:
      return {
        ...state,
        reqLogin: true,
      };

    case actions.LOGIN_UNSET:
      return {
        ...state,
        reqLogin: false,
      };

    case actions.CHANGE_PASS_SET:
      return {
        ...state,
        reqChangePass: action.data,
      };

    case actions.CHANGE_PIN_SET:
      return {
        ...state,
        reqChangePin: action.data,
      };

    case actions.OBJ_TRADING_SET:
      return {
        ...state,
        objTrading: action.data,
      };

    case actions.OBJ_TRADING_UNSET:
      return {
        ...state,
        objTrading: null,
      };

    case actions.TYPE_INDEX_SET:
      return {
        ...state,
        setting: { ...state.setting, typeIndex: action.data },
      };

    case actions.TYPE_PRICE_SET:
      return {
        ...state,
        setting: { ...state.setting, priceType: action.data },
      };

    case actions.INDEX_HIDE_SET:
      return {
        ...state,
        setting: { ...state.setting, indHid: action.data },
      };

    case actions.DBL_PRICE_SET:
      return {
        ...state,
        dblPri: action.data,
      };

    case actions.DBL_SELL_ALL_SET:
      return {
        ...state,
        dblSellAll: action.data,
      };

    case actions.SYMBOL_ACTIVE_SET:
      return {
        ...state,
        symbolActive: action.data,
      };

    case actions.CATEGORY_REQUESTING:
      return {
        ...state,
        categoryRequesting: false,
        categorySuccessful: false,
        categoryErrors: [],
      };

    case actions.CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        category: action.category,
        categoryRequesting: false,
        categorySuccessful: true,
        categoryErrors: [],
      };

    case actions.CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        categoryRequesting: false,
        categorySuccessful: false,
        categoryErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.GET_BANK_ONLINE_REQUESTING:
    case actions.GET_BANK_ONLINE_REQUEST_ERROR:
      return {
        ...state,
        allBank: [],
      };

    case actions.GET_BANK_ONLINE_REQUEST_SUCCESS:
      return {
        ...state,
        allBank: action.resData,
      };

    case actions.GET_BANK_BRANCH_REQUESTING:
    case actions.GET_BANK_BRANCH_REQUEST_ERROR:
      return {
        ...state,
        bankBranch: [],
      };

    case actions.GET_BANK_BRANCH_REQUEST_SUCCESS:
      return {
        ...state,
        bankBranch: action.resData,
      };

    case actions.GET_PROVINCE_REQUESTING:
    case actions.GET_PROVINCE_REQUEST_ERROR:
      return {
        ...state,
        allProvince: [],
      };

    case actions.GET_PROVINCE_REQUEST_SUCCESS:
      return {
        ...state,
        allProvince: action.resData,
      };

    case actions.GET_DISTRICT_REQUESTING:
    case actions.GET_DISTRICT_REQUEST_ERROR:
      return {
        ...state,
        allDistrict: [],
      };

    case actions.GET_DISTRICT_REQUEST_SUCCESS:
      return {
        ...state,
        allDistrict: action.resData,
      };

    case actions.GET_VILLAGE_REQUESTING:
    case actions.GET_VILLAGE_REQUEST_ERROR:
      return {
        ...state,
        allVillage: [],
      };

    case actions.GET_VILLAGE_REQUEST_SUCCESS:
      return {
        ...state,
        allVillage: action.resData,
      };

    case actions.LIST_ACCOUNT_REQUESTING:
    case actions.LIST_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        listAccount: [],
      };

    case actions.LIST_ACCOUNT_REQUEST_SUCCESS:
      const _acc =
        action.resData && !!action.resData.length ? action.resData[0] : null;

      return {
        ...state,
        preSec: _acc ? _acc.orderAcc.substring(0, 4) : '',
        listAccount: action.resData,
      };

    case actions.ACC_PORTFOLIO_REQUESTING:
      return {
        ...state,
        // accPortfolio: [],
        accPortfolioRequesting: true,
      };

    case actions.ACC_PORTFOLIO_SUCCESS:
      const data = action.resData;
      const _arr: any = [];
      if (data && !!data?.length) {
        data.forEach((element: any) => {
          _arr.push({
            id: element.PK_ACCOUNT_PORFOLIO,
            name: element.C_PORFOLIO_NAME,
            path: '/price/bang-gia/' + element.PK_ACCOUNT_PORFOLIO,
            value:
              element.C_LIST_SHARE_CODE && element.C_LIST_SHARE_CODE !== 'null'
                ? element.C_LIST_SHARE_CODE.split(',')
                : [],
            pinnedRow: [],
            type: 'watchlist',
            isDefault: element.C_DEFAULT,
          });
        });
      }
      return {
        ...state,
        accPortfolio: _arr,
        accPortfolioRequesting: false,
      };

    case actions.ACC_PORTFOLIO_ERROR:
      return {
        ...state,
        accPortfolio: [],
        accPortfolioRequesting: false,
      };

    case actions.UPD_PORTFOLIO_REQUESTING:
    case actions.UPD_PORTFOLIO_ERROR:
      return {
        ...state,
        updPortfolio: null,
      };

    case actions.UPD_PORTFOLIO_SUCCESS:
      return {
        ...state,
        updPortfolio: action.resData,
      };

    case actions.DEL_PORTFOLIO_REQUESTING:
    case actions.DEL_PORTFOLIO_ERROR:
      return {
        ...state,
        delPortfolio: null,
      };

    case actions.DEL_PORTFOLIO_SUCCESS:
      return {
        ...state,
        delPortfolio: action.resData,
      };

    case actions.CHECK_PIN_REQUESTING:
    case actions.CHECK_PIN_REQUEST_ERROR:
      return {
        ...state,
        checkPin: null,
      };

    case actions.CHECK_PIN_REQUEST_SUCCESS:
      return {
        ...state,
        checkPin: action.resData,
      };

    case actions.DEF_ACCOUNT_SET:
      return {
        ...state,
        defAccount: action.resData,
      };

    case actions.DEF_ACCOUNT_LAYOUT_SET:
      return {
        ...state,
        defAccountLayout: action.resData,
      };

    case 'PIN_SET':
      return {
        ...state,
        pin: action.data,
      };

    case 'TEMP_PIN_SET':
      return {
        ...state,
        tempPin: action.data,
      };
    case 'CONFIRM_ORDER':
      return {
        ...state,
        confirmOrder: action.data,
      };
    case 'REQ_TRADING':
      return {
        ...state,
        reqTrading: action.data,
      };

    case 'MODE_PRICEBOARD':
      return {
        ...state,
        modePrice: action.data,
      };

    case 'CUSTOM_GROUP':
      return {
        ...state,
        customGroup: action.data,
      };

    case 'SETTING_COL':
      return {
        ...state,
        settingCol: action.data,
      };

    case actions.DATA_CCCD_SET:
      return {
        ...state,
        dataCCCD: action.resData,
      };

    case actions.DATA_CCCD_UNSET:
      return {
        ...state,
        dataCCCD: null,
      };

    case 'FIRST_LOGIN':
      return {
        ...state,
        firstLogin: action.data,
      };

    default:
      return state;
  }
}
