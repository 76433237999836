import { INormalSelect } from 'interface';
import { AppState } from 'reducers';
import { createSelector } from 'reselect';

const getListRightUnExec = (state: AppState) => state.right.rightUnExec;
const getUpdateRightUnExec = (state: AppState) => state.right.updUnExec;
const getListRightBuy = (state: AppState) => state.right.listRightBuy;
const getRightBuyHistory = (state: AppState) => state.right.rightBuyHis;

const getRightAllStatus = (state: AppState) => {
  const listStatus: INormalSelect[] = [];

  if (!state.right.rightAllStt) return [];

  state.right.rightAllStt.forEach((element: any) => {
    listStatus.push({
      value: element.C_CODE,
      label: element.C_NAME,
    });
  });

  return listStatus;
};

const getRightAllType = (state: AppState) => {
  const listStatus: INormalSelect[] = [];

  if (!state.right.rightAllType) return [];

  state.right.rightAllType.forEach((element: any) => {
    listStatus.push({
      value: element.C_CODE,
      label: element.C_NAME,
    });
  });

  return listStatus;
};

/************************************** MAKE GET ****************************************/

export const makeListRightUnExec = () =>
  createSelector([getListRightUnExec], (rightUnExec) => rightUnExec);

export const makeUpdateRightUnExec = () =>
  createSelector([getUpdateRightUnExec], (updUnExec) => updUnExec);

export const makeGetListRightBuy = () =>
  createSelector([getListRightBuy], (listRightBuy) => listRightBuy);

export const makeGetRightBuyHistory = () =>
  createSelector([getRightBuyHistory], (rightBuyHis) => rightBuyHis);

export const makeGetRightAllStatus = () =>
  createSelector([getRightAllStatus], (rightAllStt) => rightAllStt);

export const makeGetRightAllType = () =>
  createSelector([getRightAllType], (rightAllType) => rightAllType);
