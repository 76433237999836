export const SUMMARY_CASH_CLEAR = 'summary/SUMMARY_CASH_CLEAR';
export interface SummaryCashClearAction {
  type: typeof SUMMARY_CASH_CLEAR;
}
export const SUMMARY_CASH_REQUESTING = 'summary/SUMMARY_CASH_REQUESTING';
export interface SummaryCashRequestAction {
  type: typeof SUMMARY_CASH_REQUESTING;
  params: any;
}

export const SUMMARY_CASH_REQUEST_SUCCESS = 'summary/SUMMARY_CASH_SUCCESS';
export interface SummaryCashRequestSuccessAction {
  type: typeof SUMMARY_CASH_REQUEST_SUCCESS;
  cashList: any;
}

export const SUMMARY_CASH_REQUEST_ERROR = 'summary/SUMMARY_CASH_ERROR';
export interface SummaryCashRequestErrorAction {
  type: typeof SUMMARY_CASH_REQUEST_ERROR;
  error: any;
}

export const SUMMARY_STOCK_REQUESTING = 'summary/SUMMARY_STOCK_REQUESTING';
export interface SummaryStockRequestAction {
  type: typeof SUMMARY_STOCK_REQUESTING;
  params: any;
}

export const SUMMARY_STOCK_SUCCESS = 'summary/SUMMARY_STOCK_SUCCESS';
export interface SummaryStockRequestSuccessAction {
  type: typeof SUMMARY_STOCK_SUCCESS;
  resData: any;
}

export const SUMMARY_STOCK_ERROR = 'summary/SUMMARY_STOCK_ERROR';
export interface SummaryStockRequestErrorAction {
  type: typeof SUMMARY_STOCK_ERROR;
  error: any;
}

export const SUMMARY_SHARE_EARN_REQUESTING =
  'summary/SUMMARY_SHARE_EARN_REQUESTING';
export interface ShareEarnedRequestAction {
  type: typeof SUMMARY_SHARE_EARN_REQUESTING;
  params: any;
}

export const SUMMARY_SHARE_EARN_REQUEST_SUCCESS =
  'summary/SUMMARY_SHARE_EARN_SUCCESS';
export interface ShareEarnedRequestSuccessAction {
  type: typeof SUMMARY_SHARE_EARN_REQUEST_SUCCESS;
  resData: any;
}

export const SUMMARY_SHARE_EARN_REQUEST_ERROR =
  'summary/SUMMARY_SHARE_EARN_ERROR';
export interface ShareEarnedRequestErrorAction {
  type: typeof SUMMARY_SHARE_EARN_REQUEST_ERROR;
  error: any;
}

export const CASH_CAN_ADV_REQUESTING = 'summary/CASH_CAN_ADV_REQUESTING';
export interface CashCanAdvRequestAction {
  type: typeof CASH_CAN_ADV_REQUESTING;
  params: any;
}

export const CASH_CAN_ADV_REQUEST_SUCCESS = 'summary/CASH_CAN_ADV_SUCCESS';
export interface CashCanAdvRequestSuccessAction {
  type: typeof CASH_CAN_ADV_REQUEST_SUCCESS;
  cashCanAdv: any;
}

export const CASH_CAN_ADV_REQUEST_ERROR = 'summary/CASH_CAN_ADV_ERROR';
export interface CashCanAdvRequestErrorAction {
  type: typeof CASH_CAN_ADV_REQUEST_ERROR;
  error: any;
}

export const FEE_ADV_WITHDRAW_REQUESTING =
  'summary/FEE_ADV_WITHDRAW_REQUESTING';
export interface FeeAdvWithdrawRequestAction {
  type: typeof FEE_ADV_WITHDRAW_REQUESTING;
  params: any;
}

export const FEE_ADV_WITHDRAW_REQUEST_SUCCESS =
  'summary/FEE_ADV_WITHDRAW_SUCCESS';
export interface FeeAdvWithdrawRequestSuccessAction {
  type: typeof FEE_ADV_WITHDRAW_REQUEST_SUCCESS;
  feeAdvWithdraw: any;
}

export const FEE_ADV_WITHDRAW_REQUEST_ERROR = 'summary/FEE_ADV_WITHDRAW_ERROR';
export interface FeeAdvWithdrawRequestErrorAction {
  type: typeof FEE_ADV_WITHDRAW_REQUEST_ERROR;
  error: any;
}

export const ADV_STATUS_REQUESTING = 'summary/ADV_STATUS_REQUESTING';
export interface AdvStatusRequestAction {
  type: typeof ADV_STATUS_REQUESTING;
  params: any;
}

export const ADV_STATUS_REQUEST_SUCCESS = 'summary/ADV_STATUS_SUCCESS';
export interface AdvStatusRequestSuccessAction {
  type: typeof ADV_STATUS_REQUEST_SUCCESS;
  advStt: any;
}

export const ADV_STATUS_REQUEST_ERROR = 'summary/ADV_STATUS_ERROR';
export interface AdvStatusRequestErrorAction {
  type: typeof ADV_STATUS_REQUEST_ERROR;
  error: any;
}

export const ADV_STATUS_HIS_REQUESTING = 'summary/ADV_STATUS_HIS_REQUESTING';
export interface AdvStatusHisRequestAction {
  type: typeof ADV_STATUS_HIS_REQUESTING;
  params: any;
}

export const ADV_STATUS_HIS_REQUEST_SUCCESS = 'summary/ADV_STATUS_HIS_SUCCESS';
export interface AdvStatusHisRequestSuccessAction {
  type: typeof ADV_STATUS_HIS_REQUEST_SUCCESS;
  advSttHis: any;
}

export const ADV_STATUS_HIS_REQUEST_ERROR = 'summary/ADV_STATUS_HIS_ERROR';
export interface AdvStatusHisRequestErrorAction {
  type: typeof ADV_STATUS_HIS_REQUEST_ERROR;
  error: any;
}

export const LIST_SHARE_BALANCE_REQUESTING =
  'summary/LIST_SHARE_BALANCE_REQUESTING';
export interface ListShareBalanceRequestAction {
  type: typeof LIST_SHARE_BALANCE_REQUESTING;
  params: any;
}

export const LIST_SHARE_BALANCE_REQUEST_SUCCESS =
  'summary/LIST_SHARE_BALANCE_SUCCESS';
export interface ListShareBalanceRequestSuccessAction {
  type: typeof LIST_SHARE_BALANCE_REQUEST_SUCCESS;
  listShareBalance: any;
}

export const LIST_SHARE_BALANCE_REQUEST_ERROR =
  'summary/LIST_SHARE_BALANCE_ERROR';
export interface ListShareBalanceRequestErrorAction {
  type: typeof LIST_SHARE_BALANCE_REQUEST_ERROR;
  error: any;
}

export const SHARE_TRANSFER_STATUS_REQUESTING =
  'summary/SHARE_TRANSFER_STATUS_REQUESTING';
export interface ListShareStatusRequestAction {
  type: typeof SHARE_TRANSFER_STATUS_REQUESTING;
  params: any;
}

export const SHARE_TRANSFER_REQUEST_SUCCESS = 'summary/SHARE_TRANSFER_SUCCESS';
export interface ListShareStatusRequestSuccessAction {
  type: typeof SHARE_TRANSFER_REQUEST_SUCCESS;
  listShareStt: any;
}

export const SHARE_TRANSFER_REQUEST_ERROR = 'summary/SHARE_TRANSFER_ERROR';
export interface ListShareStatusRequestErrorAction {
  type: typeof SHARE_TRANSFER_REQUEST_ERROR;
  error: any;
}

export const SHARE_TRANSFER_HIS_REQUESTING =
  'summary/SHARE_TRANSFER_HIS_REQUESTING';
export interface ShareTransferHisRequestAction {
  type: typeof SHARE_TRANSFER_HIS_REQUESTING;
  params: any;
}

export const SHARE_TRANSFER_HIS_REQUEST_SUCCESS =
  'summary/SHARE_TRANSFER_HIS_SUCCESS';
export interface ShareTransferHisRequestSuccessAction {
  type: typeof SHARE_TRANSFER_HIS_REQUEST_SUCCESS;
  shareTransferHis: any;
}

export const SHARE_TRANSFER_HIS_REQUEST_ERROR =
  'summary/SHARE_TRANSFER_HIS_ERROR';
export interface ShareTransferHisRequestErrorAction {
  type: typeof SHARE_TRANSFER_HIS_REQUEST_ERROR;
  error: any;
}

export const CASH_ACCOUNT_INFO_REQUESTING =
  'summary/CASH_ACCOUNT_INFO_REQUESTING';
export interface CashAccountInfoRequestAction {
  type: typeof CASH_ACCOUNT_INFO_REQUESTING;
  params: any;
}

export const CASH_ACCOUNT_INFO_REQUEST_SUCCESS =
  'summary/CASH_ACCOUNT_INFO_SUCCESS';
export interface CashAccountInfoRequestSuccessAction {
  type: typeof CASH_ACCOUNT_INFO_REQUEST_SUCCESS;
  cashAccountInfo: any;
}

export const CASH_ACCOUNT_INFO_REQUEST_ERROR =
  'summary/CASH_ACCOUNT_INFO_ERROR';
export interface CashAccountInfoRequestErrorAction {
  type: typeof CASH_ACCOUNT_INFO_REQUEST_ERROR;
  error: any;
}

export const CASH_ONLY_ACCOUNT_INFO_REQUESTING =
  'summary/CASH_ONLY_ACCOUNT_INFO_REQUESTING';
export interface CashOnlyAccountInfoRequestAction {
  type: typeof CASH_ONLY_ACCOUNT_INFO_REQUESTING;
  params: any;
}

export const CASH_ONLY_ACCOUNT_INFO_REQUEST_SUCCESS =
  'summary/CASH_ONLY_ACCOUNT_INFO_SUCCESS';
export interface CashOnlyAccountInfoRequestSuccessAction {
  type: typeof CASH_ONLY_ACCOUNT_INFO_REQUEST_SUCCESS;
  data: any;
}

export const CASH_ONLY_ACCOUNT_INFO_REQUEST_ERROR =
  'summary/CASH_ONLY_ACCOUNT_INFO_ERROR';
export interface CashOnlyAccountInfoRequestErrorAction {
  type: typeof CASH_ONLY_ACCOUNT_INFO_REQUEST_ERROR;
  error: any;
}

export const CASH_ACCOUNT_INFO_OL_REQUESTING =
  'summary/CASH_ACCOUNT_INFO_OL_REQUESTING';
export interface CashAccountInfoOlRequestAction {
  type: typeof CASH_ACCOUNT_INFO_OL_REQUESTING;
  params: any;
}

export const CASH_ACCOUNT_INFO_OL_REQUEST_SUCCESS =
  'summary/CASH_ACCOUNT_INFO_OL_SUCCESS';
export interface CashAccountInfoOlRequestSuccessAction {
  type: typeof CASH_ACCOUNT_INFO_OL_REQUEST_SUCCESS;
  cashAccountInfoOl: any;
}

export const CASH_ACCOUNT_INFO_OL_REQUEST_ERROR =
  'summary/CASH_ACCOUNT_INFO_OL_ERROR';
export interface CashAccountInfoOlRequestErrorAction {
  type: typeof CASH_ACCOUNT_INFO_OL_REQUEST_ERROR;
  error: any;
}

export const CASH_ONLY_ACCOUNT_INFO_OL_REQUESTING =
  'summary/CASH_ONLY_ACCOUNT_INFO_OL_REQUESTING';
export interface CashOnlyAccountInfoOlRequestAction {
  type: typeof CASH_ONLY_ACCOUNT_INFO_OL_REQUESTING;
  params: any;
}

export const CASH_ONLY_ACCOUNT_INFO_OL_REQUEST_SUCCESS =
  'summary/CASH_ONLY_ACCOUNT_INFO_OL_SUCCESS';
export interface CashOnlyAccountInfoOlRequestSuccessAction {
  type: typeof CASH_ONLY_ACCOUNT_INFO_OL_REQUEST_SUCCESS;
  data: any;
}

export const CASH_ONLY_ACCOUNT_INFO_OL_REQUEST_ERROR =
  'summary/CASH_ONLY_ACCOUNT_INFO_OL_ERROR';
export interface CashOnlyAccountInfoOlRequestErrorAction {
  type: typeof CASH_ONLY_ACCOUNT_INFO_OL_REQUEST_ERROR;
  error: any;
}

export const ACCOUNT_BENEFICIARY_REQUESTING =
  'summary/ACCOUNT_BENEFICIARY_REQUESTING';
export interface BeneficiaryAccountRequestAction {
  type: typeof ACCOUNT_BENEFICIARY_REQUESTING;
  params: any;
}

export const ACCOUNT_BENEFICIARY_REQUEST_SUCCESS =
  'summary/ACCOUNT_BENEFICIARY_SUCCESS';
export interface BeneficiaryAccountRequestSuccessAction {
  type: typeof ACCOUNT_BENEFICIARY_REQUEST_SUCCESS;
  beneficiaryAccount: any;
}

export const ACCOUNT_BENEFICIARY_REQUEST_ERROR =
  'summary/ACCOUNT_BENEFICIARY_ERROR';
export interface BeneficiaryAccountRequestErrorAction {
  type: typeof ACCOUNT_BENEFICIARY_REQUEST_ERROR;
  error: any;
}

export const ACC_BEN_ADD_REQUESTING = 'summary/ACC_BEN_ADD_REQUESTING';
export interface AccBenAddRequestAction {
  type: typeof ACC_BEN_ADD_REQUESTING;
  params: any;
}

export const ACC_BEN_ADD_SUCCESS = 'summary/ACC_BEN_ADD_SUCCESS';
export interface AccBenAddSuccessAction {
  type: typeof ACC_BEN_ADD_SUCCESS;
  resData: any;
}

export const ACC_BEN_ADD_ERROR = 'summary/ACC_BEN_ADD_ERROR';
export interface AccBenAddErrorAction {
  type: typeof ACC_BEN_ADD_ERROR;
  error: any;
}

export const CASH_TRANSFER_STATUS_REQUESTING =
  'summary/CASH_TRANSFER_STATUS_REQUESTING';
export interface ListCashStatusRequestAction {
  type: typeof CASH_TRANSFER_STATUS_REQUESTING;
  params: any;
}

export const CASH_TRANSFER_REQUEST_SUCCESS = 'summary/CASH_TRANSFER_SUCCESS';
export interface ListCashStatusRequestSuccessAction {
  type: typeof CASH_TRANSFER_REQUEST_SUCCESS;
  listCashStt: any;
}

export const CASH_TRANSFER_REQUEST_ERROR = 'summary/CASH_TRANSFER_ERROR';
export interface ListCashStatusRequestErrorAction {
  type: typeof CASH_TRANSFER_REQUEST_ERROR;
  error: any;
}

export const CASH_TRANSFER_HIS_REQUESTING =
  'summary/CASH_TRANSFER_HIS_REQUESTING';
export interface CashTransferHisRequestAction {
  type: typeof CASH_TRANSFER_HIS_REQUESTING;
  params: any;
}

export const CASH_TRANSFER_HIS_REQUEST_SUCCESS =
  'summary/CASH_TRANSFER_HIS_SUCCESS';
export interface CashTransferHisRequestSuccessAction {
  type: typeof CASH_TRANSFER_HIS_REQUEST_SUCCESS;
  cashTransferHis: any;
}

export const CASH_TRANSFER_HIS_REQUEST_ERROR =
  'summary/CASH_TRANSFER_HIS_ERROR';
export interface CashTransferHisRequestErrorAction {
  type: typeof CASH_TRANSFER_HIS_REQUEST_ERROR;
  error: any;
}

export const DETAIL_DEBTS = 'summary/DETAIL_DEBTS';
export interface DetailDebtAction {
  type: typeof DETAIL_DEBTS;
}

export const DETAIL_DEBTS_REQUESTING = 'summary/DETAIL_DEBTS_REQUESTING';
export interface DetailDebtRequestingAction {
  type: typeof DETAIL_DEBTS_REQUESTING;
  data: any;
}

export const DETAIL_DEBTS_SUCCESS = 'summary/DETAIL_DEBTS_SUCCESS';
export interface DetailDebtSuccessAction {
  type: typeof DETAIL_DEBTS_SUCCESS;
  resData: any;
}

export const DETAIL_DEBTS_ERROR = 'summary/DETAIL_DEBTS_ERROR';
export interface DetailDebtErrorAction {
  type: typeof DETAIL_DEBTS_ERROR;
  error: any;
}

export type SummaryAction =
  | SummaryCashClearAction
  | SummaryCashRequestAction
  | SummaryCashRequestSuccessAction
  | SummaryCashRequestErrorAction
  | SummaryStockRequestAction
  | SummaryStockRequestSuccessAction
  | SummaryStockRequestErrorAction
  | ShareEarnedRequestAction
  | ShareEarnedRequestSuccessAction
  | ShareEarnedRequestErrorAction
  | CashCanAdvRequestAction
  | CashCanAdvRequestSuccessAction
  | CashCanAdvRequestErrorAction
  | FeeAdvWithdrawRequestAction
  | FeeAdvWithdrawRequestSuccessAction
  | FeeAdvWithdrawRequestErrorAction
  | AdvStatusRequestAction
  | AdvStatusRequestSuccessAction
  | AdvStatusRequestErrorAction
  | AdvStatusHisRequestAction
  | AdvStatusHisRequestSuccessAction
  | AdvStatusHisRequestErrorAction
  | ListShareBalanceRequestAction
  | ListShareBalanceRequestSuccessAction
  | ListShareBalanceRequestErrorAction
  | ListShareStatusRequestAction
  | ListShareStatusRequestSuccessAction
  | ListShareStatusRequestErrorAction
  | ShareTransferHisRequestAction
  | ShareTransferHisRequestSuccessAction
  | ShareTransferHisRequestErrorAction
  | CashAccountInfoRequestAction
  | CashAccountInfoRequestSuccessAction
  | CashAccountInfoRequestErrorAction
  | CashOnlyAccountInfoRequestAction
  | CashOnlyAccountInfoRequestSuccessAction
  | CashOnlyAccountInfoRequestErrorAction
  | CashAccountInfoOlRequestAction
  | CashAccountInfoOlRequestSuccessAction
  | CashAccountInfoOlRequestErrorAction
  | CashOnlyAccountInfoOlRequestAction
  | CashOnlyAccountInfoOlRequestSuccessAction
  | CashOnlyAccountInfoOlRequestErrorAction
  | BeneficiaryAccountRequestAction
  | BeneficiaryAccountRequestSuccessAction
  | BeneficiaryAccountRequestErrorAction
  | ListCashStatusRequestAction
  | ListCashStatusRequestSuccessAction
  | ListCashStatusRequestErrorAction
  | CashTransferHisRequestAction
  | CashTransferHisRequestSuccessAction
  | CashTransferHisRequestErrorAction
  | AccBenAddRequestAction
  | AccBenAddSuccessAction
  | AccBenAddErrorAction
  | DetailDebtAction
  | DetailDebtRequestingAction
  | DetailDebtSuccessAction
  | DetailDebtErrorAction;
