import { AppState } from 'reducers';
import { createSelector } from 'reselect';

const getNewOrder = (state: AppState) => {
  return {
    newOrder: state.privData.newOrder ? state.privData.newOrder.data : null,
    newOrderSuccess: state.privData.newOrderSuccess,
    newOrderErrors: state.privData.newOrderErrors,
  };
};
const getEditOrder = (state: AppState) => {
  return {
    editOrder: state.privData.editOrder ? state.privData.editOrder.data : null,
    editOrderSuccess: state.privData.editOrderSuccess,
    editOrderErrors: state.privData.editOrderErrors,
  };
};
const getCancelOrder = (state: AppState) => {
  
  return {
    cancelOrder: state.privData.cancelOrder ? state.privData.cancelOrder : null,
    cancelOrderSuccess: state.privData.cancelOrderSuccess,
    cancelOrderErrors: state.privData.cancelOrderErrors,
  };
};
const getCashBalance = (state: AppState) => state.privData.cashBalance;
const getStockBalance = (state: AppState) => state.privData.stockBalance;
const getPositions = (state: AppState) => state.privData.positions;
const getAllPositions = (state: AppState) => state.privData.allPositions;
const getSStockInfo = (state: AppState) => state.privData.sStockInfo;
// const getPurchasePower = (state: AppState) => {
//   if (state.privData.purchasePower) return state.privData.purchasePower.data;

//   return null;
// };
const getSymbolInfo = (state: AppState) => {
  // log(state.privData.symbolInfo)
  if (state.privData.symbolInfo) return state.privData.symbolInfo.data.list[0];

  return null;
};

const getSymbolMargin = (state: AppState) =>
  state.privData.symbolInfo.dataMargin;

const getMatchOrder = (state: AppState) => state.privData.matchOrder.data;

const getAdvanceOrder = (state: AppState) => state.privData.advOrder;
const getConditionOrders = (state: AppState) => state.privData.condOrders;
const getListOrderConfirm = (state: AppState) =>
  state.privData.listOrderConfirm;

/************************************** MAKE GET ***************************************/

export const makeGetCashBalance = () =>
  createSelector([getCashBalance], (cashBalance) => cashBalance);

export const makeGetStockBalance = () =>
  createSelector([getStockBalance], (stockBalance) => stockBalance);

export const makeGetPositions = () =>
  createSelector([getPositions], (positions) => positions);

export const makeGetAllPositions = () =>
  createSelector([getAllPositions], (allPositions) => allPositions);

export const makeGetSStockInfo = () =>
  createSelector([getSStockInfo], (sStockInfo) => sStockInfo);

// export const makeGetPurchasePower = () =>
//   createSelector([getPurchasePower], (purchasePower) => purchasePower);

export const makeGetNewOrder = () =>
  createSelector([getNewOrder], (newOrder) => newOrder);

export const makeGetCancelOrder = () =>
  createSelector([getCancelOrder], (cancelOrder) => cancelOrder);

export const makeGetEditOrder = () =>
  createSelector([getEditOrder], (editOrder) => editOrder);

export const makeGetSymbolInfo = () =>
  createSelector([getSymbolInfo], (symbolInfo) => symbolInfo);

export const makeGetMatchOrder = () =>
  createSelector([getMatchOrder], (matchOrder) => matchOrder);

export const makeGetSymbolMargin = () =>
  createSelector([getSymbolMargin], (symbolMargin) => symbolMargin);

export const makeGetAdvanceOrder = () =>
  createSelector([getAdvanceOrder], (advOrder) => advOrder);

export const makeGetConditionOrders = () =>
  createSelector([getConditionOrders], (condOrders) => condOrders);

export const makeGetListOrderConfirm = () =>
  createSelector([getListOrderConfirm], (listOrderConfirm) => listOrderConfirm);
