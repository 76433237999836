import * as actions from './actionType';

export interface ReportState {
  rp001: any;
  rp002: any;
  rp003: any;
  rp004: any;
  rp005: any;
  rp006: any;
  rp007: any;
  rp008: any;
  rp009: any;
  rp010: any;
  rp011: any;
  rp012: any;
  rpCash: any;
  rpStock: any;
  rpBalDebt: any;
  rpAdv: any;
  rpDebt: any;
  rpStockTradHis: any;
  rpLoanCont: any;
  cashBusiness: any;
  debts: any;
  debtsHis: any;
  debtsHisDetail: any;
  glExport: boolean;
}

const initialState = {
  rp001: null,
  rp002: null,
  rp003: null,
  rp004: null,
  rp005: null,
  rp006: null,
  rp007: null,
  rp008: null,
  rp009: null,
  rp010: null,
  rp011: null,
  rp012: null,
  rpCash: [],
  rpStock: null,
  rpBalDebt: null,
  rpAdv: null,
  rpDebt: null,
  rpStockTradHis: null,
  rpLoanCont: null,
  cashBusiness: null,
  debts: null,
  debtsHis: null,
  debtsHisDetail: null,
  glExport: false,
};

export default function reportReducer(
  state: ReportState = initialState,
  action: actions.ReportAction,
): ReportState {
  switch (action.type) {
    case actions.REPORT_001_REQUESTING:
    case actions.REPORT_001_ERROR:
      return {
        ...state,
        rp001: null,
      };

    case actions.REPORT_001_SUCCESS:
      return {
        ...state,
        rp001: action.resData,
      };

    case actions.REPORT_002_REQUESTING:
    case actions.REPORT_002_ERROR:
      return {
        ...state,
        rp002: null,
      };

    case actions.REPORT_002_SUCCESS:
      return {
        ...state,
        rp002: action.resData,
      };

    case actions.REPORT_003_REQUESTING:
    case actions.REPORT_003_ERROR:
      return {
        ...state,
        rp003: null,
      };

    case actions.REPORT_003_SUCCESS:
      return {
        ...state,
        rp003: action.resData,
      };

    case actions.REPORT_004_REQUESTING:
    case actions.REPORT_004_ERROR:
      return {
        ...state,
        rp004: null,
      };

    case actions.REPORT_004_SUCCESS:
      return {
        ...state,
        rp004: action.resData,
      };

    case actions.REPORT_005_REQUESTING:
    case actions.REPORT_005_ERROR:
      return {
        ...state,
        rp005: null,
      };

    case actions.REPORT_005_SUCCESS:
      return {
        ...state,
        rp005: action.resData,
      };

    case actions.REPORT_006_REQUESTING:
    case actions.REPORT_006_ERROR:
      return {
        ...state,
        rp006: null,
      };

    case actions.REPORT_006_SUCCESS:
      return {
        ...state,
        rp006: action.resData,
      };

    case actions.REPORT_007_REQUESTING:
    case actions.REPORT_007_ERROR:
      return {
        ...state,
        rp007: null,
      };

    case actions.REPORT_007_SUCCESS:
      return {
        ...state,
        rp007: action.resData,
      };

    case actions.REPORT_008_REQUESTING:
    case actions.REPORT_008_ERROR:
      return {
        ...state,
        rp008: null,
      };

    case actions.REPORT_008_SUCCESS:
      return {
        ...state,
        rp008: action.resData,
      };

    case actions.REPORT_009_REQUESTING:
    case actions.REPORT_009_ERROR:
      return {
        ...state,
        rp009: null,
      };

    case actions.REPORT_009_SUCCESS:
      return {
        ...state,
        rp009: action.resData,
      };

    case actions.REPORT_010_REQUESTING:
    case actions.REPORT_010_ERROR:
      return {
        ...state,
        rp010: null,
      };

    case actions.REPORT_010_SUCCESS:
      return {
        ...state,
        rp010: action.resData,
      };

    case actions.REPORT_011_REQUESTING:
    case actions.REPORT_011_ERROR:
      return {
        ...state,
        rp011: null,
      };

    case actions.REPORT_011_SUCCESS:
      return {
        ...state,
        rp011: action.resData,
      };

    case actions.REPORT_012_REQUESTING:
    case actions.REPORT_012_ERROR:
      return {
        ...state,
        rp012: null,
      };

    case actions.REPORT_012_SUCCESS:
      return {
        ...state,
        rp012: action.resData,
      };

    case actions.REPORT_CASH_REQUESTING:
    case actions.REPORT_CASH_ERROR:
      return {
        ...state,
        rpCash: null,
      };

    case actions.REPORT_CASH_SUCCESS:
      return {
        ...state,
        rpCash: action.resData,
      };

    case actions.REPORT_STOCK_REQUESTING:
    case actions.REPORT_STOCK_ERROR:
      return {
        ...state,
        rpStock: null,
      };

    case actions.REPORT_STOCK_SUCCESS:
      return {
        ...state,
        rpStock: action.resData,
      };

    case actions.REPORT_BALANCE_DEBT_REQUESTING:
    case actions.REPORT_BALANCE_DEBT_ERROR:
      return {
        ...state,
        rpBalDebt: null,
      };

    case actions.REPORT_BALANCE_DEBT_SUCCESS:
      return {
        ...state,
        rpBalDebt: action.resData,
      };

    case actions.REPORT_ADVANCE_REQUESTING:
    case actions.REPORT_ADVANCE_ERROR:
      return {
        ...state,
        rpAdv: null,
      };

    case actions.REPORT_ADVANCE_SUCCESS:
      return {
        ...state,
        rpAdv: action.resData,
      };

    case actions.REPORT_DEBT_REQUESTING:
    case actions.REPORT_DEBT_ERROR:
      return {
        ...state,
        rpDebt: null,
      };

    case actions.REPORT_DEBT_SUCCESS:
      return {
        ...state,
        rpDebt: action.resData,
      };

    case actions.REPORT_STOCK_TRAD_HIS_REQUESTING:
    case actions.REPORT_STOCK_TRAD_HIS_ERROR:
      return {
        ...state,
        rpStockTradHis: null,
      };

    case actions.REPORT_STOCK_TRAD_HIS_SUCCESS:
      return {
        ...state,
        rpStockTradHis: action.resData,
      };

    case actions.REPORT_LOAN_CONTRACT_REQUESTING:
    case actions.REPORT_LOAN_CONTRACT_ERROR:
      return {
        ...state,
        rpLoanCont: null,
      };

    case actions.REPORT_LOAN_CONTRACT_SUCCESS:
      return {
        ...state,
        rpLoanCont: action.resData,
      };

    case actions.REPORT_CASH_BUSINESS_REQUESTING:
    case actions.REPORT_CASH_BUSINESS_ERROR:
      return {
        ...state,
        cashBusiness: null,
      };

    case actions.REPORT_CASH_BUSINESS_SUCCESS:
      return {
        ...state,
        cashBusiness: action.resData,
      };

    case actions.REPORT_DEBTS_REQUESTING:
    case actions.REPORT_DEBTS_ERROR:
      return {
        ...state,
        debts: null,
      };

    case actions.REPORT_DEBTS_SUCCESS:
      return {
        ...state,
        debts: action.resData,
      };

    case actions.REPORT_DEBTS_HIS_REQUESTING:
    case actions.REPORT_DEBTS_HIS_ERROR:
      return {
        ...state,
        debtsHis: null,
      };

    case actions.REPORT_DEBTS_HIS_SUCCESS:
      return {
        ...state,
        debtsHis: action.resData,
      };

    case actions.REPORT_DEBTS_HIS_DETAIL_REQUESTING:
    case actions.REPORT_DEBTS_HIS_DETAIL_ERROR:
      return {
        ...state,
        debtsHisDetail: null,
      };

    case actions.REPORT_DEBTS_HIS_DETAIL_SUCCESS:
      return {
        ...state,
        debtsHisDetail: action.resData,
      };

    case actions.EXPORT_GLOBAL_REQUESTING:
      return {
        ...state,
        glExport: true,
      };
    case actions.EXPORT_GLOBAL_ERROR:
    case actions.EXPORT_GLOBAL_SUCCESS:
      return {
        ...state,
        glExport: false,
      };

    default:
      return state;
  }
}
