/** đặt lệnh */
export const TD_NEW_ORDER = 'td/NEW_ORDER';
export interface TdNewOrderAction {
  type: typeof TD_NEW_ORDER;
}

export const TD_NEW_ORDER_REQUESTING = 'td/NEW_ORDER_REQUESTING';
export interface TdNewOrderRequestingAction {
  type: typeof TD_NEW_ORDER_REQUESTING;
  data: any;
}

export const TD_NEW_ORDER_SUCCESS = 'td/NEW_ORDER_SUCCESS';
export interface TdNewOrderSuccessAction {
  type: typeof TD_NEW_ORDER_SUCCESS;
  resData: any;
}

export const TD_NEW_ORDER_ERROR = 'td/NEW_ORDER_ERROR';
export interface TdNewOrderErrorAction {
  type: typeof TD_NEW_ORDER_ERROR;
  error: any;
}

/** hủy lệnh */
export const TD_CANCEL_ORDER_REQUESTING = 'td/CANCEL_ORDER_REQUESTING';
export interface TdCancelOrderRequestingAction {
  type: typeof TD_CANCEL_ORDER_REQUESTING;
  data: any;
}

export const TD_CANCEL_ORDER_SUCCESS = 'td/CANCEL_ORDER_SUCCESS';
export interface TdCancelOrderSuccessAction {
  type: typeof TD_CANCEL_ORDER_SUCCESS;
  resData: any;
}

export const TD_CANCEL_ORDER_ERROR = 'td/CANCEL_ORDER_ERROR';
export interface TdCancelOrderErrorAction {
  type: typeof TD_CANCEL_ORDER_ERROR;
  error: any;
}

/** hủy lệnh */
export const TD_ORDER_LIST_REQUESTING = 'td/ORDER_LIST_REQUESTING';
export interface TdOrderListRequestingAction {
  type: typeof TD_ORDER_LIST_REQUESTING;
  data: any;
}

export const TD_ORDER_LIST_SUCCESS = 'td/ORDER_LIST_SUCCESS';
export interface TdOrderListSuccessAction {
  type: typeof TD_ORDER_LIST_SUCCESS;
  resData: any;
}

export const TD_ORDER_LIST_ERROR = 'td/ORDER_LIST_ERROR';
export interface TdOrderListErrorAction {
  type: typeof TD_ORDER_LIST_ERROR;
  error: any;
}

export const SYMBOL_INDAY_REQUESTING = 'td/SYMBOL_INDAY_REQUESTING';
export interface SymbolIndayRequestAction {
  type: typeof SYMBOL_INDAY_REQUESTING;
  params: any;
}

export const SYMBOL_INDAY_REQUEST_SUCCESS = 'td/SYMBOL_INDAY_SUCCESS';
export interface SymbolIndayRequestSuccessAction {
  type: typeof SYMBOL_INDAY_REQUEST_SUCCESS;
  symbolInday: any;
}

export const SYMBOL_INDAY_REQUEST_ERROR = 'td/SYMBOL_INDAY_ERROR';
export interface SymbolIndayRequestErrorAction {
  type: typeof SYMBOL_INDAY_REQUEST_ERROR;
  error: any;
}

export const ORDER_HISTORY_REQUESTING = 'td/ORDER_HISTORY_REQUESTING';
export interface OrderHistoryRequestAction {
  type: typeof ORDER_HISTORY_REQUESTING;
  params: any;
}

export const ORDER_HISTORY_REQUEST_SUCCESS = 'td/ORDER_HISTORY_SUCCESS';
export interface OrderHistoryRequestSuccessAction {
  type: typeof ORDER_HISTORY_REQUEST_SUCCESS;
  orderHis: any;
}

export const ORDER_HISTORY_REQUEST_ERROR = 'td/ORDER_HISTORY_ERROR';
export interface OrderHistoryRequestErrorAction {
  type: typeof ORDER_HISTORY_REQUEST_ERROR;
  error: any;
}

export const LOAN_SHARE_REQUESTING = 'td/LOAN_SHARE_REQUESTING';
export interface LoanShareRequestAction {
  type: typeof LOAN_SHARE_REQUESTING;
  params: any;
}

export const LOAN_SHARE_REQUEST_SUCCESS = 'td/LOAN_SHARE_SUCCESS';
export interface LoanShareRequestSuccessAction {
  type: typeof LOAN_SHARE_REQUEST_SUCCESS;
  resData: any;
}

export const LOAN_SHARE_REQUEST_ERROR = 'td/LOAN_SHARE_ERROR';
export interface LoanShareRequestErrorAction {
  type: typeof LOAN_SHARE_REQUEST_ERROR;
  error: any;
}

export const ODD_SHARE_REQUESTING = 'td/ODD_SHARE_REQUESTING';
export interface OddShareRequestAction {
  type: typeof ODD_SHARE_REQUESTING;
  params: any;
}

export const ODD_SHARE_REQUEST_SUCCESS = 'td/ODD_SHARE_SUCCESS';
export interface OddShareRequestSuccessAction {
  type: typeof ODD_SHARE_REQUEST_SUCCESS;
  resData: any;
}

export const ODD_SHARE_REQUEST_ERROR = 'td/ODD_SHARE_ERROR';
export interface OddShareRequestErrorAction {
  type: typeof ODD_SHARE_REQUEST_ERROR;
  error: any;
}

export const ODD_SHARE_INDAY_REQUESTING = 'td/ODD_SHARE_INDAY_REQUESTING';
export interface OddShareIndayRequestAction {
  type: typeof ODD_SHARE_INDAY_REQUESTING;
  params: any;
}

export const ODD_SHARE_INDAY_REQUEST_SUCCESS = 'td/ODD_SHARE_INDAY_SUCCESS';
export interface OddShareIndayRequestSuccessAction {
  type: typeof ODD_SHARE_INDAY_REQUEST_SUCCESS;
  resData: any;
}

export const ODD_SHARE_INDAY_REQUEST_ERROR = 'td/ODD_SHARE_INDAY_ERROR';
export interface OddShareIndayRequestErrorAction {
  type: typeof ODD_SHARE_INDAY_REQUEST_ERROR;
  error: any;
}

export const ODD_SHARE_HIS_REQUESTING = 'td/ODD_SHARE_HIS_REQUESTING';
export interface OddShareHisRequestAction {
  type: typeof ODD_SHARE_HIS_REQUESTING;
  params: any;
}

export const ODD_SHARE_HIS_REQUEST_SUCCESS = 'td/ODD_SHARE_HIS_SUCCESS';
export interface OddShareHisRequestSuccessAction {
  type: typeof ODD_SHARE_HIS_REQUEST_SUCCESS;
  resData: any;
}

export const ODD_SHARE_HIS_REQUEST_ERROR = 'td/ODD_SHARE_HIS_ERROR';
export interface OddShareHisRequestErrorAction {
  type: typeof ODD_SHARE_HIS_REQUEST_ERROR;
  error: any;
}

export const UPD_ODD_SHARE_REQUESTING = 'td/UPD_ODD_SHARE_REQUESTING';
export interface UpdOddShareRequestAction {
  type: typeof UPD_ODD_SHARE_REQUESTING;
  params: any;
}

export const UPD_ODD_SHARE_REQUEST_SUCCESS = 'td/UPD_ODD_SHARE_REQUEST_SUCCESS';
export interface UpdOddShareRequestSuccessAction {
  type: typeof UPD_ODD_SHARE_REQUEST_SUCCESS;
  resData: any;
}

export const UPD_ODD_SHARE_REQUEST_ERROR = 'td/UPD_ODD_SHARE_REQUEST_ERROR';
export interface UpdOddShareRequestErrorAction {
  type: typeof UPD_ODD_SHARE_REQUEST_ERROR;
  error: any;
}

export type TradingAction =
  | TdNewOrderAction
  | TdNewOrderRequestingAction
  | TdNewOrderSuccessAction
  | TdNewOrderErrorAction
  | TdCancelOrderRequestingAction
  | TdCancelOrderSuccessAction
  | TdCancelOrderErrorAction
  | TdOrderListRequestingAction
  | TdOrderListSuccessAction
  | TdOrderListErrorAction
  | SymbolIndayRequestAction
  | SymbolIndayRequestSuccessAction
  | SymbolIndayRequestErrorAction
  | OrderHistoryRequestAction
  | OrderHistoryRequestSuccessAction
  | OrderHistoryRequestErrorAction
  | LoanShareRequestAction
  | LoanShareRequestSuccessAction
  | LoanShareRequestErrorAction
  | OddShareRequestAction
  | OddShareRequestSuccessAction
  | OddShareRequestErrorAction
  | OddShareIndayRequestAction
  | OddShareIndayRequestSuccessAction
  | OddShareIndayRequestErrorAction
  | OddShareHisRequestAction
  | OddShareHisRequestSuccessAction
  | OddShareHisRequestErrorAction
  | UpdOddShareRequestAction
  | UpdOddShareRequestSuccessAction
  | UpdOddShareRequestErrorAction;
