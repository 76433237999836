import * as actions from './actionType';

export function cashBalanceRequest(
  data: any,
): actions.CashBalanceRequestAction {
  return {
    type: actions.CASH_BALANCE_REQUESTING,
    data,
  };
}

export const cashBalanceRequestSuccess = function cashBalanceRequestSuccess(
  cashBalance: any,
): actions.CashBalanceRequestSuccessAction {
  return {
    type: actions.CASH_BALANCE_REQUEST_SUCCESS,
    cashBalance,
  };
};

export function cashBalanceRequestError(
  error: any,
): actions.CashBalanceRequestErrorAction {
  return {
    type: actions.CASH_BALANCE_REQUEST_ERROR,
    error,
  };
}

export function shareBalanceRequest(
  data: any,
): actions.ShareBalanceRequestAction {
  return {
    type: actions.SHARE_BALANCE_REQUESTING,
    data,
  };
}

export function shareBalanceRequestSuccess(
  shareBalance: any,
): actions.ShareBalanceRequestSuccessAction {
  return {
    type: actions.SHARE_BALANCE_REQUEST_SUCCESS,
    shareBalance,
  };
}

export function shareBalanceRequestError(
  error: any,
): actions.ShareBalanceRequestErrorAction {
  return {
    type: actions.SHARE_BALANCE_REQUEST_ERROR,
    error,
  };
}

export function purchasePowerRequest(
  data: any,
): actions.PurchasePowerRequestAction {
  return {
    type: actions.PURCHASE_POWER_REQUESTING,
    data,
  };
}

export function purchasePowerRequestSuccess(
  purchasePower: any,
): actions.PurchasePowerRequestSuccessAction {
  return {
    type: actions.PURCHASE_POWER_REQUEST_SUCCESS,
    purchasePower,
  };
}

export function purchasePowerRequestError(
  error: any,
): actions.PurchasePowerRequestErrorAction {
  return {
    type: actions.PURCHASE_POWER_REQUEST_ERROR,
    error,
  };
}

export function stockBalanceRequest(
  data: any,
): actions.StockBalanceRequestAction {
  return {
    type: actions.STOCK_BALANCE_REQUESTING,
    data,
  };
}

export function stockBalanceRequestSuccess(
  stockBalance: any,
): actions.StockBalanceRequestSuccessAction {
  return {
    type: actions.STOCK_BALANCE_REQUEST_SUCCESS,
    stockBalance,
  };
}

export function stockBalanceRequestError(
  error: any,
): actions.StockBalanceRequestErrorAction {
  return {
    type: actions.STOCK_BALANCE_REQUEST_ERROR,
    error,
  };
}

export function allPositionsSet(positions: any): actions.AllPositionSetAction {
  return {
    type: actions.ALL_POSITION_SET,
    positions,
  };
}

export function positionsRequest(data: any): actions.PositionsRequestAction {
  return {
    type: actions.POSITION_REQUESTING,
    data,
  };
}

export function positionsRequestSuccess(
  positions: any,
): actions.PositionsRequestSuccessAction {
  return {
    type: actions.POSITION_REQUEST_SUCCESS,
    positions,
  };
}

export function positionsRequestError(
  error: any,
): actions.PositionsRequestErrorAction {
  return {
    type: actions.POSITION_REQUEST_ERROR,
    error,
  };
}

export function sStockInfoRequest(data: any): actions.SStockInfoRequestAction {
  return {
    type: actions.SSTOCK_INFO_REQUESTING,
    data,
  };
}

export function sStockInfoRequestSuccess(
  resData: any,
): actions.SStockInfoRequestSuccessAction {
  return {
    type: actions.SSTOCK_INFO_REQUEST_SUCCESS,
    resData,
  };
}

export function sStockInfoRequestError(
  error: any,
): actions.SStockInfoRequestErrorAction {
  return {
    type: actions.SSTOCK_INFO_REQUEST_ERROR,
    error,
  };
}

export function newOrderClear(): actions.NewOrderClearAction {
  return {
    type: actions.NEW_ORDER_CLEAR,
  };
}

export function newOrderRequest(data: any): actions.NewOrderRequestAction {
  return {
    type: actions.NEW_ORDER_REQUESTING,
    data,
  };
}

export function newOrderRequestSuccess(
  newOrder: any,
): actions.NewOrderRequestSuccessAction {
  return {
    type: actions.NEW_ORDER_REQUEST_SUCCESS,
    newOrder,
  };
}

export function newOrderRequestError(
  error: any,
): actions.NewOrderRequestErrorAction {
  return {
    type: actions.NEW_ORDER_REQUEST_ERROR,
    error,
  };
}

export function editOrderClear(): actions.EditOrderClearAction {
  return {
    type: actions.EDIT_ORDER_CLEAR,
  };
}

export function editOrderRequest(data: any): actions.EditOrderRequestAction {
  return {
    type: actions.EDIT_ORDER_REQUESTING,
    data,
  };
}

export function editOrderRequestSuccess(
  editOrder: any,
): actions.EditOrderRequestSuccessAction {
  return {
    type: actions.EDIT_ORDER_REQUEST_SUCCESS,
    editOrder,
  };
}

export function editOrderRequestError(
  error: any,
): actions.EditOrderRequestErrorAction {
  return {
    type: actions.EDIT_ORDER_REQUEST_ERROR,
    error,
  };
}

export function cancelOrderClear(): actions.CancelOrderClearAction {
  return {
    type: actions.CANCEL_ORDER_CLEAR,
  };
}

export function cancelOrderRequest(
  data: any,
): actions.CancelOrderRequestAction {
  return {
    type: actions.CANCEL_ORDER_REQUESTING,
    data,
  };
}

export function cancelOrderRequestSuccess(
  cancelOrder: any,
): actions.CancelOrderRequestSuccessAction {
  return {
    type: actions.CANCEL_ORDER_REQUEST_SUCCESS,
    cancelOrder,
  };
}

export function cancelOrderRequestError(
  error: any,
): actions.CancelOrderRequestErrorAction {
  return {
    type: actions.CANCEL_ORDER_REQUEST_ERROR,
    error,
  };
}

export function symbolInfoRequest(data: any): actions.SymbolInfoRequestAction {
  return {
    type: actions.SYMBOL_INFO_REQUESTING,
    data,
  };
}

export function symbolInfoRequestSuccess(
  symbolInfo: any,
): actions.SymbolInfoRequestSuccessAction {
  return {
    type: actions.SYMBOL_INFO_REQUEST_SUCCESS,
    symbolInfo,
  };
}

export function symbolInfoRequestError(
  error: any,
): actions.SymbolInfoRequestErrorAction {
  return {
    type: actions.SYMBOL_INFO_REQUEST_ERROR,
    error,
  };
}

export function matchOrderRequest(data: any): actions.MatchOrderRequestAction {
  return {
    type: actions.MATCH_ORDER_REQUESTING,
    data,
  };
}

export function matchOrderRequestSuccess(
  matchOrder: any,
): actions.MatchOrderRequestSuccessAction {
  return {
    type: actions.MATCH_ORDER_REQUEST_SUCCESS,
    matchOrder,
  };
}

export function matchOrderRequestError(
  error: any,
): actions.MatchOrderRequestErrorAction {
  return {
    type: actions.MATCH_ORDER_REQUEST_ERROR,
    error,
  };
}

export function advanceOrderRequest(
  data: any,
): actions.AdvanceOrderRequestAction {
  return {
    type: actions.ADVANCE_ORDER_REQUESTING,
    data,
  };
}

export function advanceOrderRequestSuccess(
  advOrder: any,
): actions.AdvanceOrderRequestSuccessAction {
  return {
    type: actions.ADVANCE_ORDER_REQUEST_SUCCESS,
    advOrder,
  };
}

export function advanceOrderRequestError(
  error: any,
): actions.AdvanceOrderRequestErrorAction {
  return {
    type: actions.ADVANCE_ORDER_REQUEST_ERROR,
    error,
  };
}

export function condOrderRequest(data: any): actions.CondOrderRequestAction {
  return {
    type: actions.COND_ORDER_REQUESTING,
    data,
  };
}

export function condOrderRequestSuccess(
  resData: any,
): actions.CondOrderRequestSuccessAction {
  return {
    type: actions.COND_ORDER_REQUEST_SUCCESS,
    resData,
  };
}

export function condOrderRequestError(
  error: any,
): actions.CondOrderRequestErrorAction {
  return {
    type: actions.COND_ORDER_REQUEST_ERROR,
    error,
  };
}

export function listOrderConfirmRequest(
  data: any,
): actions.ListOrderConfirmRequestAction {
  return {
    type: actions.LIST_ORDER_CONFIRM_REQUESTING,
    data,
  };
}

export function listOrderConfirmSuccess(
  resData: any,
): actions.ListOrderConfirmSuccessAction {
  return {
    type: actions.LIST_ORDER_CONFIRM_SUCCESS,
    resData,
  };
}

export function listOrderConfirmError(
  error: any,
): actions.ListOrderConfirmErrorAction {
  return {
    type: actions.LIST_ORDER_CONFIRM_ERROR,
    error,
  };
}

export function sellAllSuccess(
  resData: any,
): actions.SellAllSuccessAction {
  return {
    type: actions.SELL_ALL_SUCCESS,
    resData,
  };
}
