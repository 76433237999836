import { ReactComponent as IcModalSc } from 'assets/img/icon/ic-modalsc.svg';
import { ReactComponent as IcMoneyTransfer } from 'assets/img/icon/money-transfer.svg';
import { ReactComponent as IconModalFaildDebt } from 'assets/img/icon/ic-noFaild.svg';
import { ReactComponent as IconWarning } from 'assets/img/icon/ic-warning.svg';
import { ReactComponent as IconSuccess } from 'assets/img/icon/ic-success.svg';

import { ReactComponent as IcPayment } from 'assets/img/icon/payment-noti.svg';
import { INoticeMsg } from 'interface';
import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const styleLg = {
  content: {
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: 'auto',
    left: 'calc( 50% - 210px )',
    height: 'auto',
    width: '420px',
    padding: '0',
    borderWidth: '0',
    overflow: 'inherit',
    borderRadius: '24px',
    background: 'transparent',
  },
};

const styleXs = {
  content: {
    inset: '12px',
    padding: '0',
    borderRadius: '24px',
    overflow: 'inherit',
  },
};

interface Props {
  notice: INoticeMsg;
  children: ReactNode;
  onClose: Function;
}

function Notice(props: Props) {
  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const { notice, children, onClose } = props;

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      style={dimensions.width >= 820 ? styleLg : styleXs}
      className="relative"
    >
      <div className="w-full absolute flex justify-center -top-[106px]">
        {notice?.buttonType === 'CASH_TRANSFER' ? (
          <IcMoneyTransfer />
        ) : notice?.buttonType === 'DEBTS_FAIL' ? (
          <IconModalFaildDebt />
        ) : notice?.buttonType === 'DEBTS_WARNING' ? (
          <IconWarning className="relative top-[125px]" />
        ) : notice?.buttonType === 'NICKNAME' ? (
          <IconSuccess className="relative top-[125px]" />
        ) : notice?.buttonType === 'PAYMENT' ? (
          <IcPayment />
        ) : (
          <IcModalSc />
        )}
      </div>
      <div className=" w-full h-full p-6 rounded-3xl m-auto bg-skin-modal2 border border-skin-modal">
        <div className="my-12 text-center">
          <div className="text-xl	font-bold mb-4">{notice?.title}</div>
          <div className="text-base text-skin-body text-center">
            {notice?.msg}
          </div>
        </div>
        {children}
      </div>
    </ReactModal>
  );
}

export default React.memo(Notice);
