/** List chi nhánh */
export const GL_LIST_BRANCH_REQUESTING = 'glBranch/REQUESTING';
export interface GlListBranchRequestingAction {
  type: typeof GL_LIST_BRANCH_REQUESTING;
  data: any;
}

export const GL_LIST_BRANCH_SUCCESS = 'glBranch/SUCCESS';
export interface GlListBranchSuccessAction {
  type: typeof GL_LIST_BRANCH_SUCCESS;
  resData: any;
}

export const GL_LIST_BRANCH_ERROR = 'glBranch/ERROR';
export interface GlListBranchErrorAction {
  type: typeof GL_LIST_BRANCH_ERROR;
  error: any;
}

/** List phòng giao dịch */
export const GL_LIST_SUBBRANCH_REQUESTING = 'glSubBranch/REQUESTING';
export interface GlListSubBranchRequestingAction {
  type: typeof GL_LIST_SUBBRANCH_REQUESTING;
  data: any;
}

export const GL_LIST_SUBBRANCH_SUCCESS = 'glSubBranch/SUCCESS';
export interface GlListSubBranchSuccessAction {
  type: typeof GL_LIST_SUBBRANCH_SUCCESS;
  resData: any;
}

export const GL_LIST_SUBBRANCH_ERROR = 'glSubBranch/ERROR';
export interface GlListSubBranchErrorAction {
  type: typeof GL_LIST_SUBBRANCH_ERROR;
  error: any;
}

/** Loại import */
export const GL_IMPORT_TYPE_REQUESTING = 'gl/IMPORT_TYPE_REQUESTING';
export interface GlImportTypeRequestingAction {
  type: typeof GL_IMPORT_TYPE_REQUESTING;
  data: any;
}

export const GL_IMPORT_TYPE_SUCCESS = 'gl/IMPORT_TYPE_SUCCESS';
export interface GlImportTypeSuccessAction {
  type: typeof GL_IMPORT_TYPE_SUCCESS;
  resData: any;
}

export const GL_IMPORT_TYPE_ERROR = 'gl/IMPORT_TYPE_ERROR';
export interface GlImportTypeErrorAction {
  type: typeof GL_IMPORT_TYPE_ERROR;
  error: any;
}

export type GlobalAction =
  | GlListBranchRequestingAction
  | GlListBranchSuccessAction
  | GlListBranchErrorAction
  | GlListSubBranchRequestingAction
  | GlListSubBranchSuccessAction
  | GlListSubBranchErrorAction
  | GlImportTypeRequestingAction
  | GlImportTypeSuccessAction
  | GlImportTypeErrorAction;
