import * as actions from './actionType';

export interface SettingState {
  setParams: any;
  setParamDetail: any;
  setParamUpd: any;
  setParamDel: any;

  setFuncs: any;
  setFuncDetail: any;
  setFuncUpd: any;
  setFuncDel: any;

  setUserGrs: any;
  setUserGrDetail: any;
  setUserGrUpd: any;
  setUserGrDel: any;
  setUserGrFunc: any;
}

const initialState = {
  setParams: null,
  setParamDetail: null,
  setParamUpd: null,
  setParamDel: null,

  setFuncs: null,
  setFuncDetail: null,
  setFuncUpd: null,
  setFuncDel: null,

  setUserGrs: null,
  setUserGrDetail: null,
  setUserGrUpd: null,
  setUserGrDel: null,
  setUserGrFunc: null,
};

export default function settingReducer(
  state: SettingState = initialState,
  action: actions.SettingAction,
): SettingState {
  switch (action.type) {
    case actions.SET_PARAM_SEARCH_ERROR:
      return {
        ...state,
        setParams: null,
      };

    case actions.SET_PARAM_SEARCH_SUCCESS:
      return {
        ...state,
        setParams: action.resData,
      };

    case actions.SET_PARAM_SINGLE_REQUESTING:
    case actions.SET_PARAM_SINGLE_ERROR:
      return {
        ...state,
        setParamDetail: null,
      };

    case actions.SET_PARAM_SINGLE_SUCCESS:
      return {
        ...state,
        setParamDetail: action.resData,
      };

    case actions.SET_PARAM_UPD_REQUESTING:
    case actions.SET_PARAM_UPD_ERROR:
      return {
        ...state,
        setParamUpd: null,
      };

    case actions.SET_PARAM_UPD_SUCCESS:
      return {
        ...state,
        setParamUpd: action.resData,
      };

    case actions.SET_PARAM_DEL_REQUESTING:
    case actions.SET_PARAM_DEL_ERROR:
      return {
        ...state,
        setParamDel: null,
      };

    case actions.SET_PARAM_DEL_SUCCESS:
      return {
        ...state,
        setParamDel: action.resData,
      };

    // case actions.FUNC_SEARCH_REQUESTING:
    case actions.FUNC_SEARCH_ERROR:
      return {
        ...state,
        setFuncs: null,
      };

    case actions.FUNC_SEARCH_SUCCESS:
      return {
        ...state,
        setFuncs: action.resData,
      };

    case actions.FUNC_DETAIL_REQUESTING:
    case actions.FUNC_DETAIL_ERROR:
      return {
        ...state,
        setFuncDetail: null,
      };

    case actions.FUNC_DETAIL_SUCCESS:
      return {
        ...state,
        setFuncDetail: action.resData,
      };

    case actions.FUNC_UPD_REQUESTING:
    case actions.FUNC_UPD_ERROR:
      return {
        ...state,
        setFuncUpd: null,
      };

    case actions.FUNC_UPD_SUCCESS:
      return {
        ...state,
        setFuncUpd: action.resData,
      };

    case actions.FUNC_DEL_REQUESTING:
    case actions.FUNC_DEL_ERROR:
      return {
        ...state,
        setFuncDel: null,
      };

    case actions.FUNC_DEL_SUCCESS:
      return {
        ...state,
        setFuncDel: action.resData,
      };

    // case actions.FUNC_DEL_REQUESTING:
    case actions.USER_GR_SEARCH_ERROR:
      return {
        ...state,
        setUserGrs: null,
      };

    case actions.USER_GR_SEARCH_SUCCESS:
      return {
        ...state,
        setUserGrs: action.resData,
      };

    case actions.USER_GR_DETAIL_REQUESTING:
    case actions.USER_GR_DETAIL_ERROR:
      return {
        ...state,
        setUserGrDetail: null,
      };

    case actions.USER_GR_DETAIL_SUCCESS:
      return {
        ...state,
        setUserGrDetail: action.resData,
      };

    case actions.USER_GR_UPD_REQUESTING:
    case actions.USER_GR_UPD_ERROR:
      return {
        ...state,
        setUserGrUpd: null,
      };

    case actions.USER_GR_UPD_SUCCESS:
      return {
        ...state,
        setUserGrUpd: action.resData,
      };

    case actions.USER_GR_DEL_REQUESTING:
    case actions.USER_GR_DEL_ERROR:
      return {
        ...state,
        setUserGrDel: null,
      };

    case actions.USER_GR_DEL_SUCCESS:
      return {
        ...state,
        setUserGrDel: action.resData,
      };

    case actions.USER_GR_FUNC_REQUESTING:
    case actions.USER_GR_FUNC_ERROR:
      return {
        ...state,
        setUserGrFunc: null,
      };

    case actions.USER_GR_FUNC_SUCCESS:
      return {
        ...state,
        setUserGrFunc: action.resData,
      };

    default:
      return state;
  }
}
