export const STOCK_DETAIL_REQUESTING = 'stockDetail/STOCK_DETAIL_REQUESTING';
export interface StockDetailRequestAction {
  type: typeof STOCK_DETAIL_REQUESTING;
  data: any;
}

export const STOCK_DETAIL_REQUEST_SUCCESS = 'stockDetail/STOCK_DETAIL_SUCCESS';
export interface StockDetailRequestSuccessAction {
  type: typeof STOCK_DETAIL_REQUEST_SUCCESS;
  resData: any;
}

export const STOCK_DETAIL_REQUEST_ERROR = 'stockDetail/STOCK_DETAIL_ERROR';
export interface StockDetailRequestErrorAction {
  type: typeof STOCK_DETAIL_REQUEST_ERROR;
  error: any;
}

export const STOCK_TRADE_REQUESTING = 'stockDetail/STOCK_TRADE_REQUESTING';
export interface StockTradeRequestAction {
  type: typeof STOCK_TRADE_REQUESTING;
  data: any;
}

export const STOCK_TRADE_REQUEST_SUCCESS = 'stockDetail/STOCK_TRADE_SUCCESS';
export interface StockTradeRequestSuccessAction {
  type: typeof STOCK_TRADE_REQUEST_SUCCESS;
  resData: any;
}

export const STOCK_TRADE_REQUEST_ERROR = 'stockDetail/STOCK_TRADE_ERROR';
export interface StockTradeRequestErrorAction {
  type: typeof STOCK_TRADE_REQUEST_ERROR;
  error: any;
}

export const STOCK_HIS_REQUESTING = 'stockDetail/STOCK_HIS_REQUESTING';
export interface StockHisRequestAction {
  type: typeof STOCK_HIS_REQUESTING;
  data: any;
}

export const STOCK_HIS_REQUEST_SUCCESS = 'stockDetail/STOCK_HIS_SUCCESS';
export interface StockHisRequestSuccessAction {
  type: typeof STOCK_HIS_REQUEST_SUCCESS;
  resData: any;
}

export const STOCK_HIS_REQUEST_ERROR = 'stockDetail/STOCK_HIS_ERROR';
export interface StockHisRequestErrorAction {
  type: typeof STOCK_HIS_REQUEST_ERROR;
  error: any;
}

export const TRANSFER_DATA_REQUESTING = 'stockDetail/TRANSFER_DATA_REQUESTING';
export interface TransferDataRequestAction {
  type: typeof TRANSFER_DATA_REQUESTING;
  data: any;
}

export const TRANSFER_DATA_REQUEST_SUCCESS =
  'stockDetail/TRANSFER_DATA_SUCCESS';
export interface TransferDataRequestSuccessAction {
  type: typeof TRANSFER_DATA_REQUEST_SUCCESS;
  resData: any;
}

export const TRANSFER_DATA_REQUEST_ERROR = 'stockDetail/TRANSFER_DATA_ERROR';
export interface TransferDataRequestErrorAction {
  type: typeof TRANSFER_DATA_REQUEST_ERROR;
  error: any;
}

export const STOCK_NEWS_REQUESTING = 'stockDetail/STOCK_NEWS_REQUESTING';
export interface StockNewsRequestAction {
  type: typeof STOCK_NEWS_REQUESTING;
  data: any;
}

export const STOCK_NEWS_REQUEST_SUCCESS = 'stockDetail/STOCK_NEWS_SUCCESS';
export interface StockNewsRequestSuccessAction {
  type: typeof STOCK_NEWS_REQUEST_SUCCESS;
  resData: any;
}

export const STOCK_NEWS_REQUEST_ERROR = 'stockDetail/STOCK_NEWS_ERROR';
export interface StockNewsRequestErrorAction {
  type: typeof STOCK_NEWS_REQUEST_ERROR;
  error: any;
}

export const STOCK_EVENTS_REQUESTING = 'stockDetail/STOCK_EVENTS_REQUESTING';
export interface StockEventsRequestAction {
  type: typeof STOCK_EVENTS_REQUESTING;
  data: any;
}

export const STOCK_EVENTS_REQUEST_SUCCESS = 'stockDetail/STOCK_EVENTS_SUCCESS';
export interface StockEventsRequestSuccessAction {
  type: typeof STOCK_EVENTS_REQUEST_SUCCESS;
  resData: any;
}

export const STOCK_EVENTS_REQUEST_ERROR = 'stockDetail/STOCK_EVENTS_ERROR';
export interface StockEventsRequestErrorAction {
  type: typeof STOCK_EVENTS_REQUEST_ERROR;
  error: any;
}

export const CST_REQUESTING = 'stockDetail/CST_REQUESTING';
export interface CstRequestAction {
  type: typeof CST_REQUESTING;
  data: any;
}

export const CST_REQUEST_SUCCESS = 'stockDetail/CST_REQUEST_SUCCESS';
export interface CstRequestSuccessAction {
  type: typeof CST_REQUEST_SUCCESS;
  resData: any;
}

export const CST_REQUEST_ERROR = 'stockDetail/CST_REQUEST_ERROR';
export interface CstRequestErrorAction {
  type: typeof CST_REQUEST_ERROR;
  error: any;
}

export const KQKD_REQUESTING = 'stockDetail/KQKD_REQUESTING';
export interface KqkdRequestAction {
  type: typeof KQKD_REQUESTING;
  data: any;
}

export const KQKD_REQUEST_SUCCESS = 'stockDetail/KQKD_SUCCESS';
export interface KqkdRequestSuccessAction {
  type: typeof KQKD_REQUEST_SUCCESS;
  resData: any;
}

export const KQKD_REQUEST_ERROR = 'stockDetail/KQKD_ERROR';
export interface KqkdRequestErrorAction {
  type: typeof KQKD_REQUEST_ERROR;
  error: any;
}

export const CDKT_REQUESTING = 'stockDetail/CDKT_REQUESTING';
export interface CdktRequestAction {
  type: typeof CDKT_REQUESTING;
  data: any;
}

export const CDKT_REQUEST_SUCCESS = 'stockDetail/CDKT_SUCCESS';
export interface CdktRequestSuccessAction {
  type: typeof CDKT_REQUEST_SUCCESS;
  resData: any;
}

export const CDKT_REQUEST_ERROR = 'stockDetail/CDKT_ERROR';
export interface CdktRequestErrorAction {
  type: typeof CDKT_REQUEST_ERROR;
  error: any;
}

export const NEWS_DETAIL_REQUESTING = 'stockDetail/NEWS_DETAIL_REQUESTING';
export interface NewsDetailRequestAction {
  type: typeof NEWS_DETAIL_REQUESTING;
  data: any;
}

export const NEWS_DETAIL_REQUEST_SUCCESS = 'stockDetail/NEWS_DETAIL_SUCCESS';
export interface NewsDetailRequestSuccessAction {
  type: typeof NEWS_DETAIL_REQUEST_SUCCESS;
  resData: any;
}

export const NEWS_DETAIL_REQUEST_ERROR = 'stockDetail/NEWS_DETAIL_ERROR';
export interface NewsDetailRequestErrorAction {
  type: typeof NEWS_DETAIL_REQUEST_ERROR;
  error: any;
}

export const STOCK_MANAGEMENT_REQUESTING =
  'stockDetail/STOCK_MANAGEMENT_REQUESTING';
export interface StockManagementRequestAction {
  type: typeof STOCK_MANAGEMENT_REQUESTING;
  data: any;
}

export const STOCK_MANAGEMENT_REQUEST_SUCCESS =
  'stockDetail/STOCK_MANAGEMENT_SUCCESS';
export interface StockManagementRequestSuccessAction {
  type: typeof STOCK_MANAGEMENT_REQUEST_SUCCESS;
  resData: any;
}

export const STOCK_MANAGEMENT_REQUEST_ERROR =
  'stockDetail/STOCK_MANAGEMENT_ERROR';
export interface StockManagementRequestErrorAction {
  type: typeof STOCK_MANAGEMENT_REQUEST_ERROR;
  error: any;
}

export const COMPANY_INFO_REQUESTING = 'stockDetail/COMPANY_INFO_REQUESTING';
export interface CompanyInfoRequestAction {
  type: typeof COMPANY_INFO_REQUESTING;
  data: any;
}

export const COMPANY_INFO_REQUEST_SUCCESS = 'stockDetail/COMPANY_INFO_SUCCESS';
export interface CompanyInfoRequestSuccessAction {
  type: typeof COMPANY_INFO_REQUEST_SUCCESS;
  resData: any;
}

export const COMPANY_INFO_REQUEST_ERROR = 'stockDetail/COMPANY_INFO_ERROR';
export interface CompanyInfoRequestErrorAction {
  type: typeof COMPANY_INFO_REQUEST_ERROR;
  error: any;
}

export const ASSOCIATE_REQUESTING = 'stockDetail/ASSOCIATE_REQUESTING';
export interface AssociateRequestAction {
  type: typeof ASSOCIATE_REQUESTING;
  data: any;
}

export const ASSOCIATE_REQUEST_SUCCESS = 'stockDetail/ASSOCIATE_SUCCESS';
export interface AssociateRequestSuccessAction {
  type: typeof ASSOCIATE_REQUEST_SUCCESS;
  resData: any;
}

export const ASSOCIATE_REQUEST_ERROR = 'stockDetail/ASSOCIATE_ERROR';
export interface AssociateRequestErrorAction {
  type: typeof ASSOCIATE_REQUEST_ERROR;
  error: any;
}

export const CLEAR_CACHE_STOCK_DETAIL = 'stockDetail/CLEAR_CACHE_STOCK_DETAIL';
export interface ClearCacheAction {
  type: typeof CLEAR_CACHE_STOCK_DETAIL;
}

export const COLLECTION_PRICE_REQUESTING =
  'stockDetail/COLLECTION_PRICE_REQUESTING';
export interface CollectionPriceRequestAction {
  type: typeof COLLECTION_PRICE_REQUESTING;
  data: any;
}

export const COLLECTION_PRICE_REQUEST_SUCCESS =
  'stockDetail/COLLECTION_PRICE_SUCCESS';
export interface CollectionPriceRequestSuccessAction {
  type: typeof COLLECTION_PRICE_REQUEST_SUCCESS;
  resData: any;
}

export const COLLECTION_PRICE_REQUEST_ERROR =
  'stockDetail/COLLECTION_PRICE_ERROR';
export interface CollectionPriceRequestErrorAction {
  type: typeof COLLECTION_PRICE_REQUEST_ERROR;
  error: any;
}

export const TOP_MATCH_REQUESTING = 'stockDetail/TOP_MATCH_REQUESTING';
export interface TopMatchRequestAction {
  type: typeof TOP_MATCH_REQUESTING;
  data: any;
}

export const TOP_MATCH_REQUEST_SUCCESS = 'stockDetail/TOP_MATCH_SUCCESS';
export interface TopMatchRequestSuccessAction {
  type: typeof TOP_MATCH_REQUEST_SUCCESS;
  resData: any;
}

export const TOP_MATCH_REQUEST_ERROR = 'stockDetail/TOP_MATCH_ERROR';
export interface TopMatchRequestErrorAction {
  type: typeof TOP_MATCH_REQUEST_ERROR;
  error: any;
}

export type StockDetailAction =
  | ClearCacheAction
  | StockDetailRequestAction
  | StockDetailRequestSuccessAction
  | StockDetailRequestErrorAction
  | StockTradeRequestAction
  | StockTradeRequestSuccessAction
  | StockTradeRequestErrorAction
  | StockHisRequestAction
  | StockHisRequestSuccessAction
  | StockHisRequestErrorAction
  | TransferDataRequestAction
  | TransferDataRequestSuccessAction
  | TransferDataRequestErrorAction
  | StockNewsRequestAction
  | StockNewsRequestSuccessAction
  | StockNewsRequestErrorAction
  | StockEventsRequestAction
  | StockEventsRequestSuccessAction
  | StockEventsRequestErrorAction
  | CstRequestAction
  | CstRequestSuccessAction
  | CstRequestErrorAction
  | KqkdRequestAction
  | KqkdRequestSuccessAction
  | KqkdRequestErrorAction
  | CdktRequestAction
  | CdktRequestSuccessAction
  | CdktRequestErrorAction
  | NewsDetailRequestAction
  | NewsDetailRequestSuccessAction
  | NewsDetailRequestErrorAction
  | StockManagementRequestAction
  | StockManagementRequestSuccessAction
  | StockManagementRequestErrorAction
  | CompanyInfoRequestAction
  | CompanyInfoRequestSuccessAction
  | CompanyInfoRequestErrorAction
  | AssociateRequestAction
  | AssociateRequestSuccessAction
  | AssociateRequestErrorAction
  | CollectionPriceRequestAction
  | CollectionPriceRequestSuccessAction
  | CollectionPriceRequestErrorAction
  | TopMatchRequestAction
  | TopMatchRequestSuccessAction
  | TopMatchRequestErrorAction;
