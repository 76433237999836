import * as actions from './actionType';

export interface AccInfoState {
  accInfo: any;
  accInfoUpd: any;
  accCashAppr: any;
  accBenUpd: any;
  accBenList: any;
  accBenDef: any;
  accBenDel: any;
  accSaleInfor: any;
  accSaleInforSingle: any;
  accSaleInforUpd: any;
  createNickname: any;
  manLoginDev: any;
}

const initialState = {
  accInfo: null,
  accInfoUpd: null,
  accCashAppr: null,
  accBenUpd: null,
  accBenDef: null,
  accBenList: null,
  accBenDel: null,
  accSaleInfor: null,
  accSaleInforSingle: null,
  accSaleInforUpd: null,
  createNickname: null,
  manLoginDev: null,
};

export default function mgBankReducer(
  state: AccInfoState = initialState,
  action: actions.AccountInfoAction,
): AccInfoState {
  switch (action.type) {
    // case actions.PAWN_CASH_REQUESTING:
    case actions.ACC_INFO_REQUESTING:
      return {
        ...state,
        accInfo: null,
      };

    case actions.ACC_INFO_SUCCESS:
      return {
        ...state,
        accInfo: action.resData,
      };

    case actions.ACC_UPD_INFO_REQUESTING:
    case actions.ACC_UPD_INFO_REQUEST_ERROR:
      return {
        ...state,
        accInfoUpd: null,
      };

    case actions.ACC_UPD_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        accInfoUpd: action.resData,
      };

    case actions.ACC_CASH_APPR_REQUESTING:
    case actions.ACC_CASH_APPR_ERROR:
      return {
        ...state,
        accCashAppr: null,
      };

    case actions.ACC_CASH_APPR_SUCCESS:
      return {
        ...state,
        accCashAppr: action.resData,
      };

    case actions.ACC_BEN_UPD_REQUESTING:
    case actions.ACC_BEN_UPD_ERROR:
      return {
        ...state,
        accBenUpd: null,
      };

    case actions.ACC_BEN_UPD_SUCCESS:
      return {
        ...state,
        accBenUpd: action.resData,
      };

    case actions.ACC_BEN_LIST_ERROR:
      return {
        ...state,
        accBenList: null,
      };

    case actions.ACC_BEN_LIST_SUCCESS:
      return {
        ...state,
        accBenList: action.resData,
      };

    case actions.ACC_BEN_DEL_REQUESTING:
    case actions.ACC_BEN_DEL_ERROR:
      return {
        ...state,
        accBenDel: null,
      };

    case actions.ACC_BEN_DEL_SUCCESS:
      return {
        ...state,
        accBenDel: action.resData,
      };

    case actions.ACC_BEN_DEF_REQUESTING:
    case actions.ACC_BEN_DEF_ERROR:
      return {
        ...state,
        accBenDef: null,
      };

    case actions.ACC_BEN_DEF_SUCCESS:
      return {
        ...state,
        accBenDef: action.resData,
      };

    case actions.ACC_SALE_INFOR_LIST_ERROR:
      return {
        ...state,
        accSaleInfor: null,
      };

    case actions.ACC_SALE_INFOR_LIST_SUCCESS:
      return {
        ...state,
        accSaleInfor: action.resData,
      };

    case actions.ACC_SALE_INFOR_ERROR:
      return {
        ...state,
        accSaleInforSingle: null,
      };

    case actions.ACC_SALE_INFOR_SUCCESS:
      return {
        ...state,
        accSaleInforSingle: action.resData,
      };

    case actions.ACC_SALE_INFOR_UPD_ERROR:
      return {
        ...state,
        accSaleInforUpd: null,
      };

    case actions.ACC_SALE_INFOR_UPD_SUCCESS:
      return {
        ...state,
        accSaleInforUpd: action.resData,
      };

    case actions.SHOW_CREATE_NICKNAME:
      return {
        ...state,
        createNickname: action.resData,
      };

    case actions.MAN_LOGIN_DEV_ERROR:
      return {
        ...state,
        manLoginDev: null,
      };

    case actions.MAN_LOGIN_DEV_SUCCESS:
      return {
        ...state,
        manLoginDev: action.resData,
      };

    default:
      return state;
  }
}
