
export default function RenderContentToast(toast: any) {
  console.log(toast)
  return (
    <div className="grid w-full gap-1 py-2">
      {toast?.toast.title && (
        <span className="text-skin-white text-base font-medium">
          {toast.toast?.title}
        </span>
      )}
      <span className="text-skin-text3 text-sm font-normal">
        {toast.toast?.msg || ''}
      </span>
    </div>
  )
}