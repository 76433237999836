import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';

import * as actions from './actionType';
import axios from 'axios';
import {
  accBenListError,
  accBenListSuccess,
  accBenUpdError,
  accBenUpdSuccess,
  accCashApprError,
  accCashApprSuccess,
  accInfoError,
  accInfoRequest,
  accInfoSuccess,
  accUpdInfoSuccess,
  accUpdInfoError,
  accBenDelSuccess,
  accBenDelError,
  accBenDefSuccess,
  accBenDefError,
  accSaleInforListError,
  accSaleInforListSuccess,
  accSaleInforError,
  accSaleInforSuccess,
  accSaleInforUpdError,
  accSaleInforUpdSuccess,
  manLoginDevError,
  manLoginDevSuccess,
} from './actions';
import { checkInvalidSession } from 'utils';
import { storages } from 'lib/storages';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrl = `${process.env.REACT_APP_API_URL}`;

async function postRequestApi(data: Object) {
  const url = `${appUrl}/TraditionalService`;

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* accountInfoRequestFlow(action: actions.AccInfoRequestingAction): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accInfoSuccess(_res.data[0]));
  } catch (error: any) {
    yield put(accInfoError(error));
  }
}

function* accountInforUpdRequestFlow(
  action: actions.AccUpdInfoRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accUpdInfoSuccess(Math.random()));
  } catch (error: any) {
    yield put(accUpdInfoError(error));
  }
}

function* accountCashApprRequestFlow(
  action: actions.AccCashApprRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accCashApprSuccess(Math.random()));
  } catch (error: any) {
    yield put(accCashApprError(error));
  }
}

function* accountBenUpdRequestFlow(
  action: actions.AccBenUpdRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accBenUpdSuccess(Math.random()));
  } catch (error: any) {
    yield put(accBenUpdError(error));
  }
}

function* accountBenListRequestFlow(
  action: actions.AccBenListRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accBenListSuccess(_res.data));
  } catch (error: any) {
    yield put(accBenListError(error));
  }
}

function* accountBenDelRequestFlow(
  action: actions.AccBenDelRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accBenDelSuccess(Math.random()));
  } catch (error: any) {
    yield put(accBenDelError(error));
  }
}

function* accountBenDefRequestFlow(
  action: actions.AccBenDefRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accBenDefSuccess(Math.random()));
  } catch (error: any) {
    yield put(accBenDefError(error));
  }
}

function* accSaleInforRequestFlow(
  action: actions.AccBenListRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accSaleInforListSuccess(_res.data));
  } catch (error: any) {
    yield put(accSaleInforListError(error));
  }
}

function* accSaleInforSingleRequestFlow(
  action: actions.AccSaleInforRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accSaleInforSuccess(_res.data));
  } catch (error: any) {
    yield put(accSaleInforError(error));
  }
}

function* accSaleInforUpdRequestFlow(
  action: actions.AccSaleInforUpdRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accSaleInforUpdSuccess(_res.data));
  } catch (error: any) {
    yield put(accSaleInforUpdError(error));
  }
}

function* manLoginDevRequestFlow(
  action: actions.AccBenListRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(manLoginDevSuccess(_res.data));
  } catch (error: any) {
    yield put(manLoginDevError(error));
  }
}

function* custWatcher() {
  yield all([
    takeLatest(actions.ACC_INFO_REQUESTING, accountInfoRequestFlow),
    takeLatest(actions.ACC_UPD_INFO_REQUESTING, accountInforUpdRequestFlow),
    takeLatest(actions.ACC_CASH_APPR_REQUESTING, accountCashApprRequestFlow),
    takeLatest(actions.ACC_BEN_UPD_REQUESTING, accountBenUpdRequestFlow),
    takeLatest(actions.ACC_BEN_LIST_REQUESTING, accountBenListRequestFlow),
    takeLatest(actions.ACC_BEN_DEL_REQUESTING, accountBenDelRequestFlow),
    takeLatest(actions.ACC_BEN_DEF_REQUESTING, accountBenDefRequestFlow),
    takeLatest(actions.ACC_SALE_INFOR_LIST_REQUESTING, accSaleInforRequestFlow),
    takeLatest(
      actions.ACC_SALE_INFOR_REQUESTING,
      accSaleInforSingleRequestFlow,
    ),
    takeLatest(
      actions.ACC_SALE_INFOR_UPD_REQUESTING,
      accSaleInforUpdRequestFlow,
    ),
    takeLatest(actions.MAN_LOGIN_DEV_REQUESTING, manLoginDevRequestFlow),
  ]);
}

export default custWatcher;
