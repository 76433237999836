export const CLIENT_UNSET = 'CLIENT_UNSET';
export interface ClientUnsetAction {
  type: typeof CLIENT_UNSET;
}

export const CLIENT_SET = 'CLIENT_SET';
export interface ClientSetAction {
  type: typeof CLIENT_SET;
  data: any;
}

export const CLIENT_TOAST_SET = 'CLIENT_TOAST_SET';
export interface ClientToastSetAction {
  type: typeof CLIENT_TOAST_SET;
  data: any;
}

export const CLIENT_TOAST_CLEAR = 'CLIENT_TOAST_CLEAR';
export interface ClientToastClearAction {
  type: typeof CLIENT_TOAST_CLEAR;
}

export const CLIENT_CONFIG_SET = 'CLIENT_CONFIG_SET';
export interface ClientConfigSetAction {
  type: typeof CLIENT_CONFIG_SET;
  data: any;
}

export const CLIENT_TYPENAV_SET = 'CLIENT_TYPENAV_SET';
export interface ClientTypeNavSetAction {
  type: typeof CLIENT_TYPENAV_SET;
  data: any;
}

export const DATA_SEARCH_SET = 'DATA_SEARCH_SET';
export interface DataSearchSetAction {
  type: typeof DATA_SEARCH_SET;
  data: any;
}

export const SHOW_NAV_SET = 'SHOW_NAV_SET';
export interface ShowNavAction {
  type: typeof SHOW_NAV_SET;
  data: any;
}

export const SHOW_TOUR_SET = 'SHOW_TOUR_SET';
export interface ShowTourAction {
  type: typeof SHOW_TOUR_SET;
  data: any;
}

export const SHOW_SLIDE_SET = 'SHOW_SLIDE_SET';
export interface ShowSlideAction {
  type: typeof SHOW_SLIDE_SET;
  data: any;
}

export const MODE_PRICEBOARD = 'MODE_PRICEBOARD';
export interface ModePriceboardAction {
  type: typeof MODE_PRICEBOARD;
  data: any;
}

export const CLIENT_SETTING_SET = 'CLIENT_SETTING_SET';
export interface ClientSettingSetAction {
  type: typeof CLIENT_SETTING_SET;
  data: any;
}

export const FOOTER_ACTIVE_SET = 'FOOTER_ACTIVE_SET';
export interface FooterActiveSetAction {
  type: typeof FOOTER_ACTIVE_SET;
  data: any;
}

export const CATEGORY_SET = 'CATEGORY_SET';
export interface SetCategoryAction {
  type: typeof CATEGORY_SET;
  category: any;
}

export const CATEGORY_NGANH_SET = 'CATEGORY_NGANH_SET';
export interface SetCategoryNganhAction {
  type: typeof CATEGORY_NGANH_SET;
  category: any;
}

export const CATEGORY_PORTFOLIO_SET = 'CATEGORY_PORTFOLIO_SET';
export interface SetCategoryPortfolioAction {
  type: typeof CATEGORY_PORTFOLIO_SET;
  category: any;
}

export const SETTING_SET = 'SETTING_SET';
export interface SetSettingAction {
  type: typeof SETTING_SET;
  setting: any;
}

export const LOGIN_SET = 'LOGIN_SET';
export interface SetLoginAction {
  type: typeof LOGIN_SET;
}

export const LOGIN_UNSET = 'LOGIN_UNSET';
export interface UnsetLoginAction {
  type: typeof LOGIN_UNSET;
}

export const CHANGE_PASS_SET = 'CHANGE_PASS_SET';
export interface SetChangePassAction {
  type: typeof CHANGE_PASS_SET;
  data: any;
}

export const CHANGE_PIN_SET = 'CHANGE_PIN_SET';
export interface SetChangePinAction {
  type: typeof CHANGE_PIN_SET;
  data: any;
}

export const OBJ_TRADING_SET = 'OBJ_TRADING_SET';
export interface SetObjTradingAction {
  type: typeof OBJ_TRADING_SET;
  data: any;
}

export const OBJ_TRADING_UNSET = 'OBJ_TRADING_UNSET';
export interface UnsetObjTradingAction {
  type: typeof OBJ_TRADING_UNSET;
}

export const SYMBOL_ADD = 'SYMBOL_ADD';
export interface SetSymbolAddAction {
  type: typeof SYMBOL_ADD;
  symbol: any;
}

export const SYMBOL_SCROLL = 'SYMBOL_SCROLL';
export interface SetSymbolScrollAction {
  type: typeof SYMBOL_SCROLL;
  symbol: any;
}

export const SYMBOL_PIN = 'SYMBOL_PIN';
export interface SetSymbolPinAction {
  type: typeof SYMBOL_PIN;
  symbol: any;
}

export const TYPE_INDEX_SET = 'TYPE_INDEX_SET';
export interface SetTypeIndexAction {
  type: typeof TYPE_INDEX_SET;
  data: any;
}

export const TYPE_PRICE_SET = 'TYPE_PRICE_SET';
export interface SetTypePriceAction {
  type: typeof TYPE_PRICE_SET;
  data: any;
}

export const INDEX_HIDE_SET = 'INDEX_HIDE_SET';
export interface SetIndexHideAction {
  type: typeof INDEX_HIDE_SET;
  data: any;
}

export const DBL_PRICE_SET = 'DBL_PRICE_SET';
export interface SetDblPriceAction {
  type: typeof DBL_PRICE_SET;
  data: any;
}

export const DBL_SELL_ALL_SET = 'DBL_SELL_ALL_SET';
export interface SetDblSellAllAction {
  type: typeof DBL_SELL_ALL_SET;
  data: any;
}

export const SYMBOL_ACTIVE_SET = 'SYMBOL_ACTIVE_SET';
export interface SetSymbolActiveAction {
  type: typeof SYMBOL_ACTIVE_SET;
  data: any;
}

export const CATEGORY_REQUESTING = 'CATEGORY_REQUESTING';
export interface CategoryRequestAction {
  type: typeof CATEGORY_REQUESTING;
  data: any;
}

export const CATEGORY_REQUEST_SUCCESS = 'CATEGORY_SUCCESS';
export interface CategoryRequestSuccessAction {
  type: typeof CATEGORY_REQUEST_SUCCESS;
  category: any;
}

export const CATEGORY_REQUEST_ERROR = 'CATEGORY_ERROR';
export interface CategoryRequestErrorAction {
  type: typeof CATEGORY_REQUEST_ERROR;
  error: any;
}

export const LIST_ACCOUNT_REQUESTING = 'LIST_ACCOUNT_REQUESTING';
export interface ListAccountRequestAction {
  type: typeof LIST_ACCOUNT_REQUESTING;
  data: any;
}

export const LIST_ACCOUNT_REQUEST_SUCCESS = 'LIST_ACCOUNT_SUCCESS';
export interface ListAccountRequestSuccessAction {
  type: typeof LIST_ACCOUNT_REQUEST_SUCCESS;
  resData: any;
}

export const LIST_ACCOUNT_REQUEST_ERROR = 'LIST_ACCOUNT_ERROR';
export interface ListAccountRequestErrorAction {
  type: typeof LIST_ACCOUNT_REQUEST_ERROR;
  error: any;
}

export const GET_OTP_REQUESTING = 'GET_OTP_REQUESTING';
export interface GetOtpRequestAction {
  type: typeof GET_OTP_REQUESTING;
  data: any;
}

export const GET_OTP_REQUEST_SUCCESS = 'GET_OTP_SUCCESS';
export interface GetOtpRequestSuccessAction {
  type: typeof GET_OTP_REQUEST_SUCCESS;
  resData: any;
}

export const GET_OTP_REQUEST_ERROR = 'GET_OTP_ERROR';
export interface GetOtpRequestErrorAction {
  type: typeof GET_OTP_REQUEST_ERROR;
  error: any;
}

export const GET_BANK_ONLINE_REQUESTING = 'BANK_ONLINE_REQUESTING';
export interface GetBankOnlineRequestAction {
  type: typeof GET_BANK_ONLINE_REQUESTING;
  data: any;
}

export const GET_BANK_ONLINE_REQUEST_SUCCESS = 'BANK_ONLINE_SUCCESS';
export interface GetBankOnlineRequestSuccessAction {
  type: typeof GET_BANK_ONLINE_REQUEST_SUCCESS;
  resData: any;
}

export const GET_BANK_ONLINE_REQUEST_ERROR = 'BANK_ONLINE_ERROR';
export interface GetBankOnlineRequestErrorAction {
  type: typeof GET_BANK_ONLINE_REQUEST_ERROR;
  error: any;
}

export const GET_BANK_BRANCH_REQUESTING = 'BANK_BRANCH_REQUESTING';
export interface GetBankBranchRequestAction {
  type: typeof GET_BANK_BRANCH_REQUESTING;
  data: any;
}

export const GET_BANK_BRANCH_REQUEST_SUCCESS = 'BANK_BRANCH_SUCCESS';
export interface GetBankBranchRequestSuccessAction {
  type: typeof GET_BANK_BRANCH_REQUEST_SUCCESS;
  resData: any;
}

export const GET_BANK_BRANCH_REQUEST_ERROR = 'BANK_BRANCH_REQUEST_ERROR';
export interface GetBankBranchRequestErrorAction {
  type: typeof GET_BANK_BRANCH_REQUEST_ERROR;
  error: any;
}

export const GET_PROVINCE_REQUESTING = 'GET_PROVINCE_REQUESTING';
export interface GetProvinceRequestAction {
  type: typeof GET_PROVINCE_REQUESTING;
  data: any;
}

export const GET_PROVINCE_REQUEST_SUCCESS = 'GET_PROVINCE_SUCCESS';
export interface GetProvinceRequestSuccessAction {
  type: typeof GET_PROVINCE_REQUEST_SUCCESS;
  resData: any;
}

export const GET_PROVINCE_REQUEST_ERROR = 'GET_PROVINCE_ERROR';
export interface GetProvinceRequestErrorAction {
  type: typeof GET_PROVINCE_REQUEST_ERROR;
  error: any;
}

export const DEF_ACCOUNT_SET = 'DEF_ACCOUNT_SET';
export interface DefaultAccountSetAction {
  type: typeof DEF_ACCOUNT_SET;
  resData: any;
}

export const DEF_ACCOUNT_LAYOUT_SET = 'DEF_ACCOUNT_LAYOUT_SET';
export interface DefaultAccountLayoutSetAction {
  type: typeof DEF_ACCOUNT_LAYOUT_SET;
  resData: any;
}

export const ACC_PORTFOLIO_REQUESTING = 'ACC_PORTFOLIO_REQUESTING';
export interface AccountPortfolioRequestAction {
  type: typeof ACC_PORTFOLIO_REQUESTING;
  data: any;
}

export const ACC_PORTFOLIO_SUCCESS = 'ACC_PORTFOLIO_SUCCESS';
export interface AccountPortfolioSuccessAction {
  type: typeof ACC_PORTFOLIO_SUCCESS;
  resData: any;
}

export const ACC_PORTFOLIO_ERROR = 'ACC_PORTFOLIO_ERROR';
export interface AccountPortfolioErrorAction {
  type: typeof ACC_PORTFOLIO_ERROR;
  error: any;
}

export const UPD_PORTFOLIO_REQUESTING = 'UPD_PORTFOLIO_REQUESTING';
export interface UpdatePortfolioRequestAction {
  type: typeof UPD_PORTFOLIO_REQUESTING;
  data: any;
}

export const UPD_PORTFOLIO_SUCCESS = 'UPD_PORTFOLIO_SUCCESS';
export interface UpdatePortfolioSuccessAction {
  type: typeof UPD_PORTFOLIO_SUCCESS;
  resData: any;
}

export const UPD_PORTFOLIO_ERROR = 'UPD_PORTFOLIO_ERROR';
export interface UpdatePortfolioErrorAction {
  type: typeof UPD_PORTFOLIO_ERROR;
  error: any;
}

export const DEL_PORTFOLIO_REQUESTING = 'DEL_PORTFOLIO_REQUESTING';
export interface DelPortfolioRequestAction {
  type: typeof DEL_PORTFOLIO_REQUESTING;
  data: any;
}

export const DEL_PORTFOLIO_SUCCESS = 'DEL_PORTFOLIO_SUCCESS';
export interface DelPortfolioSuccessAction {
  type: typeof DEL_PORTFOLIO_SUCCESS;
  resData: any;
}

export const DEL_PORTFOLIO_ERROR = 'DEL_PORTFOLIO_ERROR';
export interface DelPortfolioErrorAction {
  type: typeof DEL_PORTFOLIO_ERROR;
  error: any;
}

export const CLEAR_LOST_SESSION = 'CLEAR_LOST_SESSION';
export interface UnsetLostSessionAction {
  type: typeof CLEAR_LOST_SESSION;
}

export const SYMBOL_DEL = 'SYMBOL_DEL';
export interface SetSymbolDelAction {
  type: typeof SYMBOL_DEL;
  symbol: any;
}

export const SHOW_TOAST = 'SHOW_TOAST';
export interface SetToastAction {
  type: typeof SHOW_TOAST;
  resData: any;
}

export const CHECK_PIN_REQUESTING = 'CHECK_PIN_REQUESTING';
export interface CheckPinRequestAction {
  type: typeof CHECK_PIN_REQUESTING;
  data: any;
}

export const CHECK_PIN_REQUEST_SUCCESS = 'CHECK_PIN_REQUEST_SUCCESS';
export interface CheckPinRequestSuccessAction {
  type: typeof CHECK_PIN_REQUEST_SUCCESS;
  resData: any;
}

export const CHECK_PIN_REQUEST_ERROR = 'CHECK_PIN_REQUEST_ERROR';
export interface CheckPinRequestErrorAction {
  type: typeof CHECK_PIN_REQUEST_ERROR;
  error: any;
}

export const DATA_CCCD_UNSET = 'DATA_CCCD_UNSET';
export interface DataCccdUnSetAction {
  type: typeof DATA_CCCD_UNSET;
}

export const DATA_CCCD_SET = 'DATA_CCCD_SET';
export interface DataCccdSetAction {
  type: typeof DATA_CCCD_SET;
  data: any;
}

export const GET_DISTRICT_REQUESTING = 'GET_DISTRICT_REQUESTING';
export interface GetDistrictRequestAction {
  type: typeof GET_DISTRICT_REQUESTING;
  data: any;
}

export const GET_DISTRICT_REQUEST_SUCCESS = 'GET_DISTRICT_SUCCESS';
export interface GetDistrictRequestSuccessAction {
  type: typeof GET_DISTRICT_REQUEST_SUCCESS;
  resData: any;
}

export const GET_DISTRICT_REQUEST_ERROR = 'GET_DISTRICT_ERROR';
export interface GetDistrictRequestErrorAction {
  type: typeof GET_DISTRICT_REQUEST_ERROR;
  error: any;
}

export const GET_VILLAGE_REQUESTING = 'GET_VILLAGE_REQUESTING';
export interface GetVillageRequestAction {
  type: typeof GET_VILLAGE_REQUESTING;
  data: any;
}

export const GET_VILLAGE_REQUEST_SUCCESS = 'GET_VILLAGE_SUCCESS';
export interface GetVillageRequestSuccessAction {
  type: typeof GET_VILLAGE_REQUEST_SUCCESS;
  resData: any;
}

export const GET_VILLAGE_REQUEST_ERROR = 'GET_VILLAGE_ERROR';
export interface GetVillageRequestErrorAction {
  type: typeof GET_VILLAGE_REQUEST_ERROR;
  error: any;
}

export type ClientAction =
  | ClientUnsetAction
  | ClientSetAction
  | DataCccdSetAction
  | DataCccdUnSetAction
  | ClientToastSetAction
  | ClientToastClearAction
  | ClientConfigSetAction
  | ClientTypeNavSetAction
  | DataSearchSetAction
  | ClientSettingSetAction
  | ShowNavAction
  | ShowTourAction
  | ShowSlideAction
  | ModePriceboardAction
  | FooterActiveSetAction
  | SetCategoryAction
  | SetCategoryNganhAction
  | SetSettingAction
  | SetLoginAction
  | UnsetLoginAction
  | SetChangePassAction
  | SetChangePinAction
  | SetObjTradingAction
  | UnsetObjTradingAction
  | SetSymbolAddAction
  | SetSymbolScrollAction
  | SetTypeIndexAction
  | SetTypePriceAction
  | SetIndexHideAction
  | SetDblPriceAction
  | SetDblSellAllAction
  | SetSymbolActiveAction
  | CategoryRequestAction
  | CategoryRequestSuccessAction
  | CategoryRequestErrorAction
  | ListAccountRequestAction
  | ListAccountRequestSuccessAction
  | ListAccountRequestErrorAction
  | GetOtpRequestAction
  | GetOtpRequestSuccessAction
  | GetOtpRequestErrorAction
  | GetBankOnlineRequestAction
  | GetBankOnlineRequestSuccessAction
  | GetBankOnlineRequestErrorAction
  | GetBankBranchRequestAction
  | GetBankBranchRequestSuccessAction
  | GetBankBranchRequestErrorAction
  | GetProvinceRequestAction
  | GetProvinceRequestSuccessAction
  | GetProvinceRequestErrorAction
  | DefaultAccountSetAction
  | DefaultAccountLayoutSetAction
  | AccountPortfolioRequestAction
  | AccountPortfolioSuccessAction
  | AccountPortfolioErrorAction
  | UpdatePortfolioRequestAction
  | UpdatePortfolioSuccessAction
  | UpdatePortfolioErrorAction
  | DelPortfolioRequestAction
  | DelPortfolioSuccessAction
  | DelPortfolioErrorAction
  | UnsetLostSessionAction
  | SetSymbolDelAction
  | SetToastAction
  | CheckPinRequestAction
  | CheckPinRequestSuccessAction
  | CheckPinRequestErrorAction
  | GetDistrictRequestAction
  | GetDistrictRequestSuccessAction
  | GetDistrictRequestErrorAction
  | GetVillageRequestAction
  | GetVillageRequestSuccessAction
  | GetVillageRequestErrorAction;
