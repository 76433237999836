import * as actions from './actionType';

export interface CashState {
  transferIn: any;
  transferOut: any;
}

const initialState = {
  transferIn: null,
  transferOut: null,
};

export default function cashReducer(
  state: CashState = initialState,
  action: actions.CashAction,
): CashState {
  switch (action.type) {
    case actions.CASH_TRANSFER_IN_REQUESTING:
    case actions.CASH_TRANSFER_IN_ERROR:
      return {
        ...state,
        transferIn: null,
      };

    case actions.CASH_TRANSFER_IN_SUCCESS:
      return {
        ...state,
        transferIn: action.resData,
      };

    case actions.CASH_TRANSFER_OUT_REQUESTING:
    case actions.CASH_TRANSFER_OUT_ERROR:
      return {
        ...state,
        transferOut: null,
      };

    case actions.CASH_TRANSFER_OUT_SUCCESS:
      return {
        ...state,
        transferOut: action.resData,
      };

    default:
      return state;
  }
}
