import { ParamModel } from 'interface';
import * as actions from './actionType';

/** dữ liệu khách hàng tạm */
export function regCustTempSet(data: any): actions.RegCustTempAction {
  return {
    type: actions.REG_CUST_TEMP,
    data,
  };
}

/** check email/ mobile */
export function regCheckCustRequest(
  data: any,
): actions.RegCheckCustRequestingAction {
  return {
    type: actions.REG_CHECK_CUST_REQUESTING,
    data,
  };
}

export function regCheckCustSuccess(
  resData: any,
): actions.RegCheckCustSuccessAction {
  return {
    type: actions.REG_CHECK_CUST_SUCCESS,
    resData,
  };
}

export function regCheckCustError(
  error: Error | string,
): actions.RegCheckCustErrorAction {
  return {
    type: actions.REG_CHECK_CUST_ERROR,
    error,
  };
}

/** verify otp */
export function regCheckOtpRequest(
  data: any,
): actions.RegCheckOtpRequestingAction {
  return {
    type: actions.REG_CHECK_OTP_REQUESTING,
    data,
  };
}

export function regCheckOtpSuccess(
  resData: any,
): actions.RegCheckOtpSuccessAction {
  return {
    type: actions.REG_CHECK_OTP_SUCCESS,
    resData,
  };
}

export function regCheckOtpError(
  error: Error | string,
): actions.RegCheckOtpErrorAction {
  return {
    type: actions.REG_CHECK_OTP_ERROR,
    error,
  };
}

/** verify otp */
export function regAccountRequest(
  data: any,
): actions.RegAccountRequestingAction {
  return {
    type: actions.REG_ACCOUNT_REQUESTING,
    data,
  };
}

export function regAccountSuccess(
  resData: any,
): actions.RegAccountSuccessAction {
  return {
    type: actions.REG_ACCOUNT_SUCCESS,
    resData,
  };
}

export function regAccountError(
  error: Error | string,
): actions.RegAccountErrorAction {
  return {
    type: actions.REG_ACCOUNT_ERROR,
    error,
  };
}

/** reset pass check cust */
export function resCheckCustRequest(
  data: any,
): actions.ResCheckCustRequestingAction {
  return {
    type: actions.RES_CHECK_CUST_REQUESTING,
    data,
  };
}

export function resCheckCustSuccess(
  resData: any,
): actions.ResCheckCustSuccessAction {
  return {
    type: actions.RES_CHECK_CUST_SUCCESS,
    resData,
  };
}

export function resCheckCustError(
  error: Error | string,
): actions.ResCheckCustErrorAction {
  return {
    type: actions.RES_CHECK_CUST_ERROR,
    error,
  };
}

/** reset pass check cust */
export function resChangePassRequest(
  data: any,
): actions.ResChangePassRequestingAction {
  return {
    type: actions.RES_CHANGE_PASS_REQUESTING,
    data,
  };
}

export function resChangePassSuccess(
  resData: any,
): actions.ResChangePassSuccessAction {
  return {
    type: actions.RES_CHANGE_PASS_SUCCESS,
    resData,
  };
}

export function resChangePassError(
  error: Error | string,
): actions.ResChangePassErrorAction {
  return {
    type: actions.RES_CHANGE_PASS_ERROR,
    error,
  };
}
