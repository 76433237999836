import { call, put, takeLatest, all } from 'redux-saga/effects'
import { handleApiErrors } from 'lib/api-error'
import * as actions from './actionType'
import {
  stockDetailRequestSuccess,
  stockDetailRequestError,
  stockTradeRequestSuccess,
  stockTradeRequestError,
  cstRequestSuccess,
  cstRequestError,
  kqkdRequestSuccess,
  kqkdRequestError,
  cdktRequestSuccess,
  cdktRequestError,
  stockHisRequestSuccess,
  stockNewsRequestSuccess,
  stockNewsRequestError,
  newsDetailRequestSuccess,
  newsDetailRequestError,
  stockEventsRequestSuccess,
  stockEventsRequestError,
  stockManagementRequestSuccess,
  stockManagementRequestError,
  companyInfoRequestSuccess,
  companyInfoRequestError,
  associateRequestSuccess,
  associateRequestError,
  transferDataRequestSuccess,
  transferDataRequestError,
  collectionPriceRequestSuccess,
  collectionPriceRequestError,
  topMatchRequestSuccess,
  topMatchRequestError,
} from './actions'
import { processMapData } from 'utils/processMapData'

const infoUrl = `${process.env.REACT_APP_INFO_URL}`
const priceUrl = `${process.env.REACT_APP_PRICE_URL}`
const algoUrl = `${process.env.REACT_APP_ALGO_API_URL}`

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error
    })
}

function stockDetailRequestApi(data: any) {
  const url = `${priceUrl}/getliststockdata/${data.toUpperCase()}`
  const request = fetch(url)

  return handleRequest(request)
}

function stockTradeRequestApi(data: any) {
  const url = `${priceUrl}/getliststocktrade/${data.toUpperCase()}`
  const request = fetch(url)

  return handleRequest(request)
}

function reportRequestApi(data: any) {
  const url = `${infoUrl}/stockReport.pt?${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function reportHisRequestApi(data: any) {
  const url = `${infoUrl}/stockHis.pt?${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function transferDataRequestApi(data: any) {
  const url = `${infoUrl}/transferData.pt?${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function reportNewsRequestApi(data: any) {
  const url = `${infoUrl}/stockNews.pt?symbol=${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function newsDetailRequestApi(data: any) {
  const url = `${infoUrl}/newsDetail.pt?id=${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function stockEventsRequestApi(data: any) {
  const url = `${infoUrl}/stockEvents.pt?${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function stockManageRequestApi(data: any) {
  const url = `${infoUrl}/stockMangement.pt?symbol=${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function companyInfoRequestApi(data: any) {
  const url = `${infoUrl}/companyInfo.pt?symbol=${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function associateRequestApi(data: any) {
  const url = `${infoUrl}/associate.pt?symbol=${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function collectionPriceRequestApi(data: any) {
  const url = `${algoUrl}getPriceAllocation/${data}`
  const request = fetch(url)
  return handleRequest(request)
}

function topMatchRequestApi(data: any) {
  const url = `${algoUrl}pbapi/api/stockBoard/chart/stock/${data}/100`
  const request = fetch(url)
  return handleRequest(request)
}

function* stockDetailRequestFlow(
  action: actions.StockDetailRequestAction
): any {
  const { data } = action
  try {
    // call to our snapshotRequestApi function with the client
    const dataList = yield call(stockDetailRequestApi, data)

    let symbol
    if (dataList && !!dataList.length) {
      symbol = processMapData._processMapDataCS(dataList[0])
    }

    // dispatch the action with our symbol!
    yield put(stockDetailRequestSuccess(symbol))
  } catch (error) {
    yield put(stockDetailRequestError(error))
  }
}

function* stockTradeRequestFlow(action: actions.StockTradeRequestAction): any {
  const { data } = action
  try {
    // call to our snapshotRequestApi function with the client
    const dataList = yield call(stockTradeRequestApi, data)

    // dispatch the action with our symbol!
    yield put(stockTradeRequestSuccess(dataList))
  } catch (error) {
    yield put(stockTradeRequestError(error))
  }
}

function* cstRequestFlow(action: actions.CstRequestAction): any {
  try {
    const { data } = action
    const dataList = yield call(reportRequestApi, data)

    yield put(cstRequestSuccess(dataList))
  } catch (error) {
    yield put(cstRequestError(error))
  }
}

function* kqkdRequestFlow(action: actions.KqkdRequestAction): any {
  try {
    const { data } = action
    const dataList = yield call(reportRequestApi, data)

    yield put(kqkdRequestSuccess(dataList))
  } catch (error) {
    yield put(kqkdRequestError(error))
  }
}

function* cdktRequestFlow(action: actions.CdktRequestAction): any {
  try {
    const { data } = action
    const dataList = yield call(reportRequestApi, data)

    yield put(cdktRequestSuccess(dataList))
  } catch (error) {
    yield put(cdktRequestError(error))
  }
}

function* stockHisRequestFlow(action: actions.StockHisRequestAction): any {
  try {
    const { data } = action
    const dataList = yield call(reportHisRequestApi, data)

    yield put(stockHisRequestSuccess(dataList))
  } catch (error) {
    yield put(stockTradeRequestError(error))
  }
}

function* transferDataRequestFlow(
  action: actions.TransferDataRequestAction
): any {
  try {
    const { data } = action
    const dataList = yield call(transferDataRequestApi, data)

    yield put(transferDataRequestSuccess(dataList))
  } catch (error) {
    yield put(transferDataRequestError(error))
  }
}

function* stockNewsRequestFlow(action: actions.StockNewsRequestAction): any {
  try {
    const { data } = action
    const news = yield call(reportNewsRequestApi, data)

    yield put(stockNewsRequestSuccess(news))
  } catch (error) {
    yield put(stockNewsRequestError(error))
  }
}

function* newsDetailRequestFlow(action: actions.NewsDetailRequestAction): any {
  try {
    const { data } = action
    const newsDetail = yield call(newsDetailRequestApi, data)

    yield put(newsDetailRequestSuccess(newsDetail))
  } catch (error) {
    yield put(newsDetailRequestError(error))
  }
}

function* stockEventsRequestFlow(
  action: actions.StockEventsRequestAction
): any {
  try {
    const { data } = action
    const events = yield call(stockEventsRequestApi, data)

    yield put(stockEventsRequestSuccess(events))
  } catch (error) {
    yield put(stockEventsRequestError(error))
  }
}

function* stockManageRequestFlow(
  action: actions.StockManagementRequestAction
): any {
  try {
    const { data } = action
    const events = yield call(stockManageRequestApi, data)

    yield put(stockManagementRequestSuccess(events.data))
  } catch (error) {
    yield put(stockManagementRequestError(error))
  }
}

function* companyInfoRequestFlow(
  action: actions.CompanyInfoRequestAction
): any {
  try {
    const { data } = action
    const events = yield call(companyInfoRequestApi, data)

    yield put(companyInfoRequestSuccess(events))
  } catch (error) {
    yield put(companyInfoRequestError(error))
  }
}

function* associateRequestFlow(action: actions.AssociateRequestAction): any {
  try {
    const { data } = action
    const associate = yield call(associateRequestApi, data)

    yield put(associateRequestSuccess(associate))
  } catch (error) {
    yield put(associateRequestError(error))
  }
}

function* collectionPriceRequestFlow(
  action: actions.CollectionPriceRequestAction
): any {
  try {
    const { data } = action
    const res = yield call(collectionPriceRequestApi, data)

    yield put(collectionPriceRequestSuccess(res.data))
  } catch (error) {
    yield put(collectionPriceRequestError(error))
  }
}

function* topMatchRequestFlow(action: actions.TopMatchRequestAction): any {
  try {
    const { data } = action
    const res = yield call(topMatchRequestApi, data)

    yield put(topMatchRequestSuccess(res.data.reverse()))
  } catch (error) {
    yield put(topMatchRequestError(error))
  }
}

function* stockDetailWatcher() {
  yield all([
    takeLatest(actions.STOCK_DETAIL_REQUESTING, stockDetailRequestFlow),
    takeLatest(actions.STOCK_TRADE_REQUESTING, stockTradeRequestFlow),
    takeLatest(actions.CST_REQUESTING, cstRequestFlow),
    takeLatest(actions.KQKD_REQUESTING, kqkdRequestFlow),
    takeLatest(actions.CDKT_REQUESTING, cdktRequestFlow),
    takeLatest(actions.STOCK_HIS_REQUESTING, stockHisRequestFlow),
    takeLatest(actions.STOCK_NEWS_REQUESTING, stockNewsRequestFlow),
    takeLatest(actions.NEWS_DETAIL_REQUESTING, newsDetailRequestFlow),
    takeLatest(actions.STOCK_EVENTS_REQUESTING, stockEventsRequestFlow),
    takeLatest(actions.STOCK_MANAGEMENT_REQUESTING, stockManageRequestFlow),
    takeLatest(actions.COMPANY_INFO_REQUESTING, companyInfoRequestFlow),
    takeLatest(actions.ASSOCIATE_REQUESTING, associateRequestFlow),
    takeLatest(actions.TRANSFER_DATA_REQUESTING, transferDataRequestFlow),

    takeLatest(actions.COLLECTION_PRICE_REQUESTING, collectionPriceRequestFlow),
    takeLatest(actions.TOP_MATCH_REQUESTING, topMatchRequestFlow),
  ])
}

export default stockDetailWatcher
