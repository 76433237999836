//TODO: stock real
export const ANAL_STOCK_REAL_REQUESTING = 'anal/STOCK_REAL_REQUESTING';
export interface AnalStockRealRequestAction {
  type: typeof ANAL_STOCK_REAL_REQUESTING;
  params: any;
}

export const ANAL_STOCK_REAL_SUCCESS = 'anal/STOCK_REAL_SUCCESS';
export interface AnalStockRealSuccessAction {
  type: typeof ANAL_STOCK_REAL_SUCCESS;
  resData: any;
}

export const ANAL_STOCK_REAL_ERROR = 'anal/STOCK_REAL_ERROR';
export interface AnalStockRealErrorAction {
  type: typeof ANAL_STOCK_REAL_ERROR;
  error: any;
}

//TODO: danh sách mã chứng khoán
export const ANAL_SEC_LIST_ALL_REQUESTING = 'anal/SEC_LIST_ALL_REQUESTING';
export interface AnalSecListAllRequestAction {
  type: typeof ANAL_SEC_LIST_ALL_REQUESTING;
  params: any;
}

export const ANAL_SEC_LIST_ALL_SUCCESS = 'anal/SEC_LIST_ALL_SUCCESS';
export interface AnalSecListAllSuccessAction {
  type: typeof ANAL_SEC_LIST_ALL_SUCCESS;
  resData: any;
}

export const ANAL_SEC_LIST_ALL_ERROR = 'anal/SEC_LIST_ALL_ERROR';
export interface AnalSecListAllErrorAction {
  type: typeof ANAL_SEC_LIST_ALL_ERROR;
  error: any;
}

export const ANAL_STOCK_MATCH_PRICE_REQUESTING =
  'anal/STOCK_MATCH_PRICE_REQUESTING';
export interface AnalStockMatchPriceRequestAction {
  type: typeof ANAL_STOCK_MATCH_PRICE_REQUESTING;
  params: any;
}

export const ANAL_STOCK_MATCH_PRICE_SUCCESS = 'anal/STOCK_MATCH_PRICE_SUCCESS';
export interface AnalStockMatchPriceSuccessAction {
  type: typeof ANAL_STOCK_MATCH_PRICE_SUCCESS;
  resData: any;
}

export const ANAL_STOCK_MATCH_PRICE_ERROR = 'anal/STOCK_MATCH_PRICE_ERROR';
export interface AnalStockMatchPriceErrorAction {
  type: typeof ANAL_STOCK_MATCH_PRICE_ERROR;
  error: any;
}

export const ANAL_STOCK_TOP_MATCH = 'anal/STOCK_TOP_MATCH';
export interface AnalStockTopMatchAction {
  type: typeof ANAL_STOCK_TOP_MATCH;
  params: any;
}

export const ANAL_STOCK_TOP_MATCH_REQUESTING =
  'anal/STOCK_TOP_MATCH_REQUESTING';
export interface AnalStockTopMatchRequestAction {
  type: typeof ANAL_STOCK_TOP_MATCH_REQUESTING;
  params: any;
}

export const ANAL_STOCK_TOP_MATCH_SUCCESS = 'anal/STOCK_TOP_MATCH_SUCCESS';
export interface AnalStockTopMatchSuccessAction {
  type: typeof ANAL_STOCK_TOP_MATCH_SUCCESS;
  resData: any;
}

export const ANAL_STOCK_TOP_MATCH_ERROR = 'anal/STOCK_TOP_MATCH_ERROR';
export interface AnalStockTopMatchErrorAction {
  type: typeof ANAL_STOCK_TOP_MATCH_ERROR;
  error: any;
}

export const ANAL_STOCK_MB_ACTIVE_REQUESTING =
  'anal/STOCK_MB_ACTIVE_REQUESTING';
export interface AnalStockMBActiveRequestAction {
  type: typeof ANAL_STOCK_MB_ACTIVE_REQUESTING;
  params: any;
}

export const ANAL_STOCK_MB_ACTIVE_SUCCESS = 'anal/STOCK_MB_ACTIVE_SUCCESS';
export interface AnalStockMBActiveSuccessAction {
  type: typeof ANAL_STOCK_MB_ACTIVE_SUCCESS;
  resData: any;
}

export const ANAL_STOCK_MB_ACTIVE_ERROR = 'anal/STOCK_MB_ACTIVE_ERROR';
export interface AnalStockMBActiveErrorAction {
  type: typeof ANAL_STOCK_MB_ACTIVE_ERROR;
  error: any;
}

export const ANAL_BASIC_INFO_REQUESTING = 'anal/BASIC_INFO_REQUESTING';
export interface AnalBasicInfoRequestAction {
  type: typeof ANAL_BASIC_INFO_REQUESTING;
  params: any;
}

export const ANAL_BASIC_INFO_SUCCESS = 'anal/BASIC_INFO_SUCCESS';
export interface AnalBasicInfoSuccessAction {
  type: typeof ANAL_BASIC_INFO_SUCCESS;
  resData: any;
}

export const ANAL_BASIC_INFO_ERROR = 'anal/BASIC_INFO_ERROR';
export interface AnalBasicInfoErrorAction {
  type: typeof ANAL_BASIC_INFO_ERROR;
  error: any;
}

export const ANAL_INDEX_REAL_REQUESTING = 'anal/INDEX_REAL_REQUESTING';
export interface AnalIndexRealRequestAction {
  type: typeof ANAL_INDEX_REAL_REQUESTING;
  params: any;
}

export const ANAL_INDEX_REAL_SUCCESS = 'anal/INDEX_REAL_SUCCESS';
export interface AnalIndexRealSuccessAction {
  type: typeof ANAL_INDEX_REAL_SUCCESS;
  resData: any;
}

export const ANAL_INDEX_REAL_ERROR = 'anal/INDEX_REAL_ERROR';
export interface AnalIndexRealErrorAction {
  type: typeof ANAL_INDEX_REAL_ERROR;
  error: any;
}

export const ANAL_NN_INDAY_REQUESTING = 'anal/NN_INDAY_REQUESTING';
export interface AnalNNIndayRequesttAction {
  type: typeof ANAL_NN_INDAY_REQUESTING;
  params: any;
}

export const ANAL_NN_INDAY_SUCCESS = 'anal/ANAL_NN_INDAY_SUCCESS';
export interface AnalNNIndaySuccessAction {
  type: typeof ANAL_NN_INDAY_SUCCESS;
  resData: any;
}

export const ANAL_NN_INDAY_ERROR = 'anal/NN_INDAY_ERROR';
export interface AnalNNIndayErrorAction {
  type: typeof ANAL_NN_INDAY_ERROR;
  error: any;
}

export const ANAL_NN_10DAY_REQUESTING = 'anal/NN_10DAY_REQUESTING';
export interface AanalNN10dayRequestAction {
  type: typeof ANAL_NN_10DAY_REQUESTING;
  params: any;
}

export const ANAL_NN_10DAY_SUCCESS = 'anal/ANAL_NN_10DAY_SUCCESS';
export interface AnalNN10daySuccessAction {
  type: typeof ANAL_NN_10DAY_SUCCESS;
  resData: any;
}

export const ANAL_NN_10DAY_ERROR = 'anal/NN_10DAY_ERROR';
export interface AnalNN10dayErrorAction {
  type: typeof ANAL_NN_10DAY_ERROR;
  error: any;
}

export const ANAL_PB_DONG_TIEN_REQUESTING = 'anal/ANAL_PB_DONG_TIEN_REQUESTING';
export interface AnalPBDongTienRequestAction {
  type: typeof ANAL_PB_DONG_TIEN_REQUESTING;
  params: any;
}

export const ANAL_PB_DONG_TIEN_SUCCESS = 'anal/PB_DONG_TIEN_SUCCESS';
export interface AnalPBDongTienSuccessAction {
  type: typeof ANAL_PB_DONG_TIEN_SUCCESS;
  resData: any;
}

export const ANAL_PB_DONG_TIEN_ERROR = 'anal/PB_DONG_TIEN_ERROR';
export interface AnalPBDongTienErrorAction {
  type: typeof ANAL_PB_DONG_TIEN_ERROR;
  error: any;
}

export const ANAL_LICH_SU_GIA_REQUESTING = 'anal/LICH_SU_GIA_REQUESTING';
export interface AnalLichSuGiaRequestAction {
  type: typeof ANAL_LICH_SU_GIA_REQUESTING;
  params: any;
}

export const ANAL_LICH_SU_GIA_SUCCESS = 'anal/LICH_SU_GIA_SUCCESS';
export interface AnalLichSuGiaSuccessAction {
  type: typeof ANAL_LICH_SU_GIA_SUCCESS;
  resData: any;
}

export const ANAL_LICH_SU_GIA_ERROR = 'anal/LICH_SU_GIA_ERROR';
export interface AnalLichSuGiaErrorAction {
  type: typeof ANAL_LICH_SU_GIA_ERROR;
  error: any;
}

export const ANAL_FS_RATIO_REQUESTING = 'anal/FS_RATIO_REQUESTING';
export interface AnalFSRatioRequestAction {
  type: typeof ANAL_FS_RATIO_REQUESTING;
  params: any;
}

export const ANAL_FS_RATIO_SUCCESS = 'anal/FS_RATIO_SUCCESS';
export interface AnalFSRatioSuccessAction {
  type: typeof ANAL_FS_RATIO_SUCCESS;
  resData: any;
}

export const ANAL_FS_RATIO_ERROR = 'anal/FS_RATIO_ERROR';
export interface AnalFSRatioErrorAction {
  type: typeof ANAL_FS_RATIO_ERROR;
  error: any;
}

export const ANAL_RANK_FS_RATIO_REQUESTING =
  'anal/ANAL_RANK_FS_RATIO_REQUESTING';
export interface AnalRankFSRatioRequestAction {
  type: typeof ANAL_RANK_FS_RATIO_REQUESTING;
  params: any;
}

export const ANAL_RANK_FS_RATIO_SUCCESS = 'anal/RANK_FS_RATIO_SUCCESS';
export interface AnalRankFSRatioSuccessAction {
  type: typeof ANAL_RANK_FS_RATIO_SUCCESS;
  resData: any;
}

export const ANAL_RANK_FS_RATIO_ERROR = 'anal/RANK_FS_RATIO_ERROR';
export interface AnalRankFSRatioErrorAction {
  type: typeof ANAL_RANK_FS_RATIO_ERROR;
  error: any;
}

export const ANAL_SEC_OVERVIEW_REQUESTING = 'anal/SEC_OVERVIEW_REQUESTING';
export interface AnalSecOverviewRequestAction {
  type: typeof ANAL_SEC_OVERVIEW_REQUESTING;
  params: any;
}

export const ANAL_SEC_OVERVIEW_SUCCESS = 'anal/SEC_OVERVIEW_SUCCESS';
export interface AnalSecOverviewSuccessAction {
  type: typeof ANAL_SEC_OVERVIEW_SUCCESS;
  resData: any;
}

export const ANAL_SEC_OVERVIEW_ERROR = 'anal/SEC_OVERVIEW_ERROR';
export interface AnalSecOverviewErrorAction {
  type: typeof ANAL_SEC_OVERVIEW_ERROR;
  error: any;
}

export const ANAL_COM_INTRO_REQUESTING = 'anal/COM_INTRO_REQUESTING';
export interface AnalComIntroductionRequestAction {
  type: typeof ANAL_COM_INTRO_REQUESTING;
  params: any;
}

export const ANAL_COM_INTRO_SUCCESS = 'anal/COM_INTRO_SUCCESS';
export interface AnalComIntroductionSuccesssAction {
  type: typeof ANAL_COM_INTRO_SUCCESS;
  resData: any;
}

export const ANAL_COM_INTRO_ERROR = 'anal/COM_INTRO_ERROR';
export interface AnalComIntroductionErrorAction {
  type: typeof ANAL_COM_INTRO_ERROR;
  error: any;
}

export const ANAL_COM_BASIC_REQUESTING = 'anal/COM_BASIC_REQUESTING';
export interface AnalComBasicRequestAction {
  type: typeof ANAL_COM_BASIC_REQUESTING;
  params: any;
}

export const ANAL_COM_BASIC_SUCCESS = 'anal/COM_BASIC_SUCCESS';
export interface AnalComBasicSuccessAction {
  type: typeof ANAL_COM_BASIC_SUCCESS;
  resData: any;
}

export const ANAL_COM_BASIC_ERROR = 'anal/COM_BASIC_ERROR';
export interface AnalComBasicErrorAction {
  type: typeof ANAL_COM_BASIC_ERROR;
  error: any;
}

export const ANAL_COM_SAME_IND_REQUESTING = 'anal/COM_SAME_IND_REQUESTING';
export interface AnalComSameIndustryRequestAction {
  type: typeof ANAL_COM_SAME_IND_REQUESTING;
  params: any;
}

export const ANAL_COM_SAME_IND_SUCCESS = 'anal/COM_SAME_IND_SUCCESS';
export interface AnalComSameIndustrySuccessAction {
  type: typeof ANAL_COM_SAME_IND_SUCCESS;
  resData: any;
}

export const ANAL_COM_SAME_IND_ERROR = 'anal/COM_SAME_IND_ERROR';
export interface AnalComSameIndustryErrorAction {
  type: typeof ANAL_COM_SAME_IND_ERROR;
  error: any;
}

export const ANAL_COM_LEADER = 'anal/COM_LEADER';
export interface AnalComLeaderAction {
  type: typeof ANAL_COM_LEADER;
  params: any;
}

export const ANAL_COM_LEADER_REQUESTING = 'anal/COM_LEADER_REQUESTING';
export interface AnalComLeaderRequestAction {
  type: typeof ANAL_COM_LEADER_REQUESTING;
  params: any;
}

export const ANAL_COM_LEADER_SUCCESS = 'anal/COM_LEADER_SUCCESS';
export interface AnalComLeaderSuccessAction {
  type: typeof ANAL_COM_LEADER_SUCCESS;
  resData: any;
}

export const ANAL_COM_LEADER_ERROR = 'anal/COM_LEADER_ERROR';
export interface AnalComLeaderErrorAction {
  type: typeof ANAL_COM_LEADER_ERROR;
  error: any;
}

export const ANAL_COM_HOLDER = 'anal/COM_HOLDER';
export interface AnalComHolderAction {
  type: typeof ANAL_COM_HOLDER;
  params: any;
}

export const ANAL_COM_HOLDER_REQUESTING = 'anal/COM_HOLDER_REQUESTING';
export interface AnalComHolderRequestAction {
  type: typeof ANAL_COM_HOLDER_REQUESTING;
  params: any;
}

export const ANAL_COM_HOLDER_SUCCESS = 'anal/COM_HOLDER_SUCCESS';
export interface AnalComHolderSuccessAction {
  type: typeof ANAL_COM_HOLDER_SUCCESS;
  resData: any;
}

export const ANAL_COM_HOLDER_ERROR = 'anal/COM_HOLDER_ERROR';
export interface AnalComHolderErrorAction {
  type: typeof ANAL_COM_HOLDER_ERROR;
  error: any;
}

export const ANAL_COM_EVENTS_REQUESTING = 'anal/COM_EVENTS_REQUESTING';
export interface AnalComEventsRequestAction {
  type: typeof ANAL_COM_EVENTS_REQUESTING;
  params: any;
}

export const ANAL_COM_EVENTS_SUCCESS = 'anal/COM_EVENTS_SUCCESS';
export interface AnalComEventsSuccessAction {
  type: typeof ANAL_COM_EVENTS_SUCCESS;
  resData: any;
}

export const ANAL_COM_EVENTS_ERROR = 'anal/COM_EVENTS_ERROR';
export interface AnalComEventsErrorAction {
  type: typeof ANAL_COM_EVENTS_ERROR;
  error: any;
}

export const ANAL_COM_BIGTRANS_REQUESTING = 'anal/COM_BIGTRANS_REQUESTING';
export interface AnalComBigTransRequestAction {
  type: typeof ANAL_COM_BIGTRANS_REQUESTING;
  params: any;
}

export const ANAL_COM_BIGTRANS_SUCCESS = 'anal/COM_BIGTRANS_SUCCESS';
export interface AnalComBigTransSuccessAction {
  type: typeof ANAL_COM_BIGTRANS_SUCCESS;
  resData: any;
}

export const ANAL_COM_BIGTRANS_ERROR = 'anal/COM_BIGTRANS_ERROR';
export interface AnalComBigTransErrorAction {
  type: typeof ANAL_COM_BIGTRANS_ERROR;
  error: any;
}

export const ANAL_COM_RELATED = 'anal/COM_RELATED';
export interface AnalComRelatedAction {
  type: typeof ANAL_COM_RELATED;
  params: any;
}

export const ANAL_COM_RELATED_REQUESTING = 'anal/COM_RELATED_REQUESTING';
export interface AnalComRelatedRequestAction {
  type: typeof ANAL_COM_RELATED_REQUESTING;
  params: any;
}

export const ANAL_COM_RELATED_SUCCESS = 'anal/COM_RELATED_SUCCESS';
export interface AnalComRelatedSuccessAction {
  type: typeof ANAL_COM_RELATED_SUCCESS;
  resData: any;
}

export const ANAL_COM_RELATED_ERROR = 'anal/COM_RELATED_ERROR';
export interface AnalComRelatedErrorAction {
  type: typeof ANAL_COM_RELATED_ERROR;
  error: any;
}

export const ANAL_FILTER_RANGE_REQUESTING = 'anal/FILTER_RANGE_REQUESTING';
export interface AnalFilterRangeRequestAction {
  type: typeof ANAL_FILTER_RANGE_REQUESTING;
}

export const ANAL_FILTER_RANGE_SUCCESS = 'anal/FILTER_RANGE_SUCCESS';
export interface AnalFilterRangeSuccessAction {
  type: typeof ANAL_FILTER_RANGE_SUCCESS;
  resData: any;
}

export const ANAL_FILTER_RANGE_ERROR = 'anal/FILTER_RANGE_ERROR';
export interface AnalFilterRangeErrorAction {
  type: typeof ANAL_FILTER_RANGE_ERROR;
  error: any;
}

export const ANAL_FILTER_ACT_REQUESTING = 'anal/FILTER_ACT_REQUESTING';
export interface AnalFilterActionRequestAction {
  type: typeof ANAL_FILTER_ACT_REQUESTING;
  params: any;
}

export const ANAL_FILTER_ACT_SUCCESS = 'anal/FILTER_ACT_SUCCESS';
export interface AnalFilterActionSuccessAction {
  type: typeof ANAL_FILTER_ACT_SUCCESS;
  resData: any;
}

export const ANAL_FILTER_ACT_ERROR = 'anal/FILTER_ACT_ERROR';
export interface AnalFilterActionErrorAction {
  type: typeof ANAL_FILTER_ACT_ERROR;
  error: any;
}

export const ANAL_USER_FILTER_REQUESTING = 'anal/USER_FILTER_REQUESTING';
export interface AnalUserFilterRequestAction {
  type: typeof ANAL_USER_FILTER_REQUESTING;
  params: any;
}

export const ANAL_USER_FILTER_SUCCESS = 'anal/USER_FILTER_SUCCESS';
export interface AnalUserFilterSuccessAction {
  type: typeof ANAL_USER_FILTER_SUCCESS;
  resData: any;
}

export const ANAL_USER_FILTER_ERROR = 'anal/USER_FILTER_ERROR';
export interface AnalUserFilterErrorAction {
  type: typeof ANAL_USER_FILTER_ERROR;
  error: any;
}

export const ANAL_USER_SIGNAL_REQUESTING = 'anal/USER_SIGNAL_REQUESTING';
export interface AnalUserSignalRequestAction {
  type: typeof ANAL_USER_SIGNAL_REQUESTING;
  params: any;
}

export const ANAL_USER_SIGNAL_SUCCESS = 'anal/USER_SIGNAL_SUCCESS';
export interface AnalUserSignalSuccessAction {
  type: typeof ANAL_USER_SIGNAL_SUCCESS;
  resData: any;
}

export const ANAL_USER_SIGNAL_ERROR = 'anal/USER_SIGNAL_ERROR';
export interface AnalUserSignalErrorAction {
  type: typeof ANAL_USER_SIGNAL_ERROR;
  error: any;
}

export const ANAL_DEL_FILTER_REQUESTING = 'anal/DEL_FILTER_REQUESTING';
export interface AnalDelFilterRequestAction {
  type: typeof ANAL_DEL_FILTER_REQUESTING;
  params: any;
}

export const ANAL_DEL_FILTER_SUCCESS = 'anal/DEL_FILTER_SUCCESS';
export interface AnalDelFilterSuccessAction {
  type: typeof ANAL_DEL_FILTER_SUCCESS;
  resData: any;
}

export const ANAL_DEL_FILTER_ERROR = 'anal/DEL_FILTER_ERROR';
export interface AnalDelFilterErrorAction {
  type: typeof ANAL_DEL_FILTER_ERROR;
  error: any;
}

export type AnalAction =
  | AnalStockRealRequestAction
  | AnalStockRealSuccessAction
  | AnalStockRealErrorAction
  | AnalSecListAllRequestAction
  | AnalSecListAllSuccessAction
  | AnalSecListAllErrorAction
  | AnalStockMatchPriceRequestAction
  | AnalStockMatchPriceSuccessAction
  | AnalStockMatchPriceErrorAction
  | AnalStockTopMatchAction
  | AnalStockTopMatchRequestAction
  | AnalStockTopMatchSuccessAction
  | AnalStockTopMatchErrorAction
  | AnalStockMBActiveRequestAction
  | AnalStockMBActiveSuccessAction
  | AnalStockMBActiveErrorAction
  | AnalBasicInfoRequestAction
  | AnalBasicInfoSuccessAction
  | AnalBasicInfoErrorAction
  | AnalIndexRealRequestAction
  | AnalIndexRealSuccessAction
  | AnalIndexRealErrorAction
  | AnalNNIndayRequesttAction
  | AnalNNIndaySuccessAction
  | AnalNNIndayErrorAction
  | AanalNN10dayRequestAction
  | AnalNN10daySuccessAction
  | AnalNN10dayErrorAction
  | AnalPBDongTienRequestAction
  | AnalPBDongTienSuccessAction
  | AnalPBDongTienErrorAction
  | AnalLichSuGiaRequestAction
  | AnalLichSuGiaSuccessAction
  | AnalLichSuGiaErrorAction
  | AnalFSRatioRequestAction
  | AnalFSRatioSuccessAction
  | AnalFSRatioErrorAction
  | AnalRankFSRatioRequestAction
  | AnalRankFSRatioSuccessAction
  | AnalRankFSRatioErrorAction
  | AnalSecOverviewRequestAction
  | AnalSecOverviewSuccessAction
  | AnalSecOverviewErrorAction
  | AnalComIntroductionRequestAction
  | AnalComIntroductionSuccesssAction
  | AnalComIntroductionErrorAction
  | AnalComBasicRequestAction
  | AnalComBasicSuccessAction
  | AnalComBasicErrorAction
  | AnalComSameIndustryRequestAction
  | AnalComSameIndustrySuccessAction
  | AnalComSameIndustryErrorAction
  | AnalComLeaderAction
  | AnalComLeaderRequestAction
  | AnalComLeaderSuccessAction
  | AnalComLeaderErrorAction
  | AnalComHolderAction
  | AnalComHolderRequestAction
  | AnalComHolderSuccessAction
  | AnalComHolderErrorAction
  | AnalComEventsRequestAction
  | AnalComEventsSuccessAction
  | AnalComEventsErrorAction
  | AnalComBigTransRequestAction
  | AnalComBigTransSuccessAction
  | AnalComBigTransErrorAction
  | AnalComRelatedAction
  | AnalComRelatedRequestAction
  | AnalComRelatedSuccessAction
  | AnalComRelatedErrorAction
  | AnalFilterRangeRequestAction
  | AnalFilterRangeSuccessAction
  | AnalFilterRangeErrorAction
  | AnalFilterActionRequestAction
  | AnalFilterActionSuccessAction
  | AnalFilterActionErrorAction
  | AnalUserFilterRequestAction
  | AnalUserFilterSuccessAction
  | AnalUserFilterErrorAction
  | AnalUserSignalRequestAction
  | AnalUserSignalSuccessAction
  | AnalUserSignalErrorAction
  | AnalDelFilterRequestAction
  | AnalDelFilterSuccessAction
  | AnalDelFilterErrorAction;
