/** get list user */
export const USER_SEARCH = 'user/SEARCH';
export interface UserSearchAction {
  type: typeof USER_SEARCH;
}

export const USER_SEARCH_REQUESTING = 'user/SEARCH_REQUESTING';
export interface UserSearchRequestingAction {
  type: typeof USER_SEARCH_REQUESTING;
  data: any;
}

export const USER_SEARCH_SUCCESS = 'user/SEARCH_SUCCESS';
export interface UserSearchSuccessAction {
  type: typeof USER_SEARCH_SUCCESS;
  resData: any;
}

export const USER_SEARCH_ERROR = 'user/SEARCH_ERROR';
export interface UserSearchErrorAction {
  type: typeof USER_SEARCH_ERROR;
  error: any;
}

/** get detail user */
export const USER_DETAIL = 'user/DETAIL';
export interface UserDetailAction {
  type: typeof USER_DETAIL;
}

export const USER_DETAIL_REQUESTING = 'user/DETAIL_REQUESTING';
export interface UserDetailRequestingAction {
  type: typeof USER_DETAIL_REQUESTING;
  data: any;
}

export const USER_DETAIL_SUCCESS = 'user/DETAIL_SUCCESS';
export interface UserDetailSuccessAction {
  type: typeof USER_DETAIL_SUCCESS;
  resData: any;
}

export const USER_DETAIL_ERROR = 'user/DETAIL_ERROR';
export interface UserDetailErrorAction {
  type: typeof USER_DETAIL_ERROR;
  error: any;
}

/** update user */
export const USER_UPD = 'user/UPD';
export interface UserUpdAction {
  type: typeof USER_UPD;
}

export const USER_UPD_REQUESTING = 'user/UPD_REQUESTING';
export interface UserUpdRequestingAction {
  type: typeof USER_UPD_REQUESTING;
  data: any;
}

export const USER_UPD_SUCCESS = 'user/UPD_SUCCESS';
export interface UserUpdSuccessAction {
  type: typeof USER_UPD_SUCCESS;
  resData: any;
}

export const USER_UPD_ERROR = 'user/UPD_ERROR';
export interface UserUpdErrorAction {
  type: typeof USER_UPD_ERROR;
  error: any;
}

/** delete user */
export const USER_DEL = 'user/DEL';
export interface UserDelAction {
  type: typeof USER_DEL;
}

export const USER_DEL_REQUESTING = 'user/DEL_REQUESTING';
export interface UserDelRequestingAction {
  type: typeof USER_DEL_REQUESTING;
  data: any;
}

export const USER_DEL_SUCCESS = 'user/DEL_SUCCESS';
export interface UserDelSuccessAction {
  type: typeof USER_DEL_SUCCESS;
  resData: any;
}

export const USER_DEL_ERROR = 'user/DEL_ERROR';
export interface UserDelErrorAction {
  type: typeof USER_DEL_ERROR;
  error: any;
}

/** get list quyền xem cập nhập dl / xem báo cáo */

export const USER_RIGHT_BY_ACC_REQUESTING = 'user/RIGHT_BY_ACC_REQUESTING';
export interface UserRightByAccRequestingAction {
  type: typeof USER_RIGHT_BY_ACC_REQUESTING;
  data: any;
}

export const USER_RIGHT_BY_ACC_SUCCESS = 'user/RIGHT_BY_ACC_SUCCESS';
export interface UserRightByAccSuccessAction {
  type: typeof USER_RIGHT_BY_ACC_SUCCESS;
  resData: any;
}

export const USER_RIGHT_BY_ACC_ERROR = 'user/RIGHT_BY_ACC_ERROR';
export interface UserRightByAccErrorAction {
  type: typeof USER_RIGHT_BY_ACC_ERROR;
  error: any;
}

/** mở mới tài khoản */
export const USER_REGISTER = 'user/REGISTER';
export interface UserRegisterAction {
  type: typeof USER_REGISTER;
  data: any;
}

export const USER_REGISTER_REQUESTING = 'user/REGISTER_REQUESTING';
export interface UserRegisterRequestingAction {
  type: typeof USER_REGISTER_REQUESTING;
  data: any;
}

export const USER_REGISTER_SUCCESS = 'user/REGISTER_SUCCESS';
export interface UserRegisterSuccessAction {
  type: typeof USER_REGISTER_SUCCESS;
  resData: any;
}

export const USER_REGISTER_ERROR = 'user/REGISTER_ERROR';
export interface UserRegisterErrorAction {
  type: typeof USER_REGISTER_ERROR;
  error: any;
}

/** check thông tin tài khoản */
export const USER_REGIS_CHECK_REQUESTING = 'user/REGIS_CHECK_REQUESTING';
export interface UserRegisCheckRequestingAction {
  type: typeof USER_REGIS_CHECK_REQUESTING;
  data: any;
}

export const USER_REGIS_CHECK_SUCCESS = 'user/REGIS_CHECK_SUCCESS';
export interface UserRegisCheckSuccessAction {
  type: typeof USER_REGIS_CHECK_SUCCESS;
  resData: any;
}

export const USER_REGIS_CHECK_ERROR = 'user/REGIS_CHECK_ERROR';
export interface UserRegisCheckErrorAction {
  type: typeof USER_REGIS_CHECK_ERROR;
  error: any;
}

/** chi tiết tài sản */
export const USER_ASSET_DETAIL_REQUESTING = 'user/ASSET_DETAIL_REQUESTING';
export interface UserAssetDetailRequestingAction {
  type: typeof USER_ASSET_DETAIL_REQUESTING;
  data: any;
}

export const USER_ASSET_DETAIL_SUCCESS = 'user/ASSET_DETAIL_SUCCESS';
export interface UserAssetDetailSuccessAction {
  type: typeof USER_ASSET_DETAIL_SUCCESS;
  resData: any;
}

export const USER_ASSET_DETAIL_ERROR = 'user/ASSET_DETAIL_ERROR';
export interface UserAssetDetailErrorAction {
  type: typeof USER_ASSET_DETAIL_ERROR;
  error: any;
}

/** profit close  */
export const USER_PROFIT_CLOSE_REQUESTING = 'user/PROFIT_CLOSE_REQUESTING';
export interface UserProfitCloseRequestingAction {
  type: typeof USER_PROFIT_CLOSE_REQUESTING;
  data: any;
}

export const USER_PROFIT_CLOSE_SUCCESS = 'user/PROFIT_CLOSE_SUCCESS';
export interface UserProfitCloseSuccessAction {
  type: typeof USER_PROFIT_CLOSE_SUCCESS;
  resData: any;
}

export const USER_PROFIT_CLOSE_ERROR = 'user/PROFIT_CLOSE_ERROR';
export interface UserProfitCloseErrorAction {
  type: typeof USER_PROFIT_CLOSE_ERROR;
  error: any;
}

/** biến động tài sản */
export const USER_ASSET_CHART_REQUESTING = 'user/ASSET_CHART_REQUESTING';
export interface UserAssetChartRequestingAction {
  type: typeof USER_ASSET_CHART_REQUESTING;
  data: any;
}

export const USER_ASSET_CHART_SUCCESS = 'user/ASSET_CHART_SUCCESS';
export interface UserAssetChartSuccessAction {
  type: typeof USER_ASSET_CHART_SUCCESS;
  resData: any;
}

export const USER_ASSET_CHART_ERROR = 'user/ASSET_CHART_ERROR';
export interface UserAssetChartErrorAction {
  type: typeof USER_ASSET_CHART_ERROR;
  error: any;
}

/** profit symbol  */
export const USER_PROFIT_SYMBOL_REQUESTING = 'user/PROFIT_SYMBOL_REQUESTING';
export interface UserProfitSymbolRequestingAction {
  type: typeof USER_PROFIT_SYMBOL_REQUESTING;
  data: any;
}

export const USER_PROFIT_SYMBOL_SUCCESS = 'user/PROFIT_SYMBOL_SUCCESS';
export interface UserProfitSymbolSuccessAction {
  type: typeof USER_PROFIT_SYMBOL_SUCCESS;
  resData: any;
}

export const USER_PROFIT_SYMBOL_ERROR = 'user/PROFIT_SYMBOL_ERROR';
export interface UserProfitSymbolErrorAction {
  type: typeof USER_PROFIT_SYMBOL_ERROR;
  error: any;
}

export type UserAction =
  | UserSearchAction
  | UserSearchRequestingAction
  | UserSearchSuccessAction
  | UserSearchErrorAction
  | UserDetailAction
  | UserDetailRequestingAction
  | UserDetailSuccessAction
  | UserDetailErrorAction
  | UserUpdAction
  | UserUpdRequestingAction
  | UserUpdSuccessAction
  | UserUpdErrorAction
  | UserDelAction
  | UserDelRequestingAction
  | UserDelSuccessAction
  | UserDelErrorAction
  | UserRightByAccRequestingAction
  | UserRightByAccSuccessAction
  | UserRightByAccErrorAction
  | UserRegisterAction
  | UserRegisterRequestingAction
  | UserRegisterSuccessAction
  | UserRegisterErrorAction
  | UserRegisCheckRequestingAction
  | UserRegisCheckSuccessAction
  | UserRegisCheckErrorAction
  | UserProfitCloseRequestingAction
  | UserProfitCloseSuccessAction
  | UserProfitCloseErrorAction
  | UserAssetDetailRequestingAction
  | UserAssetDetailSuccessAction
  | UserAssetDetailErrorAction
  | UserAssetChartRequestingAction
  | UserAssetChartSuccessAction
  | UserAssetChartErrorAction
  | UserProfitSymbolRequestingAction
  | UserProfitSymbolSuccessAction
  | UserProfitSymbolErrorAction;
