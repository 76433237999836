import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';
import * as actions from './actionType';
import {
  rightUnExecRequestSuccess,
  rightUnExecRequestError,
  updateUnExecRequestSuccess,
  updateUnExecRequestError,
  listRightBuyRequestSuccess,
  listRightBuyRequestError,
  rightBuyHistoryRequestSuccess,
  rightBuyHistoryRequestError,
  rightAllStatusSuccess,
  rightAllStatusError,
  rightAllTypeSuccess,
  rightAllTypeError,
} from './actions';
import { checkInvalidSession } from 'utils';
import { storages } from 'lib/storages';

const appUrl = `${process.env.REACT_APP_API_URL}/TraditionalService`;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function summaryApi(data: any) {
  const request = fetch(appUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* rightInfoUnExecRequestFlow(
  action: actions.RightUnExecRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(rightUnExecRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(rightUnExecRequestError(error));
  }
}

function* listRightBuyRequestFlow(
  action: actions.ListRightBuyRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(listRightBuyRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(listRightBuyRequestError(error));
  }
}

function* rightBuyHisRequestFlow(
  action: actions.RightBuyHistoryRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(rightBuyHistoryRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(rightBuyHistoryRequestError(error));
  }
}

function* rightAllStatusRequestFlow(
  action: actions.RightAllStatusRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(rightAllStatusSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(rightAllStatusError(error));
  }
}

function* updateInfoUnExecRequestFlow(
  action: actions.UpdateUnExecRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(updateUnExecRequestSuccess(Math.random()));
  } catch (error) {
    // log(error)
    yield put(updateUnExecRequestError(error));
  }
}

function* rightAllTypeRequestFlow(
  action: actions.RightAllTypeRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(rightAllTypeSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(rightAllTypeError(error));
  }
}

function* rightWatcher() {
  yield all([
    takeLatest(actions.RIGHT_UN_EXEC_REQUESTING, rightInfoUnExecRequestFlow),
    takeLatest(actions.UPDATE_UN_EXEC_REQUESTING, updateInfoUnExecRequestFlow),
    takeLatest(actions.RIGHT_BUY_STT_REQUESTING, listRightBuyRequestFlow),
    takeLatest(actions.RIGHT_BUY_HIS_REQUESTING, rightBuyHisRequestFlow),
    takeLatest(actions.RIGHT_ALL_STATUS_REQUESTING, rightAllStatusRequestFlow),
    takeLatest(actions.RIGHT_ALL_TYPE_REQUESTING, rightAllTypeRequestFlow),
  ]);
}

export default rightWatcher;
