import * as actions from './actionType';
import * as _ from 'lodash';

export interface SummaryState {
  cashList: any;
  cashListRequesting: boolean;
  cashListErrors: any;
  shareEarn: any;

  cashCanAdv: any;
  cashCanAdvRequesting: boolean;
  cashCanAdvErrors: any;

  feeAdvWithdraw: any;
  advStt: any;
  advSttHis: any;
  listShareBalance: any;
  listShareStt: any;
  shareTransferHis: any;

  cashAccountInfo: any;
  cashAccountInfoOl: any;
  beneficiaryAccount: any;
  accBenAdd: any;

  listCashStt: any;
  cashTransferHis: any;

  sumStockList: any;
  detailDebts: any;
}

const initialState = {
  cashList: [],
  cashListRequesting: false,
  cashListErrors: [],
  shareEarn: null,
  cashCanAdv: [],
  cashCanAdvRequesting: false,
  cashCanAdvErrors: [],

  feeAdvWithdraw: null,
  advStt: null,
  advSttHis: null,
  listShareBalance: null,
  listShareStt: null,
  shareTransferHis: null,

  cashAccountInfo: null,
  cashAccountInfoOl: null,
  beneficiaryAccount: null,
  accBenAdd: null,

  listCashStt: null,
  cashTransferHis: null,

  sumStockList: null,
  detailDebts: null,
};

export default function summaryReducer(
  state: SummaryState = initialState,
  action: actions.SummaryAction,
): SummaryState {
  switch (action.type) {
    case actions.SUMMARY_CASH_CLEAR:
      return {
        ...state,
        cashList: [],
        cashListRequesting: false,
        cashListErrors: [],
      };
    case actions.SUMMARY_CASH_REQUESTING:
      return {
        ...state,
        cashList: [],
        cashListRequesting: true,
        cashListErrors: [],
      };

    case actions.SUMMARY_CASH_REQUEST_SUCCESS:
      return {
        ...state,
        cashList: action.cashList,
        cashListRequesting: false,
        cashListErrors: [],
      };

    case actions.SUMMARY_CASH_REQUEST_ERROR:
      return {
        ...state,
        cashList: [],
        cashListRequesting: false,
        cashListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.SUMMARY_STOCK_REQUESTING:
    case actions.SUMMARY_STOCK_ERROR:
      return {
        ...state,
        sumStockList: null,
      };
    case actions.SUMMARY_STOCK_SUCCESS:
      return {
        ...state,
        sumStockList: action.resData,
      };

    case actions.SUMMARY_SHARE_EARN_REQUESTING:
    case actions.SUMMARY_SHARE_EARN_REQUEST_ERROR:
      return {
        ...state,
        shareEarn: null,
      };
    case actions.SUMMARY_SHARE_EARN_REQUEST_SUCCESS:
      return {
        ...state,
        shareEarn: action.resData,
      };

    case actions.CASH_CAN_ADV_REQUESTING:
      return {
        ...state,
        cashCanAdv: [],
        cashCanAdvRequesting: true,
        cashCanAdvErrors: [],
      };

    case actions.CASH_CAN_ADV_REQUEST_SUCCESS:
      return {
        ...state,
        cashCanAdv: action.cashCanAdv,
        cashCanAdvRequesting: false,
        cashCanAdvErrors: [],
      };

    case actions.CASH_CAN_ADV_REQUEST_ERROR:
      return {
        ...state,
        cashCanAdv: [],
        cashCanAdvRequesting: false,
        cashCanAdvErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.FEE_ADV_WITHDRAW_REQUESTING:
    case actions.FEE_ADV_WITHDRAW_REQUEST_ERROR:
      return {
        ...state,
        feeAdvWithdraw: null,
      };
    case actions.FEE_ADV_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        feeAdvWithdraw: action.feeAdvWithdraw,
      };

    case actions.ADV_STATUS_REQUESTING:
    case actions.ADV_STATUS_REQUEST_ERROR:
      return {
        ...state,
        advStt: null,
      };
    case actions.ADV_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        advStt: action.advStt,
      };

    case actions.ADV_STATUS_HIS_REQUESTING:
    case actions.ADV_STATUS_HIS_REQUEST_ERROR:
      return {
        ...state,
        advSttHis: null,
      };
    case actions.ADV_STATUS_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        advSttHis: action.advSttHis,
      };

    case actions.LIST_SHARE_BALANCE_REQUESTING:
    case actions.LIST_SHARE_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        listShareBalance: null,
      };
    case actions.LIST_SHARE_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        listShareBalance: action.listShareBalance,
      };

    case actions.SHARE_TRANSFER_STATUS_REQUESTING:
    case actions.SHARE_TRANSFER_REQUEST_ERROR:
      return {
        ...state,
        listShareStt: null,
      };
    case actions.SHARE_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        listShareStt: action.listShareStt,
      };

    case actions.SHARE_TRANSFER_HIS_REQUESTING:
    case actions.SHARE_TRANSFER_HIS_REQUEST_ERROR:
      return {
        ...state,
        shareTransferHis: null,
      };
    case actions.SHARE_TRANSFER_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        shareTransferHis: action.shareTransferHis,
      };

    case actions.CASH_ACCOUNT_INFO_REQUESTING:
    case actions.CASH_ACCOUNT_INFO_REQUEST_ERROR:
      return {
        ...state,
        cashAccountInfo: null,
      };
    case actions.CASH_ACCOUNT_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        cashAccountInfo: action.cashAccountInfo,
      };

    case actions.CASH_ONLY_ACCOUNT_INFO_REQUEST_SUCCESS:
      const cashAcc = _.cloneDeep(state.cashAccountInfo);
      if (!action.data && !cashAcc) {
        return {
          ...state,
        };
      } else if (!action.data && cashAcc) {
        cashAcc.C_ADVANCE_VALUE = 0;
        cashAcc.C_ADVANCE_FEE = 0;
        return {
          ...state,
          cashAccountInfo: cashAcc,
        };
      } else {
        cashAcc.C_ADVANCE_VALUE = action.data?.C_ADVANCE_VALUE || 0;
        cashAcc.C_ADVANCE_FEE = action.data?.C_ADVANCE_FEE || 0;
        return {
          ...state,
          cashAccountInfo: cashAcc,
        };
      }

    case actions.CASH_ACCOUNT_INFO_OL_REQUESTING:
    case actions.CASH_ACCOUNT_INFO_OL_REQUEST_ERROR:
      return {
        ...state,
        cashAccountInfoOl: null,
      };
    case actions.CASH_ACCOUNT_INFO_OL_REQUEST_SUCCESS:
      return {
        ...state,
        cashAccountInfoOl: action.cashAccountInfoOl,
      };

    case actions.CASH_ONLY_ACCOUNT_INFO_OL_REQUEST_SUCCESS:
      const cashAccOL = _.cloneDeep(state.cashAccountInfoOl);
      if (!action.data && !cashAccOL) {
        return {
          ...state,
        };
      } else if (!action.data && cashAccOL) {
        cashAccOL.C_ADVANCE_VALUE = 0;
        cashAccOL.C_ADVANCE_FEE = 0;
        return {
          ...state,
          cashAccountInfoOl: cashAccOL,
        };
      } else {
        cashAccOL.C_ADVANCE_VALUE = action.data?.C_ADVANCE_VALUE || 0;
        cashAccOL.C_ADVANCE_FEE = action.data?.C_ADVANCE_FEE || 0;
        return {
          ...state,
          cashAccountInfoOl: cashAccOL,
        };
      }

    case actions.ACCOUNT_BENEFICIARY_REQUESTING:
    case actions.ACCOUNT_BENEFICIARY_REQUEST_ERROR:
      return {
        ...state,
        beneficiaryAccount: null,
      };
    case actions.ACCOUNT_BENEFICIARY_REQUEST_SUCCESS:
      return {
        ...state,
        beneficiaryAccount: action.beneficiaryAccount,
      };

    case actions.CASH_TRANSFER_STATUS_REQUESTING:
    case actions.CASH_TRANSFER_REQUEST_ERROR:
      return {
        ...state,
        listCashStt: null,
      };
    case actions.CASH_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        listCashStt: action.listCashStt,
      };

    case actions.CASH_TRANSFER_HIS_REQUESTING:
    case actions.CASH_TRANSFER_HIS_REQUEST_ERROR:
      return {
        ...state,
        cashTransferHis: null,
      };
    case actions.CASH_TRANSFER_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        cashTransferHis: action.cashTransferHis,
      };

    case actions.ACC_BEN_ADD_REQUESTING:
    case actions.ACC_BEN_ADD_ERROR:
      return {
        ...state,
        accBenAdd: null,
      };
    case actions.ACC_BEN_ADD_SUCCESS:
      return {
        ...state,
        accBenAdd: action.resData,
      };

    case actions.DETAIL_DEBTS_REQUESTING:
    case actions.DETAIL_DEBTS_ERROR:
      return {
        ...state,
        detailDebts: null,
      };
    case actions.DETAIL_DEBTS_SUCCESS:
      return {
        ...state,
        detailDebts: action.resData,
      };

    default:
      return state;
  }
}
