import {
  clientSettingSet,
  setCategory,
  setCategoryNganh,
  setCategoryPortfolio,
} from 'containers/client/actions';
import pkg from '../../package.json';
import { IAccPortfolio, ICategory } from 'interface/response';
import * as _ from 'lodash';
const setting = {};

export const categoryDefault = [
  {
    id: 'mac-dinh',
    name: 'Mặc định',
    path: '/price/bang-gia/mac-dinh',
    value: [],
    pinnedRow: [],
    type: 'watchlist',
  },
  //index
  {
    name: 'VN30',
    path: '/price/bang-gia/vn30',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'INDEX',
  },
  {
    name: 'HNX30',
    path: '/price/bang-gia/hnx30',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'INDEX',
  },
  //main
  {
    name: 'HOSE',
    path: '/price/bang-gia/hsx',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'MAIN',
  },
  {
    name: 'HNX',
    path: '/price/bang-gia/hnx',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'MAIN',
  },
  {
    name: 'UPCOM',
    path: '/price/bang-gia/upcom',
    groupName: 'UPCOM',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'MAIN',
  },
  //ngành
  {
    name: 'Ngành',
    path: '/price/bang-gia/danh-muc-nganh',
    groupName: 'NGANH',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'NGANH',
  },
  //Thỏa thuận
  {
    name: 'Thoả thuận HOSE',
    path: '/price/bang-gia/thoa-thuan-hsx',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'PT',
  },
  {
    name: 'Thoả thuận HNX',
    path: '/price/bang-gia/thoa-thuan-hnx',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'PT',
  },
  {
    name: 'Thoả thuận UPCOM',
    path: '/price/bang-gia/thoa-thuan-upcom',
    groupName: 'UPCOM',
    type: 'group',
    value: [],
    pinnedRow: [],
    groupNav: 'PT',
  },
  //LOLE
  // {
  //   name: 'HOSE',
  //   path: '/price/bang-gia/lo-le-hose',
  //   groupName: 'LOLE',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  //   groupNav: 'LOLE',
  // },
  // {
  //   name: 'HNX',
  //   path: '/price/bang-gia/lo-le-hnx',
  //   groupName: 'LOLE',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  //   groupNav: 'LOLE',
  // },
];

export const DMNGANH = [
  {
    id: '0500',
    sequence: 1,
    name: 'Dầu khí',
    path: '/price/bang-gia/nganh-0500',
  },
  {
    id: '1300',
    sequence: 2,
    name: 'Hóa chất',
    path: '/price/bang-gia/nganh-1300',
  },
  {
    id: '1700',
    sequence: 3,
    name: 'Tài nguyên cơ bản',
    path: '/price/bang-gia/nganh-1700',
  },
  {
    id: '2300',
    sequence: 4,
    name: 'Xây dựng và vật liệu',
    path: '/price/bang-gia/nganh-2300',
  },
  {
    id: '2700',
    sequence: 5,
    name: 'Hàng & Dịch vụ Công nghiệp',
    path: '/price/bang-gia/nganh-2700',
  },
  {
    id: '3300',
    sequence: 6,
    name: 'Ô tô và phụ tùng',
    path: '/price/bang-gia/nganh-3300',
  },
  {
    id: '3500',
    sequence: 7,
    name: 'Thực phẩm và đồ uống',
    path: '/price/bang-gia/nganh-3500',
  },
  {
    id: '3700',
    sequence: 8,
    name: 'Hàng cá nhân & Gia dụng',
    path: '/price/bang-gia/nganh-3700',
  },
  {
    id: '4500',
    sequence: 9,
    name: 'Y tế',
    path: '/price/bang-gia/nganh-4500',
  },
  {
    id: '5300',
    sequence: 10,
    name: 'Bán lẻ',
    path: '/price/bang-gia/nganh-5300',
  },
  {
    id: '5500',
    sequence: 11,
    name: 'Truyền thông',
    path: '/price/bang-gia/nganh-5500',
  },
  {
    id: '5700',
    sequence: 12,
    name: 'Du lịch và Giải trí',
    path: '/price/bang-gia/nganh-5700',
  },
  {
    id: '6500',
    sequence: 13,
    name: 'Viễn thông',
    path: '/price/bang-gia/nganh-6500',
  },
  {
    id: '7500',
    sequence: 14,
    name: 'Tiện ích',
    path: '/price/bang-gia/nganh-7500',
  },
  {
    id: '8300',
    sequence: 15,
    name: 'Ngân hàng',
    path: '/price/bang-gia/nganh-8300',
  },
  {
    id: '8500',
    sequence: 16,
    name: 'Bảo hiểm',
    path: '/price/bang-gia/nganh-8500',
  },
  {
    id: '8700',
    sequence: 17,
    name: 'Dịch vụ tài chính',
    path: '/price/bang-gia/nganh-8700',
  },
  {
    id: '9500',
    sequence: 18,
    name: 'Công nghệ Thông tin',
    path: '/price/bang-gia/nganh-9500',
  },
];

export const storages = {
  loadState: (key: string) => {
    const { name } = pkg;
    const _name = key + '_' + name;
    try {
      const serializedState = localStorage.getItem(_name);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  },
  saveState: (key: string, state: any) => {
    const { name } = pkg;
    const _name = key + '_' + name;
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(_name, serializedState);
    } catch (err: any) {
      // ...
      console.log('save state error:', err.toString());
    }
  },
  getSetting: (dispatch: any) => {
    const storedSetting = storages.loadState('setting');

    if (storedSetting) {
      dispatch(clientSettingSet(storedSetting));
    } else {
      // chưa có category
      dispatch(clientSettingSet(setting));
      storages.saveState('setting', setting);
    }

    return true;
  },
  removeState: (key: string): void => {
    const { name } = pkg;
    localStorage.removeItem(key + '_' + name);
  },
};

export function getCategory({ dispatch }: { dispatch: any }) {
  const storedCategory = storages.loadState('category');

  if (storedCategory) {
    dispatch(setCategory(storedCategory));
  } else {
    // chưa có category
    dispatch(setCategory(categoryDefault));
    storages.saveState('category', categoryDefault);
  }
  return true;
}

export function getCategoryNganhStorage({
  dispatch,
  industryList,
}: {
  dispatch: any;
  industryList: ICategory[];
}) {
  // chỉ lấy ngành từ localstorage
  const storedCategory = storages.loadState('nganh');

  let categoryNganh: any[] = [];
  industryList.forEach((element) => {
    const _dataStore = _.find(
      storedCategory,
      (o: ICategory) => o.path === element.path,
    );
    categoryNganh.push({ ...element, pinnedRow: _dataStore?.pinnedRow || [] });
  });

  // console.log('_catWatchList', _catWatchList);

  const _newCategory = [...categoryNganh];
  dispatch(setCategoryNganh(_newCategory));
  storages.saveState('nganh', _newCategory);
  return true;
}

export function getCategoryPortfolioStorage({
  dispatch,
  accPortfolio,
}: {
  dispatch: any;
  accPortfolio: IAccPortfolio[];
}) {
  // chỉ lấy ngành từ localstorage
  const storedCategory = storages.loadState('portfolio');

  let categoryNganh: any[] = [];
  accPortfolio.forEach((element) => {
    const _dataStore = _.find(
      storedCategory,
      (o: IAccPortfolio) => o.path === element.path,
    );
    categoryNganh.push({ ...element, pinnedRow: _dataStore?.pinnedRow || [] });
  });

  // console.log('_catWatchList', _catWatchList);

  const _newCategory = [...categoryNganh];
  dispatch(setCategoryPortfolio(_newCategory));
  storages.saveState('portfolio', _newCategory);
  return true;
}
