import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';

import * as actions from './actionType';
import axios from 'axios';
import {
  notiMarkAllReadError,
  notiMarkAllReadPriError,
  notiMarkAllReadPriSuccess,
  notiMarkAllReadSuccess,
  notiMarkReadError,
  notiMarkReadSuccess,
  notiPersonalError,
  notiPersonalSuccess,
  notiPublicError,
  notiPublicSuccess,
} from './actions';
import { checkInvalidSession } from 'utils';
import { storages } from 'lib/storages';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrlFile = `${process.env.REACT_APP_FILE_URL}`;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function notificationPublicApi(data: Object) {
  const request = fetch(appUrlFile + '/listAllNotification', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function notificationPersonalApi(data: Object) {
  const request = fetch(appUrlFile + '/listNotification', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function notificationMarkReadApi(data: Object) {
  console.log('data', data);

  const request = fetch(appUrlFile + '/markRead', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function notificationMarkAllReadApi(data: Object) {
  console.log('data', data);

  const request = fetch(appUrlFile + '/markAllRead', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* notiPublicRequestFlow(
  action: actions.NotiPublicRequestingAction,
): any {
  try {
    const { data } = action;

    const _res: any = yield call(notificationPublicApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(notiPublicSuccess(_res));
  } catch (error: any) {
    yield put(notiPublicError(error));
  }
}

function* notiPersonalRequestFlow(
  action: actions.NotiPersonalRequestingAction,
): any {
  try {
    const { data } = action;

    const _res: any = yield call(notificationPersonalApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(notiPersonalSuccess(_res));
  } catch (error: any) {
    yield put(notiPersonalError(error));
  }
}

function* notiMarkReadRequestFlow(
  action: actions.NotiPersonalRequestingAction,
): any {
  try {
    const { data } = action;

    const _res: any = yield call(notificationMarkReadApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(notiMarkReadSuccess(Math.random()));
  } catch (error: any) {
    yield put(notiMarkReadError(error));
  }
}

function* notiMarkAllReadRequestFlow(
  action: actions.NotiPersonalRequestingAction,
): any {
  try {
    const { data } = action;

    const _res: any = yield call(notificationMarkAllReadApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(notiMarkAllReadSuccess(Math.random()));
  } catch (error: any) {
    yield put(notiMarkAllReadError(error));
  }
}

function* notiMarkAllReadPriRequestFlow(
  action: actions.NotiPersonalRequestingAction,
): any {
  try {
    const { data } = action;

    const _res: any = yield call(notificationMarkAllReadApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(notiMarkAllReadPriSuccess(Math.random()));
  } catch (error: any) {
    yield put(notiMarkAllReadPriError(error));
  }
}

function* custWatcher() {
  yield all([
    takeLatest(actions.NOTI_PUBLIC_REQUESTING, notiPublicRequestFlow),
    takeLatest(actions.NOTI_PERSONAL_REQUESTING, notiPersonalRequestFlow),
    takeLatest(actions.NOTI_MARK_READ_REQUESTING, notiMarkReadRequestFlow),
    takeLatest(
      actions.NOTI_MARK_ALL_READ_REQUESTING,
      notiMarkAllReadRequestFlow,
    ),
    takeLatest(
      actions.NOTI_MARK_ALL_READ_PRI_REQUESTING,
      notiMarkAllReadPriRequestFlow,
    ),
  ]);
}

export default custWatcher;
