export const RIGHT_UN_EXEC_REQUESTING = 'right/RIGHT_UN_EXEC_REQUESTING';
export interface RightUnExecRequestAction {
  type: typeof RIGHT_UN_EXEC_REQUESTING;
  params: any;
}

export const RIGHT_UN_EXEC_REQUEST_SUCCESS = 'right/RIGHT_UN_EXEC_SUCCESS';
export interface RightUnExecRequestSuccessAction {
  type: typeof RIGHT_UN_EXEC_REQUEST_SUCCESS;
  rightUnExec: any;
}

export const RIGHT_UN_EXEC_REQUEST_ERROR = 'right/RIGHT_UN_EXEC_ERROR';
export interface RightUnExecRequestErrorAction {
  type: typeof RIGHT_UN_EXEC_REQUEST_ERROR;
  error: any;
}

export const UPDATE_UN_EXEC_REQUESTING = 'right/UPDATE_UN_EXEC_REQUESTING';
export interface UpdateUnExecRequestAction {
  type: typeof UPDATE_UN_EXEC_REQUESTING;
  params: any;
}

export const UPDATE_UN_EXEC_REQUEST_SUCCESS = 'right/UPDATE_UN_EXEC_SUCCESS';
export interface UpdateUnExecRequestSuccessAction {
  type: typeof UPDATE_UN_EXEC_REQUEST_SUCCESS;
  updUnExec: any;
}

export const UPDATE_UN_EXEC_REQUEST_ERROR = 'right/UPDATE_UN_EXEC_ERROR';
export interface UpdateUnExecRequestErrorAction {
  type: typeof UPDATE_UN_EXEC_REQUEST_ERROR;
  error: any;
}

export const RIGHT_BUY_STT_REQUESTING = 'right/RIGHT_BUY_STT_REQUESTING';
export interface ListRightBuyRequestAction {
  type: typeof RIGHT_BUY_STT_REQUESTING;
  params: any;
}

export const RIGHT_BUY_STT_REQUEST_SUCCESS = 'right/RIGHT_BUY_STT_SUCCESS';
export interface ListRightBuyRequestSuccessAction {
  type: typeof RIGHT_BUY_STT_REQUEST_SUCCESS;
  listRightBuy: any;
}

export const RIGHT_BUY_STT_REQUEST_ERROR = 'right/RIGHT_BUY_STT__ERROR';
export interface ListRightBuyRequestErrorAction {
  type: typeof RIGHT_BUY_STT_REQUEST_ERROR;
  error: any;
}

export const RIGHT_BUY_HIS_REQUESTING = 'right/RIGHT_BUY_HIS_REQUESTING';
export interface RightBuyHistoryRequestAction {
  type: typeof RIGHT_BUY_HIS_REQUESTING;
  params: any;
}

export const RIGHT_BUY_HIS_REQUEST_SUCCESS = 'right/RIGHT_BUY_HIS_SUCCESS';
export interface RightBuyHistoryRequestSuccessAction {
  type: typeof RIGHT_BUY_HIS_REQUEST_SUCCESS;
  rightBuyHis: any;
}

export const RIGHT_BUY_HIS_REQUEST_ERROR = 'right/RIGHT_BUY_HIS_ERROR';
export interface RightBuyHistoryRequestErrorAction {
  type: typeof RIGHT_BUY_HIS_REQUEST_ERROR;
  error: any;
}

export const RIGHT_ALL_STATUS_REQUESTING = 'right/RIGHT_ALL_STATUS_REQUESTING';
export interface RightAllStatusRequestAction {
  type: typeof RIGHT_ALL_STATUS_REQUESTING;
  params: any;
}

export const RIGHT_ALL_STATUS_SUCCESS = 'right/RIGHT_ALL_STATUS_SUCCESS';
export interface RightAllStatusSuccessAction {
  type: typeof RIGHT_ALL_STATUS_SUCCESS;
  resData: any;
}

export const RIGHT_ALL_STATUS_ERROR = 'right/RIGHT_ALL_STATUS_ERROR';
export interface RightAllStatusErrorAction {
  type: typeof RIGHT_ALL_STATUS_ERROR;
  error: any;
}

export const RIGHT_ALL_TYPE_REQUESTING = 'right/RIGHT_ALL_TYPE_REQUESTING';
export interface RightAllTypeRequestAction {
  type: typeof RIGHT_ALL_TYPE_REQUESTING;
  params: any;
}

export const RIGHT_ALL_TYPE_SUCCESS = 'right/RIGHT_ALL_TYPE_SUCCESS';
export interface RightAllTypeSuccessAction {
  type: typeof RIGHT_ALL_TYPE_SUCCESS;
  resData: any;
}

export const RIGHT_ALL_TYPE_ERROR = 'right/RIGHT_ALL_TYPE_ERROR';
export interface RightAllTypeErrorAction {
  type: typeof RIGHT_ALL_TYPE_ERROR;
  error: any;
}
export type RightAction =
  | RightUnExecRequestAction
  | RightUnExecRequestSuccessAction
  | RightUnExecRequestErrorAction
  | UpdateUnExecRequestAction
  | UpdateUnExecRequestSuccessAction
  | UpdateUnExecRequestErrorAction
  | ListRightBuyRequestAction
  | ListRightBuyRequestSuccessAction
  | ListRightBuyRequestErrorAction
  | RightBuyHistoryRequestAction
  | RightBuyHistoryRequestSuccessAction
  | RightBuyHistoryRequestErrorAction
  | RightAllStatusRequestAction
  | RightAllStatusSuccessAction
  | RightAllStatusErrorAction
  | RightAllTypeRequestAction
  | RightAllTypeSuccessAction
  | RightAllTypeErrorAction;
