import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';

import * as actions from './actionType';
import axios from 'axios';
import {
  regAccountError,
  regAccountSuccess,
  regCheckCustError,
  regCheckCustSuccess,
  regCheckOtpError,
  regCheckOtpSuccess,
  resChangePassError,
  resChangePassSuccess,
  resCheckCustError,
  resCheckCustSuccess,
} from './actions';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrl = `${process.env.REACT_APP_API_URL}`;

async function postRequestApi(data: Object, path: string) {
  const url = `${appUrl}/openAccount/${path}`;

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

async function postChangePassRequestApi(data: Object, path: string) {
  const url = `${appUrl}/${path}`;

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* regCheckCustRequestFlow(
  action: actions.RegCheckCustRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, 'verifyPhoneEmail');

    if (!_res.rc || _res.rc < 1) {
      if (_res.rs.includes('Invalid session')) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.rs);
    }
    yield put(regCheckCustSuccess(Math.random()));
  } catch (error: any) {
    yield put(regCheckCustError(error));
  }
}

function* regCheckOtpRequestFlow(
  action: actions.RegCheckOtpRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, 'verifyOtp');

    if (!_res.rc || _res.rc < 1) {
      if (_res.rs.includes('Invalid session')) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.rs);
    }
    yield put(regCheckOtpSuccess(Math.random()));
  } catch (error: any) {
    yield put(regCheckOtpError(error));
  }
}

function* regAccountRequestFlow(
  action: actions.RegAccountRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, 'create');

    if (!_res.rc || _res.rc < 1) {
      if (_res.rs.includes('Invalid session')) {
        yield put({ type: 'INVALID_SESSION' });
      } else yield put({ type: 'API_ERR', msg: _res.rs });
      throw Error(_res.rs);
    }
    yield put(regAccountSuccess(_res.data[0].C_ACCOUNT_CODE));
  } catch (error: any) {
    yield put(regAccountError(error));
  }
}

function* resCheckCustRequestFlow(
  action: actions.ResCheckCustRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postChangePassRequestApi, data, 'verifyEmail');

    if (!_res.rc || _res.rc < 1) {
      if (_res.rs.includes('Invalid session')) {
        yield put({ type: 'INVALID_SESSION' });
      }
      // else yield put({ type: "API_ERR", msg: _res.rs });
      throw Error(_res.rs);
    }
    yield put(resCheckCustSuccess(Math.random()));
  } catch (error: any) {
    yield put(resCheckCustError(error));
  }
}

function* resChangePassRequestFlow(
  action: actions.ResChangePassRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(
      postChangePassRequestApi,
      data,
      'ResetPassword',
    );

    if (!_res.rc || _res.rc < 1) {
      if (_res.rs.includes('Invalid session')) {
        yield put({ type: 'INVALID_SESSION' });
      }
      // else yield put({ type: "API_ERR", msg: _res.rs });
      throw Error(_res.rs);
    }
    yield put(resChangePassSuccess(_res.data));
  } catch (error: any) {
    yield put(resChangePassError(error));
  }
}

function* regAccountWatcher() {
  yield all([
    takeLatest(actions.REG_CHECK_CUST_REQUESTING, regCheckCustRequestFlow),
    takeLatest(actions.REG_CHECK_OTP_REQUESTING, regCheckOtpRequestFlow),
    takeLatest(actions.REG_ACCOUNT_REQUESTING, regAccountRequestFlow),

    takeLatest(actions.RES_CHECK_CUST_REQUESTING, resCheckCustRequestFlow),
    takeLatest(actions.RES_CHANGE_PASS_REQUESTING, resChangePassRequestFlow),
  ]);
}

export default regAccountWatcher;
