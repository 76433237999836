/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import {
  receive1101,
  socketReady,
  serverOff,
  receive3220,
  receiveReregister,
  receive3210,
} from './actions';

import { hmsToSeconds } from 'utils';
import { ISocketContextType } from 'interface';
import {
  _process3210,
  _process3220,
  _process3250,
  _process3310,
  _process6210,
  _process6220,
} from 'utils/priceboard';

const socketUrl = `${process.env.REACT_APP_SOCKET_URL}`;

const WebSocketContext = React.createContext<ISocketContextType | null>(null);

export { WebSocketContext };

const WebSocketProvider: React.FC<any> = ({ children, store }) => {
  let socket: any;
  let ws;

  let countPing = 0;
  let interval: any, timer: any;
  let renderQueue: any[] = [],
    mounted = false;

  const dispatch = useDispatch();

  React.useEffect(() => {
    window.addEventListener('hashchange', handleChange);
    document.addEventListener('visibilitychange', handleActivity);

    const d = new Date();
    const diffSecond =
      hmsToSeconds(d.getHours() + ':' + d.getHours() + ':' + d.getSeconds()) -
      hmsToSeconds('15:05:00');
    if (!timer && diffSecond < 0)
      timer = setInterval(processUpdateCell, 5 * 10);

    return () => {
      window.removeEventListener('hashchange', handleChange);
      document.removeEventListener('visibilitychange', handleActivity);

      if (interval) clearInterval(interval);
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
      renderQueue = [];
    };
  }, []);

  function handleActivity() {
    console.log('clear queue');
    renderQueue = [];
  }

  function handleChange() {
    const pathname = window.location.hash;
    const d = new Date();
    const diffSecond =
      hmsToSeconds(d.getHours() + ':' + d.getHours() + ':' + d.getSeconds()) -
      hmsToSeconds('15:05:00');
    // console.log(pathname, diffSecond, timer);
    if (pathname.indexOf('/price/bang-gia') > -1 && diffSecond < 0) {
      if (!timer) timer = setInterval(processUpdateCell, 5 * 10);
    } else {
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
      renderQueue = [];
    }
  }

  const sendMessage = (message: any) => {
    if (!socket) return;
    if (message.action === 'join') {
      socket.emit('regs', JSON.stringify(message), (dt: any) => {
        dispatch(socketReady());
        if (dt === 'OK') {
          dispatch({ type: 'REGISTER_OK' });
        } else {
          dispatch({ type: 'REGISTER_NOT_OK' });
        }
      });
    } else {
      socket.emit('regs', JSON.stringify(message));
    }
  };

  const processUpdateCell = () => {
    mounted = true;

    const _processData = renderQueue.splice(0, 10);
    // console.log(renderQueue.length)
    mounted = false;
    _processData.forEach((element) => {
      if (element.id === 3220) _process3220(element);
      if (element.id === 3210) _process3210(element);
      if (element.id === 3250) _process3250(element);
      if (element.id === 3310) _process3310(element);
    });
  };

  const processTimeIndex = (msg1101: { time: any }) => {
    const time = msg1101.time;
    const diffSecond = hmsToSeconds(time) - hmsToSeconds('15:05:00');
    // console.log(diffSecond)
    if (diffSecond > 0) {
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
      renderQueue = [];
    }
  };

  const sendPing = () => {
    // log('ping server', countPing)
    if (countPing > 4) {
      return dispatch(serverOff());
    }
    if (!socket) return;

    countPing++;

    let msg = { action: 'ping', mode: 'sync', data: ' ' };

    socket.emit('regs', JSON.stringify(msg), () => {
      countPing = 0;
      dispatch(socketReady());
    });
  };

  const reconnectTrigger = () => {
    if (socket) socket.connect();
  };

  const init = () => {
    if (socket) {
      socket = null;
    }
    if (!socket) {
      if (interval) clearInterval(interval);

      socket = io.connect(socketUrl, {
        autoConnect: false,
        reconnection: false,
        // reconnectionDelay: 1000,
        // reconnectionAttempts: 5,
      });

      socket.on('connect', () => {
        console.log('connected');
        dispatch(socketReady());
      });

      socket.on('reconnect', () => {
        console.log('reconnect');
        dispatch(socketReady());
      });

      socket.on('disconnect', () => {
        console.log('disconnect');
        // log(socket)
        dispatch(serverOff());
        init();
      });

      socket.on('reconnect_failed', () => {
        console.log('reconnect_failed');
        dispatch(serverOff());
      });

      socket.on('reconnect_error', () => {
        console.log('reconnect_error');
        dispatch(serverOff());
      });

      socket.on('public', (msg: { data: any }) => {
        // publicHandler(msg, dispatch, store);
        const payload = msg.data;
        const _st = store.getState().socket.regTrade;
        switch (payload.id) {
          case 6666:
            dispatch(receiveReregister(true));
            break;
          case 1101:
            processTimeIndex(payload);
            dispatch(socketReady());
            dispatch(receive1101(payload));
            break;
          // case 3211:
          //   dispatch(receive3211(payload));
          //   break;
          case 3210:
            // _process3210(payload);
            if (!mounted) renderQueue.push(payload);
            if (payload.sym === _st) {
              // dispatch mess 3210
              dispatch(receive3210(payload));
            }
            break;
          case 3220:
          case 3223:
            // _process3220(payload);
            if (!mounted) renderQueue.push(payload);
            if (payload.sym.startsWith('VN30') || payload.sym === _st) {
              // dispatch mess 3220
              dispatch(receive3220(payload));
            }
            break;
          case 3250:
            if (!mounted) renderQueue.push(payload);
            break;
          case 3310:
            _process3310(payload);
            break;
          // lô lẻ
          case 6210:
            _process6210(payload);
            break;
          case 6220:
            _process6220(payload);
            break;
          default:
            break;
        }
      });

      socket.on('private', (msg: { action: any }) => {
        const action = msg.action;
        if (action === 'ping') {
          sendPing();
        }
      });

      socket.open((err: any) => {
        if (err) {
          // an error has occurred
          dispatch(serverOff());
        } else {
          // the connection was successfully established
          dispatch(socketReady());
        }
      });

      interval = setInterval(sendPing, 10 * 1000);
    }
  };

  ws = {
    socket: socket,
    sendMessage,
    reconnectTrigger,
    init,
  };

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
