import * as actions from './actionType';

export interface NotiState {
  notiPublic: any;
  notiPersonal: any;
  notiMarkRead: any;
  notiMarkAllRead: any;
  notiMarkAllReadPri: any;
}

const initialState = {
  notiPublic: null,
  notiPersonal: null,
  notiMarkRead: null,
  notiMarkAllRead: null,
  notiMarkAllReadPri: null,
};

export default function mgBankReducer(
  state: NotiState = initialState,
  action: actions.NotiAction,
): NotiState {
  switch (action.type) {
    case actions.NOTI_PUBLIC_REQUESTING:
    case actions.NOTI_PUBLIC_ERROR:
      return {
        ...state,
        notiPublic: null,
      };

    case actions.NOTI_PUBLIC_SUCCESS:
      return {
        ...state,
        notiPublic: action.resData,
      };

    case actions.NOTI_PERSONAL_REQUESTING:
    case actions.NOTI_PERSONAL_ERROR:
      return {
        ...state,
        notiPersonal: null,
      };

    case actions.NOTI_PERSONAL_SUCCESS:
      return {
        ...state,
        notiPersonal: action.resData,
      };

    case actions.NOTI_MARK_READ_ERROR:
      return {
        ...state,
        notiMarkRead: null,
      };

    case actions.NOTI_MARK_READ_SUCCESS:
      return {
        ...state,
        notiMarkRead: action.resData,
      };

    case actions.NOTI_MARK_ALL_READ_ERROR:
      return {
        ...state,
        notiMarkAllRead: null,
      };

    case actions.NOTI_MARK_ALL_READ_SUCCESS:
      return {
        ...state,
        notiMarkAllRead: action.resData,
      };

    case actions.NOTI_MARK_ALL_READ_PRI_ERROR:
      return {
        ...state,
        notiMarkAllReadPri: null,
      };

    case actions.NOTI_MARK_ALL_READ_PRI_SUCCESS:
      return {
        ...state,
        notiMarkAllReadPri: action.resData,
      };

    default:
      return state;
  }
}
