import React, { FunctionComponent } from 'react';

interface Props {
  input: any;
  type: string | undefined;
  placeholder: string | undefined;
  disabled: boolean | undefined;
  meta: any;
}

const _regexp = /^[0-9\b]+$/;

const RenderOtp: FunctionComponent<Props> = (props) => {
  const wrapperRef = React.useRef<HTMLInputElement>(null);

  const inputEl1 = React.useRef<string>('');
  const inputEl2 = React.useRef<string>('');
  const inputEl3 = React.useRef<string>('');
  const inputEl4 = React.useRef<string>('');
  const inputEl5 = React.useRef<string>('');
  const inputEl6 = React.useRef<string>('');

  const refVal1 = React.useRef<HTMLInputElement>(null);
  const refVal2 = React.useRef<HTMLInputElement>(null);
  const refVal3 = React.useRef<HTMLInputElement>(null);
  const refVal4 = React.useRef<HTMLInputElement>(null);
  const refVal5 = React.useRef<HTMLInputElement>(null);
  const refVal6 = React.useRef<HTMLInputElement>(null);

  const refSpan1 = React.useRef<HTMLInputElement>(null);
  const refSpan2 = React.useRef<HTMLInputElement>(null);
  const refSpan3 = React.useRef<HTMLInputElement>(null);
  const refSpan4 = React.useRef<HTMLInputElement>(null);
  const refSpan5 = React.useRef<HTMLInputElement>(null);
  const refSpan6 = React.useRef<HTMLInputElement>(null);

  const {
    input,
    meta: { touched, error },
  } = props;

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    if (_regexp.test(value)) {
      switch (name) {
        case 'inputEl1':
          inputEl1.current = value;
          refVal2?.current?.focus();
          break;
        case 'inputEl2':
          inputEl2.current = value;
          refVal3?.current?.focus();
          break;
        case 'inputEl3':
          inputEl3.current = value;
          refVal4?.current?.focus();
          break;
        case 'inputEl4':
          inputEl4.current = value;
          refVal5?.current?.focus();
          break;
        case 'inputEl5':
          inputEl5.current = value;
          refVal6?.current?.focus();
          break;
        case 'inputEl6':
          inputEl6.current = value;
          break;

        default:
          break;
      }

      setTimeout(() => {
        changeInput();
      }, 100);
    }
  }

  function _handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const { name } = target;
    if (e.key === 'Delete' || e.key === 'Backspace') {
      switch (name) {
        case 'inputEl1':
          inputEl1.current = '';
          setTimeout(() => {
            refSpan1.current?.classList.add('!bg-skin-soild');
          }, 200);
          break;
        case 'inputEl2':
          inputEl2.current = '';
          refVal1?.current?.focus();
          break;
        case 'inputEl3':
          inputEl3.current = '';
          refVal2?.current?.focus();
          break;
        case 'inputEl4':
          inputEl4.current = '';
          refVal3?.current?.focus();
          break;
        case 'inputEl5':
          inputEl5.current = '';
          refVal4?.current?.focus();
          break;
        case 'inputEl6':
          inputEl6.current = '';
          refVal5?.current?.focus();
          break;

        default:
          break;
      }

      setTimeout(() => {
        changeInput();
      }, 100);
    }
  }

  function changeInput() {
    const _val = [
      inputEl1.current,
      inputEl2.current,
      inputEl3.current,
      inputEl4.current,
      inputEl5.current,
      inputEl6.current,
    ];
    input?.onChange(_val.join(''));
  }

  function _handleOnFocus(e: React.FocusEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const { name } = target;
    console.log(name);
    switch (name) {
      case 'inputEl1':
        refSpan1.current?.classList.add('!bg-skin-soild');
        break;
      case 'inputEl2':
        refSpan2.current?.classList.add('!bg-skin-soild');
        break;
      case 'inputEl3':
        refSpan3.current?.classList.add('!bg-skin-soild');
        break;
      case 'inputEl4':
        refSpan4.current?.classList.add('!bg-skin-soild');
        break;
      case 'inputEl5':
        refSpan5.current?.classList.add('!bg-skin-soild');
        break;
      case 'inputEl6':
        refSpan6.current?.classList.add('!bg-skin-soild');
        break;

      default:
        break;
    }
  }

  function _handleOnBlur(e: any) {
    const target = e.target as HTMLInputElement;
    const { name } = target;
    switch (name) {
      case 'inputEl1':
        refSpan1.current?.classList.remove('!bg-skin-soild');
        break;
      case 'inputEl2':
        refSpan2.current?.classList.remove('!bg-skin-soild');
        break;
      case 'inputEl3':
        refSpan3.current?.classList.remove('!bg-skin-soild');
        break;
      case 'inputEl4':
        refSpan4.current?.classList.remove('!bg-skin-soild');
        break;
      case 'inputEl5':
        refSpan5.current?.classList.remove('!bg-skin-soild');
        break;
      case 'inputEl6':
        refSpan6.current?.classList.remove('!bg-skin-soild');
        break;

      default:
        break;
    }
  }

  return (
    <div className="flex flex-col w-full" ref={wrapperRef}>
      <div className="w-[452px] h-20 grid grid-cols-6 gap-4">
        <div className="flex flex-col relative">
          <input
            value={inputEl1.current}
            maxLength={1}
            minLength={0}
            ref={refVal1}
            name="inputEl1"
            onChange={_handleChange}
            onKeyDown={_handleKeyDown}
            onFocus={_handleOnFocus}
            onBlur={_handleOnBlur}
            className={
              'w-full h-full p-4 rounded-2xl text-[28px] font-semibold text-center border-none text-input-trading ' +
              (inputEl1.current
                ? 'bg-transparent'
                : 'bg-skin-otp focus:bg-skin-green')
            }
          />
          {!inputEl1.current && (
            <span
              ref={refSpan1}
              className="absolute bottom-4 left-4 w-[30px] h-[2px] rounded-full bg-skin-white"
            />
          )}
        </div>
        <div className="flex flex-col relative">
          <input
            value={inputEl2.current}
            maxLength={1}
            minLength={0}
            ref={refVal2}
            name="inputEl2"
            onChange={_handleChange}
            onKeyDown={_handleKeyDown}
            onFocus={_handleOnFocus}
            onBlur={_handleOnBlur}
            className={
              'w-full h-full p-4 rounded-2xl text-[28px] font-semibold text-center border-none text-input-trading ' +
              (inputEl2.current
                ? 'bg-transparent'
                : 'bg-skin-otp focus:bg-skin-green')
            }
          />
          {!inputEl2.current && (
            <span
              ref={refSpan2}
              className="absolute bottom-4 left-4 w-[30px] h-[2px] rounded-full bg-skin-white"
            />
          )}
        </div>
        <div className="flex flex-col relative">
          <input
            value={inputEl3.current}
            maxLength={1}
            minLength={0}
            ref={refVal3}
            name="inputEl3"
            onChange={_handleChange}
            onKeyDown={_handleKeyDown}
            onFocus={_handleOnFocus}
            onBlur={_handleOnBlur}
            className={
              'w-full h-full p-4 rounded-2xl text-[28px] font-semibold text-center border-none text-input-trading ' +
              (inputEl3.current
                ? 'bg-transparent'
                : 'bg-skin-otp focus:bg-skin-green')
            }
          />
          {!inputEl3.current && (
            <span
              ref={refSpan3}
              className="absolute bottom-4 left-4 w-[30px] h-[2px] rounded-full bg-skin-white"
            />
          )}
        </div>
        <div className="flex flex-col relative">
          <input
            value={inputEl4.current}
            maxLength={1}
            minLength={0}
            ref={refVal4}
            name="inputEl4"
            onChange={_handleChange}
            onKeyDown={_handleKeyDown}
            onFocus={_handleOnFocus}
            onBlur={_handleOnBlur}
            className={
              'w-full h-full p-4 rounded-2xl text-[28px] font-semibold text-center border-none text-input-trading ' +
              (inputEl4.current
                ? 'bg-transparent'
                : 'bg-skin-otp focus:bg-skin-green')
            }
          />
          {!inputEl4.current && (
            <span
              ref={refSpan4}
              className="absolute bottom-4 left-4 w-[30px] h-[2px] rounded-full bg-skin-white"
            />
          )}
        </div>
        <div className="flex flex-col relative">
          <input
            value={inputEl5.current}
            maxLength={1}
            minLength={0}
            ref={refVal5}
            name="inputEl5"
            onChange={_handleChange}
            onKeyDown={_handleKeyDown}
            onFocus={_handleOnFocus}
            onBlur={_handleOnBlur}
            className={
              'w-full h-full p-4 rounded-2xl text-[28px] font-semibold text-center border-none text-input-trading ' +
              (inputEl5.current
                ? 'bg-transparent'
                : 'bg-skin-otp focus:bg-skin-green')
            }
          />
          {!inputEl5.current && (
            <span
              ref={refSpan5}
              className="absolute bottom-4 left-4 w-[30px] h-[2px] rounded-full bg-skin-white"
            />
          )}
        </div>
        <div className="flex flex-col relative">
          <input
            value={inputEl6.current}
            maxLength={1}
            minLength={0}
            ref={refVal6}
            name="inputEl6"
            onChange={_handleChange}
            onKeyDown={_handleKeyDown}
            onFocus={_handleOnFocus}
            onBlur={_handleOnBlur}
            className={
              'w-full h-full p-4 rounded-2xl text-[28px] font-semibold text-center border-none text-input-trading ' +
              (inputEl6.current
                ? 'bg-transparent'
                : 'bg-skin-otp focus:bg-skin-green')
            }
          />
          {!inputEl6.current && (
            <span
              ref={refSpan6}
              className="absolute bottom-4 left-4 w-[30px] h-[2px] rounded-full bg-skin-white"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderOtp;
