import { call, put, takeLatest, all } from 'redux-saga/effects'
import { handleApiErrors } from 'lib/api-error'
import * as actions from './actionType'
import { mapIndexList } from 'utils/cfg'
import {
  analBasicInfoError,
  analBasicInfoSuccess,
  analComBasicError,
  analComBasicSuccess,
  analComBigTransError,
  analComBigTransSuccess,
  analComEventsError,
  analComEventsSuccess,
  analComHolderError,
  analComHolderSuccess,
  analComIntroductionError,
  analComIntroductionSuccess,
  analComLeaderError,
  analComLeaderSuccess,
  analComRelatedError,
  analComRelatedSuccess,
  analComSameIndustryError,
  analComSameIndustrySuccess,
  analDelFilterError,
  analDelFilterSuccess,
  analFilterActionError,
  analFilterActionSuccess,
  analFilterRangeError,
  analFilterRangeSuccess,
  analFSRatioError,
  analFSRatioSuccess,
  analIndexRealError,
  analIndexRealSuccess,
  analLichSuGiaError,
  analLichSuGiaSuccess,
  analNNIndayError,
  analNNIndaySuccess,
  analPBDongTienError,
  analPBDongTienSuccess,
  analRankFSRatioError,
  analRankFSRatioSuccess,
  analSecListAllError,
  analSecListAllSuccess,
  analSecOverviewError,
  analSecOverviewSuccess,
  analStockMatchPriceError,
  analStockMatchPriceSuccess,
  analStockMBActiveError,
  analStockMBActiveSuccess,
  analStockRealError,
  analStockRealSuccess,
  analStockTopMatchError,
  analStockTopMatchSuccess,
  analUserFilterError,
  analUserFilterSuccess,
  analUserSignalError,
  analUserSignalSuccess,
} from './actions'
import { processMapData } from 'utils/processMapData'

const appUrl = `${process.env.REACT_APP_ALGO_API_URL}`
const priceUrl = `${process.env.REACT_APP_PRICE_URL}`

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error
    })
}

function getIndexRealtime(data: any) {
  const request = fetch(
    appUrl + 'pbapi/api/stockBoard/index/' + mapIndexList[data]
  )
  return handleRequest(request)
}

function getNNInday(data: any) {
  const request = fetch(
    appUrl + 'pbapi/api/stockBoard/index/getFGDetails/' + mapIndexList[data]
  )
  return handleRequest(request)
}

function getBasicInfo(data: any) {
  const request = fetch(appUrl + 'stockInfo.eva?symbol=' + data)
  return handleRequest(request)
}

function getTopMatch(data: any) {
  const request = fetch(appUrl + 'getDealDetail/' + data + ',100')
  return handleRequest(request)
}

function getStockRealtime(data: any) {
  const url = `${priceUrl}/getliststockdata/${data.toUpperCase()}`
  const request = fetch(url)
  return handleRequest(request)
}

function getStockMatchPrice(data: any) {
  const request = fetch(
    appUrl + 'pbapi/api/stockBoard/stock/getDetailsActively/' + data
  )
  return handleRequest(request)
}

function getStockMBActive(data: any) {
  const request = fetch(appUrl + 'getPriceAllocation/' + data)
  return handleRequest(request)
}

function getPBDongTien(data: any) {
  let _param = ''
  switch (data) {
    case 'VNINDEX':
      _param = 'HOSE'
      break
    case 'HNX':
    case 'HNXINDEX':
      _param = 'HNC'
      break
    case 'UPCOMINDEX':
    case 'UPCOM':
      _param = 'UPCOM'
      break

    default:
      _param = data
      break
  }

  const request = fetch(
    appUrl + 'pbapi/api/stockBoard/index/calculateCashFlowAllocation/' + _param
  )
  return handleRequest(request)
}

function getLichSuGia(data: any) {
  const request = fetch(appUrl + 'pbapi/api/secTradingHistory', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getFSRatio(data: any) {
  const request = fetch(appUrl + 'pbapi/api/secFSRatios', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getRankFSRatio(data: any) {
  const request = fetch(appUrl + 'pbapi/api/secRankingFSRatios', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getSecOverview(data: any) {
  const request = fetch(appUrl + 'pbapi/api/secOverview', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getComIntroduction(data: any) {
  const request = fetch(appUrl + 'companyInfo.eva?symbol=' + data)
  return handleRequest(request)
}

function getComSameInd(data: any) {
  const request = fetch(appUrl + 'pbapi/api/companies/sameIndustry', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getComLeader(data: any) {
  const request = fetch(appUrl + 'stockMangement.eva?symbol=' + data)
  return handleRequest(request)
}

function getComHolder(data: any) {
  const request = fetch(appUrl + 'pbapi/api/companies/shareholders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getComEvents(data: any) {
  const request = fetch(
    appUrl +
      'pbapi/api/news/sec_news?startDate=' +
      data.startDate +
      '&reqLanguage=' +
      data.lang.toUpperCase() +
      '&secCode=' +
      data.secCode
  )
  return handleRequest(request)
}

function getComBigTrans(data: any) {
  const request = fetch(appUrl + 'pbapi/api/secBigTrans', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getComRelated(data: any) {
  const request = fetch(appUrl + 'pbapi/api/companies/relatedCompanies?' + data)
  return handleRequest(request)
}

function getUserFilter(data: any) {
  const request = fetch(appUrl + 'pbapi/api/getFilters', {
    method: 'GET',
    headers: {
      'X-USERNAME': data,
    },
  })
  return handleRequest(request)
}

function getUserSignal(data: any) {
  const request = fetch(appUrl + 'pbapi/api/usersignals/' + data, {
    method: 'GET',
    headers: {
      'X-USERNAME': data,
    },
  })
  return handleRequest(request)
}

function getFilterAct(data: any) {
  const _user = data['user']
  delete data['user']

  const request = fetch(appUrl + 'pbapi/api/filter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-USERNAME': _user,
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function delFilterApi(data: any) {
  const _user = data['user']
  delete data['user']

  const request = fetch(appUrl + 'pbapi/api/deleteFilter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-USERNAME': _user,
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getComBasic(data: any) {
  const request = fetch(appUrl + 'pbapi/api/companies/basic', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function getFilterRange() {
  const request = fetch(appUrl + 'pbapi/api/getFilterRange')
  return handleRequest(request)
}

function analSecListAllRequestApi(data: any) {
  const request = fetch(appUrl + 'pbapi/api/secListAll', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  })
  return handleRequest(request)
}

function* analStockRealRequestFlow(
  action: actions.AnalStockRealRequestAction
): any {
  const { params } = action
  try {
    const resData = yield call(getStockRealtime, params)
    // if (resData.status != 200) {
    //   yield put({
    //     type: 'REQUEST_PRIVATE_FALSE',
    //     msg: resData.message,
    //   })
    //   throw Error(resData.message)
    // }

    yield put(
      analStockRealSuccess(
        resData && !!resData.length
          ? processMapData._processMapDataCS(resData[0])
          : null
      )
    )
  } catch (error) {
    // log(error)
    yield put(analStockRealError(error))
  }
}

function* analIndexRealRequestFlow(
  action: actions.AnalIndexRealRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getIndexRealtime, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analIndexRealSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analIndexRealError(error))
  }
}

function* analStockInfoRequestFlow(
  action: actions.AnalBasicInfoRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getBasicInfo, params)
    // if (resData.status != 200) {
    //   yield put({
    //     type: 'REQUEST_PRIVATE_FALSE',
    //     msg: resData.message,
    //   })
    //   throw Error(resData.message)
    // }

    yield put(analBasicInfoSuccess(resData))
  } catch (error) {
    // log(error)
    yield put(analBasicInfoError(error))
  }
}

function* analStockTopMatchRequestFlow(
  action: actions.AnalStockTopMatchRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getTopMatch, params)
    // if (resData.status != 200) {
    //   yield put({
    //     type: 'REQUEST_PRIVATE_FALSE',
    //     msg: resData.message,
    //   })
    //   throw Error(resData.message)
    // }

    yield put(analStockTopMatchSuccess(resData))
  } catch (error) {
    // log(error)
    yield put(analStockTopMatchError(error))
  }
}

function* analStockMatchPriceRequestFlow(
  action: actions.AnalStockMatchPriceRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getStockMatchPrice, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analStockMatchPriceSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analStockMatchPriceError(error))
  }
}

function* analStockMBActiveRequestFlow(
  action: actions.AnalStockMBActiveRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getStockMBActive, params)
    // if (resData.status != 200) {
    //   yield put({
    //     type: 'REQUEST_PRIVATE_FALSE',
    //     msg: resData.message,
    //   })
    //   throw Error(resData.message)
    // }

    yield put(analStockMBActiveSuccess(resData))
  } catch (error) {
    // log(error)
    yield put(analStockMBActiveError(error))
  }
}

function* analPBDongTienRequestFlow(
  action: actions.AnalPBDongTienRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getPBDongTien, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analPBDongTienSuccess(resData.data[0]))
  } catch (error) {
    // log(error)
    yield put(analPBDongTienError(error))
  }
}

function* analNNIndayRequestFlow(
  action: actions.AnalNNIndayRequesttAction
): any {
  try {
    const { params } = action
    const resData = yield call(getNNInday, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analNNIndaySuccess(resData.data[0]))
  } catch (error) {
    // log(error)
    yield put(analNNIndayError(error))
  }
}

function* analLichSuGiaRequestFlow(
  action: actions.AnalLichSuGiaRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getLichSuGia, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analLichSuGiaSuccess(JSON.parse(resData.data)))
  } catch (error) {
    // log(error)
    yield put(analLichSuGiaError(error))
  }
}

function* analFSRatioRequestFlow(
  action: actions.AnalFSRatioRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getFSRatio, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analFSRatioSuccess(JSON.parse(resData.data)))
  } catch (error) {
    // log(error)
    yield put(analFSRatioError(error))
  }
}

function* analRankFSRatioRequestFlow(
  action: actions.AnalRankFSRatioRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getRankFSRatio, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analRankFSRatioSuccess(JSON.parse(resData.data)))
  } catch (error) {
    // log(error)
    yield put(analRankFSRatioError(error))
  }
}

function* analSecOverviewRequestFlow(
  action: actions.AnalSecOverviewRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getSecOverview, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analSecOverviewSuccess(JSON.parse(resData.data)))
  } catch (error) {
    // log(error)
    yield put(analSecOverviewError(error))
  }
}

function* analComIntroductionRequestFlow(
  action: actions.AnalComIntroductionRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComIntroduction, params)
    // if (resData.status != 200) {
    //   yield put({
    //     type: 'REQUEST_PRIVATE_FALSE',
    //     msg: resData.message,
    //   })
    //   throw Error(resData.message)
    // }

    yield put(analComIntroductionSuccess(resData))
  } catch (error) {
    // log(error)
    yield put(analComIntroductionError(error))
  }
}

function* analComBasicRequestFlow(
  action: actions.AnalComBasicRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComBasic, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analComBasicSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analComBasicError(error))
  }
}

function* analComSameIndRequestFlow(
  action: actions.AnalComSameIndustryRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComSameInd, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analComSameIndustrySuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analComSameIndustryError(error))
  }
}

function* analComLeaderRequestFlow(
  action: actions.AnalComLeaderRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComLeader, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analComLeaderSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analComLeaderError(error))
  }
}

function* analComHolderRequestFlow(
  action: actions.AnalComHolderRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComHolder, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analComHolderSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analComHolderError(error))
  }
}

function* analComEventsRequestFlow(
  action: actions.AnalComEventsRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComEvents, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analComEventsSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analComEventsError(error))
  }
}

function* analComBigTransRequestFlow(
  action: actions.AnalComBigTransRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComBigTrans, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analComBigTransSuccess(JSON.parse(resData.data)))
  } catch (error) {
    // log(error)
    yield put(analComBigTransError(error))
  }
}

function* analComRelatedRequestFlow(
  action: actions.AnalComRelatedRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getComRelated, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analComRelatedSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analComRelatedError(error))
  }
}

function* analFilerRangeRequestFlow(): any {
  try {
    const resData = yield call(getFilterRange)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analFilterRangeSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analFilterRangeError(error))
  }
}

function* analFilerActRequestFlow(
  action: actions.AnalFilterActionRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getFilterAct, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message || resData?.dict?.errorMessage || 'Error',
      })
      throw Error(resData.message || resData?.dict?.errorMessage || 'Error')
    }

    yield put(analFilterActionSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(analFilterActionError(error))
  }
}

function* analUserFilerRequestFlow(
  action: actions.AnalUserFilterRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getUserFilter, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analUserFilterSuccess(resData.data))
  } catch (error) {
    yield put(analUserFilterError(error))
  }
}

function* analDelFilerRequestFlow(
  action: actions.AnalDelFilterRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(delFilterApi, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analDelFilterSuccess(Math.random()))
  } catch (error) {
    yield put(analDelFilterError(error))
  }
}

function* analUserSignalRequestFlow(
  action: actions.AnalUserSignalRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(getUserSignal, params)
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      })
      throw Error(resData.message)
    }

    yield put(analUserSignalSuccess(resData.data))
  } catch (error) {
    yield put(analUserSignalError(error))
  }
}

function* analSecListAllRequestFlow(
  action: actions.AnalSecListAllRequestAction
): any {
  try {
    const { params } = action
    const resData = yield call(analSecListAllRequestApi, params)
    console.log(resData)
    yield put(analSecListAllSuccess(JSON.parse(resData.data)?.dict))
  } catch (error) {
    yield put(analSecListAllError(error))
  }
}

function* analWatcher() {
  yield all([
    takeLatest(actions.ANAL_STOCK_REAL_REQUESTING, analStockRealRequestFlow),
    takeLatest(actions.ANAL_SEC_LIST_ALL_REQUESTING, analSecListAllRequestFlow),
    takeLatest(actions.ANAL_INDEX_REAL_REQUESTING, analIndexRealRequestFlow),
    takeLatest(actions.ANAL_NN_INDAY_REQUESTING, analNNIndayRequestFlow),
    takeLatest(actions.ANAL_PB_DONG_TIEN_REQUESTING, analPBDongTienRequestFlow),
    takeLatest(actions.ANAL_LICH_SU_GIA_REQUESTING, analLichSuGiaRequestFlow),
    takeLatest(actions.ANAL_FS_RATIO_REQUESTING, analFSRatioRequestFlow),
    takeLatest(
      actions.ANAL_RANK_FS_RATIO_REQUESTING,
      analRankFSRatioRequestFlow
    ),

    takeLatest(actions.ANAL_BASIC_INFO_REQUESTING, analStockInfoRequestFlow),
    takeLatest(
      actions.ANAL_STOCK_TOP_MATCH_REQUESTING,
      analStockTopMatchRequestFlow
    ),
    takeLatest(
      actions.ANAL_STOCK_MATCH_PRICE_REQUESTING,
      analStockMatchPriceRequestFlow
    ),
    takeLatest(
      actions.ANAL_STOCK_MB_ACTIVE_REQUESTING,
      analStockMBActiveRequestFlow
    ),

    takeLatest(
      actions.ANAL_SEC_OVERVIEW_REQUESTING,
      analSecOverviewRequestFlow
    ),
    takeLatest(
      actions.ANAL_COM_INTRO_REQUESTING,
      analComIntroductionRequestFlow
    ),
    takeLatest(actions.ANAL_COM_BASIC_REQUESTING, analComBasicRequestFlow),
    takeLatest(actions.ANAL_COM_SAME_IND_REQUESTING, analComSameIndRequestFlow),
    takeLatest(actions.ANAL_COM_LEADER_REQUESTING, analComLeaderRequestFlow),
    takeLatest(actions.ANAL_COM_HOLDER_REQUESTING, analComHolderRequestFlow),
    takeLatest(actions.ANAL_COM_EVENTS_REQUESTING, analComEventsRequestFlow),
    takeLatest(
      actions.ANAL_COM_BIGTRANS_REQUESTING,
      analComBigTransRequestFlow
    ),
    takeLatest(actions.ANAL_COM_RELATED_REQUESTING, analComRelatedRequestFlow),

    /** filter */
    takeLatest(actions.ANAL_FILTER_RANGE_REQUESTING, analFilerRangeRequestFlow),
    takeLatest(actions.ANAL_FILTER_ACT_REQUESTING, analFilerActRequestFlow),
    takeLatest(actions.ANAL_USER_FILTER_REQUESTING, analUserFilerRequestFlow),
    takeLatest(actions.ANAL_DEL_FILTER_REQUESTING, analDelFilerRequestFlow),

    /** signal */
    takeLatest(actions.ANAL_USER_SIGNAL_REQUESTING, analUserSignalRequestFlow),
  ])
}

export default analWatcher
