/* eslint-disable no-global-assign */
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';

import 'index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-virtualized/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rc-slider/assets/index.css';

import './assets/css/rcStyle.css';
import './assets/css/styles.css';
import './assets/css/nav.css';
import './assets/css/reactmodal.css';

import App from './App';
import rootReducer from './reducers';
import rootSagas from './sagas';
import './i18n/config';

import { WindowContextProvider } from 'shared/windowActive';
import WebSocketProvider from 'containers/socket/webSocket';
import AlgoSignalProvider from './containers/socket/signalAlgo';

const sagaMiddleware = createSagaMiddleware();
const middlewareProd = [sagaMiddleware];

/* prod */
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      middlewareProd,
    ) as any,
});

sagaMiddleware.run(rootSagas);

/* disable console log when production build */
let consoleHolder: Console = console;
function debug(bool: boolean) {
  if (!bool) {
    consoleHolder = console;
    console = {} as any;
    Object.keys(consoleHolder).forEach(function (key) {
      console[key as keyof Console] = function () { } as any;
    });
  } else {
    console = consoleHolder;
  }
}

debug(process.env.NODE_ENV !== 'production');

const container = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <WindowContextProvider>
      <WebSocketProvider store={store}>
        <AlgoSignalProvider>
          <App />
        </AlgoSignalProvider>
      </WebSocketProvider>
    </WindowContextProvider>
  </Provider>,
  container,
);
