import * as actions from './actionType';

export interface TradingState {
  tdNewOrder: any;
  tdCancelOrder: any;
  tdOrderList: any;
  symbolInday: any;
  orderHis: any;
  loanShare: any;

  oddShare: any;
  oddShareInday: any;
  oddShareHis: any;
  updOddShare: any;
}

const initialState = {
  tdNewOrder: null,
  tdCancelOrder: null,
  tdOrderList: null,
  symbolInday: null,
  orderHis: [],
  loanShare: null,

  oddShare: null,
  oddShareInday: null,
  oddShareHis: null,
  updOddShare: null,
};

export default function tradingReducer(
  state: TradingState = initialState,
  action: actions.TradingAction,
): TradingState {
  switch (action.type) {
    case actions.TD_NEW_ORDER_REQUESTING:
    case actions.TD_NEW_ORDER_ERROR:
      return {
        ...state,
        tdNewOrder: null,
      };
    case actions.TD_NEW_ORDER_SUCCESS:
      return {
        ...state,
        tdNewOrder: action.resData,
      };

    case actions.TD_CANCEL_ORDER_REQUESTING:
    case actions.TD_CANCEL_ORDER_ERROR:
      return {
        ...state,
        tdCancelOrder: null,
      };
    case actions.TD_CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        tdCancelOrder: action.resData,
      };

    case actions.TD_ORDER_LIST_ERROR:
      return {
        ...state,
        tdOrderList: null,
      };
    case actions.TD_ORDER_LIST_SUCCESS:
      return {
        ...state,
        tdOrderList: action.resData,
      };
    case actions.SYMBOL_INDAY_REQUESTING:
    case actions.SYMBOL_INDAY_REQUEST_ERROR:
      return {
        ...state,
        symbolInday: null,
      };
    case actions.SYMBOL_INDAY_REQUEST_SUCCESS:
      return {
        ...state,
        symbolInday: action.symbolInday,
      };

    case actions.ORDER_HISTORY_REQUESTING:
      return {
        ...state,
        orderHis: null,
      };
    case actions.ORDER_HISTORY_REQUEST_ERROR:
      return {
        ...state,
        orderHis: [],
      };
    case actions.ORDER_HISTORY_REQUEST_SUCCESS:
      return {
        ...state,
        orderHis: action.orderHis,
      };

    case actions.LOAN_SHARE_REQUESTING:
    case actions.LOAN_SHARE_REQUEST_ERROR:
      return {
        ...state,
        loanShare: null,
      };
    case actions.LOAN_SHARE_REQUEST_SUCCESS:
      return {
        ...state,
        loanShare: action.resData,
      };

    case actions.ODD_SHARE_REQUESTING:
    case actions.ODD_SHARE_REQUEST_ERROR:
      return {
        ...state,
        oddShare: null,
      };
    case actions.ODD_SHARE_REQUEST_SUCCESS:
      return {
        ...state,
        oddShare: action.resData,
      };

    case actions.ODD_SHARE_INDAY_REQUESTING:
    case actions.ODD_SHARE_INDAY_REQUEST_ERROR:
      return {
        ...state,
        oddShareInday: null,
      };
    case actions.ODD_SHARE_INDAY_REQUEST_SUCCESS:
      return {
        ...state,
        oddShareInday: action.resData,
      };

    case actions.ODD_SHARE_HIS_REQUESTING:
    case actions.ODD_SHARE_HIS_REQUEST_ERROR:
      return {
        ...state,
        oddShareHis: null,
      };
    case actions.ODD_SHARE_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        oddShareHis: action.resData,
      };

    case actions.UPD_ODD_SHARE_REQUESTING:
    case actions.UPD_ODD_SHARE_REQUEST_ERROR:
      return {
        ...state,
        updOddShare: null,
      };
    case actions.UPD_ODD_SHARE_REQUEST_SUCCESS:
      return {
        ...state,
        updOddShare: action.resData,
      };

    default:
      return state;
  }
}
