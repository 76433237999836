import {
  numberFormat,
  formatVolume10,
  StringToInt,
  colorFix,
  StringToDouble,
} from 'utils';

let arrUpdate: any = [];

export function _testProcessG(
  sym: string,
  data: string,
  idPrice: string,
  idVol: string,
) {
  if (!sym) return;

  const _type = sym.startsWith('VN30F') ? 'D' : 'A';

  let _rootPrice = document.getElementById(sym + idPrice);
  let _rootVol = document.getElementById(sym + idVol);
  let _rootRef = document.getElementById(sym + 'ref');

  if (!_rootPrice || !_rootVol) return;

  const _g = data.split('|');

  const _oldVol = StringToInt(_rootVol?.children[0].innerHTML);
  const _decimal = StringToInt(
    StringToDouble(_rootRef?.children[0].innerHTML) > 50 ? 1 : 2,
  );

  const price = _g[0];
  const vol = StringToInt(_g[1]);
  const clp = _g[0] === 'ATO' || _g[0] === 'ATC' ? '' : _g[2];
  const clv = _oldVol < vol ? 'i' : _oldVol > vol ? 'd' : clp;

  const _oldPrice = _rootPrice.children[0].innerHTML;
  const _newPrice = numberFormat(price, _type === 'D' ? 1 : _decimal);

  if (_oldPrice !== _newPrice) {
    if (!arrUpdate[sym + idPrice]) {
      _rootPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      if (clp) _rootPrice.classList.add(clp, 'active');
      arrUpdate[sym + idPrice] = true;
    }
    _rootPrice.children[0].innerHTML = _newPrice;
  }

  if (_oldVol !== vol) {
    const _newVol = _type === 'D' ? numberFormat(vol) : formatVolume10(vol);
    if (!arrUpdate[sym + idVol]) {
      _rootVol?.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      if (clv) _rootVol?.classList.add(clv, 'active');
      arrUpdate[sym + idVol] = true;
    }
    _rootVol.children[0].innerHTML = _newVol;
  }

  if (arrUpdate[sym + idPrice]) {
    setTimeout(() => {
      arrUpdate[sym + idPrice] = false;
      if (clp) {
        _rootPrice?.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
        _rootPrice?.classList.add(clp);
      }
    }, 500);
  }
  if (arrUpdate[sym + idVol]) {
    setTimeout(() => {
      arrUpdate[sym + idVol] = false;
      if (clv) {
        _rootVol?.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active', clv);
      }
      if (clp) _rootVol?.classList.add(clp);
    }, 500);
  }
}

export function _testProcessGLoLe(
  sym: string,
  data: string,
  idPrice: string,
  idVol: string,
) {
  if (!sym) return;

  const _type = sym.startsWith('VN30F') ? 'D' : 'A';

  let _rootPrice = document.getElementById(sym + idPrice);
  let _rootVol = document.getElementById(sym + idVol);

  if (!_rootPrice || !_rootVol) return;

  const _g = data.split('|');

  const _oldVol = StringToInt(_rootVol?.children[0].innerHTML);

  // console.log(_rootVol.children[0])

  const price = _g[0];
  const vol = StringToInt(_g[1]);
  const clp = _g[0] === 'ATO' || _g[0] === 'ATC' ? '' : _g[2];
  const clv = _oldVol < vol ? 'i' : _oldVol > vol ? 'd' : clp;

  const _oldPrice = _rootPrice.children[0].innerHTML;
  const _newPrice = numberFormat(price, _type === 'D' ? 1 : 2);

  if (_oldPrice !== _newPrice) {
    if (!arrUpdate[sym + idPrice])
      _rootPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootPrice.children[0].innerHTML = _newPrice;

    if (clp && !arrUpdate[sym + idPrice]) {
      arrUpdate[sym + idPrice] = true;
      _rootPrice.classList.add(clp, 'active');
    }
  }

  if (_oldVol !== vol) {
    const _newVol = _type === 'D' ? numberFormat(vol) : formatVolume10(vol);
    if (!arrUpdate[sym + idVol])
      _rootVol?.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootVol.children[0].innerHTML = _newVol;

    if (clv && !arrUpdate[sym + idVol]) {
      arrUpdate[sym + idVol] = true;
      _rootVol?.classList.add(clv, 'active');
    }
  }

  if (arrUpdate[sym + idPrice]) {
    setTimeout(() => {
      arrUpdate[sym + idPrice] = false;
      if (clp) _rootPrice?.classList.remove('active');
    }, 500);
  }
  if (arrUpdate[sym + idVol]) {
    setTimeout(() => {
      arrUpdate[sym + idVol] = false;
      if (clv) {
        _rootVol?.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
        if (clp) _rootVol?.classList.add(clp);
      }
    }, 500);
  }
}

export function _testProcess3210(data: {
  sym: string;
  g1: string;
  g2: string;
  g3: string;
  g4: string;
  g5: string;
  g6: string;
}) {
  _testProcessG(data.sym, data.g1, 'bP1', 'bV1');
  _testProcessG(data.sym, data.g2, 'bP2', 'bV2');
  _testProcessG(data.sym, data.g3, 'bP3', 'bV3');
  _testProcessG(data.sym, data.g4, 'oP1', 'oV1');
  _testProcessG(data.sym, data.g5, 'oP2', 'oV2');
  _testProcessG(data.sym, data.g6, 'oP3', 'oV3');
}

export function _process3210(data: {
  side: string;
  sym: string;
  g1: string;
  g2: string;
  g3: string;
  vol4: any;
}) {
  if (data.side === 'B') {
    _testProcessG(data.sym, data.g1, 'bP1', 'bV1');
    _testProcessG(data.sym, data.g2, 'bP2', 'bV2');
    _testProcessG(data.sym, data.g3, 'bP3', 'bV3');

    _processDuMuaBan(data.sym, data.vol4, 'duMua');
  } else {
    _testProcessG(data.sym, data.g1, 'oP1', 'oV1');
    _testProcessG(data.sym, data.g2, 'oP2', 'oV2');
    _testProcessG(data.sym, data.g3, 'oP3', 'oV3');

    _processDuMuaBan(data.sym, data.vol4, 'duBan');
  }
}

export function _process6210(data: {
  side: string;
  sym: any;
  g1: any;
  g2: any;
  g3: any;
}) {
  if (data.side === 'B') {
    _testProcessGLoLe(data.sym, data.g1, 'bP1', 'bV1');
    _testProcessGLoLe(data.sym, data.g2, 'bP2', 'bV2');
    _testProcessGLoLe(data.sym, data.g3, 'bP3', 'bV3');
  } else {
    _testProcessGLoLe(data.sym, data.g1, 'oP1', 'oV1');
    _testProcessGLoLe(data.sym, data.g2, 'oP2', 'oV2');
    _testProcessGLoLe(data.sym, data.g3, 'oP3', 'oV3');
  }
}

export function _process1101(data: { mc: string }) {
  let _rootIndex = document.getElementById('index_' + data.mc);
  let _rootIndexChange = document.getElementById('indexChange_' + data.mc);
  let _rootIndexLot = document.getElementById('indexLot_' + data.mc);
  let _rootIndexVal = document.getElementById('indexVal_' + data.mc);

  if (_rootIndex) _rootIndex.classList.add('active');
  if (_rootIndexChange) _rootIndexChange.classList.add('active');
  if (_rootIndexLot) _rootIndexLot.classList.add('active');
  if (_rootIndexVal) _rootIndexVal.classList.add('active');

  setTimeout(() => {
    if (_rootIndex) _rootIndex.classList.remove('active');
    if (_rootIndexChange) _rootIndexChange.classList.remove('active');
    if (_rootIndexLot) _rootIndexLot.classList.remove('active');
    if (_rootIndexVal) _rootIndexVal.classList.remove('active');
  }, 500);
}

export function _process3250(data: {
  symbol: string;
  fBVol: string | number;
  fSVolume: string | number;
  fRoom: string | number;
}) {
  const _type = data.symbol.startsWith('VN30F') ? 'D' : 'A';
  let _rootFBVol = document.getElementById(data.symbol + 'fBVol');
  let _rootFSVol = document.getElementById(data.symbol + 'fSVol');
  let _rootFRoom = document.getElementById(data.symbol + 'fRoom');

  if (!_rootFBVol || !_rootFSVol) return;
  // log(data)
  _rootFBVol.children[0].innerHTML =
    _type === 'D' ? numberFormat(data.fBVol) : formatVolume10(data.fBVol);
  _rootFSVol.children[0].innerHTML =
    _type === 'D' ? numberFormat(data.fSVolume) : formatVolume10(data.fSVolume);
  if (_rootFRoom)
    _rootFRoom.children[0].innerHTML =
      _type === 'D' ? numberFormat(data.fRoom) : formatVolume10(data.fRoom);

  _rootFBVol.classList.add('active');
  _rootFSVol.classList.add('active');
  _rootFRoom?.classList.add('active');

  setTimeout(() => {
    if (_rootFBVol) _rootFBVol.classList.remove('active');
    if (_rootFSVol) _rootFSVol.classList.remove('active');
    if (_rootFRoom) _rootFRoom.classList.remove('active');
  }, 500);
}

export function _process3310(data: {
  sym: string;
  Total: number;
  BVolume: number;
  SVolume: number;
}) {
  let _rootDuMua = document.getElementById(data.sym + 'duMua');
  let _rootDuBan = document.getElementById(data.sym + 'duBan');
  let _rootlot = document.getElementById(data.sym + 'lot');

  if (_rootlot) {
    const _oldVol = StringToInt(_rootlot.children[0].innerHTML);
    if (_oldVol !== data.Total) {
      _rootlot.children[0].innerHTML = formatVolume10(data.Total);
      _rootlot.classList.add('active');
    }
  }
  if (_rootDuMua) {
    const _oldVol = StringToInt(_rootDuMua.children[0].innerHTML);
    if (_oldVol !== data.BVolume) {
      _rootDuMua.children[0].innerHTML = formatVolume10(data.BVolume);
      _rootDuMua.classList.add('active');
    }
  }
  if (_rootDuBan) {
    const _oldVol = StringToInt(_rootDuBan.children[0].innerHTML);
    if (_oldVol !== data.SVolume) {
      _rootDuBan.children[0].innerHTML = formatVolume10(data.SVolume);
      _rootDuBan.classList.add('active');
    }
  }

  setTimeout(() => {
    if (_rootDuMua) _rootDuMua.classList.remove('active');
    if (_rootDuBan) _rootDuBan.classList.remove('active');
    if (_rootlot) _rootlot.classList.remove('active');
  }, 500);
}

export function _processDuMuaBan(sym: string, data: number, idVol: string) {
  const _type = sym.startsWith('VN30F') ? 'D' : 'A';
  let _rootDuMuaBan = document.getElementById(sym + idVol);

  if (!_rootDuMuaBan || data < 1) return;

  _rootDuMuaBan.children[0].innerHTML =
    _type === 'D' ? numberFormat(data) : formatVolume10(data);

  _rootDuMuaBan.classList.add('active');

  setTimeout(() => {
    if (_rootDuMuaBan) _rootDuMuaBan.classList.remove('active');
  }, 500);
}

export function _process3220(data: {
  sym: string;
  lastPrice: number;
  lastVol: string | number;
  cl: any;
  hp: string | number;
  ch: string;
  lp: string | number;
  lc: string;
  ap: string | number;
  ca: string;
  totalVol: number;
  change: string | number;
  changePc: string | number;
}) {
  const _type = data.sym.startsWith('VN30F') ? 'D' : 'A';

  let _rootLastPrice = document.getElementById(data.sym + 'lastPrice');
  let _rootLastVol = document.getElementById(data.sym + 'lastVolume');
  let _rootChange = document.getElementById(data.sym + 'change');
  let _rootChangePc = document.getElementById(data.sym + 'changePc');
  let _rootLot = document.getElementById(data.sym + 'lot');
  let _rootHighP = document.getElementById(data.sym + 'highP');
  let _rootLowP = document.getElementById(data.sym + 'lowP');
  let _rootAveP = document.getElementById(data.sym + 'aveP');
  let _rootSym = document.getElementById(data.sym + 'sym');
  let _rootOther = document.getElementById(data.sym + 'other');
  let _rootRef = document.getElementById(data.sym + 'ref');
  let _rootVN30 = document.getElementById('index_11');

  if (!_rootSym || !_rootLastVol) return;

  const _decimal = StringToInt(
    StringToDouble(_rootRef?.children[0].innerHTML) > 50 ? 1 : 2,
  );

  const _oldPrice = _rootLastPrice?.children[0].innerHTML;
  const _newPrice = numberFormat(data.lastPrice, _type === 'D' ? 1 : _decimal);
  const _oldVol = _rootLastVol.children[0].innerHTML;
  const _newVol =
    _type === 'D' ? numberFormat(data.lastVol) : formatVolume10(data.lastVol);

  const clp = data.cl;

  if (_rootHighP) {
    const _oldHP = _rootHighP.children[0].innerHTML;
    const _newHP = numberFormat(data.hp, _type === 'D' ? 1 : _decimal);
    if (_oldHP !== _newHP) {
      _rootHighP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      _rootHighP.classList.add(data.ch);
      _rootHighP.children[0].innerHTML = _newHP;
    }
  }

  if (_rootLowP) {
    const _oldLP = _rootLowP.children[0].innerHTML;
    const _newLP = numberFormat(data.lp, _type === 'D' ? 1 : _decimal);
    if (_oldLP !== _newLP) {
      _rootLowP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      _rootLowP.classList.add(data.lc);
      _rootLowP.children[0].innerHTML = _newLP;
    }
  }
  if (_rootAveP) {
    const _oldAP = _rootAveP.children[0].innerHTML;
    const _newAP = numberFormat(data.ap, _type === 'D' ? 1 : _decimal);
    if (_oldAP !== _newAP) {
      _rootAveP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      _rootAveP.classList.add(data.ca);
      _rootAveP.children[0].innerHTML = _newAP;
    }
  }

  if (_oldPrice !== _newPrice && _rootLastPrice) {
    if (!arrUpdate[data.sym + 'lastPrice']) {
      arrUpdate[data.sym + 'lastPrice'] = true;
      _rootLastPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      _rootLastPrice.classList.add(clp, 'active');
    }
    _rootLastPrice.children[0].innerHTML = _newPrice;

    if (_rootSym) _rootSym.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootSym) _rootSym.classList.add(clp);

    if (_oldVol === _newVol && _rootLastVol) {
      _rootLastVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      _rootLastVol.classList.add(clp);
    }
  }

  if (_oldVol !== _newVol && _rootLastVol) {
    if (!arrUpdate[data.sym + 'lastVolume']) {
      arrUpdate[data.sym + 'lastVolume'] = true;
      _rootLastVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      _rootLastVol.classList.add(clp, 'active');
    }
    _rootLastVol.children[0].innerHTML = _newVol;
  }

  if (_rootLot && data.totalVol) {
    const _oldVol = StringToInt(_rootLot.children[0].innerHTML);
    if (_oldVol !== data.totalVol) {
      _rootLot.children[0].innerHTML =
        _type === 'D'
          ? numberFormat(data.totalVol)
          : formatVolume10(data.totalVol);
      if (!arrUpdate[data.sym + 'lot']) {
        arrUpdate[data.sym + 'lot'] = true;
        _rootLot.classList.add('active');
      }
    }
  }

  if (_rootChange) {
    const _oldChange = _rootChange.children[0].innerHTML;
    const _newChange = numberFormat(data.change, _type === 'D' ? 1 : 2);

    if (_oldChange !== _newChange) {
      if (!arrUpdate[data.sym + 'change']) {
        arrUpdate[data.sym + 'change'] = true;
        _rootChange.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
        _rootChange.classList.add(clp, 'active');
      }
      _rootChange.children[0].innerHTML = _newChange;
    }
  }
  if (_rootChangePc) {
    const _oldChangePc = _rootChangePc.children[0].innerHTML;
    const _newChangePc = numberFormat(data.changePc, _type === 'D' ? 1 : 2);
    if (_oldChangePc !== _newChangePc) {
      if (!arrUpdate[data.sym + 'changePc']) {
        arrUpdate[data.sym + 'changePc'] = true;
        _rootChangePc.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
        _rootChangePc.classList.add(clp, 'active');
      }

      _rootChangePc.children[0].innerHTML = _newChangePc + '%';
    }
  }

  // log(data.ch, data.lc, data.ca)

  if (_type === 'D') {
    // phái sinh
    if (_rootVN30) {
      const _valVN30 = StringToDouble(_rootVN30.innerHTML);
      if (_rootOther && _valVN30) {
        _rootOther.classList.remove('d', 'i', 'r');
        _rootOther.innerHTML = numberFormat(data.lastPrice - _valVN30, 2);
        _rootOther.classList.add(
          _valVN30 < data.lastPrice
            ? 'i'
            : _valVN30 > data.lastPrice
              ? 'd'
              : 'r',
        );
      }
    }
  }

  if (arrUpdate[data.sym + 'lot']) {
    setTimeout(() => {
      arrUpdate[data.sym + 'lot'] = false;
      _rootLot?.classList.remove('active');
    }, 500);
  }
  if (arrUpdate[data.sym + 'lastPrice']) {
    setTimeout(() => {
      arrUpdate[data.sym + 'lastPrice'] = false;
      if (clp) {
        _rootLastPrice?.classList.remove(
          'd',
          'e',
          'i',
          'r',
          'c',
          'f',
          'active',
        );
        _rootLastPrice?.classList.add(clp);
      }
    }, 500);
  }
  if (arrUpdate[data.sym + 'lastVolume']) {
    setTimeout(() => {
      arrUpdate[data.sym + 'lastVolume'] = false;
      if (clp) {
        _rootLastVol?.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
        _rootLastVol?.classList.add(clp);
      }
    }, 500);
  }
  if (arrUpdate[data.sym + 'change']) {
    setTimeout(() => {
      arrUpdate[data.sym + 'change'] = false;
      if (clp) {
        _rootChange?.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
        _rootChange?.classList.add(clp);
      }
    }, 500);
  }
  if (arrUpdate[data.sym + 'changePc']) {
    setTimeout(() => {
      arrUpdate[data.sym + 'changePc'] = false;
      if (clp) {
        _rootChangePc?.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
        _rootChangePc?.classList.add(clp);
      }
    }, 500);
  }
}

export function _process6220(data: {
  sym: string;
  lastPrice: string | number;
  lastVol: string | number;
  cl: string;
  change: string | number;
  changePc: string | number;
}) {
  let _rootLastPrice = document.getElementById(data.sym + 'lastPrice');
  let _rootLastVol = document.getElementById(data.sym + 'lastVolume');
  let _rootChange = document.getElementById(data.sym + 'change');
  let _rootChangePc = document.getElementById(data.sym + 'changePc');
  let _rootSym = document.getElementById(data.sym + 'sym');

  if (!_rootSym || !_rootLastVol) return;

  const _oldPrice = _rootLastPrice?.children[0].innerHTML;
  const _newPrice = numberFormat(data.lastPrice, 2);
  const _oldVol = _rootLastVol.children[0].innerHTML;
  const _newVol = numberFormat(data.lastVol);

  if (_rootSym) _rootSym.classList.remove('d', 'e', 'i', 'r', 'c', 'f');

  if (_oldPrice !== _newPrice && _rootLastPrice) {
    _rootLastPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootLastPrice.children[0].innerHTML = _newPrice;

    _rootLastPrice.classList.add(data.cl, 'active');
  }
  if (_oldVol !== _newVol && _rootLastVol) {
    _rootLastVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootLastVol.children[0].innerHTML = _newVol;

    _rootLastVol.classList.add(data.cl, 'active');
  }

  if (_rootChange) {
    _rootChange.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootChange.children[0].innerHTML = numberFormat(data.change, 2);
    _rootChange.classList.add(data.cl, 'active');
  }
  if (_rootChangePc) {
    _rootChangePc.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootChangePc.children[0].innerHTML = numberFormat(data.changePc, 2) + '%';
    _rootChangePc.classList.add(data.cl);
  }

  if (_rootSym) _rootSym.classList.add(data.cl, 'active');

  setTimeout(() => {
    if (_rootLastPrice) _rootLastPrice.classList.remove('active');
    if (_rootLastVol) _rootLastVol.classList.remove('active');
    if (_rootChange) _rootChange.classList.remove('active');
    if (_rootChangePc) _rootChangePc.classList.remove('active');
  }, 500);
}

export function _process9100(data: {
  sym: string;
  bp1: string;
  bv1: string;
  r: number;
  c: number;
  f: number;
  bp2: string;
  bv2: string;
  bp3: string;
  bv3: string;
  sp1: string;
  sv1: string;
  sp2: string;
  sv2: string;
  sp3: string;
  sv3: string;
  lastPrice: number;
  lastVol: any;
}) {
  _testProcessG(
    data.sym,
    data.bp1 +
      '|' +
      data.bv1 +
      '|' +
      colorFix(data.bp1, data.r, data.c, data.f, data.r),
    'bP1Odd',
    'bV1Odd',
  );
  _testProcessG(
    data.sym,
    data.bp2 +
      '|' +
      data.bv2 +
      '|' +
      colorFix(data.bp2, data.r, data.c, data.f, data.r),
    'bP2Odd',
    'bV2Odd',
  );
  _testProcessG(
    data.sym,
    data.bp3 +
      '|' +
      data.bv3 +
      '|' +
      colorFix(data.bp3, data.r, data.c, data.f, data.r),
    'bP3Odd',
    'bV3Odd',
  );
  _testProcessG(
    data.sym,
    data.sp1 +
      '|' +
      data.sv1 +
      '|' +
      colorFix(data.sp1, data.r, data.c, data.f, data.r),
    'oP1Odd',
    'oV1Odd',
  );
  _testProcessG(
    data.sym,
    data.sp2 +
      '|' +
      data.sv2 +
      '|' +
      colorFix(data.sp2, data.r, data.c, data.f, data.r),
    'oP2Odd',
    'oV2Odd',
  );
  _testProcessG(
    data.sym,
    data.sp3 +
      '|' +
      data.sv3 +
      '|' +
      colorFix(data.sp3, data.r, data.c, data.f, data.r),
    'oP3Odd',
    'oV3Odd',
  );

  let _rootLastPrice = document.getElementById(data.sym + 'lastPriceOdd');
  let _rootLastVol = document.getElementById(data.sym + 'lastVolumeOdd');
  let _rootChange = document.getElementById(data.sym + 'changeOdd');

  if (!_rootLastPrice) return;
  const _oldPrice = _rootLastPrice.children[0].innerHTML;
  const _newPrice = numberFormat(data.lastPrice, 2);
  const _oldVol = _rootLastVol?.children[0].innerHTML;
  const _newVol = formatVolume10(data.lastVol);

  const _cl = colorFix(data.lastPrice, data.r, data.c, data.f, data.r);

  if (_oldPrice !== _newPrice && _rootLastPrice) {
    _rootLastPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootLastPrice.children[0].innerHTML = _newPrice;

    _rootLastPrice.classList.add(_cl, 'active');
  }
  if (_oldVol !== _newVol && _rootLastVol) {
    _rootLastVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootLastVol.children[0].innerHTML = _newVol;

    _rootLastVol.classList.add(_cl, 'active');
  }

  if (_rootChange) {
    _rootChange.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootChange.innerHTML =
      (_cl === 'd' ? '-' : '') + numberFormat(data.lastPrice - data.r, 2);
    _rootChange.classList.add(_cl);
  }

  setTimeout(() => {
    if (_rootLastPrice) _rootLastPrice.classList.remove('active');
    if (_rootLastVol) _rootLastVol.classList.remove('active');
  }, 500);
}
