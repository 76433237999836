import React, { createContext } from 'react';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { IAlgoContextType } from 'interface';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

const socketNativeUrl = `${process.env.REACT_APP_SOCKET_ALGO_URL}`;

const AlgoSignalContext = createContext<IAlgoContextType | null>(null);

export { AlgoSignalContext };

const AlgoSocketProvider: React.FC<any> = ({ children }) => {
  let socket: any,
    timer: any,
    renderQueue: any[] = [],
    mounted = false;
  let as;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!timer) timer = setInterval(processUpdateCell, 10 * 10);

    return () => {
      if (timer) clearInterval(timer);
      renderQueue = [];
    };
  }, []);

  const processRealtime = (eventData: any) => {
    const _res = eventData.slice(0, -1).slice(1);
    const _arrData = _res.split(',');
    const _data = _.takeRight(_arrData, _arrData.length - 2);
    if (eventData.includes('stock-chart:') && _data.length > 2) {
      // stock realtime
      const _stock = _arrData[1].split(':')[1];
      // console.warn(_data)
      dispatch({ type: 'ALGO_STOCK_CHART', resData: [...[_stock], ..._data] });
    } else if (!mounted) renderQueue.push(eventData);
    // });
  };

  const processUpdateCell = () => {
    mounted = true;

    const _processData = renderQueue.splice(0, 1);
    mounted = false;
    _processData.forEach((element) => {
      // bỏ dấu []
      // console.log(element)
      const _res = element.slice(0, -1).slice(1);
      const _arrData = _res.split(',');
      const _data = _.takeRight(_arrData, _arrData.length - 2);
      if (element.includes('index-realtime:')) {
        // index realtime
        dispatch({
          type: 'ALGO_INDEX_RECEIVE',
          resData: JSON.parse(_data.join(',')),
        });
      } else if (element.includes('stock-realtime:')) {
        // index realtime
        // console.log("stock-realtime");
        dispatch({
          type: 'ALGO_STOCK_RECEIVE',
          resData: JSON.parse(_data.join(',')),
        });
      } else if (
        element.includes('index-chart:') &&
        _data.length === 1 &&
        _arrData[2].split(';')?.length > 1
      ) {
        // index realtime
        const _index = _arrData[1].split(':')[1];
        console.error(_index, _data);
        dispatch({
          type: 'ALGO_INDEX_CHART',
          resData: [...[_index], ..._arrData[2].split(';')],
        });
      } else if (element.includes('stock-chart:') && _data.length > 2) {
        // stock realtime
        const _stock = _arrData[1].split(':')[1];
        // console.warn(_data)
        dispatch({
          type: 'ALGO_STOCK_CHART',
          resData: [...[_stock], ..._data],
        });
      } else if (element.includes('CACHE_REALTIME')) {
        // index realtime
        dispatch({
          type: 'ALGO_CACHE_RECEIVE',
          resData: JSON.parse(_data.join(',')),
        });
      }
    });
  };

  const sendMessage = (message: any) => {
    // console.log(message);
    if (!socket || socket.readyState !== 1) return;

    socket.send(JSON.stringify(message));
  };

  const init = () => {
    console.log('init algo');
    if (socket) {
      socket = null;
    }
    if (!socket) {
      console.log('init algo', socket);
      socket = new W3CWebSocket(socketNativeUrl);

      socket.onopen = function () {
        console.log('connected');
        dispatch({ type: 'ALGO_SIGNAL_READY', resData: true });
      };

      socket.onmessage = function (event: any) {
        // console.log(event.data)
        if (event.data && event.data.startsWith('[')) {
          // log(_res, JSON.parse(_res));
          // try {
          //   const _responseJson = JSON.parse(event.data);
          //   console.log(_responseJson)
          processRealtime(event.data);
          // if(!mounted) renderQueue.push(event.data)
          // dispatch({ type: 'ALGO_SIGNAL_RECEIVE', resData: _responseJson });
          // } catch (error) {
          //   // error
          //   console.log(error)
          // }
        }
      };

      socket.onclose = function () {
        console.log('disconnect');
        dispatch({ type: 'ALGO_SIGNAL_READY', resData: false });
      };
    }
  };

  as = {
    socket: socket,
    sendMessage,
    init,
  };

  return (
    <AlgoSignalContext.Provider value={as}>
      {children}
    </AlgoSignalContext.Provider>
  );
};

export default AlgoSocketProvider;
