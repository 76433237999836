export const ALL_STOCK_REQUESTING = 'priB/STOCK_REQUESTING';
export interface AllStockRequestAction {
  type: typeof ALL_STOCK_REQUESTING;
}

export const ALL_STOCK_REQUEST_SUCCESS = 'priB/STOCK_SUCCESS';
export interface AllStockRequestSuccessAction {
  type: typeof ALL_STOCK_REQUEST_SUCCESS;
  allStock: any;
}

export const ALL_STOCK_REQUEST_ERROR = 'priB/STOCK_ERROR';
export interface AllStockRequestErrorAction {
  type: typeof ALL_STOCK_REQUEST_ERROR;
  error: any;
}

export const ALL_STOCK_RIGHT_REQUESTING = 'priB/STOCK_RIGHT_REQUESTING';
export interface AllStockRightRequestAction {
  type: typeof ALL_STOCK_RIGHT_REQUESTING;
}

export const ALL_STOCK_RIGHT_REQUEST_SUCCESS = 'priB/STOCK_RIGHT_SUCCESS';
export interface AllStockRightRequestSuccessAction {
  type: typeof ALL_STOCK_RIGHT_REQUEST_SUCCESS;
  allStockRight: any;
}

export const ALL_STOCK_RIGHT_REQUEST_ERROR = 'priB/STOCK_RIGHT_ERROR';
export interface AllStockRightRequestErrorAction {
  type: typeof ALL_STOCK_RIGHT_REQUEST_ERROR;
  error: any;
}

export const MARGIN_LIST_REQUESTING = 'priB/MARGIN_REQUESTING';
export interface MarginListRequestAction {
  type: typeof MARGIN_LIST_REQUESTING;
}

export const MARGIN_LIST_REQUEST_SUCCESS = 'priB/MARGIN_SUCCESS';
export interface MarginListRequestSuccessAction {
  type: typeof MARGIN_LIST_REQUEST_SUCCESS;
  marginList: any;
}

export const MARGIN_LIST_REQUEST_ERROR = 'priB/MARGIN_ERROR';
export interface MarginListRequestErrorAction {
  type: typeof MARGIN_LIST_REQUEST_ERROR;
  error: any;
}

export const LIST_MESSAGE_REQUESTING = 'priB/MESSAGE_REQUESTING';
export interface ListMessageRequestAction {
  type: typeof LIST_MESSAGE_REQUESTING;
}

export const LIST_MESSAGE_REQUEST_SUCCESS = 'priB/MESSAGE_SUCCESS';
export interface ListMessageRequestSuccessAction {
  type: typeof LIST_MESSAGE_REQUEST_SUCCESS;
  listMessage: any;
}

export const LIST_MESSAGE_REQUEST_ERROR = 'priB/MESSAGE_ERROR';
export interface ListMessageRequestErrorAction {
  type: typeof LIST_MESSAGE_REQUEST_ERROR;
  error: any;
}

export const SNAPSHOT_REQUESTING = 'priB/SNAPSHOT_REQUESTING';
export interface SnapshotRequestAction {
  type: typeof SNAPSHOT_REQUESTING;
  data: any;
}

export const SNAPSHOT_REQUEST_SUCCESS = 'priB/SNAPSHOT_SUCCESS';
export interface SnapshotRequestSuccessAction {
  type: typeof SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const SNAPSHOT_REQUEST_ERROR = 'priB/SNAPSHOT_ERROR';
export interface SnapshotRequestErrorAction {
  type: typeof SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const SNAPSHOT_UNDERLYING_REQUESTING =
  'priB/SNAPSHOT_UNDERLYING_REQUESTING';
export interface SnapshotUnderlyingCWRequestAction {
  type: typeof SNAPSHOT_UNDERLYING_REQUESTING;
  data: any;
}

export const SNAPSHOT_UNDERLYING_REQUEST_SUCCESS =
  'priB/SNAPSHOT_UNDERLYING_SUCCESS';
export interface SnapshotUnderlyingCWSuccessAction {
  type: typeof SNAPSHOT_UNDERLYING_REQUEST_SUCCESS;
  dataList: any;
}

export const SNAPSHOT_UNDERLYING_REQUEST_ERROR =
  'priB/SNAPSHOT_UNDERLYING_ERROR';
export interface SnapshotUnderlyingCWErrorAction {
  type: typeof SNAPSHOT_UNDERLYING_REQUEST_ERROR;
  error: any;
}

export const PS_SNAPSHOT_REQUESTING = 'priB/PS_SNAPSHOT_REQUESTING';
export interface PsSnapshotRequestAction {
  type: typeof PS_SNAPSHOT_REQUESTING;
  data: any;
}

export const PS_SNAPSHOT_REQUEST_SUCCESS = 'priB/PS_SNAPSHOT_SUCCESS';
export interface PsSnapshotRequestSuccessAction {
  type: typeof PS_SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const PS_SNAPSHOT_REQUEST_ERROR = 'priB/PS_SNAPSHOT_ERROR';
export interface PsSnapshotRequestErrorAction {
  type: typeof PS_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const PS_INDEX_REQUESTING = 'priB/PS_INDEX_REQUESTING';
export interface PsIndexRequestAction {
  type: typeof PS_INDEX_REQUESTING;
  data: any;
}

export const PS_INDEX_REQUEST_SUCCESS = 'priB/PS_INDEX_SUCCESS';
export interface PsIndexRequestSuccessAction {
  type: typeof PS_INDEX_REQUEST_SUCCESS;
  dataList: any;
}

export const PS_INDEX_REQUEST_ERROR = 'priB/PS_INDEX_ERROR';
export interface PsIndexRequestErrorAction {
  type: typeof PS_INDEX_REQUEST_ERROR;
  error: any;
}

export const LOLE_SNAPSHOT_REQUESTING = 'priB/LOLE_REQUESTING';
export interface LoleSnapshotRequestAction {
  type: typeof LOLE_SNAPSHOT_REQUESTING;
  data: any;
}

export const LOLE_SNAPSHOT_REQUEST_SUCCESS = 'priB/LOLE_SUCCESS';
export interface LoleSnapshotRequestSuccessAction {
  type: typeof LOLE_SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const LOLE_SNAPSHOT_REQUEST_ERROR = 'priB/LOLE_ERROR';
export interface LoleSnapshotRequestErrorAction {
  type: typeof LOLE_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const LOLE_HSX_SNAPSHOT_REQUESTING = 'priB/LOLE_HSX_REQUESTING';
export interface LoleHSXSnapshotRequestAction {
  type: typeof LOLE_HSX_SNAPSHOT_REQUESTING;
  data: any;
}

export const LOLE_HSX_SNAPSHOT_REQUEST_SUCCESS = 'priB/LOLE_HSX_SUCCESS';
export interface LoleHSXSnapshotRequestSuccessAction {
  type: typeof LOLE_HSX_SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const LOLE_HSX_SNAPSHOT_REQUEST_ERROR = 'priB/LOLE_HSX_ERROR';
export interface LoleHSXSnapshotRequestErrorAction {
  type: typeof LOLE_HSX_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const CLEAR_PART_REQUEST = 'priB/CLEAR_PART_REQUEST';
export interface ClearPartRequestAction {
  type: typeof CLEAR_PART_REQUEST;
}

export const CLEAR_SNAPSHOT_REQUEST = 'priB/CLEAR_SNAPSHOT_REQUEST';
export interface ClearSnapshotRequestAction {
  type: typeof CLEAR_SNAPSHOT_REQUEST;
}

export const SNAPSHOT_PART_REQUESTING = 'priB/SNAPSHOT_PART_REQUESTING';
export interface SnapshotPartRequestAction {
  type: typeof SNAPSHOT_PART_REQUESTING;
  data: any;
}

export const SNAPSHOT_PART_REQUEST_SUCCESS = 'priB/SNAPSHOT_PART_SUCCESS';
export interface SnapshotPartRequestSuccessAction {
  type: typeof SNAPSHOT_PART_REQUEST_SUCCESS;
  dataList: any;
}

export const SNAPSHOT_PART_REQUEST_ERROR = 'priB/SNAPSHOT_PART_ERROR';
export interface SnapshotPartRequestErrorAction {
  type: typeof SNAPSHOT_PART_REQUEST_ERROR;
  error: any;
}

export const STOCK_INFO_REQUESTING = 'priB/STOCK_INFO_REQUESTING';
export interface StockInfoRequestAction {
  type: typeof STOCK_INFO_REQUESTING;
  data: any;
}

export const STOCK_INFO_REQUEST_SUCCESS = 'priB/STOCK_INFO_SUCCESS';
export interface StockInfoRequestSuccessAction {
  type: typeof STOCK_INFO_REQUEST_SUCCESS;
  stockInfo: any;
}

export const STOCK_INFO_REQUEST_ERROR = 'priB/STOCK_INFO_ERROR';
export interface StockInfoRequestErrorAction {
  type: typeof STOCK_INFO_REQUEST_ERROR;
  error: any;
}

export const ALL_ORD_REQUESTING = 'priB/ALL_ORD_REQUESTING';
export interface AllOrdRequestAction {
  type: typeof ALL_ORD_REQUESTING;
  data: any;
}

export const ALL_ORD_REQUEST_SUCCESS = 'priB/ALL_ORD_SUCCESS';
export interface AllOrdRequestSuccessAction {
  type: typeof ALL_ORD_REQUEST_SUCCESS;
  allOrder: any;
}

export const ALL_ORD_REQUEST_ERROR = 'priB/ALL_ORD_ERROR';
export interface AllOrdRequestErrorAction {
  type: typeof ALL_ORD_REQUEST_ERROR;
  error: any;
}

export const CLEAR_ORD_LIST = 'priB/CLEAR_ORD_LIST';
export interface ClearOrdListAction {
  type: typeof CLEAR_ORD_LIST;
}

export const PT_LIST_REQUESTING = 'priB/PT_LIST_REQUESTING';
export interface PtListRequestAction {
  type: typeof PT_LIST_REQUESTING;
}

export const PT_LIST_REQUEST_SUCCESS = 'priB/PT_LIST_SUCCESS';
export interface PtListRequestSuccessAction {
  type: typeof PT_LIST_REQUEST_SUCCESS;
  ptList: any;
}

export const PT_LIST_REQUEST_ERROR = 'priB/PT_LIST_ERROR';
export interface PtListRequestErrorAction {
  type: typeof PT_LIST_REQUEST_ERROR;
  error: any;
}

export const PS_LIST_REQUESTING = 'priB/PS_LIST_REQUESTING';
export interface PsListRequestAction {
  type: typeof PS_LIST_REQUESTING;
}

export const PS_LIST_REQUEST_SUCCESS = 'priB/PS_LIST_SUCCESS';
export interface PsListRequestSuccessAction {
  type: typeof PS_LIST_REQUEST_SUCCESS;
  psList: any;
}

export const PS_LIST_REQUEST_ERROR = 'priB/PS_LIST_ERROR';
export interface PsListRequestErrorAction {
  type: typeof PS_LIST_REQUEST_ERROR;
  error: any;
}

export const CW_LIST_REQUESTING = 'priB/CW_LIST_REQUESTING';
export interface CwListRequestAction {
  type: typeof CW_LIST_REQUESTING;
}

export const CW_LIST_REQUEST_SUCCESS = 'priB/CW_LIST_SUCCESS';
export interface CwListRequestSuccessAction {
  type: typeof CW_LIST_REQUEST_SUCCESS;
  cwList: any;
}

export const CW_LIST_REQUEST_ERROR = 'priB/CW_LIST_ERROR';
export interface CwListRequestErrorAction {
  type: typeof CW_LIST_REQUEST_ERROR;
  error: any;
}

export const LIST_30_REQUESTING = 'priB/LIST_30_REQUESTING';
export interface List30RequestAction {
  type: typeof LIST_30_REQUESTING;
  data: any;
}

export const LIST_30_REQUEST_SUCCESS = 'priB/LIST_30_SUCCESS';
export interface List30RequestSuccessAction {
  type: typeof LIST_30_REQUEST_SUCCESS;
  list30: any;
}

export const LIST_30_REQUEST_ERROR = 'priB/LIST_30_ERROR';
export interface List30RequestErrorAction {
  type: typeof LIST_30_REQUEST_ERROR;
  error: any;
}

export const LIST_NGANH_REQUESTING = 'priB/LIST_NGANH_REQUESTING';
export interface ListNganhRequestAction {
  type: typeof LIST_NGANH_REQUESTING;
  data: any;
}

export const LIST_NGANH_REQUEST_SUCCESS = 'priB/LIST_NGANH_SUCCESS';
export interface ListNganhRequestSuccessAction {
  type: typeof LIST_NGANH_REQUEST_SUCCESS;
  listNganh: any;
}

export const LIST_NGANH_REQUEST_ERROR = 'priB/LIST_NGANH_ERROR';
export interface ListNganhRequestErrorAction {
  type: typeof LIST_NGANH_REQUEST_ERROR;
  error: any;
}

export const LIST_ETF_REQUESTING = 'priB/LIST_ETF_REQUESTING';
export interface ListEtfRequestAction {
  type: typeof LIST_ETF_REQUESTING;
  data: any;
}

export const LIST_ETF_REQUEST_SUCCESS = 'priB/LIST_ETF_REQUEST_SUCCESS';
export interface ListEtfRequestSuccessAction {
  type: typeof LIST_ETF_REQUEST_SUCCESS;
  listEtf: any;
}

export const LIST_ETF_REQUEST_ERROR = 'priB/LIST_ETF_REQUEST_ERROR';
export interface ListEtfRequestErrorAction {
  type: typeof LIST_ETF_REQUEST_ERROR;
  error: any;
}

export const BRANCH_CODE_REQUESTING = 'priB/BRANCH_CODE_REQUESTING';
export interface BranchCodeRequestAction {
  type: typeof BRANCH_CODE_REQUESTING;
}

export const BRANCH_CODE_REQUEST_SUCCESS = 'priB/BRANCH_CODE_SUCCESS';
export interface BranchCodeRequestSuccessAction {
  type: typeof BRANCH_CODE_REQUEST_SUCCESS;
  branchList: any;
}

export const BRANCH_CODE_REQUEST_ERROR = 'priB/BRANCH_CODE_ERROR';
export interface BranchCodeRequestErrorAction {
  type: typeof BRANCH_CODE_REQUEST_ERROR;
  error: any;
}

export const NOI_CAP_REQUESTING = 'priB/NOI_CAP_REQUESTING';
export interface NoicapRequestAction {
  type: typeof NOI_CAP_REQUESTING;
}

export const NOI_CAP_REQUEST_SUCCESS = 'priB/NOI_CAP_SUCCESS';
export interface NoicapRequestSuccessAction {
  type: typeof NOI_CAP_REQUEST_SUCCESS;
  noicap: any;
}

export const NOI_CAP_REQUEST_ERROR = 'priB/NOI_CAP_ERROR';
export interface NoicapRequestErrorAction {
  type: typeof NOI_CAP_REQUEST_ERROR;
  error: any;
}

export const SUMMARY_REQUESTING = 'priB/SUMMARY_REQUESTING';
export interface IndSumRequestAction {
  type: typeof SUMMARY_REQUESTING;
}

export const SUMMARY_REQUEST_SUCCESS = 'priB/SUMMARY_SUCCESS';
export interface IndSumRequestSuccessAction {
  type: typeof SUMMARY_REQUEST_SUCCESS;
  indSum: any;
}

export const SUMMARY_REQUEST_ERROR = 'priB/SUMMARY_ERROR';
export interface IndSumRequestErrorAction {
  type: typeof SUMMARY_REQUEST_ERROR;
  error: any;
}

export const GET_STOCK_BY_ID_REQUESTING = 'priB/STOCK_BY_ID_REQUESTING';
export interface GetStockByIdRequestAction {
  type: typeof GET_STOCK_BY_ID_REQUESTING;
  data: any;
}

export const GET_STOCK_BY_ID_REQUEST_SUCCESS = 'priB/STOCK_BY_ID_SUCCESS';
export interface GetStockByIdRequestSuccessAction {
  type: typeof GET_STOCK_BY_ID_REQUEST_SUCCESS;
  dataList: any;
}

export const GET_STOCK_BY_ID_REQUEST_ERROR = 'priB/STOCK_BY_ID_ERROR';
export interface GetStockByIdRequestErrorAction {
  type: typeof GET_STOCK_BY_ID_REQUEST_ERROR;
  error: any;
}

export const INDEX_REQUESTING = 'priB/INDEX_REQUESTING';
export interface IndexRequestAction {
  type: typeof INDEX_REQUESTING;
  data: any;
}

export const INDEX_REQUEST_SUCCESS = 'priB/INDEX_SUCCESS';
export interface IndexRequestSuccessAction {
  type: typeof INDEX_REQUEST_SUCCESS;
  indexList: any;
}

export const INDEX_REQUEST_ERROR = 'priB/INDEX_ERROR';
export interface IndexRequestErrorAction {
  type: typeof INDEX_REQUEST_ERROR;
  error: any;
}

export const WORLD_INDEX_REQUESTING = 'priB/WORLD_INDEX_REQUESTING';
export interface WorldIndexRequestAction {
  type: typeof WORLD_INDEX_REQUESTING;
}

export const WORLD_INDEX_REQUEST_SUCCESS = 'priB/WORLD_INDEX_REQUEST_SUCCESS';
export interface WorldIndexRequestSuccessAction {
  type: typeof WORLD_INDEX_REQUEST_SUCCESS;
  worldList: any;
}

export const WORLD_INDEX_REQUEST_ERROR = 'priB/WORLD_INDEX_REQUEST_ERROR';
export interface WorldIndexRequestErrorAction {
  type: typeof WORLD_INDEX_REQUEST_ERROR;
  error: any;
}

export const NDATA_SNAPSHOT_REQUESTING = 'priB/NDATA_REQUESTING';
export interface NdataSnapshotRequestAction {
  type: typeof NDATA_SNAPSHOT_REQUESTING;
  data: any;
}

export const NDATA_SNAPSHOT_REQUEST_SUCCESS = 'priB/NDATA_SUCCESS';
export interface NdataSnapshotRequestSuccessAction {
  type: typeof NDATA_SNAPSHOT_REQUEST_SUCCESS;
  ndata: any;
}

export const NDATA_SNAPSHOT_REQUEST_ERROR = 'priB/NDATA_ERROR';
export interface NdataSnapshotRequestErrorAction {
  type: typeof NDATA_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const TRADE_HIS_PS_REQUESTING = 'priB/TRADE_HIS_PS_REQUESTING';
export interface TradeHisPsRequestAction {
  type: typeof TRADE_HIS_PS_REQUESTING;
  data: any;
}

export const TRADE_HIS_PS_REQUEST_SUCCESS = 'priB/TRADE_HIS_PS_SUCCESS';
export interface TradeHisPsRequestSuccessAction {
  type: typeof TRADE_HIS_PS_REQUEST_SUCCESS;
  dataList: any;
}

export const TRADE_HIS_PS_REQUEST_ERROR = 'priB/TRADE_HIS_PS_ERROR';
export interface TradeHisPsRequestErrorAction {
  type: typeof TRADE_HIS_PS_REQUEST_ERROR;
  error: any;
}

export const TRADE_HIS_PS_Add_ROW = 'priB/TRADE_HIS_PS_Add_ROW';
export interface TradeHisPsAddRowAction {
  type: typeof TRADE_HIS_PS_Add_ROW;
  data: any;
}

export const SORT_TYPE = 'priB/SORT_TYPE';
export interface SortTypeTblAction {
  type: typeof SORT_TYPE;
  _type: any;
}

export const DM_NGANH_REQUESTING = 'priB/DM_NGANH_REQUESTING';
export interface DmNganhRequestAction {
  type: typeof DM_NGANH_REQUESTING;
}

export const DM_NGANH_REQUEST_SUCCESS = 'priB/DM_NGANH_SUCCESS';
export interface DmNganhRequestSuccessAction {
  type: typeof DM_NGANH_REQUEST_SUCCESS;
  dataList: any;
}

export const DM_NGANH_REQUEST_ERROR = 'priB/DM_NGANH_ERROR';
export interface DmNganhRequestErrorAction {
  type: typeof DM_NGANH_REQUEST_ERROR;
  error: any;
}

export const TOP_INTEREST_REQUESTING = 'priB/TOP_INTEREST_REQUESTING';
export interface TopInterestRequestAction {
  type: typeof TOP_INTEREST_REQUESTING;
}

export const TOP_INTEREST_REQUEST_SUCCESS = 'priB/TOP_INTEREST_REQUEST_SUCCESS';
export interface TopInterestRequestSuccessAction {
  type: typeof TOP_INTEREST_REQUEST_SUCCESS;
  dataList: any;
}

export const TOP_INTEREST_REQUEST_ERROR = 'priB/TOP_INTEREST_REQUEST_ERROR';
export interface TopInterestRequestErrorAction {
  type: typeof TOP_INTEREST_REQUEST_ERROR;
  error: any;
}

/** Top giá tăng/giảm */
export const TOP_STOCK_CHANGE_REQUESTING = 'priB/TOP_STOCK_CHANGE_REQUESTING';
export interface TopStockChangeRequestAction {
  type: typeof TOP_STOCK_CHANGE_REQUESTING;
  data: any;
}

export const TOP_STOCK_CHANGE_SUCCESS = 'priB/TOP_STOCK_CHANGE_SUCCESS';
export interface TopStockChangeSuccessAction {
  type: typeof TOP_STOCK_CHANGE_SUCCESS;
  dataList: any;
}

export const TOP_STOCK_CHANGE_ERROR = 'priB/TOP_STOCK_CHANGE_ERROR';
export interface TopStockChangeErrorAction {
  type: typeof TOP_STOCK_CHANGE_ERROR;
  error: any;
}

/** Top kl tăng/giảm */
export const TOP_STOCK_TRADE_REQUESTING = 'priB/TOP_STOCK_TRADE_REQUESTING';
export interface TopStockTradeRequestAction {
  type: typeof TOP_STOCK_TRADE_REQUESTING;
  data: any;
}

export const TOP_STOCK_TRADE_SUCCESS = 'priB/TOP_STOCK_TRADE_SUCCESS';
export interface TopStockTradeSuccessAction {
  type: typeof TOP_STOCK_TRADE_SUCCESS;
  dataList: any;
}

export const TOP_STOCK_TRADE_ERROR = 'priB/TOP_STOCK_TRADE_ERROR';
export interface TopStockTradeErrorAction {
  type: typeof TOP_STOCK_TRADE_ERROR;
  error: any;
}

/** lấy snapshot mã chứng khoán pinned */
export const SNP_STOCK_PINNED_REQUESTING = 'priB/SNP_STOCK_PINNED_REQUESTING';
export interface SnpStockPinnedRequestAction {
  type: typeof SNP_STOCK_PINNED_REQUESTING;
  data: any;
}

export const SNP_STOCK_PINNED_SUCCESS = 'priB/SNP_STOCK_PINNED_SUCCESS';
export interface SnpStockPinnedSuccessAction {
  type: typeof SNP_STOCK_PINNED_SUCCESS;
  resData: any;
}

export const SNP_STOCK_PINNED_ERROR = 'priB/SNP_STOCK_PINNED_ERROR';
export interface SnpStockPinnedErrorAction {
  type: typeof SNP_STOCK_PINNED_ERROR;
  error: any;
}

export const STOCK_LIST_PRICE_CLEAR = 'priB/STOCK_LIST_PRICE_CLEAR';
export interface StockListSnapshotClearAction {
  type: typeof STOCK_LIST_PRICE_CLEAR;
}

export const STOCK_LIST_SNAPSHOT_TEMP_SUCCESS =
  'priB/STOCK_LIST_SNAPSHOT_TEMP_SUCCESS';
export interface StockListSnapshotTempSuccessAction {
  type: typeof STOCK_LIST_SNAPSHOT_TEMP_SUCCESS;
  data: any;
}

export const SNAPSHOT_REQUEST_SUCCESS_FULL =
  'priB/SNAPSHOT_REQUEST_SUCCESS_FULL';
export interface SnapshotRequestSuccessFullAction {
  type: typeof SNAPSHOT_REQUEST_SUCCESS_FULL;
}

export const STOCK_LIST_PARTSNAP_TEMP_SUCCESS =
  'priB/STOCK_LIST_PARTSNAP_TEMP_SUCCESS';
export interface StockListPartSnapTempSuccessAction {
  type: typeof STOCK_LIST_PARTSNAP_TEMP_SUCCESS;
  data: any;
}

export const PART_ID_REQUEST_SUCCESS_FULL = 'priB/PART_ID_REQUEST_SUCCESS_FULL';
export interface PartIdRequestSuccessFullAction {
  type: typeof PART_ID_REQUEST_SUCCESS_FULL;
}

/** danh sách nâng cao */
export const ADV_STOCK_DATA_BYID = 'priB/ADV_STOCK_DATA_BYID';

export const ADV_STOCK_DATA_BYID_REQUESTING =
  'priB/ADV_STOCK_DATA_BYID_REQUESTING';
export interface AdvStockDataByIdRequestAction {
  type: typeof ADV_STOCK_DATA_BYID_REQUESTING;
  data: any;
}

export const ADV_STOCK_DATA_BYID_SUCCESS = 'priB/ADV_STOCK_DATA_BYID_SUCCESS';
export interface AdvStockDataByIdSuccessAction {
  type: typeof ADV_STOCK_DATA_BYID_SUCCESS;
  data: any;
}

export const ADV_STOCK_DATA_BYID_ERROR = 'priB/ADV_STOCK_DATA_BYID_ERROR';
export interface AdvStockDataByIdErrorAction {
  type: typeof ADV_STOCK_DATA_BYID_ERROR;
  error: any;
}

export const ADV_STOCK_LIST_DATA_REQUESTING =
  'priB/ADV_STOCK_LIST_DATA_REQUESTING';
export interface AdvStockListDataRequestAction {
  type: typeof ADV_STOCK_LIST_DATA_REQUESTING;
  data: any;
}

export const ADV_STOCK_LIST_DATA_SUCCESS = 'priB/ADV_STOCK_LIST_DATA_SUCCESS';
export interface AdvStockListDataSuccessAction {
  type: typeof ADV_STOCK_LIST_DATA_SUCCESS;
  data: any;
}

export const ADV_STOCK_LIST_DATA_ERROR = 'priB/ADV_STOCK_LIST_DATA_ERROR';
export interface AdvStockListDataErrorAction {
  type: typeof ADV_STOCK_LIST_DATA_ERROR;
  error: any;
}

// lấy chi tiết mã advance single  => dùng khi thêm
export const ADV_STOCK_SINGLE_REQUESTING = 'priB/ADV_STOCK_SINGLE_REQUESTING';
export interface AdvStockSingleRequestAction {
  type: typeof ADV_STOCK_SINGLE_REQUESTING;
  data: any;
}

export const ADV_STOCK_SINGLE_SUCCESS = 'priB/ADV_STOCK_SINGLE_SUCCESS';
export interface AdvStockSingleSuccessAction {
  type: typeof ADV_STOCK_SINGLE_SUCCESS;
  data: any;
}

export const ADV_STOCK_SINGLE_ERROR = 'priB/ADV_STOCK_SINGLE_ERROR';
export interface AdvStockSingleErrorAction {
  type: typeof ADV_STOCK_SINGLE_ERROR;
  error: any;
}

/** lấy snapshot advance mã chứng khoán pinned */
export const ADV_STOCK_PINNED_REQUESTING = 'priB/ADV_STOCK_PINNED_REQUESTING';
export interface AdvStockPinnedRequestAction {
  type: typeof ADV_STOCK_PINNED_REQUESTING;
  data: any;
}

export const ADV_STOCK_PINNED_SUCCESS = 'priB/ADV_STOCK_PINNED_SUCCESS';
export interface AdvStockPinnedSuccessAction {
  type: typeof ADV_STOCK_PINNED_SUCCESS;
  resData: any;
}

export const ADV_STOCK_PINNED_ERROR = 'priB/ADV_STOCK_PINNED_ERROR';
export interface AdvStockPinnedErrorAction {
  type: typeof ADV_STOCK_PINNED_ERROR;
  error: any;
}

export const ADV_STOCK_LIST_DATA = 'priB/ADV_STOCK_LIST_DATA';
export const ADV_STOCK_LIST_DATA_CLEAR = 'priB/ADV_STOCK_LIST_DATA_CLEAR';
export interface AdvStockListDataClearAction {
  type: typeof ADV_STOCK_LIST_DATA_CLEAR;
}

export type PriceBoardAction =
  | AllStockRequestAction
  | AllStockRequestSuccessAction
  | AllStockRequestErrorAction
  | AllStockRightRequestAction
  | AllStockRightRequestSuccessAction
  | AllStockRightRequestErrorAction
  | ListMessageRequestAction
  | ListMessageRequestSuccessAction
  | ListMessageRequestErrorAction
  | SnapshotRequestAction
  | SnapshotRequestSuccessAction
  | SnapshotRequestErrorAction
  | SnapshotUnderlyingCWRequestAction
  | SnapshotUnderlyingCWSuccessAction
  | SnapshotUnderlyingCWErrorAction
  | PsSnapshotRequestAction
  | PsSnapshotRequestSuccessAction
  | PsSnapshotRequestErrorAction
  | PsIndexRequestAction
  | PsIndexRequestSuccessAction
  | PsIndexRequestErrorAction
  | LoleSnapshotRequestAction
  | LoleSnapshotRequestSuccessAction
  | LoleSnapshotRequestErrorAction
  | LoleHSXSnapshotRequestAction
  | LoleHSXSnapshotRequestSuccessAction
  | LoleHSXSnapshotRequestErrorAction
  | ClearPartRequestAction
  | ClearSnapshotRequestAction
  | SnapshotPartRequestAction
  | SnapshotPartRequestSuccessAction
  | SnapshotPartRequestErrorAction
  | StockInfoRequestAction
  | StockInfoRequestSuccessAction
  | StockInfoRequestErrorAction
  | AllOrdRequestAction
  | AllOrdRequestSuccessAction
  | AllOrdRequestErrorAction
  | PtListRequestAction
  | PtListRequestSuccessAction
  | PtListRequestErrorAction
  | PsListRequestAction
  | PsListRequestSuccessAction
  | PsListRequestErrorAction
  | CwListRequestAction
  | CwListRequestSuccessAction
  | CwListRequestErrorAction
  | List30RequestAction
  | List30RequestSuccessAction
  | List30RequestErrorAction
  | ListNganhRequestAction
  | ListNganhRequestSuccessAction
  | ListNganhRequestErrorAction
  | ListEtfRequestAction
  | ListEtfRequestSuccessAction
  | ListEtfRequestErrorAction
  | IndSumRequestAction
  | IndSumRequestSuccessAction
  | IndSumRequestErrorAction
  | GetStockByIdRequestAction
  | GetStockByIdRequestSuccessAction
  | GetStockByIdRequestErrorAction
  | IndexRequestAction
  | IndexRequestSuccessAction
  | IndexRequestErrorAction
  | WorldIndexRequestAction
  | WorldIndexRequestSuccessAction
  | WorldIndexRequestErrorAction
  | NdataSnapshotRequestAction
  | NdataSnapshotRequestSuccessAction
  | NdataSnapshotRequestErrorAction
  | TradeHisPsRequestAction
  | TradeHisPsRequestSuccessAction
  | TradeHisPsRequestErrorAction
  | TradeHisPsAddRowAction
  | SortTypeTblAction
  | DmNganhRequestAction
  | DmNganhRequestSuccessAction
  | DmNganhRequestErrorAction
  | TopInterestRequestAction
  | TopInterestRequestSuccessAction
  | TopInterestRequestErrorAction
  | ClearOrdListAction
  | TopStockChangeRequestAction
  | TopStockChangeSuccessAction
  | TopStockChangeErrorAction
  | TopStockTradeRequestAction
  | TopStockTradeSuccessAction
  | TopStockTradeErrorAction
  | SnpStockPinnedRequestAction
  | SnpStockPinnedSuccessAction
  | SnpStockPinnedErrorAction
  | StockListSnapshotClearAction
  | StockListSnapshotTempSuccessAction
  | StockListPartSnapTempSuccessAction
  | SnapshotRequestSuccessFullAction
  | PartIdRequestSuccessFullAction
  | AdvStockDataByIdRequestAction
  | AdvStockDataByIdSuccessAction
  | AdvStockDataByIdErrorAction
  | AdvStockListDataRequestAction
  | AdvStockListDataSuccessAction
  | AdvStockListDataErrorAction
  | AdvStockSingleRequestAction
  | AdvStockSingleSuccessAction
  | AdvStockSingleErrorAction
  | AdvStockListDataClearAction
  | AdvStockPinnedRequestAction
  | AdvStockPinnedSuccessAction
  | AdvStockPinnedErrorAction;
