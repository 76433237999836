import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';
import {
  categoryRequestSuccess,
  categoryRequestError,
  listAccountRequestSuccess,
  listAccountRequestError,
  getOtpRequestSuccess,
  getOtpRequestError,
  getBankOnlineRequestSuccess,
  getBankOnlineRequestError,
  getBankBranchRequestSuccess,
  getBankBranchRequestError,
  getProvinceRequestSuccess,
  getProvinceRequestError,
  getDistrictRequestError,
  getDistrictRequestSuccess,
  getVillageRequestError,
  getVillageRequestSuccess,
  checkPinRequestSuccess,
  checkPinRequestError,
  accountPortfolioSuccess,
  accountPortfolioError,
  updatePortfolioSuccess,
  updatePortfolioError,
  delPortfolioSuccess,
  delPortfolioError,
} from './actions';
import { checkInvalidSession, getMsgByErrorCode } from 'utils';
import * as actions from './actionType';
import axios from 'axios';
import { storages } from 'lib/storages';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function categoryRequestApi(data: any) {
  const url = `${apiUrl}/CoreServlet.pt`;
  const request = axios.post(url, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });

  return handleRequest(request);
}

async function postRequestApi(data: any) {
  const url = `${apiUrl}/TraditionalService`;
  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* categoryRequestFlow(action: actions.CategoryRequestAction): any {
  try {
    const category = yield call(categoryRequestApi, action.data);
    // log(category)
    yield put(categoryRequestSuccess(category.data));
  } catch (error) {
    yield put(categoryRequestError(error));
  }
}

function* listAccountRequestFlow(
  action: actions.ListAccountRequestAction,
): any {
  try {
    const accounts = yield call(postRequestApi, action.data);
    // log(category)
    const { data } = accounts;
    console.log(data);
    yield put(listAccountRequestSuccess(data));
  } catch (error) {
    // console.log('listAccountRequestFlow', error);
    yield put(listAccountRequestError(error));
  }
}

function* getOtpRequestFlow(action: actions.GetOtpRequestAction): any {
  try {
    // yield put(getOtpRequestSuccess(Math.random()));
    // return;
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(getOtpRequestSuccess(Math.random()));
  } catch (error) {
    yield put(getOtpRequestError(error));
  }
}

function* checkPinRequestFlow(action: actions.CheckPinRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(checkPinRequestSuccess(Math.random()));
  } catch (error) {
    yield put(checkPinRequestError(error));
  }
}

function* getBankOnlineRequestFlow(
  action: actions.GetBankOnlineRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getBankOnlineRequestSuccess(resData.data));
  } catch (error) {
    yield put(getBankOnlineRequestError(error));
  }
}

function* getBankBranchRequestFlow(
  action: actions.GetBankBranchRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getBankBranchRequestSuccess(resData.data));
  } catch (error) {
    yield put(getBankBranchRequestError(error));
  }
}

function* getProvinceRequestFlow(
  action: actions.GetProvinceRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getProvinceRequestSuccess(resData.data));
  } catch (error) {
    yield put(getProvinceRequestError(error));
  }
}

function* getDistrictRequestFlow(
  action: actions.GetDistrictRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getDistrictRequestSuccess(resData.data));
  } catch (error) {
    yield put(getDistrictRequestError(error));
  }
}

function* getVillageRequestFlow(action: actions.GetVillageRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getVillageRequestSuccess(resData.data));
  } catch (error) {
    yield put(getVillageRequestError(error));
  }
}

function* accountPortfolioRequestFlow(
  action: actions.AccountPortfolioRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(accountPortfolioSuccess(resData.data[0].data1));
  } catch (error) {
    yield put(accountPortfolioError(error));
  }
}

function* updatePortfolioRequestFlow(
  action: actions.UpdatePortfolioRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(updatePortfolioSuccess(Math.random()));
  } catch (error) {
    yield put(updatePortfolioError(error));
  }
}

function* delPortfolioRequestFlow(
  action: actions.DelPortfolioRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(delPortfolioSuccess(Math.random()));
  } catch (error) {
    yield put(delPortfolioError(error));
  }
}

function* clientWatcher() {
  yield all([
    takeLatest(actions.CATEGORY_REQUESTING, categoryRequestFlow),
    takeLatest(actions.LIST_ACCOUNT_REQUESTING, listAccountRequestFlow),
    takeLatest(actions.GET_OTP_REQUESTING, getOtpRequestFlow),
    takeLatest(actions.CHECK_PIN_REQUESTING, checkPinRequestFlow),
    takeLatest(actions.GET_BANK_ONLINE_REQUESTING, getBankOnlineRequestFlow),
    takeLatest(actions.GET_BANK_BRANCH_REQUESTING, getBankBranchRequestFlow),
    takeLatest(actions.GET_PROVINCE_REQUESTING, getProvinceRequestFlow),
    takeLatest(actions.GET_DISTRICT_REQUESTING, getDistrictRequestFlow),
    takeLatest(actions.GET_VILLAGE_REQUESTING, getVillageRequestFlow),
    takeLatest(actions.ACC_PORTFOLIO_REQUESTING, accountPortfolioRequestFlow),
    takeLatest(actions.UPD_PORTFOLIO_REQUESTING, updatePortfolioRequestFlow),
    takeLatest(actions.DEL_PORTFOLIO_REQUESTING, delPortfolioRequestFlow),
  ]);
}

export default clientWatcher;
