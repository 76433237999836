import { FunctionComponent, useState } from 'react';
import { ReactComponent as IcEye } from 'assets/img/icon/eye.svg';
import { ReactComponent as IcEyeSlash } from 'assets/img/icon/eye-slash.svg';
import { ReactComponent as IcTick } from 'assets/img/icon/tick.svg';
import { ReactComponent as IcNoTick } from 'assets/img/icon/no-tick.svg';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';

const listTick = [
  'valid.passLength',
  'valid.passLetter',
  'valid.passNumber',
  'valid.passCharacter',
];
interface Props {
  input: any;
  type: string | undefined;
  placeholder: string | undefined;
  disabled: boolean | undefined;
  meta: any;
  isTick?: boolean;
}

const RenderLoginInput: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { input, type, disabled, placeholder, meta, isTick } = props;
  const { touched, error, warning, active } = meta;
  const [typeInput, setTypeInput] = useState(
    type === 'password' ? 'password' : 'text',
  );
  console.log(warning)
  return (
    <div className="flex flex-col relative">
      <div className="w-full rounded-full flex ">
        <input
          {...input}
          type={typeInput}
          className={
            'w-full h-12 rounded-full px-4 py-2 text-input-trading text-base bg-skin-input placeholder:text-skin-subdued ' +
            (touched && (error || (warning && !!warning.length)) ? 'border !border-skin-valid' : 'border-none')
          }
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="false"
          autoCorrect="false"
          aria-autocomplete="none"
          onChange={(e) => type === 'username' ? input.onChange(e.target.value.toUpperCase()) : input.onChange(e.target.value)}
        />
      </div>
      {type === 'password' && (
        <span
          className={
            'absolute top-3 right-3 ' + (touched && error ? 'icon-valid' : '')
          }
          onClick={() =>
            setTypeInput(typeInput === 'text' ? 'password' : 'text')
          }
        >
          {typeInput === 'text' ? <IcEye /> : <IcEyeSlash />}
        </span>
      )}
      {touched && error && (
        <div className="text-sm text-skin-red mt-2">{error}</div>
      )}
      {isTick && active && (
        <ul className="absolute top-0 -right-[220px] p-3 bg-skin-card border border-skin-strong rounded-xl grid gap-3 z-50">
          <li className="text-sm">{t('pass-included')}</li>
          {listTick &&
            listTick.map((item, index) => {
              return (
                <li key={index} className="flex text-xs items-center">
                  {_.some(warning, (o) => o === item) ? (
                    <>
                      <IcNoTick className="mr-1" />
                      <span className="text-skin-red">{t(item)}</span>
                    </>
                  ) : (
                    <>
                      <IcTick className="mr-1" />
                      <span className="text-skin-active">{t(item)}</span>
                    </>
                  )}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default RenderLoginInput;
