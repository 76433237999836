import { call, put, takeLatest, all } from 'redux-saga/effects';
import { handleApiErrors } from 'lib/api-error';

import * as actions from './actionType';
import axios from 'axios';
import {
  glListBranchSuccess,
  glListBranchError,
  glListSubBranchSuccess,
  glListSubBranchError,
  glImportTypeSuccess,
  glImportTypeError,
} from './actions';
import { checkInvalidSession } from 'utils';
import { storages } from 'lib/storages';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const appUrl = `${process.env.REACT_APP_API_URL}`;

async function postRequestApi(data: Object) {
  const url = `${appUrl}/traditional-service`;

  try {
    const response = axios.post(url, JSON.stringify(data));
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* glListBranchRequestFlow(
  action: actions.GlListBranchRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(glListBranchSuccess(_res.data));
  } catch (error: any) {
    yield put(glListBranchError(error));
  }
}

function* glListSubBranchRequestFlow(
  action: actions.GlListBranchRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(glListSubBranchSuccess(_res.data));
  } catch (error: any) {
    yield put(glListSubBranchError(error));
  }
}

function* glImportTypeRequestFlow(
  action: actions.GlImportTypeRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(postRequestApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(glImportTypeSuccess(_res.data));
  } catch (error: any) {
    yield put(glImportTypeError(error));
  }
}

function* globalWatcher() {
  yield all([
    takeLatest(actions.GL_LIST_BRANCH_REQUESTING, glListBranchRequestFlow),
    takeLatest(
      actions.GL_LIST_SUBBRANCH_REQUESTING,
      glListSubBranchRequestFlow,
    ),
    takeLatest(actions.GL_IMPORT_TYPE_REQUESTING, glImportTypeRequestFlow),
  ]);
}

export default globalWatcher;
