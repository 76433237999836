import * as actions from './actionType';

/** new order */
export function tdNewOrderRequest(
  data: any,
): actions.TdNewOrderRequestingAction {
  return {
    type: actions.TD_NEW_ORDER_REQUESTING,
    data,
  };
}

export function tdNewOrderSuccess(
  resData: any,
): actions.TdNewOrderSuccessAction {
  return {
    type: actions.TD_NEW_ORDER_SUCCESS,
    resData,
  };
}

export function tdNewOrderError(
  error: Error | string,
): actions.TdNewOrderErrorAction {
  return {
    type: actions.TD_NEW_ORDER_ERROR,
    error,
  };
}

/** hủy lệnh */
export function tdCancelOrderRequest(
  data: any,
): actions.TdCancelOrderRequestingAction {
  return {
    type: actions.TD_CANCEL_ORDER_REQUESTING,
    data,
  };
}

export function tdCancelOrderSuccess(
  resData: any,
): actions.TdCancelOrderSuccessAction {
  return {
    type: actions.TD_CANCEL_ORDER_SUCCESS,
    resData,
  };
}

export function tdCancelOrderError(
  error: Error | string,
): actions.TdCancelOrderErrorAction {
  return {
    type: actions.TD_CANCEL_ORDER_ERROR,
    error,
  };
}

/** danh sách lệnh */
export function tdOrderListRequest(
  data: any,
): actions.TdOrderListRequestingAction {
  return {
    type: actions.TD_ORDER_LIST_REQUESTING,
    data,
  };
}

export function tdOrderListSuccess(
  resData: any,
): actions.TdOrderListSuccessAction {
  return {
    type: actions.TD_ORDER_LIST_SUCCESS,
    resData,
  };
}

export function tdOrderListError(
  error: Error | string,
): actions.TdOrderListErrorAction {
  return {
    type: actions.TD_ORDER_LIST_ERROR,
    error,
  };
}

export function symbolIndayRequest(
  params: any,
): actions.SymbolIndayRequestAction {
  return {
    type: actions.SYMBOL_INDAY_REQUESTING,
    params,
  };
}

export function symbolIndayRequestSuccess(
  symbolInday: any,
): actions.SymbolIndayRequestSuccessAction {
  return {
    type: actions.SYMBOL_INDAY_REQUEST_SUCCESS,
    symbolInday,
  };
}

export function symbolIndayRequestError(
  error: any,
): actions.SymbolIndayRequestErrorAction {
  return {
    type: actions.SYMBOL_INDAY_REQUEST_ERROR,
    error,
  };
}

export function orderHistoryRequest(
  params: any,
): actions.OrderHistoryRequestAction {
  return {
    type: actions.ORDER_HISTORY_REQUESTING,
    params,
  };
}

export function orderHistoryRequestSuccess(
  orderHis: any,
): actions.OrderHistoryRequestSuccessAction {
  return {
    type: actions.ORDER_HISTORY_REQUEST_SUCCESS,
    orderHis,
  };
}

export function orderHistoryRequestError(
  error: any,
): actions.OrderHistoryRequestErrorAction {
  return {
    type: actions.ORDER_HISTORY_REQUEST_ERROR,
    error,
  };
}

export function loanShareRequest(params: any): actions.LoanShareRequestAction {
  return {
    type: actions.LOAN_SHARE_REQUESTING,
    params,
  };
}

export function loanShareRequestSuccess(
  resData: any,
): actions.LoanShareRequestSuccessAction {
  return {
    type: actions.LOAN_SHARE_REQUEST_SUCCESS,
    resData,
  };
}

export function loanShareRequestError(
  error: any,
): actions.LoanShareRequestErrorAction {
  return {
    type: actions.LOAN_SHARE_REQUEST_ERROR,
    error,
  };
}
/************************************ Oddlot ************************************/
export function oddShareRequest(params: any): actions.OddShareRequestAction {
  return {
    type: actions.ODD_SHARE_REQUESTING,
    params,
  };
}

export function oddShareRequestSuccess(
  resData: any,
): actions.OddShareRequestSuccessAction {
  return {
    type: actions.ODD_SHARE_REQUEST_SUCCESS,
    resData,
  };
}

export function oddShareRequestError(
  error: any,
): actions.OddShareRequestErrorAction {
  return {
    type: actions.ODD_SHARE_REQUEST_ERROR,
    error,
  };
}

export function oddShareIndayRequest(
  params: any,
): actions.OddShareIndayRequestAction {
  return {
    type: actions.ODD_SHARE_INDAY_REQUESTING,
    params,
  };
}

export function oddShareIndayRequestSuccess(
  resData: any,
): actions.OddShareIndayRequestSuccessAction {
  return {
    type: actions.ODD_SHARE_INDAY_REQUEST_SUCCESS,
    resData,
  };
}

export function oddShareIndayRequestError(
  error: any,
): actions.OddShareIndayRequestErrorAction {
  return {
    type: actions.ODD_SHARE_INDAY_REQUEST_ERROR,
    error,
  };
}

export function oddShareHisRequest(
  params: any,
): actions.OddShareHisRequestAction {
  return {
    type: actions.ODD_SHARE_HIS_REQUESTING,
    params,
  };
}

export function oddShareHisRequestSuccess(
  resData: any,
): actions.OddShareHisRequestSuccessAction {
  return {
    type: actions.ODD_SHARE_HIS_REQUEST_SUCCESS,
    resData,
  };
}

export function oddShareHisRequestError(
  error: any,
): actions.OddShareHisRequestErrorAction {
  return {
    type: actions.ODD_SHARE_HIS_REQUEST_ERROR,
    error,
  };
}

export function updOddShareRequest(
  params: any,
): actions.UpdOddShareRequestAction {
  return {
    type: actions.UPD_ODD_SHARE_REQUESTING,
    params,
  };
}

export function updOddShareRequestSuccess(
  resData: any,
): actions.UpdOddShareRequestSuccessAction {
  return {
    type: actions.UPD_ODD_SHARE_REQUEST_SUCCESS,
    resData,
  };
}

export function updOddShareRequestError(
  error: any,
): actions.UpdOddShareRequestErrorAction {
  return {
    type: actions.UPD_ODD_SHARE_REQUEST_ERROR,
    error,
  };
}
