import * as actions from './actionType';

export interface GlobalState {
  glListBranch: any;
  glListSubBranch: any;
  glImportType: any;
}

const initialState = {
  glListBranch: null,
  glListSubBranch: null,
  glImportType: null,
};

export default function globalReducer(
  state: GlobalState = initialState,
  action: actions.GlobalAction,
): GlobalState {
  switch (action.type) {
    /** chi nhánh */
    case actions.GL_LIST_BRANCH_REQUESTING:
    case actions.GL_LIST_BRANCH_ERROR:
      return {
        ...state,
        glListBranch: null,
      };

    case actions.GL_LIST_BRANCH_SUCCESS:
      return {
        ...state,
        glListBranch: action.resData,
      };

    /** pgd*/
    case actions.GL_LIST_SUBBRANCH_REQUESTING:
    case actions.GL_LIST_SUBBRANCH_ERROR:
      return {
        ...state,
        glListSubBranch: null,
      };

    case actions.GL_LIST_SUBBRANCH_SUCCESS:
      return {
        ...state,
        glListSubBranch: action.resData,
      };

    /** import type */
    case actions.GL_IMPORT_TYPE_REQUESTING:
    case actions.GL_IMPORT_TYPE_ERROR:
      return {
        ...state,
        glImportType: null,
      };

    case actions.GL_IMPORT_TYPE_SUCCESS:
      return {
        ...state,
        glImportType: action.resData,
      };

    default:
      return state;
  }
}
