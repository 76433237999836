import { handleApiErrors } from 'lib/api-error';
import { storages } from 'lib/storages';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { checkInvalidSession, getMsgByErrorCode } from 'utils';
import * as actions from './actionType';
import { SummaryCashRequestAction } from './actionType';
import {
  accBenAddError,
  accBenAddSuccess,
  advStatusHisRequestError,
  advStatusHisRequestSuccess,
  advStatusRequestError,
  advStatusRequestSuccess,
  beneficiaryAccountRequestError,
  beneficiaryAccountRequestSuccess,
  cashAccountInfoOlRequestError,
  cashAccountInfoOlRequestSuccess,
  cashAccountInfoRequestError,
  cashAccountInfoRequestSuccess,
  cashCanAdvRequestError,
  cashCanAdvRequestSuccess,
  cashOnlyAccountInfoOlRequestError,
  cashOnlyAccountInfoOlRequestSuccess,
  cashOnlyAccountInfoRequestError,
  cashOnlyAccountInfoRequestSuccess,
  cashTransferHisRequestError,
  cashTransferHisRequestSuccess,
  detailDebtError,
  detailDebtSuccess,
  feeAdvWithdrawRequestError,
  feeAdvWithdrawRequestSuccess,
  listCashStatusRequestError,
  listCashStatusRequestSuccess,
  listShareBalanceRequestError,
  listShareBalanceRequestSuccess,
  listShareStatusRequestError,
  listShareStatusRequestSuccess,
  shareEarnedRequestError,
  shareEarnedRequestSuccess,
  shareTransferHisRequestError,
  shareTransferHisRequestSuccess,
  summaryCashRequestError,
  summaryCashRequestSuccess,
  summaryStockRequestError,
  summaryStockRequestSuccess,
} from './actions';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function summaryApi(data: any) {
  const request = fetch(appUrl + '/TraditionalService', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* summaryCashRequestFlow(action: SummaryCashRequestAction): any {
  const { params } = action;
  try {
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(summaryCashRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(summaryCashRequestError(error));
  }
}

function* summaryStockRequestFlow(
  action: actions.SummaryStockRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(summaryStockRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(summaryStockRequestError(error));
  }
}

function* shareEarnRequestFlow(action: actions.ShareEarnedRequestAction): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(shareEarnedRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(shareEarnedRequestError(error));
  }
}

function* cashCanAdvRequestFlow(action: actions.CashCanAdvRequestAction): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(cashCanAdvRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(cashCanAdvRequestError(error));
  }
}

function* cashFeeAdvRequestFlow(
  action: actions.FeeAdvWithdrawRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(feeAdvWithdrawRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(feeAdvWithdrawRequestError(error));
  }
}

function* advStatusRequestFlow(action: actions.AdvStatusRequestAction): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(advStatusRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(advStatusRequestError(error));
  }
}

function* advStatusHisRequestFlow(
  action: actions.AdvStatusHisRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(advStatusHisRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(advStatusHisRequestError(error));
  }
}

function* listShareBalanceRequestFlow(
  action: actions.ListShareBalanceRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(listShareBalanceRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(listShareBalanceRequestError(error));
  }
}

function* listShareStatusRequestFlow(
  action: actions.ListShareStatusRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(listShareStatusRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(listShareStatusRequestError(error));
  }
}

function* shareTransferHisRequestFlow(
  action: actions.ShareTransferHisRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(shareTransferHisRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(shareTransferHisRequestError(error));
  }
}

function* cashAccountInfoRequestFlow(
  action: actions.CashAccountInfoRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    const cmdPortFolio = {
      group: 'Q',
      user: params.user,
      session: params.session,
      data: {
        type: 'string',
        cmd: 'Web.Portfolio.AccountStatus',
        p1: params.data.p1,
      },
    };

    const resAccStatus = yield call(summaryApi, cmdPortFolio);

    const _cashInfo = resData.data[0];

    const _objData = {
      ..._cashInfo,
      C_CASH_BALANCE:
        resAccStatus.data?.cash_balance || _cashInfo.C_CASH_BALANCE || 0,
      C_WITHDRAWAL_CASH:
        resAccStatus.data?.withdrawal_cash - (_cashInfo.C_CASH_BLOCK || 0),
    };

    yield put(cashAccountInfoRequestSuccess(_objData));
  } catch (error) {
    // log(error)
    yield put(cashAccountInfoRequestError(error));
  }
}

function* cashOnlyAccountInfoRequestFlow(
  action: actions.CashOnlyAccountInfoRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(cashOnlyAccountInfoRequestSuccess(resData.data[0]));
  } catch (error) {
    // log(error)
    yield put(cashOnlyAccountInfoRequestError(error));
  }
}

function* cashAccountInfoOlRequestFlow(
  action: actions.CashAccountInfoOlRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    const cmdPortFolio = {
      group: 'Q',
      user: params.user,
      session: params.session,
      data: {
        type: 'string',
        cmd: 'Web.Portfolio.AccountStatus',
        p1: params.data.p1,
      },
    };

    const resAccStatus = yield call(summaryApi, cmdPortFolio);

    const _cashInfo = resData.data[0];

    const _objData = {
      ..._cashInfo,
      C_CASH_BALANCE:
        resAccStatus.data?.cash_balance || _cashInfo.C_CASH_BALANCE || 0,
      C_WITHDRAWAL_CASH:
        resAccStatus.data?.withdrawal_cash - (_cashInfo.C_CASH_BLOCK || 0),
    };

    yield put(cashAccountInfoOlRequestSuccess(_objData));
  } catch (error) {
    // log(error)
    yield put(cashAccountInfoOlRequestError(error));
  }
}

function* cashOnlyAccountInfoOlRequestFlow(
  action: actions.CashOnlyAccountInfoOlRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(cashOnlyAccountInfoOlRequestSuccess(resData.data[0]));
  } catch (error) {
    // log(error)
    yield put(cashOnlyAccountInfoOlRequestError(error));
  }
}

function* accountBeneficiaryRequestFlow(
  action: actions.BeneficiaryAccountRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(beneficiaryAccountRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(beneficiaryAccountRequestError(error));
  }
}

function* listCashStatusRequestFlow(
  action: actions.ListCashStatusRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(listCashStatusRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(listCashStatusRequestError(error));
  }
}

function* cashTransferHisRequestFlow(
  action: actions.CashTransferHisRequestAction,
): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(cashTransferHisRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(cashTransferHisRequestError(error));
  }
}

function* accBenAddRequestFlow(action: actions.AccBenAddRequestAction): any {
  try {
    const { params } = action;
    const resData = yield call(summaryApi, params);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(accBenAddSuccess(Math.random()));
  } catch (error) {
    // log(error)
    yield put(accBenAddError(error));
  }
}

function* detailDebtsRequestFlow(
  action: actions.DetailDebtRequestingAction,
): any {
  try {
    const { data } = action;
    const _res: any = yield call(summaryApi, data);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }

    yield put(detailDebtSuccess(_res.data[0]));
  } catch (error: any) {
    yield put(detailDebtError(error));
  }
}

function* sumaryWatcher() {
  yield all([
    takeLatest(actions.SUMMARY_CASH_REQUESTING, summaryCashRequestFlow),
    takeLatest(actions.SUMMARY_STOCK_REQUESTING, summaryStockRequestFlow),
    takeLatest(actions.SUMMARY_SHARE_EARN_REQUESTING, shareEarnRequestFlow),

    takeLatest(actions.CASH_CAN_ADV_REQUESTING, cashCanAdvRequestFlow),
    takeLatest(actions.FEE_ADV_WITHDRAW_REQUESTING, cashFeeAdvRequestFlow),
    takeLatest(actions.ADV_STATUS_REQUESTING, advStatusRequestFlow),
    takeLatest(actions.ADV_STATUS_HIS_REQUESTING, advStatusHisRequestFlow),
    takeLatest(actions.ACC_BEN_ADD_REQUESTING, accBenAddRequestFlow),
    takeLatest(
      actions.LIST_SHARE_BALANCE_REQUESTING,
      listShareBalanceRequestFlow,
    ),
    takeLatest(
      actions.SHARE_TRANSFER_STATUS_REQUESTING,
      listShareStatusRequestFlow,
    ),
    takeLatest(
      actions.SHARE_TRANSFER_HIS_REQUESTING,
      shareTransferHisRequestFlow,
    ),

    takeLatest(
      actions.CASH_ACCOUNT_INFO_REQUESTING,
      cashAccountInfoRequestFlow,
    ),

    takeLatest(
      actions.CASH_ONLY_ACCOUNT_INFO_REQUESTING,
      cashOnlyAccountInfoRequestFlow,
    ),

    takeLatest(
      actions.CASH_ACCOUNT_INFO_OL_REQUESTING,
      cashAccountInfoOlRequestFlow,
    ),

    takeLatest(
      actions.CASH_ONLY_ACCOUNT_INFO_OL_REQUESTING,
      cashOnlyAccountInfoOlRequestFlow,
    ),

    takeLatest(
      actions.ACCOUNT_BENEFICIARY_REQUESTING,
      accountBeneficiaryRequestFlow,
    ),

    takeLatest(
      actions.CASH_TRANSFER_STATUS_REQUESTING,
      listCashStatusRequestFlow,
    ),
    takeLatest(
      actions.CASH_TRANSFER_HIS_REQUESTING,
      cashTransferHisRequestFlow,
    ),
    takeLatest(actions.DETAIL_DEBTS_REQUESTING, detailDebtsRequestFlow),
  ]);
}

export default sumaryWatcher;
