import { clearLogin } from 'containers/login/actions';
import * as actionTypes from 'containers/login/actionType';
import i18n from 'i18next';
import { IUserForgot } from 'interface';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { AppState } from 'reducers';
import {
  Field,
  formValueSelector,
  getFormSyncErrors,
  getFormSyncWarnings,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { BtnClose, BtnSubmit } from 'utils/styledUtils';
import { required } from 'utils/validation';

import { setToast } from 'containers/client/actions';
import ClockOtp from 'helper/img/ClockOtp';
import RenderLoginInput from 'helper/input/renderLoginInput';
import RenderOtp from 'helper/input/renderOTP';
import { handleApiErrors } from 'lib/api-error';
import { checkInvalidOtp, hideMiddleDigits } from 'utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
interface Props {
  account: any;
  onClose: Function;
  setStep: any;
  email?: string;
  errorMsg?: Error | string | null;
  formWarning?: any;
  formErrors?: any;
  newPass?: string;
  confirmPass?: string;
}

const validate = (values: any) => {
  const { newPass, confirmPass } = values;
  const errors: any = {};

  if (!newPass) {
    errors.newPass = i18n.t('valid.requiredPass');
  }
  if (!confirmPass) {
    errors.confirmPass = i18n.t('valid.requiredPass');
  }
  if (values.confirmPass !== values.newPass) {
    errors.confirmPass = i18n.t('valid.validateConfirmPass');
  }
  return errors;
};

const warn = (values: any) => {
  const warns: any = {};
  const { newPass } = values;
  let _arr: string[] = [];

  const _upperChar = new RegExp(
    '(?=.*[A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ])',
  );
  const _upperNum = new RegExp('(?=.*[0-9])');
  const _specialChar = new RegExp('(?=.*[~`!@#$^*])');

  if (!newPass || newPass.length < 8 || newPass.length > 16) {
    _arr.push('valid.passLength');
  }
  if (!_upperChar.test(values.newPass)) {
    _arr.push('valid.passLetter');
  }
  if (!_upperNum.test(values.newPass)) {
    _arr.push('valid.passNumber');
  }
  if (!_specialChar.test(values.newPass)) {
    _arr.push('valid.passCharacter');
  }

  warns.newPass = _arr;
  return warns;
};

const apiUrl = process.env.REACT_APP_API_URL;

const FormForgotPass: React.FunctionComponent<
  InjectedFormProps<IUserForgot> & Props
> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [time, setTime] = React.useState<number>(300); // time giảm dần
  const [totalTime, setTotalTime] = React.useState<number>(300); // Thời gian otp hiệu lực

  const abortController = new AbortController();

  const {
    account,
    onClose,
    setStep,
    handleSubmit,
    formWarning,
    formErrors,
    newPass,
    confirmPass,
  } = props;

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  React.useEffect(() => {
    if (!time) return;
    const intervalId = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [time]);

  function submit(values: IUserForgot) {
    if (!account) return;
    var params = new URLSearchParams();
    params.append('username', account?.username);
    params.append('ss', '');
    params.append('step', '2');
    params.append('otp', values?.otp);
    params.append('p1', account?.method === 'phone' ? account?.phone : '');
    params.append('p2', account?.method === 'email' ? account?.email : '');
    params.append('p3', account?.card);
    params.append('p4', values?.newPass);

    const request = fetch(`${apiUrl}/ForgetPass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: params.toString(),
      signal: abortController.signal,
    });
    handleRequest(request);
  }

  function handleRequest(request: any) {
    return request
      .then(handleApiErrors)
      .then((response: any) => response.json())
      .then((json: any) => {
        if (json.rc > 0) {
          setStep(3);
        } else {
          _handleToast(checkInvalidOtp(json.rs), 'error');
        }
      })
      .catch((error: any) => {
        _handleToast(error, 'error');
      });
  }

  function handleGetOtp() {
    setTime(300);
    getOtpNoSession();
  }

  function getOtpNoSession() {
    if (!account) return;
    var params = new URLSearchParams();
    params.append('username', '123456');
    params.append('ss', '');
    params.append('step', '1');
    params.append('otp', '');
    params.append('p1', account?.method === 'phone' ? account?.phone : '');
    params.append('p2', account?.method === 'email' ? account?.email : '');
    params.append('p3', account?.card);
    params.append('p4', '');

    const request = fetch(`${apiUrl}/ForgetPass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: params.toString(),
      signal: abortController.signal,
    });
    handleRequestCheckAccount(request);
  }

  function handleRequestCheckAccount(request: any) {
    return request
      .then(handleApiErrors)
      .then((response: any) => response.json())
      .then((json: any) => {
        if (json.rc < 1) {
          _handleToast(json.rs, 'error');
        }
      })
      .catch((error: any) => {
        _handleToast(error, 'error');
      });
  }

  function _handleToast(msg: string, type = 'info') {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: 'Thông báo',
      type,
    };
    dispatch(setToast(toastMsg));
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="grid gap-4">
      <div className="font-briGro flex justify-between items-center">
        <span className="text-[32px] font-semibold">
          {t('forgot.create-pass')}
        </span>
        <span className="text-skin-a text-base">{t('forgot.help')}</span>
      </div>
      <div>
        <div className="mb-2">{t('forgot.new-pass')}</div>
        <Field
          name="newPass"
          type="password"
          autoComplete="off"
          placeholder={t('forgot.new-pass')}
          component={RenderLoginInput}
          isTick={true}
        />
      </div>
      <div>
        <div className="mb-2">{t('forgot.accept-pass')}</div>
        <Field
          name="confirmPass"
          type="password"
          placeholder={t('forgot.accept-pass')}
          autoComplete="current-newPass"
          component={RenderLoginInput}
        />
      </div>
      <div className="text-skin-body">
        {t('forgot.send-otp-success1')}{' '}
        <span className="text-skin-active">
          {account?.method === 'phone'
            ? hideMiddleDigits(account?.phone)
            : account?.email}
        </span>
        {t('forgot.send-otp-success2')}
        <span className="text-skin-title"> {t('forgot.2-minute')}</span>
      </div>
      <div className="relative flex justify-center items-center h-[58px]">
        <ClockOtp time={time} totalTime={totalTime} />
      </div>
      <div>
        <div className="mb-2">{t('forgot.otp')}</div>
        <Field name="otp" component={RenderOtp} validate={required} />
      </div>
      <div>
        <span className="text-skin-subdued">
          {time > 0 ? t('forgot.quest-opt-send') : t('forgot.expired-otp-code')}
        </span>{' '}
        <span
          className="text-skin-active cursor-pointer"
          onClick={handleGetOtp}
        >
          {t('forgot.send-to')}
        </span>
      </div>
      <div className="flex">
        <BtnClose
          type="button"
          className="w-[220px] mr-2"
          onClick={() => onClose()}
        >
          {t('button.comeback')}
        </BtnClose>
        <BtnSubmit
          type="submit"
          disabled={
            (formErrors && Object.keys(formErrors).length > 0) ||
            (formWarning.newPass && !!formWarning.newPass.length)
          }
          className="success"
        >
          {t('button.continue')}
        </BtnSubmit>
      </div>
    </form>
  );
};

const _FormForgotPass = reduxForm<IUserForgot, Props>({
  form: 'formForgotPass2',
  enableReinitialize: true,
  validate,
  warn,
})(FormForgotPass as any);

const selector = formValueSelector('formForgotPass2');

const makeMapStateToProps = () => {
  const mapStateToProps = (state: AppState) => {
    const { newPass, confirmPass, otp } = selector(
      state,
      'newPass',
      'confirmPass',
      'otp',
    );

    return {
      errorMsg: state.error[actionTypes.PAGE_LOGIN],
      formErrors: getFormSyncErrors('formForgotPass2')(state),
      formWarning: getFormSyncWarnings('formForgotPass2')(state),

      newPass,
      confirmPass,
      otp,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormForgotPass);
