import { FunctionComponent } from 'react';
import { ReactComponent as IconTimes } from 'assets/img/icon/ic-time.svg';

interface Props {
  time: number;
  totalTime: number;
}

const RenderImgClockOtp: FunctionComponent<Props> = (props) => {
  const { time, totalTime } = props;

  return (
    <div className="relative">
      <IconTimes />
      <div className="absolute bottom-0">
        <p
          className="font-bold"
          style={{
            background: `linear-gradient(106.46deg, #FFFFFF 31.54%, rgba(78, 78, 78, 0.4) 124.03%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            color: 'transparent',
          }}
        >
          {`${Math.floor(time / 60)}`.padStart(2, '0')}:
          {`${time % 60}`.padStart(2, '0')}
        </p>
      </div>
      <svg
        width="66px"
        height="66px"
        className="absolute -top-3 -left-3"
        style={{
          transform: 'rotate(-92deg)',
        }}
      >
        <circle
          cx="33" // 60.91px / 2
          cy="33" // 58px / 2
          r="27.5" // 29px - 1.5px (stroke width)
          stroke="var(--color-neutral-400)"
          strokeWidth="2"
          fill="none"
        />
        <circle
          cx="33" // 60.91px / 2
          cy="33" // 58px / 2
          r="27.5" // 29px - 1.5px (stroke width)
          strokeWidth="7"
          strokeLinecap="round"
          stroke="var(--color-surface-soild-green)"
          fill="none"
          strokeDasharray="0.5, 172.287" // Length of the dash and gap
          strokeDashoffset={(time / totalTime) * (2 * Math.PI * 27.5)}
          style={{
            transition: 'stroke-dashoffset 1s linear',
            animation: 'dashOffset 2s linear infinite',
          }}
        />
        <circle
          cx="33" // 60.91px / 2
          cy="33" // 58px / 2
          r="27.5" // 29px - 1.5px (stroke width)
          strokeWidth="2"
          stroke="var(--color-surface-soild-green)"
          fill="none"
          strokeDasharray="172.787" // 2 * Math.PI * 27.5
          strokeDashoffset={(time / totalTime) * (2 * Math.PI * 27.5)}
          style={{ transition: 'stroke-dashoffset 1s linear' }}
        />
      </svg>
    </div>
  );
};

export default RenderImgClockOtp;
