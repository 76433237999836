import { numberFormat } from 'utils';

interface IMarket {
  [key: string | number]: string;
}

export const MARKET: IMarket = {
  10: 'VN-Index',
  11: 'VN30-Index',
  '02': 'HNX-Index',
  12: 'HNX30-Index',
  '03': 'UPCOM-Index',
};

export function _getClassIndex(cPrice: number, oPrice: number) {
  if (cPrice - oPrice > 0) {
    return 'i';
  } else if (cPrice - oPrice < 0) {
    return 'd';
  }
  return 'r';
}

export const processMapData = {
  _processMapDataCS: (item: any) => {
    let symbol: any = {};
    symbol.sym = item.sym;
    symbol.mc = item.mc;
    symbol.c = item.c;
    symbol.f = item.f;
    symbol.r = item.r;
    symbol.lastPrice = item.lastPrice;
    symbol.lastVolume = item.lastVolume;
    symbol.status_info = item.status_info;
    symbol.matchCL = item.cl;
    symbol.lot = item.lot;
    symbol.change = item.ot;
    if (item.changePc) {
      symbol.changePc = item.changePc;
    } else {
      symbol.changePc = numberFormat(
        ((item.lastPrice - item.r) / item.r) * 100,
        2,
      );
    }
    symbol.avePrice = item.avePrice;
    symbol.highPrice = item.highPrice;
    symbol.lowPrice = item.lowPrice;
    symbol.fBVol = item.fBVol;
    symbol.fSVolume = item.fSVolume;
    symbol.fRoom = item.fRoom;
    let g1 = item.g1.split('|');
    symbol.bP1 = g1[0];
    symbol.bV1 = g1[1];
    symbol.bCl1 = g1[0] === 'ATC' || g1[0] === 'ATO' ? '' : g1[2];
    symbol.bVC1 = symbol.bCl1;
    let g2 = item.g2.split('|');
    symbol.bP2 = g2[0];
    symbol.bV2 = g2[1];
    symbol.bCl2 = g2[2];
    symbol.bVC2 = symbol.bCl2;
    let g3 = item.g3.split('|');
    symbol.bP3 = g3[0];
    symbol.bV3 = g3[1];
    symbol.bCl3 = g3[2];
    symbol.bVC3 = symbol.bCl3;
    let g4 = item.g4.split('|');
    symbol.oP1 = g4[0];
    symbol.oV1 = g4[1];
    symbol.oCl1 = g4[0] === 'ATC' || g4[0] === 'ATO' ? '' : g4[2];
    symbol.oVC1 = symbol.oCl1;
    let g5 = item.g5.split('|');
    symbol.oP2 = g5[0];
    symbol.oV2 = g5[1];
    symbol.oCl2 = g5[2];
    symbol.oVC2 = symbol.oCl2;
    let g6 = item.g6.split('|');
    symbol.oP3 = g6[0];
    symbol.oV3 = g6[1];
    symbol.oCl3 = g6[2];
    symbol.oVC3 = symbol.oCl3;
    let g7 = item.g7.split('|');
    symbol.duMua = g7[0];
    symbol.duBan = g7[1];
    symbol.mr = item.mr;

    return symbol;
  },
  _processMapDataPS: (item: any) => {
    let symbol: any = {};
    symbol.sym = item.sym;
    symbol.matureDate = item.matureDate;
    symbol.mc = item.mc;
    symbol.c = item.c;
    symbol.f = item.f;
    symbol.r = item.r;
    symbol.lastPrice = item.lastPrice;
    symbol.lastVolume = item.lastVolume;
    symbol.lot = item.lot;
    symbol.oi = item.oi;
    symbol.change = item.lastPrice - item.r;
    symbol.avePrice = item.avePrice;
    symbol.highPrice = item.highPrice;
    symbol.lowPrice = item.lowPrice;
    symbol.fBVol = item.fBVol;
    symbol.fSVolume = item.fSVolume;
    let g1 = item.g1.split('|');
    symbol.bP1 = g1[0];
    symbol.bV1 = g1[1];
    symbol.bCl1 = g1[0] === 'ATC' || g1[0] === 'ATO' ? '' : g1[2];
    symbol.bVC1 = symbol.bCl1;
    let g2 = item.g2.split('|');
    symbol.bP2 = g2[0];
    symbol.bV2 = g2[1];
    symbol.bCl2 = g2[2];
    symbol.bVC2 = symbol.bCl2;
    let g3 = item.g3.split('|');
    symbol.bP3 = g3[0];
    symbol.bV3 = g3[1];
    symbol.bCl3 = g3[2];
    symbol.bVC3 = symbol.bCl3;
    let g4 = item.g4.split('|');
    symbol.oP1 = g4[0];
    symbol.oV1 = g4[1];
    symbol.oCl1 = g4[0] === 'ATC' || g4[0] === 'ATO' ? '' : g4[2];
    symbol.oVC1 = symbol.oCl1;
    let g5 = item.g5.split('|');
    symbol.oP2 = g5[0];
    symbol.oV2 = g5[1];
    symbol.oCl2 = g5[2];
    symbol.oVC2 = symbol.oCl2;
    let g6 = item.g6.split('|');
    symbol.oP3 = g6[0];
    symbol.oV3 = g6[1];
    symbol.oCl3 = g6[2];
    symbol.oVC3 = symbol.oCl3;
    // let g7 = item.g7.split('|');
    // symbol.duMua = g7[0];
    // symbol.duBan = g7[1];
    // log(symbol);
    return symbol;
  },
  _processMapDataIndex: (element: any) => {
    let index: any = {};
    index.mc = element.mc;
    index.name = MARKET[element.mc];
    index.cIndex = element.cIndex;
    index.oIndex = element.oIndex;
    index.vol = element.vol;
    index.value = element.value;
    index.time = element.time;
    index.status = element.status;
    let dt = element.ot.split('|');
    index.change = dt[0];
    index.changePc = dt[1];
    index.up = dt[3];
    index.down = dt[4];
    index.rel = dt[5];
    index.cl = _getClassIndex(element.cIndex, element.oIndex);
    return index;
  },
};
