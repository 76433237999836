import { handleApiErrors } from 'lib/api-error';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { storages } from 'lib/storages';
import { checkInvalidSession, getMsgByErrorCode } from 'utils';
import { processMapData } from 'utils/processMapData';
import {
  advanceOrderRequestError,
  advanceOrderRequestSuccess,
  cancelOrderRequestError,
  cancelOrderRequestSuccess,
  cashBalanceRequestError,
  cashBalanceRequestSuccess,
  condOrderRequestError,
  condOrderRequestSuccess,
  editOrderRequestError,
  editOrderRequestSuccess,
  listOrderConfirmError,
  listOrderConfirmSuccess,
  matchOrderRequestError,
  matchOrderRequestSuccess,
  newOrderRequestError,
  newOrderRequestSuccess,
  positionsRequestError,
  positionsRequestSuccess,
  shareBalanceRequestError,
  shareBalanceRequestSuccess,
  sStockInfoRequestError,
  sStockInfoRequestSuccess,
  stockBalanceRequestError,
  stockBalanceRequestSuccess,
  symbolInfoRequestError,
  symbolInfoRequestSuccess,
} from './actions';
import * as actions from './actionType';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

const apiUrl = `${process.env.REACT_APP_API_URL}`;

async function postRequestApi(data: any) {
  const url = `${apiUrl}/TraditionalService`;
  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* cashBalanceRequestFlow(
  action: actions.CashBalanceRequestAction,
): any {
  // console.log(action)
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(cashBalanceRequestSuccess(resData.data));
  } catch (error) {
    yield put(cashBalanceRequestError(error));
  }
}

function* shareBalanceRequestFlow(
  action: actions.ShareBalanceRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(shareBalanceRequestSuccess(resData));
  } catch (error) {
    yield put(shareBalanceRequestError(error));
  }
}

function* stockBalanceRequestFlow(
  action: actions.StockBalanceRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(stockBalanceRequestSuccess(resData.data));
  } catch (error) {
    yield put(stockBalanceRequestError(error));
  }
}

function* positionsRequestFlow(action: actions.PositionsRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(positionsRequestSuccess(resData.data));
  } catch (error) {
    yield put(positionsRequestError(error));
  }
}

function* sStockInfoRequestFlow(action: actions.SStockInfoRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(
      sStockInfoRequestSuccess(processMapData._processMapDataCS(resData.data)),
    );
  } catch (error) {
    yield put(sStockInfoRequestError(error));
  }
}

function* newOrderRequestFlow(action: actions.NewOrderRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(newOrderRequestSuccess(resData));
  } catch (error) {
    yield put(newOrderRequestError(error));
  }
}

function* editOrderRequestFlow(action: actions.EditOrderRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(editOrderRequestSuccess(resData));
  } catch (error) {
    yield put(editOrderRequestError(error));
  }
}

function* cancelOrderRequestFlow(
  action: actions.CancelOrderRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(cancelOrderRequestSuccess(resData));
  } catch (error) {
    yield put(cancelOrderRequestError(error));
  }
}

function* symbolInfoRequestFlow(action: actions.SymbolInfoRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(symbolInfoRequestSuccess(resData));
  } catch (error) {
    yield put(symbolInfoRequestError(error));
  }
}

function* matchOrderRequestFlow(action: actions.MatchOrderRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(matchOrderRequestSuccess(resData));
  } catch (error) {
    yield put(matchOrderRequestError(error));
  }
}

function* listOrderConfirmRequestFlow(
  action: actions.ListOrderConfirmRequestAction,
): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(listOrderConfirmSuccess(resData.data));
  } catch (error) {
    yield put(listOrderConfirmError(error));
  }
}

function* advOrderRequestFlow(action: actions.AdvanceOrderRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(advanceOrderRequestSuccess(resData.data));
  } catch (error) {
    yield put(advanceOrderRequestError(error));
  }
}

function* condOrderRequestFlow(action: actions.CondOrderRequestAction): any {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        storages.removeState('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }
    yield put(condOrderRequestSuccess(resData.data));
  } catch (error) {
    yield put(condOrderRequestError(error));
  }
}

function* privDataWatcher() {
  yield all([
    takeLatest(actions.CASH_BALANCE_REQUESTING, cashBalanceRequestFlow),
    takeLatest(actions.POSITION_REQUESTING, positionsRequestFlow),
    takeLatest(actions.SSTOCK_INFO_REQUESTING, sStockInfoRequestFlow),

    takeLatest(actions.STOCK_BALANCE_REQUESTING, stockBalanceRequestFlow),
    takeEvery(actions.NEW_ORDER_REQUESTING, newOrderRequestFlow),
    takeEvery(actions.ADVANCE_ORDER_REQUESTING, advOrderRequestFlow),
    takeEvery(actions.COND_ORDER_REQUESTING, condOrderRequestFlow),

    takeLatest(actions.SHARE_BALANCE_REQUESTING, shareBalanceRequestFlow),

    takeLatest(actions.EDIT_ORDER_REQUESTING, editOrderRequestFlow),
    takeLatest(actions.CANCEL_ORDER_REQUESTING, cancelOrderRequestFlow),
    takeLatest(actions.SYMBOL_INFO_REQUESTING, symbolInfoRequestFlow),
    takeLatest(actions.MATCH_ORDER_REQUESTING, matchOrderRequestFlow),
    takeLatest(
      actions.LIST_ORDER_CONFIRM_REQUESTING,
      listOrderConfirmRequestFlow,
    ),
  ]);
}

export default privDataWatcher;
