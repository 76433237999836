import { ParamModel } from 'interface';
import * as actions from './actionType';

/** danh sách nguồn */
export function notiPersonalRequest(
  data: any,
): actions.NotiPersonalRequestingAction {
  return {
    type: actions.NOTI_PERSONAL_REQUESTING,
    data,
  };
}

export function notiPersonalSuccess(
  resData: any,
): actions.NotiPersonalSuccessAction {
  return {
    type: actions.NOTI_PERSONAL_SUCCESS,
    resData,
  };
}

export function notiPersonalError(
  error: Error | string,
): actions.NotiPersonalErrorAction {
  return {
    type: actions.NOTI_PERSONAL_ERROR,
    error,
  };
}
export function notiMarkReadRequest(
  data: any,
): actions.NotiMarkReadRequestingAction {
  return {
    type: actions.NOTI_MARK_READ_REQUESTING,
    data,
  };
}

export function notiMarkReadSuccess(
  resData: any,
): actions.NotiMarkReadSuccessAction {
  return {
    type: actions.NOTI_MARK_READ_SUCCESS,
    resData,
  };
}

export function notiMarkReadError(
  error: Error | string,
): actions.NotiMarkReadErrorAction {
  return {
    type: actions.NOTI_MARK_READ_ERROR,
    error,
  };
}

export function notiMarkAllReadRequest(
  data: any,
): actions.NotiMarkAllReadRequestingAction {
  return {
    type: actions.NOTI_MARK_ALL_READ_REQUESTING,
    data,
  };
}

export function notiMarkAllReadSuccess(
  resData: any,
): actions.NotiMarkAllReadSuccessAction {
  return {
    type: actions.NOTI_MARK_ALL_READ_SUCCESS,
    resData,
  };
}

export function notiMarkAllReadError(
  error: Error | string,
): actions.NotiMarkAllReadErrorAction {
  return {
    type: actions.NOTI_MARK_ALL_READ_ERROR,
    error,
  };
}

export function notiMarkAllReadPriRequest(
  data: any,
): actions.NotiMarkAllReadPriRequestingAction {
  return {
    type: actions.NOTI_MARK_ALL_READ_PRI_REQUESTING,
    data,
  };
}

export function notiMarkAllReadPriSuccess(
  resData: any,
): actions.NotiMarkAllReadPriSuccessAction {
  return {
    type: actions.NOTI_MARK_ALL_READ_PRI_SUCCESS,
    resData,
  };
}

export function notiMarkAllReadPriError(
  error: Error | string,
): actions.NotiMarkAllReadPriErrorAction {
  return {
    type: actions.NOTI_MARK_ALL_READ_PRI_ERROR,
    error,
  };
}

export function notiPublicRequest(
  data: any,
): actions.NotiPublicRequestingAction {
  return {
    type: actions.NOTI_PUBLIC_REQUESTING,
    data,
  };
}

export function notiPublicSuccess(
  resData: any,
): actions.NotiPublicSuccessAction {
  return {
    type: actions.NOTI_PUBLIC_SUCCESS,
    resData,
  };
}

export function notiPublicError(
  error: Error | string,
): actions.NotiPublicErrorAction {
  return {
    type: actions.NOTI_PUBLIC_ERROR,
    error,
  };
}
