import { ParamModel } from 'interface';
import * as actions from './actionType';

export const setCreateNickname = function setToast(
  resData: any,
): actions.SetCreateNicknamAction {
  return {
    type: actions.SHOW_CREATE_NICKNAME,
    resData,
  };
};

/** danh sách nguồn */
export function accInfoRequest(data: any): actions.AccInfoRequestingAction {
  return {
    type: actions.ACC_INFO_REQUESTING,
    data,
  };
}

export function accInfoSuccess(resData: any): actions.AccInfoSuccessAction {
  return {
    type: actions.ACC_INFO_SUCCESS,
    resData,
  };
}

export function accInfoError(
  error: Error | string,
): actions.AccInfoErrorAction {
  return {
    type: actions.ACC_INFO_ERROR,
    error,
  };
}

// ----UPD thông tin kh ----
export function accUpdInfoRequest(
  data: any,
): actions.AccUpdInfoRequestingAction {
  return {
    type: actions.ACC_UPD_INFO_REQUESTING,
    data,
  };
}

export function accUpdInfoSuccess(
  resData: any,
): actions.AccUpdInfoSuccessAction {
  return {
    type: actions.ACC_UPD_INFO_REQUEST_SUCCESS,
    resData,
  };
}

export function accUpdInfoError(
  error: Error | string,
): actions.AccUpdInfoErrorAction {
  return {
    type: actions.ACC_UPD_INFO_REQUEST_ERROR,
    error,
  };
}

/** duyệt nghiệp vụ tiển */
export function accCashApprRequest(
  data: any,
): actions.AccCashApprRequestingAction {
  return {
    type: actions.ACC_CASH_APPR_REQUESTING,
    data,
  };
}

export function accCashApprSuccess(
  resData: any,
): actions.AccCashApprSuccessAction {
  return {
    type: actions.ACC_CASH_APPR_SUCCESS,
    resData,
  };
}

export function accCashApprError(
  error: Error | string,
): actions.AccCashApprErrorAction {
  return {
    type: actions.ACC_CASH_APPR_ERROR,
    error,
  };
}

/** thêm mới tài khoản thụ hưởng */
export function accBenUpdRequest(data: any): actions.AccBenUpdRequestingAction {
  return {
    type: actions.ACC_BEN_UPD_REQUESTING,
    data,
  };
}

export function accBenUpdSuccess(resData: any): actions.AccBenUpdSuccessAction {
  return {
    type: actions.ACC_BEN_UPD_SUCCESS,
    resData,
  };
}

export function accBenUpdError(
  error: Error | string,
): actions.AccBenUpdErrorAction {
  return {
    type: actions.ACC_BEN_UPD_ERROR,
    error,
  };
}

/** danh sách tài khoản thụ hưởng */
export function accBenListRequest(
  data: any,
): actions.AccBenListRequestingAction {
  return {
    type: actions.ACC_BEN_LIST_REQUESTING,
    data,
  };
}

export function accBenListSuccess(
  resData: any,
): actions.AccBenListSuccessAction {
  return {
    type: actions.ACC_BEN_LIST_SUCCESS,
    resData,
  };
}

export function accBenListError(
  error: Error | string,
): actions.AccBenListErrorAction {
  return {
    type: actions.ACC_BEN_LIST_ERROR,
    error,
  };
}

/**xóa tài khoản thụ hưởng  */
export function accBenDelRequest(data: any): actions.AccBenDelRequestingAction {
  return {
    type: actions.ACC_BEN_DEL_REQUESTING,
    data,
  };
}

export function accBenDelSuccess(resData: any): actions.AccBenDelSuccessAction {
  return {
    type: actions.ACC_BEN_DEL_SUCCESS,
    resData,
  };
}

export function accBenDelError(
  error: Error | string,
): actions.AccBenDelErrorAction {
  return {
    type: actions.ACC_BEN_DEL_ERROR,
    error,
  };
}

/**set tk thụ hưởng mặc định */
export function accBenDefRequest(data: any): actions.AccBenDefRequestingAction {
  return {
    type: actions.ACC_BEN_DEF_REQUESTING,
    data,
  };
}

export function accBenDefSuccess(resData: any): actions.AccBenDefSuccessAction {
  return {
    type: actions.ACC_BEN_DEF_SUCCESS,
    resData,
  };
}

export function accBenDefError(
  error: Error | string,
): actions.AccBenDefErrorAction {
  return {
    type: actions.ACC_BEN_DEF_ERROR,
    error,
  };
}

/** Lấy danh sách thông tin mô giới */
export function accSaleInforListRequest(
  data: any,
): actions.AccSaleInforListRequestingAction {
  return {
    type: actions.ACC_SALE_INFOR_LIST_REQUESTING,
    data,
  };
}

export function accSaleInforListSuccess(
  resData: any,
): actions.AccSaleInforListSuccessAction {
  return {
    type: actions.ACC_SALE_INFOR_LIST_SUCCESS,
    resData,
  };
}

export function accSaleInforListError(
  error: Error | string,
): actions.AccSaleInforListErrorAction {
  return {
    type: actions.ACC_SALE_INFOR_LIST_ERROR,
    error,
  };
}

/** Lấy thông tin mô giới */
export function accSaleInforRequest(
  data: any,
): actions.AccSaleInforRequestingAction {
  return {
    type: actions.ACC_SALE_INFOR_REQUESTING,
    data,
  };
}

export function accSaleInforSuccess(
  resData: any,
): actions.AccSaleInforSuccessAction {
  return {
    type: actions.ACC_SALE_INFOR_SUCCESS,
    resData,
  };
}

export function accSaleInforError(
  error: Error | string,
): actions.AccSaleInforErrorAction {
  return {
    type: actions.ACC_SALE_INFOR_ERROR,
    error,
  };
}
/**Change sale id */
export function accSaleInforUpdRequest(
  data: any,
): actions.AccSaleInforUpdRequestingAction {
  return {
    type: actions.ACC_SALE_INFOR_UPD_REQUESTING,
    data,
  };
}

export function accSaleInforUpdSuccess(
  resData: any,
): actions.AccSaleInforUpdSuccessAction {
  return {
    type: actions.ACC_SALE_INFOR_UPD_SUCCESS,
    resData,
  };
}

export function accSaleInforUpdError(
  error: Error | string,
): actions.AccSaleInforUpdErrorAction {
  return {
    type: actions.ACC_SALE_INFOR_UPD_ERROR,
    error,
  };
}

/**Quản lý thiết bị đăng nhập */
export function manLoginDevRequest(
  data: any,
): actions.ManLoginDevRequestingAction {
  return {
    type: actions.MAN_LOGIN_DEV_REQUESTING,
    data,
  };
}

export function manLoginDevSuccess(
  resData: any,
): actions.ManLoginDevSuccessAction {
  return {
    type: actions.MAN_LOGIN_DEV_SUCCESS,
    resData,
  };
}

export function manLoginDevError(
  error: Error | string,
): actions.ManLoginDevErrorAction {
  return {
    type: actions.MAN_LOGIN_DEV_ERROR,
    error,
  };
}
