export const NOTI_PERSONAL_REQUESTING = 'noti/PERSONAL_REQUESTING';
export interface NotiPersonalRequestingAction {
  type: typeof NOTI_PERSONAL_REQUESTING;
  data: any;
}

export const NOTI_PERSONAL_SUCCESS = 'noti/PERSONAL_SUCCESS';
export interface NotiPersonalSuccessAction {
  type: typeof NOTI_PERSONAL_SUCCESS;
  resData: any;
}

export const NOTI_PERSONAL_ERROR = 'noti/PERSONAL_ERROR';
export interface NotiPersonalErrorAction {
  type: typeof NOTI_PERSONAL_ERROR;
  error: any;
}

export const NOTI_MARK_READ_REQUESTING = 'noti/MARK_READ_REQUESTING';
export interface NotiMarkReadRequestingAction {
  type: typeof NOTI_MARK_READ_REQUESTING;
  data: any;
}

export const NOTI_MARK_READ_SUCCESS = 'noti/MARK_READ_SUCCESS';
export interface NotiMarkReadSuccessAction {
  type: typeof NOTI_MARK_READ_SUCCESS;
  resData: any;
}

export const NOTI_MARK_READ_ERROR = 'noti/MARK_READ_ERROR';
export interface NotiMarkReadErrorAction {
  type: typeof NOTI_MARK_READ_ERROR;
  error: any;
}

export const NOTI_MARK_ALL_READ_REQUESTING = 'noti/MARK_ALL_READ_REQUESTING';
export interface NotiMarkAllReadRequestingAction {
  type: typeof NOTI_MARK_ALL_READ_REQUESTING;
  data: any;
}

export const NOTI_MARK_ALL_READ_SUCCESS = 'noti/MARK_ALL_READ_SUCCESS';
export interface NotiMarkAllReadSuccessAction {
  type: typeof NOTI_MARK_ALL_READ_SUCCESS;
  resData: any;
}

export const NOTI_MARK_ALL_READ_ERROR = 'noti/MARK_ALL_READ_ERROR';
export interface NotiMarkAllReadErrorAction {
  type: typeof NOTI_MARK_ALL_READ_ERROR;
  error: any;
}

export const NOTI_MARK_ALL_READ_PRI_REQUESTING =
  'noti/MARK_ALL_READ_PRI_REQUESTING';
export interface NotiMarkAllReadPriRequestingAction {
  type: typeof NOTI_MARK_ALL_READ_PRI_REQUESTING;
  data: any;
}

export const NOTI_MARK_ALL_READ_PRI_SUCCESS = 'noti/MARK_ALL_READ_PRI_SUCCESS';
export interface NotiMarkAllReadPriSuccessAction {
  type: typeof NOTI_MARK_ALL_READ_PRI_SUCCESS;
  resData: any;
}

export const NOTI_MARK_ALL_READ_PRI_ERROR = 'noti/MARK_ALL_READ_PRI_ERROR';
export interface NotiMarkAllReadPriErrorAction {
  type: typeof NOTI_MARK_ALL_READ_PRI_ERROR;
  error: any;
}

export const NOTI_PUBLIC_REQUESTING = 'noti/PUBLIC_REQUESTING';
export interface NotiPublicRequestingAction {
  type: typeof NOTI_PUBLIC_REQUESTING;
  data: any;
}

export const NOTI_PUBLIC_SUCCESS = 'noti/PUBLIC_SUCCESS';
export interface NotiPublicSuccessAction {
  type: typeof NOTI_PUBLIC_SUCCESS;
  resData: any;
}

export const NOTI_PUBLIC_ERROR = 'noti/PUBLIC_ERROR';
export interface NotiPublicErrorAction {
  type: typeof NOTI_PUBLIC_ERROR;
  error: any;
}

export type NotiAction =
  | NotiPersonalRequestingAction
  | NotiPersonalSuccessAction
  | NotiPersonalErrorAction
  | NotiMarkReadRequestingAction
  | NotiMarkReadSuccessAction
  | NotiMarkReadErrorAction
  | NotiMarkAllReadRequestingAction
  | NotiMarkAllReadSuccessAction
  | NotiMarkAllReadErrorAction
  | NotiMarkAllReadPriRequestingAction
  | NotiMarkAllReadPriSuccessAction
  | NotiMarkAllReadPriErrorAction
  | NotiPublicRequestingAction
  | NotiPublicSuccessAction
  | NotiPublicErrorAction;
