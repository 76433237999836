/** Giải ngân/ thu nợ */
export const REPORT_001 = 'rp/001';
export interface Report001Action {
  type: typeof REPORT_001;
}

export const REPORT_001_REQUESTING = 'rp/001_REQUESTING';
export interface Report001RequestingAction {
  type: typeof REPORT_001_REQUESTING;
  data: any;
}

export const REPORT_001_SUCCESS = 'rp/001_SUCCESS';
export interface Report001SuccessAction {
  type: typeof REPORT_001_SUCCESS;
  resData: any;
}

export const REPORT_001_ERROR = 'rp/001_ERROR';
export interface Report001ErrorAction {
  type: typeof REPORT_001_ERROR;
  error: any;
}

/** Dư nợ theo tài khoản */
export const REPORT_002 = 'rp/002';
export interface Report002Action {
  type: typeof REPORT_002;
}

export const REPORT_002_REQUESTING = 'rp/002_REQUESTING';
export interface Report002RequestingAction {
  type: typeof REPORT_002_REQUESTING;
  data: any;
}

export const REPORT_002_SUCCESS = 'rp/002_SUCCESS';
export interface Report002SuccessAction {
  type: typeof REPORT_002_SUCCESS;
  resData: any;
}

export const REPORT_002_ERROR = 'rp/002_ERROR';
export interface Report002ErrorAction {
  type: typeof REPORT_002_ERROR;
  error: any;
}

/** Chi tiết dư nợ */
export const REPORT_003 = 'rp/003';
export interface Report003Action {
  type: typeof REPORT_003;
}

export const REPORT_003_REQUESTING = 'rp/003_REQUESTING';
export interface Report003RequestingAction {
  type: typeof REPORT_003_REQUESTING;
  data: any;
}

export const REPORT_003_SUCCESS = 'rp/003_SUCCESS';
export interface Report003SuccessAction {
  type: typeof REPORT_003_SUCCESS;
  resData: any;
}

export const REPORT_003_ERROR = 'rp/003_ERROR';
export interface Report003ErrorAction {
  type: typeof REPORT_003_ERROR;
  error: any;
}

/** Dư nợ đến hạn */
export const REPORT_004 = 'rp/004';
export interface Report004Action {
  type: typeof REPORT_004;
}

export const REPORT_004_REQUESTING = 'rp/004_REQUESTING';
export interface Report004RequestingAction {
  type: typeof REPORT_004_REQUESTING;
  data: any;
}

export const REPORT_004_SUCCESS = 'rp/004_SUCCESS';
export interface Report004SuccessAction {
  type: typeof REPORT_004_SUCCESS;
  resData: any;
}

export const REPORT_004_ERROR = 'rp/004_ERROR';
export interface Report004ErrorAction {
  type: typeof REPORT_004_ERROR;
  error: any;
}

/** Sao kê nợ */
export const REPORT_005 = 'rp/005';
export interface Report005Action {
  type: typeof REPORT_005;
}

export const REPORT_005_REQUESTING = 'rp/005_REQUESTING';
export interface Report005RequestingAction {
  type: typeof REPORT_005_REQUESTING;
  data: any;
}

export const REPORT_005_SUCCESS = 'rp/005_SUCCESS';
export interface Report005SuccessAction {
  type: typeof REPORT_005_SUCCESS;
  resData: any;
}

export const REPORT_005_ERROR = 'rp/005_ERROR';
export interface Report005ErrorAction {
  type: typeof REPORT_005_ERROR;
  error: any;
}

/** Lịch sử đăng ký gói */
export const REPORT_006 = 'rp/006';
export interface Report006Action {
  type: typeof REPORT_006;
}

export const REPORT_006_REQUESTING = 'rp/006_REQUESTING';
export interface Report006RequestingAction {
  type: typeof REPORT_006_REQUESTING;
  data: any;
}

export const REPORT_006_SUCCESS = 'rp/006_SUCCESS';
export interface Report006SuccessAction {
  type: typeof REPORT_006_SUCCESS;
  resData: any;
}

export const REPORT_006_ERROR = 'rp/006_ERROR';
export interface Report006ErrorAction {
  type: typeof REPORT_006_ERROR;
  error: any;
}

/** lịch sử khớp lệnh */
export const REPORT_007 = 'rp/007';
export interface Report007Action {
  type: typeof REPORT_007;
}

export const REPORT_007_REQUESTING = 'rp/007_REQUESTING';
export interface Report007RequestingAction {
  type: typeof REPORT_007_REQUESTING;
  data: any;
}

export const REPORT_007_SUCCESS = 'rp/007_SUCCESS';
export interface Report007SuccessAction {
  type: typeof REPORT_007_SUCCESS;
  resData: any;
}

export const REPORT_007_ERROR = 'rp/007_ERROR';
export interface Report007ErrorAction {
  type: typeof REPORT_007_ERROR;
  error: any;
}

/** báo cáo phí hợp tác */
export const REPORT_008 = 'rp/008';
export interface Report008Action {
  type: typeof REPORT_008;
}

export const REPORT_008_REQUESTING = 'rp/008_REQUESTING';
export interface Report008RequestingAction {
  type: typeof REPORT_008_REQUESTING;
  data: any;
}

export const REPORT_008_SUCCESS = 'rp/008_SUCCESS';
export interface Report008SuccessAction {
  type: typeof REPORT_008_SUCCESS;
  resData: any;
}

export const REPORT_008_ERROR = 'rp/008_ERROR';
export interface Report008ErrorAction {
  type: typeof REPORT_008_ERROR;
  error: any;
}

/** báo cáo phí điện tương tác */
export const REPORT_009 = 'rp/009';
export interface Report009Action {
  type: typeof REPORT_009;
}

export const REPORT_009_REQUESTING = 'rp/009_REQUESTING';
export interface Report009RequestingAction {
  type: typeof REPORT_009_REQUESTING;
  data: any;
}

export const REPORT_009_SUCCESS = 'rp/009_SUCCESS';
export interface Report009SuccessAction {
  type: typeof REPORT_009_SUCCESS;
  resData: any;
}

export const REPORT_009_ERROR = 'rp/009_ERROR';
export interface Report009ErrorAction {
  type: typeof REPORT_009_ERROR;
  error: any;
}

/** báo cáo phí phụ thu */
export const REPORT_010 = 'rp/010';
export interface Report010Action {
  type: typeof REPORT_010;
}

export const REPORT_010_REQUESTING = 'rp/010_REQUESTING';
export interface Report010RequestingAction {
  type: typeof REPORT_010_REQUESTING;
  data: any;
}

export const REPORT_010_SUCCESS = 'rp/010_SUCCESS';
export interface Report010SuccessAction {
  type: typeof REPORT_010_SUCCESS;
  resData: any;
}

export const REPORT_010_ERROR = 'rp/010_ERROR';
export interface Report010ErrorAction {
  type: typeof REPORT_010_ERROR;
  error: any;
}

/** báo cáo thống kê tài sản - dashboard */
export const REPORT_011 = 'rp/011';
export interface Report011Action {
  type: typeof REPORT_011;
}

export const REPORT_011_REQUESTING = 'rp/011_REQUESTING';
export interface Report011RequestingAction {
  type: typeof REPORT_011_REQUESTING;
  data: any;
}

export const REPORT_011_SUCCESS = 'rp/011_SUCCESS';
export interface Report011SuccessAction {
  type: typeof REPORT_011_SUCCESS;
  resData: any;
}

export const REPORT_011_ERROR = 'rp/011_ERROR';
export interface Report011ErrorAction {
  type: typeof REPORT_011_ERROR;
  error: any;
}

/** báo cáo thống kê doanh số - dashboard */
export const REPORT_012 = 'rp/012';
export interface Report012Action {
  type: typeof REPORT_012;
}

export const REPORT_012_REQUESTING = 'rp/012_REQUESTING';
export interface Report012RequestingAction {
  type: typeof REPORT_012_REQUESTING;
  data: any;
}

export const REPORT_012_SUCCESS = 'rp/012_SUCCESS';
export interface Report012SuccessAction {
  type: typeof REPORT_012_SUCCESS;
  resData: any;
}

export const REPORT_012_ERROR = 'rp/012_ERROR';
export interface Report012ErrorAction {
  type: typeof REPORT_012_ERROR;
  error: any;
}

/**Báo cáo sao kê tiền */
export const REPORT_CASH = 'rp/CASH';
export interface ReportCashAction {
  type: typeof REPORT_CASH;
}

export const REPORT_CASH_REQUESTING = 'rp/CASH_REQUESTING';
export interface ReportCashRequestingAction {
  type: typeof REPORT_CASH_REQUESTING;
  data: any;
}

export const REPORT_CASH_SUCCESS = 'rp/CASH_SUCCESS';
export interface ReportCashSuccessAction {
  type: typeof REPORT_CASH_SUCCESS;
  resData: any;
}

export const REPORT_CASH_ERROR = 'rp/CASH_ERROR';
export interface ReportCashErrorAction {
  type: typeof REPORT_CASH_ERROR;
  error: any;
}

/**Báo cáo sao kê chứng khoán */
export const REPORT_STOCK = 'rp/STOCK';
export interface ReportStockAction {
  type: typeof REPORT_STOCK;
}

export const REPORT_STOCK_REQUESTING = 'rp/STOCK_REQUESTING';
export interface ReportStockRequestingAction {
  type: typeof REPORT_STOCK_REQUESTING;
  data: any;
}

export const REPORT_STOCK_SUCCESS = 'rp/STOCK_SUCCESS';
export interface ReportStockSuccessAction {
  type: typeof REPORT_STOCK_SUCCESS;
  resData: any;
}

export const REPORT_STOCK_ERROR = 'rp/STOCK_ERROR';
export interface ReportStockErrorAction {
  type: typeof REPORT_STOCK_ERROR;
  error: any;
}

/**tra cứu nợ --Báo cáo dư nợ*/
export const REPORT_BALANCE_DEBT = 'rp/BALANCE_DEBT';
export interface ReportBalDebtAction {
  type: typeof REPORT_BALANCE_DEBT;
}

export const REPORT_BALANCE_DEBT_REQUESTING = 'rp/BALANCE_DEBT_REQUESTING';
export interface ReportBalDebtRequestingAction {
  type: typeof REPORT_BALANCE_DEBT_REQUESTING;
  data: any;
}

export const REPORT_BALANCE_DEBT_SUCCESS = 'rp/BALANCE_DEBT_SUCCESS';
export interface ReportBalDebtSuccessAction {
  type: typeof REPORT_BALANCE_DEBT_SUCCESS;
  resData: any;
}

export const REPORT_BALANCE_DEBT_ERROR = 'rp/BALANCE_DEBT_ERROR';
export interface ReportBalDebtErrorAction {
  type: typeof REPORT_BALANCE_DEBT_ERROR;
  error: any;
}

/**tra cứu nợ --Báo cáo ứng trước*/
export const REPORT_ADVANCE = 'rp/ADVANCE';
export interface ReportAdvAction {
  type: typeof REPORT_ADVANCE;
}

export const REPORT_ADVANCE_REQUESTING = 'rp/ADVANCE_REQUESTING';
export interface ReportAdvRequestingAction {
  type: typeof REPORT_ADVANCE_REQUESTING;
  data: any;
}

export const REPORT_ADVANCE_SUCCESS = 'rp/ADVANCE_SUCCESS';
export interface ReportAdvSuccessAction {
  type: typeof REPORT_ADVANCE_SUCCESS;
  resData: any;
}

export const REPORT_ADVANCE_ERROR = 'rp/ADVANCE_ERROR';
export interface ReportAdvErrorAction {
  type: typeof REPORT_ADVANCE_ERROR;
  error: any;
}

/**tra cứu nợ --Sao kê nợ*/
export const REPORT_DEBT = 'rp/DEBT';
export interface ReportDebtAction {
  type: typeof REPORT_DEBT;
}

export const REPORT_DEBT_REQUESTING = 'rp/DEBT_REQUESTING';
export interface ReportDebtRequestingAction {
  type: typeof REPORT_DEBT_REQUESTING;
  data: any;
}

export const REPORT_DEBT_SUCCESS = 'rp/DEBT_SUCCESS';
export interface ReportDebtSuccessAction {
  type: typeof REPORT_DEBT_SUCCESS;
  resData: any;
}

export const REPORT_DEBT_ERROR = 'rp/DEBT_ERROR';
export interface ReportDebtErrorAction {
  type: typeof REPORT_DEBT_ERROR;
  error: any;
}

/**Danh sách lịch sử gdcp */
export const REPORT_STOCK_TRAD_HIS = 'rp/STOCK_TRAD_HIS';
export interface ReportStockTradHisAction {
  type: typeof REPORT_STOCK_TRAD_HIS;
}

export const REPORT_STOCK_TRAD_HIS_REQUESTING = 'rp/STOCK_TRAD_HIS_REQUESTING';
export interface ReportStockTradHisRequestingAction {
  type: typeof REPORT_STOCK_TRAD_HIS_REQUESTING;
  data: any;
}

export const REPORT_STOCK_TRAD_HIS_SUCCESS = 'rp/STOCK_TRAD_HIS_SUCCESS';
export interface ReportStockTradHisSuccessAction {
  type: typeof REPORT_STOCK_TRAD_HIS_SUCCESS;
  resData: any;
}

export const REPORT_STOCK_TRAD_HIS_ERROR = 'rp/STOCK_TRAD_HIS_ERROR';
export interface ReportStockTradHisErrorAction {
  type: typeof REPORT_STOCK_TRAD_HIS_ERROR;
  error: any;
}
/**Hợp đồng nguồn */
export const REPORT_LOAN_CONTRACT = 'rp/LOAN_CONTRACT';
export interface ReportLoanContAction {
  type: typeof REPORT_LOAN_CONTRACT;
}

export const REPORT_LOAN_CONTRACT_REQUESTING = 'rp/LOAN_CONTRACT_REQUESTING';
export interface ReportLoanContRequestingAction {
  type: typeof REPORT_LOAN_CONTRACT_REQUESTING;
  data: any;
}

export const REPORT_LOAN_CONTRACT_SUCCESS = 'rp/LOAN_CONTRACT_SUCCESS';
export interface ReportLoanContSuccessAction {
  type: typeof REPORT_LOAN_CONTRACT_SUCCESS;
  resData: any;
}

export const REPORT_LOAN_CONTRACT_ERROR = 'rp/LOAN_CONTRACT_ERROR';
export interface ReportLoanContErrorAction {
  type: typeof REPORT_LOAN_CONTRACT_ERROR;
  error: any;
}
/**Loại giao dịch */
export const REPORT_CASH_BUSINESS = 'rp/CASH_BUSINESS';
export interface ReportCashBusAction {
  type: typeof REPORT_CASH_BUSINESS;
}

export const REPORT_CASH_BUSINESS_REQUESTING = 'rp/CASH_BUSINESS_REQUESTING';
export interface ReportCashBusRequestingAction {
  type: typeof REPORT_CASH_BUSINESS_REQUESTING;
  data: any;
}

export const REPORT_CASH_BUSINESS_SUCCESS = 'rp/CASH_BUSINESS_SUCCESS';
export interface ReportCashBusSuccessAction {
  type: typeof REPORT_CASH_BUSINESS_SUCCESS;
  resData: any;
}

export const REPORT_CASH_BUSINESS_ERROR = 'rp/CASH_BUSINESS_ERROR';
export interface ReportCashBusErrorAction {
  type: typeof REPORT_CASH_BUSINESS_ERROR;
  error: any;
}
/**các khoản nợ */
export const REPORT_DEBTS = 'rp/DEBTS';
export interface ReportDebtsAction {
  type: typeof REPORT_DEBTS;
}

export const REPORT_DEBTS_REQUESTING = 'rp/DEBTS_REQUESTING';
export interface ReportDebtsRequestingAction {
  type: typeof REPORT_DEBTS_REQUESTING;
  data: any;
}

export const REPORT_DEBTS_SUCCESS = 'rp/DEBTS_SUCCESS';
export interface ReportDebtsSuccessAction {
  type: typeof REPORT_DEBTS_SUCCESS;
  resData: any;
}

export const REPORT_DEBTS_ERROR = 'rp/DEBTS_ERROR';
export interface ReportDebtsErrorAction {
  type: typeof REPORT_DEBTS_ERROR;
  error: any;
}

/**Lịch sử trả nợ */
export const REPORT_DEBTS_HIS = 'rp/DEBTS_HIS';
export interface ReportDebtsHisAction {
  type: typeof REPORT_DEBTS_HIS;
}

export const REPORT_DEBTS_HIS_REQUESTING = 'rp/DEBTS_HIS_REQUESTING';
export interface ReportDebtsHisRequestingAction {
  type: typeof REPORT_DEBTS_HIS_REQUESTING;
  data: any;
}

export const REPORT_DEBTS_HIS_SUCCESS = 'rp/DEBTS_HIS_SUCCESS';
export interface ReportDebtsHisSuccessAction {
  type: typeof REPORT_DEBTS_HIS_SUCCESS;
  resData: any;
}

export const REPORT_DEBTS_HIS_ERROR = 'rp/DEBTS_HIS_ERROR';
export interface ReportDebtsHisErrorAction {
  type: typeof REPORT_DEBTS_HIS_ERROR;
  error: any;
}

/**Chi tiết lịch sử trả nợ */
export const REPORT_DEBTS_HIS_DETAIL = 'rp/DEBTS_HIS_DETAIL';
export interface ReportDebtsHisDetailAction {
  type: typeof REPORT_DEBTS_HIS_DETAIL;
}

export const REPORT_DEBTS_HIS_DETAIL_REQUESTING =
  'rp/DEBTS_HIS_DETAIL_REQUESTING';
export interface ReportDebtsHisDetailRequestingAction {
  type: typeof REPORT_DEBTS_HIS_DETAIL_REQUESTING;
  data: any;
}

export const REPORT_DEBTS_HIS_DETAIL_SUCCESS = 'rp/DEBTS_HIS_DETAIL_SUCCESS';
export interface ReportDebtsHisDetailSuccessAction {
  type: typeof REPORT_DEBTS_HIS_DETAIL_SUCCESS;
  resData: any;
}

export const REPORT_DEBTS_HIS_DETAIL_ERROR = 'rp/DEBTS_HIS_DETAIL_ERROR';
export interface ReportDebtsHisDetailErrorAction {
  type: typeof REPORT_DEBTS_HIS_DETAIL_ERROR;
  error: any;
}

/**export --Báo cáo */
export const EXPORT_GLOBAL = 'ex/GLOBAL';
export interface ExportGlobalAction {
  type: typeof EXPORT_GLOBAL;
}

export const EXPORT_GLOBAL_REQUESTING = 'ex/GLOBAL_REQUESTING';
export interface ExportGlobalRequestingAction {
  type: typeof EXPORT_GLOBAL_REQUESTING;
  data: any;
}

export const EXPORT_GLOBAL_SUCCESS = 'ex/GLOBAL_SUCCESS';
export interface ExportGlobalSuccessAction {
  type: typeof EXPORT_GLOBAL_SUCCESS;
  resData: any;
}

export const EXPORT_GLOBAL_ERROR = 'ex/GLOBAL_ERROR';
export interface ExportGlobalErrorAction {
  type: typeof EXPORT_GLOBAL_ERROR;
  error: any;
}

export type ReportAction =
  | Report001Action
  | Report001RequestingAction
  | Report001SuccessAction
  | Report001ErrorAction
  | Report002Action
  | Report002RequestingAction
  | Report002SuccessAction
  | Report002ErrorAction
  | Report003Action
  | Report003RequestingAction
  | Report003SuccessAction
  | Report003ErrorAction
  | Report004Action
  | Report004RequestingAction
  | Report004SuccessAction
  | Report004ErrorAction
  | Report005Action
  | Report005RequestingAction
  | Report005SuccessAction
  | Report005ErrorAction
  | Report006Action
  | Report006RequestingAction
  | Report006SuccessAction
  | Report006ErrorAction
  | Report007Action
  | Report007RequestingAction
  | Report007SuccessAction
  | Report007ErrorAction
  | Report008Action
  | Report008RequestingAction
  | Report008SuccessAction
  | Report008ErrorAction
  | Report009Action
  | Report009RequestingAction
  | Report009SuccessAction
  | Report009ErrorAction
  | Report010Action
  | Report010RequestingAction
  | Report010SuccessAction
  | Report010ErrorAction
  | Report011Action
  | Report011RequestingAction
  | Report011SuccessAction
  | Report011ErrorAction
  | Report012Action
  | Report012RequestingAction
  | Report012SuccessAction
  | Report012ErrorAction
  | ReportCashAction
  | ReportCashRequestingAction
  | ReportCashSuccessAction
  | ReportCashErrorAction
  | ReportStockAction
  | ReportStockRequestingAction
  | ReportStockSuccessAction
  | ReportStockErrorAction
  | ReportBalDebtAction
  | ReportBalDebtRequestingAction
  | ReportBalDebtSuccessAction
  | ReportBalDebtErrorAction
  | ReportAdvAction
  | ReportAdvRequestingAction
  | ReportAdvSuccessAction
  | ReportAdvErrorAction
  | ReportDebtAction
  | ReportDebtRequestingAction
  | ReportDebtSuccessAction
  | ReportDebtErrorAction
  | ReportStockTradHisAction
  | ReportStockTradHisRequestingAction
  | ReportStockTradHisSuccessAction
  | ReportStockTradHisErrorAction
  | ReportLoanContAction
  | ReportLoanContRequestingAction
  | ReportLoanContSuccessAction
  | ReportLoanContErrorAction
  | ReportCashBusAction
  | ReportCashBusRequestingAction
  | ReportCashBusSuccessAction
  | ReportCashBusErrorAction
  | ReportDebtsAction
  | ReportDebtsRequestingAction
  | ReportDebtsSuccessAction
  | ReportDebtsErrorAction
  | ReportDebtsHisAction
  | ReportDebtsHisRequestingAction
  | ReportDebtsHisSuccessAction
  | ReportDebtsHisErrorAction
  | ReportDebtsHisDetailAction
  | ReportDebtsHisDetailRequestingAction
  | ReportDebtsHisDetailSuccessAction
  | ReportDebtsHisDetailErrorAction
  | ExportGlobalAction
  | ExportGlobalRequestingAction
  | ExportGlobalSuccessAction
  | ExportGlobalErrorAction;
