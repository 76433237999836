import { checkHasActByFunc, findAll } from 'utils'
import { navItems } from '_nav'
import { clientTokenSet } from 'containers/client/actions'
import { storages } from './storages'

interface Props {
  location: { pathname: string }
}

export function checkAuthorization({ dispatch }: any): boolean {
  const storedToken = storages.loadState('token')
  if (storedToken) {
    if (!storedToken.sid) return false
    dispatch(clientTokenSet(storedToken))
    return true
  }

  return false
}

export function checkDefaultAuthorization({ dispatch }: any): boolean {
  if (dispatch?.token) return true

  if (checkAuthorization(dispatch) && !dispatch?.reqLogin) return true

  return false
}

export function checkDefaultRight(props: Props, token: any): boolean {
  console.log(props, token)
  const {
    location: { pathname },
  } = props
  const _item = findAll(pathname, navItems)
  if (!_item || !_item.length) return false
  return checkHasActByFunc(_item[0]?.right, token?.rightList)
}
