export const CASH_BALANCE_REQUESTING = 'priv/CASH_BALANCE_REQUESTING';
export interface CashBalanceRequestAction {
  type: typeof CASH_BALANCE_REQUESTING;
  data: any;
}

export const CASH_BALANCE_REQUEST_SUCCESS = 'priv/CASH_BALANCE_SUCCESS';
export interface CashBalanceRequestSuccessAction {
  type: typeof CASH_BALANCE_REQUEST_SUCCESS;
  cashBalance: any;
}

export const CASH_BALANCE_REQUEST_ERROR = 'priv/CASH_BALANCE_ERROR';
export interface CashBalanceRequestErrorAction {
  type: typeof CASH_BALANCE_REQUEST_ERROR;
  error: any;
}

export const SHARE_BALANCE_REQUESTING = 'priv/SHARE_BALANCE_REQUESTING';
export interface ShareBalanceRequestAction {
  type: typeof SHARE_BALANCE_REQUESTING;
  data: any;
}

export const SHARE_BALANCE_REQUEST_SUCCESS =
  'priv/SHARE_BALANCE_REQUEST_SUCCESS';
export interface ShareBalanceRequestSuccessAction {
  type: typeof SHARE_BALANCE_REQUEST_SUCCESS;
  shareBalance: any;
}

export const SHARE_BALANCE_REQUEST_ERROR = 'priv/SHARE_BALANCE_REQUEST_ERROR';
export interface ShareBalanceRequestErrorAction {
  type: typeof SHARE_BALANCE_REQUEST_ERROR;
  error: any;
}

export const PURCHASE_POWER_REQUESTING = 'priv/PURCHASE_POWER_REQUESTING';
export interface PurchasePowerRequestAction {
  type: typeof PURCHASE_POWER_REQUESTING;
  data: any;
}

export const PURCHASE_POWER_REQUEST_SUCCESS = 'priv/PURCHASE_POWER_SUCCESS';
export interface PurchasePowerRequestSuccessAction {
  type: typeof PURCHASE_POWER_REQUEST_SUCCESS;
  purchasePower: any;
}

export const PURCHASE_POWER_REQUEST_ERROR = 'priv/PURCHASE_POWER_ERROR';
export interface PurchasePowerRequestErrorAction {
  type: typeof PURCHASE_POWER_REQUEST_ERROR;
  error: any;
}

export const STOCK_BALANCE_REQUESTING = 'priv/STOCK_BALANCE_REQUESTING';
export interface StockBalanceRequestAction {
  type: typeof STOCK_BALANCE_REQUESTING;
  data: any;
}

export const STOCK_BALANCE_REQUEST_SUCCESS = 'priv/STOCK_BALANCE_SUCCESS';
export interface StockBalanceRequestSuccessAction {
  type: typeof STOCK_BALANCE_REQUEST_SUCCESS;
  stockBalance: any;
}

export const STOCK_BALANCE_REQUEST_ERROR = 'priv/STOCK_BALANCE_ERROR';
export interface StockBalanceRequestErrorAction {
  type: typeof STOCK_BALANCE_REQUEST_ERROR;
  error: any;
}

export const ALL_POSITION_SET = 'priv/ALL_POSITION_SET';
export interface AllPositionSetAction {
  type: typeof ALL_POSITION_SET;
  positions: any;
}

export const POSITION_REQUESTING = 'priv/POSITION_REQUESTING';
export interface PositionsRequestAction {
  type: typeof POSITION_REQUESTING;
  data: any;
}

export const POSITION_REQUEST_SUCCESS = 'priv/POSITION_SUCCESS';
export interface PositionsRequestSuccessAction {
  type: typeof POSITION_REQUEST_SUCCESS;
  positions: any;
}

export const POSITION_REQUEST_ERROR = 'priv/POSITION_ERROR';
export interface PositionsRequestErrorAction {
  type: typeof POSITION_REQUEST_ERROR;
  error: any;
}

export const SSTOCK_INFO_REQUESTING = 'priv/SSTOCK_INFO_REQUESTING';
export interface SStockInfoRequestAction {
  type: typeof SSTOCK_INFO_REQUESTING;
  data: any;
}

export const SSTOCK_INFO_REQUEST_SUCCESS = 'priv/SSTOCK_INFO_SUCCESS';
export interface SStockInfoRequestSuccessAction {
  type: typeof SSTOCK_INFO_REQUEST_SUCCESS;
  resData: any;
}

export const SSTOCK_INFO_REQUEST_ERROR = 'priv/SSTOCK_INFO_ERROR';
export interface SStockInfoRequestErrorAction {
  type: typeof SSTOCK_INFO_REQUEST_ERROR;
  error: any;
}

export const NEW_ORDER_CLEAR = 'priv/NEW_ORDER_CLEAR';
export interface NewOrderClearAction {
  type: typeof NEW_ORDER_CLEAR;
}

export const NEW_ORDER_REQUESTING = 'priv/NEW_ORDER_REQUESTING';
export interface NewOrderRequestAction {
  type: typeof NEW_ORDER_REQUESTING;
  data: any;
}

export const NEW_ORDER_REQUEST_SUCCESS = 'priv/NEW_ORDER_SUCCESS';
export interface NewOrderRequestSuccessAction {
  type: typeof NEW_ORDER_REQUEST_SUCCESS;
  newOrder: any;
}

export const NEW_ORDER_REQUEST_ERROR = 'priv/NEW_ORDER_ERROR';
export interface NewOrderRequestErrorAction {
  type: typeof NEW_ORDER_REQUEST_ERROR;
  error: any;
}

export const EDIT_ORDER_CLEAR = 'priv/EDIT_ORDER_CLEAR';
export interface EditOrderClearAction {
  type: typeof EDIT_ORDER_CLEAR;
}

export const EDIT_ORDER_REQUESTING = 'priv/EDIT_ORDER_REQUESTING';
export interface EditOrderRequestAction {
  type: typeof EDIT_ORDER_REQUESTING;
  data: any;
}

export const EDIT_ORDER_REQUEST_SUCCESS = 'priv/EDIT_ORDER_SUCCESS';
export interface EditOrderRequestSuccessAction {
  type: typeof EDIT_ORDER_REQUEST_SUCCESS;
  editOrder: any;
}

export const EDIT_ORDER_REQUEST_ERROR = 'priv/EDIT_ORDER_ERROR';
export interface EditOrderRequestErrorAction {
  type: typeof EDIT_ORDER_REQUEST_ERROR;
  error: any;
}

export const CANCEL_ORDER_CLEAR = 'priv/CANCEL_ORDER_CLEAR';
export interface CancelOrderClearAction {
  type: typeof CANCEL_ORDER_CLEAR;
}

export const CANCEL_ORDER_REQUESTING = 'priv/CANCEL_ORDER_REQUESTING';
export interface CancelOrderRequestAction {
  type: typeof CANCEL_ORDER_REQUESTING;
  data: any;
}

export const CANCEL_ORDER_REQUEST_SUCCESS = 'priv/CANCEL_ORDER_SUCCESS';
export interface CancelOrderRequestSuccessAction {
  type: typeof CANCEL_ORDER_REQUEST_SUCCESS;
  cancelOrder: any;
}

export const CANCEL_ORDER_REQUEST_ERROR = 'priv/CANCEL_ORDER_ERROR';
export interface CancelOrderRequestErrorAction {
  type: typeof CANCEL_ORDER_REQUEST_ERROR;
  error: any;
}

export const SYMBOL_INFO_REQUESTING = 'priv/SYMBOL_INFO_REQUESTING';
export interface SymbolInfoRequestAction {
  type: typeof SYMBOL_INFO_REQUESTING;
  data: any;
}

export const SYMBOL_INFO_REQUEST_SUCCESS = 'priv/SYMBOL_INFO_SUCCESS';
export interface SymbolInfoRequestSuccessAction {
  type: typeof SYMBOL_INFO_REQUEST_SUCCESS;
  symbolInfo: any;
}

export const SYMBOL_INFO_REQUEST_ERROR = 'priv/SYMBOL_INFO_ERROR';
export interface SymbolInfoRequestErrorAction {
  type: typeof SYMBOL_INFO_REQUEST_ERROR;
  error: any;
}

export const MATCH_ORDER_REQUESTING = 'priv/MATCH_ORDER_REQUESTING';
export interface MatchOrderRequestAction {
  type: typeof MATCH_ORDER_REQUESTING;
  data: any;
}

export const MATCH_ORDER_REQUEST_SUCCESS = 'priv/MATCH_ORDER_SUCCESS';
export interface MatchOrderRequestSuccessAction {
  type: typeof MATCH_ORDER_REQUEST_SUCCESS;
  matchOrder: any;
}

export const MATCH_ORDER_REQUEST_ERROR = 'priv/MATCH_ORDER_ERROR';
export interface MatchOrderRequestErrorAction {
  type: typeof MATCH_ORDER_REQUEST_ERROR;
  error: any;
}

export const ADVANCE_ORDER_REQUESTING = 'priv/ADVANCE_ORDER_REQUESTING';
export interface AdvanceOrderRequestAction {
  type: typeof ADVANCE_ORDER_REQUESTING;
  data: any;
}

export const ADVANCE_ORDER_REQUEST_SUCCESS = 'priv/ADVANCE_ORDER_SUCCESS';
export interface AdvanceOrderRequestSuccessAction {
  type: typeof ADVANCE_ORDER_REQUEST_SUCCESS;
  advOrder: any;
}

export const ADVANCE_ORDER_REQUEST_ERROR = 'priv/ADVANCE_ORDER_ERROR';
export interface AdvanceOrderRequestErrorAction {
  type: typeof ADVANCE_ORDER_REQUEST_ERROR;
  error: any;
}

export const COND_ORDER_REQUESTING = 'priv/COND_ORDER_REQUESTING';
export interface CondOrderRequestAction {
  type: typeof COND_ORDER_REQUESTING;
  data: any;
}

export const COND_ORDER_REQUEST_SUCCESS = 'priv/COND_ORDER_SUCCESS';
export interface CondOrderRequestSuccessAction {
  type: typeof COND_ORDER_REQUEST_SUCCESS;
  resData: any;
}

export const COND_ORDER_REQUEST_ERROR = 'priv/COND_ORDER_ERROR';
export interface CondOrderRequestErrorAction {
  type: typeof COND_ORDER_REQUEST_ERROR;
  error: any;
}

export const LIST_ORDER_CONFIRM_REQUESTING =
  'priv/LIST_ORDER_CONFIRM_REQUESTING';
export interface ListOrderConfirmRequestAction {
  type: typeof LIST_ORDER_CONFIRM_REQUESTING;
  data: any;
}

export const LIST_ORDER_CONFIRM_SUCCESS = 'priv/LIST_ORDER_CONFIRM_SUCCESS';
export interface ListOrderConfirmSuccessAction {
  type: typeof LIST_ORDER_CONFIRM_SUCCESS;
  resData: any;
}

export const LIST_ORDER_CONFIRM_ERROR = 'priv/LIST_ORDER_CONFIRM_ERROR';
export interface ListOrderConfirmErrorAction {
  type: typeof LIST_ORDER_CONFIRM_ERROR;
  error: any;
}

export const SELL_ALL_SUCCESS = 'priv/SELL_ALL_SUCCESS';
export interface SellAllSuccessAction {
  type: typeof SELL_ALL_SUCCESS;
  resData: any;
}

export type PrivDataAction =
  | CashBalanceRequestAction
  | CashBalanceRequestSuccessAction
  | CashBalanceRequestErrorAction
  | ShareBalanceRequestAction
  | ShareBalanceRequestSuccessAction
  | ShareBalanceRequestErrorAction
  | PurchasePowerRequestAction
  | PurchasePowerRequestSuccessAction
  | PurchasePowerRequestErrorAction
  | StockBalanceRequestAction
  | StockBalanceRequestSuccessAction
  | StockBalanceRequestErrorAction
  | AllPositionSetAction
  | PositionsRequestAction
  | PositionsRequestSuccessAction
  | PositionsRequestErrorAction
  | SStockInfoRequestAction
  | SStockInfoRequestSuccessAction
  | SStockInfoRequestErrorAction
  | NewOrderClearAction
  | NewOrderRequestAction
  | NewOrderRequestSuccessAction
  | NewOrderRequestErrorAction
  | EditOrderClearAction
  | EditOrderRequestAction
  | EditOrderRequestSuccessAction
  | EditOrderRequestErrorAction
  | CancelOrderClearAction
  | CancelOrderRequestAction
  | CancelOrderRequestSuccessAction
  | CancelOrderRequestErrorAction
  | SymbolInfoRequestAction
  | SymbolInfoRequestSuccessAction
  | SymbolInfoRequestErrorAction
  | MatchOrderRequestAction
  | MatchOrderRequestSuccessAction
  | MatchOrderRequestErrorAction
  | AdvanceOrderRequestAction
  | AdvanceOrderRequestSuccessAction
  | AdvanceOrderRequestErrorAction
  | CondOrderRequestAction
  | CondOrderRequestSuccessAction
  | CondOrderRequestErrorAction
  | ListOrderConfirmRequestAction
  | ListOrderConfirmSuccessAction
  | ListOrderConfirmErrorAction
  | SellAllSuccessAction;
