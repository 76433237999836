import * as actions from './actionType';

export function cashTransferInRequest(
  data: any,
): actions.CashTransferInRequestingAction {
  return {
    type: actions.CASH_TRANSFER_IN_REQUESTING,
    data,
  };
}

export function cashTransferInSuccess(
  resData: any,
): actions.CashTransferInSuccessAction {
  return {
    type: actions.CASH_TRANSFER_IN_SUCCESS,
    resData,
  };
}

export function cashTransferInError(
  error: Error | string,
): actions.CashTransferInErrorAction {
  return {
    type: actions.CASH_TRANSFER_IN_ERROR,
    error,
  };
}

export function cashTransferOutRequest(
  data: any,
): actions.CashTransferOutRequestingAction {
  return {
    type: actions.CASH_TRANSFER_OUT_REQUESTING,
    data,
  };
}

export function cashTransferOutSuccess(
  resData: any,
): actions.CashTransferOutSuccessAction {
  return {
    type: actions.CASH_TRANSFER_OUT_SUCCESS,
    resData,
  };
}

export function cashTransferOutError(
  error: Error | string,
): actions.CashTransferOutErrorAction {
  return {
    type: actions.CASH_TRANSFER_OUT_ERROR,
    error,
  };
}
