/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { clientTypeNavSet, setLogin } from 'containers/client/actions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { connect, useDispatch } from 'react-redux';

import { navItems } from '../_nav';

import { ReactComponent as ComponentAsset } from 'assets/img/nav/asset.svg';
import { ReactComponent as ComponentCash } from 'assets/img/nav/cash.svg';
import { ReactComponent as ComponentContact } from 'assets/img/nav/contact.svg';
import { ReactComponent as ComponentExplore } from 'assets/img/nav/explore.svg';
import { ReactComponent as ComponentExtension } from 'assets/img/nav/extension.svg';
import { ReactComponent as ComponentHelp } from 'assets/img/nav/help.svg';
import { ReactComponent as ComponentInvest } from 'assets/img/nav/invest.svg';
import { ReactComponent as ComponentSms } from 'assets/img/nav/sms.svg';
import { ReactComponent as ComponentStock } from 'assets/img/nav/stock.svg';

import { ReactComponent as IcArrowDown } from 'assets/img/icon/arrow-down.svg';
import { ReactComponent as IcArrowLeft } from 'assets/img/icon/arrow-left.svg';
import { ReactComponent as IcConnect } from 'assets/img/icon/connect.svg';
import { ReactComponent as IcResize } from 'assets/img/icon/resize.svg';
import { ReactComponent as IcShowNav } from 'assets/img/icon/show-nav.svg';
import { ReactComponent as ComponentBond } from 'assets/img/nav/bond.svg';
import { ReactComponent as ComponentDerivative } from 'assets/img/nav/derivative.svg';
import { ReactComponent as ComponentProduct } from 'assets/img/nav/product.svg';

import BgMenu from 'assets/img/nav/bg-menu.png';

import { ReactComponent as IconLogo } from 'assets/img/logo/logo-small-ups.svg';
import { ReactComponent as IconLogoZo } from 'assets/img/logo/logo_zo_ups.svg';
import { NavItems } from 'interface';
import { makeGetSocketStatus, makeGetToken } from 'lib/selector';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppState } from 'reducers';
import { checkHasActByFunc, getActiveNav } from 'utils';
import packageJson from '../../package.json';

function usePrevious(value: any) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  typeNav: string | undefined;
  showNav: boolean;
  token: any;
  serverStatus?: string;
}

function NavLeft(props: Props): JSX.Element {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = location;

  const [linkActive, setLinkActive] = React.useState<string>('');
  const [showTab, setShowTab] = React.useState<boolean>(false);

  const { token, typeNav, serverStatus } = props;

  const prePathname: string | undefined | null = usePrevious(pathname);

  const nav1 = _.filter(navItems, (o) => o.group === '1');
  const nav2 = _.filter(navItems, (o) => o.group === '2');
  const nav3 = _.filter(navItems, (o) => o.group === '3');

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1360) {
        dispatch(clientTypeNavSet('minimize'));
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (pathname && pathname !== prePathname) {
      console.log(pathname);
      setTimeout(() => {
        setLinkActive(pathname);
      }, 200);
    }
  }, [pathname]);

  function getImageByKey(key: string) {
    switch (key) {
      case 'asset':
        return <ComponentAsset />;
      case 'invest':
        return <ComponentInvest />;
      case 'cash':
        return <ComponentCash />;
      case 'stock':
        return <ComponentStock />;
      case 'extension':
        return <ComponentExtension />;
      case 'explore':
        return <ComponentExplore />;
      case 'sms':
        return <ComponentSms />;
      case 'help':
        return <ComponentHelp />;
      case 'contact':
        return <ComponentContact />;
      case 'bond':
        return <ComponentBond />;
      case 'derivative':
        return <ComponentDerivative />;
      case 'product':
        return <ComponentProduct />;
      default:
        return <ComponentAsset />;
    }
  }

  function renderMiniHasAct(record: NavItems) {
    // console.log(record);
    if (record?.token && !token) {
      return (
        <a className="w-full" onClick={() => dispatch(setLogin())}>
          <span className="mx-1">{getImageByKey(record.icon!)}</span>
          <span className="mx-1"> {t(record.labelCode!)}</span>
        </a>
      );
    } else if (record.url === '/help') {
      return (
        <a
          href="https://hdsd.upstock.com.vn"
          target="_blank"
          className={'w-full '}
        >
          <span className="mx-1">{getImageByKey(record.icon!)}</span>
          <span className="mx-1">{t(record.labelCode!)}</span>
        </a>
      );
    } else
      return (
        <Link
          to={record.url!}
          className={
            'w-full ' + (getActiveNav(linkActive, record.url!) ? 'active' : '')
          }
        >
          <span className="mx-1">{getImageByKey(record.icon!)}</span>
          <span className="mx-1">{t(record.labelCode!)}</span>
        </Link>
      );
  }

  function renderMiniHasActIcon(record: NavItems) {
    if (record?.token && !token) {
      return (
        <a className="w-full" onClick={() => dispatch(setLogin())}>
          <span className="mx-1">{getImageByKey(record.icon!)}</span>
        </a>
      );
    } else if (record.url === '/help') {
      return (
        <a
          href="https://hdsd.upstock.com.vn"
          target="_blank"
          className={'w-full '}
        >
          <span className="mx-1">{getImageByKey(record.icon!)}</span>
        </a>
      );
    } else {
      return (
        <Link
          to={record.url!}
          className={
            'w-full ' + (getActiveNav(linkActive, record.url!) ? 'active' : '')
          }
        >
          <span className="mx-1">{getImageByKey(record.icon!)}</span>
        </Link>
      );
    }
  }

  function renderNavItem(navItems: NavItems[]) {
    return (
      <>
        {navItems &&
          navItems.map((item, index) => {
            return checkHasActByFunc(item?.right || [], token?.rightList) ? (
              <li key={index}>
                {!item?.children && renderMiniHasAct(item)}
                {item?.children && (
                  <>
                    <a
                      className="justify-between"
                      onClick={() => setLinkActive(item.url!)}
                    >
                      <span className="flex items-center">
                        <span className="mx-1">
                          {getImageByKey(item.icon!)}
                        </span>
                        <span className="mx-1">{t(item.labelCode!)}</span>
                      </span>
                      {getActiveNav(linkActive, item.url!) ? (
                        <IcArrowDown className="w-3 h-3" />
                      ) : (
                        <IcArrowLeft className="w-3 h-3" />
                      )}
                    </a>
                    {getActiveNav(linkActive, item.url!) && (
                      <ul className="sub-menu mt-1">
                        {item?.children &&
                          item?.children.map((subItem, index) => {
                            return (
                              checkHasActByFunc(
                                subItem?.right || [],
                                token?.rightList,
                              ) && (
                                <li key={index}>
                                  {subItem?.token && !token ? (
                                    <a
                                      className="w-full"
                                      onClick={() => dispatch(setLogin())}
                                    >
                                      <span className="w-7 flex justify-center">
                                        {getActiveNav(
                                          linkActive,
                                          subItem.url,
                                        ) && (
                                            <IcConnect className="fill-green" />
                                          )}
                                      </span>
                                      <span className="mx-1">
                                        {t(subItem.labelCode!)}
                                      </span>
                                    </a>
                                  ) : (
                                    <Link
                                      to={subItem.url!}
                                      className={
                                        'w-full ' +
                                        (getActiveNav(linkActive, subItem.url)
                                          ? 'active'
                                          : '')
                                      }
                                    >
                                      <span className="w-7 flex justify-center">
                                        {getActiveNav(
                                          linkActive,
                                          subItem.url,
                                        ) && (
                                            <IcConnect className="fill-green" />
                                          )}
                                      </span>
                                      <span className="mx-1">
                                        {t(subItem.labelCode!)}
                                      </span>
                                    </Link>
                                  )}
                                </li>
                              )
                            );
                          })}
                      </ul>
                    )}
                  </>
                )}
              </li>
            ) : (
              <li key={index}>
                <a className="w-full disabled">
                  <span className="mx-1">{getImageByKey(item.icon!)}</span>
                  <span className="mx-1">{t(item.labelCode!)}</span>
                </a>
              </li>
            );
          })}
      </>
    );
  }

  function renderNavItemIcon(navItems: NavItems[]) {
    return (
      <>
        {navItems &&
          navItems.map((item, index) => {
            return checkHasActByFunc(item?.right || [], token?.rightList) ? (
              <li key={index}>
                {!item?.children && renderMiniHasActIcon(item)}
                {item?.children && (
                  <>
                    <a
                      className="justify-between"
                      onClick={() => setLinkActive(item.url!)}
                    >
                      {getImageByKey(item.icon!)}
                    </a>
                    {getActiveNav(linkActive, item.url!) && (
                      <ul className="sub-menu mt-1">
                        {item?.children &&
                          item?.children.map((subItem, index) => {
                            return (
                              checkHasActByFunc(
                                subItem?.right || [],
                                token?.rightList,
                              ) && (
                                <li key={index}>
                                  {subItem?.token && !token ? (
                                    <a
                                      className="w-full"
                                      onClick={() => dispatch(setLogin())}
                                    >
                                      <span className="w-7 flex justify-center">
                                        {getActiveNav(
                                          linkActive,
                                          subItem.url,
                                        ) && (
                                            <IcConnect className="fill-green" />
                                          )}
                                      </span>
                                      <span className="mx-1">
                                        {t(subItem.labelCode!)}
                                      </span>
                                    </a>
                                  ) : (
                                    <Link
                                      to={subItem.url!}
                                      className={
                                        'w-full ' +
                                        (getActiveNav(linkActive, subItem.url)
                                          ? 'active'
                                          : '')
                                      }
                                    >
                                      <span className="w-7 flex justify-center">
                                        {getActiveNav(
                                          linkActive,
                                          subItem.url,
                                        ) && (
                                            <IcConnect className="fill-green" />
                                          )}
                                      </span>
                                      <span className="mx-1">
                                        {t(subItem.labelCode!)}
                                      </span>
                                    </Link>
                                  )}
                                </li>
                              )
                            );
                          })}
                      </ul>
                    )}
                  </>
                )}
              </li>
            ) : (
              <li key={index}>
                <a className="w-full disabled">{getImageByKey(item.icon!)}</a>
              </li>
            );
          })}
      </>
    );
  }

  function handleChangeType() {
    if (typeNav === 'hover') {
      dispatch(clientTypeNavSet('hidden'));
    } else dispatch(clientTypeNavSet('minimize'));
  }

  function handleShowNav() {
    dispatch(clientTypeNavSet('full'));
    setShowTab(false);
  }

  function handleOnMouseLeave() {
    if (typeNav === 'hover') dispatch(clientTypeNavSet('minimize'));
  }

  return (
    <div>
      {typeNav !== 'minimize' && typeNav !== 'hidden' && (
        <>
          <div
            className={
              'nav-account w-full flex flex-col gap-6 relative bg-center bg-no-repeat bg-cover px-2 py-6 ' +
              (typeNav === 'hover' ? 'nav_hover' : '')
            }
            style={{
              backgroundImage: `url(${BgMenu} )`,
            }}
          >
            <div className="w-full h-12 flex justify-center items-center py-1">
              <IconLogo />
            </div>
            <div className="flex flex-col" onMouseLeave={handleOnMouseLeave}>
              <div className=" h-[calc(100vh-130px)]">
                <PerfectScrollBar>
                  <ul>
                    {renderNavItem(nav1)}
                    <li className="divider"></li>
                    {renderNavItem(nav2)}
                    <li className="divider"></li>
                    {renderNavItem(nav3)}
                  </ul>
                </PerfectScrollBar>
              </div>
              <div className="mt-auto h-[41px] px-4 pb-4">
                <div className="h-px border-t border-skin-weak" />
                <div className="mt-2 text-xs font-medium flex justify-between">
                  <span
                    className={
                      'flex items-center ' +
                      (serverStatus === 'on' ? 'text-green' : 'text-red')
                    }
                  >
                    <IcConnect
                      className={
                        'mr-2 ' +
                        (serverStatus === 'on' ? 'fill-green' : 'fill-red')
                      }
                    />
                    {serverStatus === 'on'
                      ? t('nav.connect')
                      : t('nav.disconnected')}
                  </span>
                  {packageJson && (
                    <span className="text-skin-disable">
                      v.{packageJson.version}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className="h-9 w-9 bg-skin-navLeft rounded-full absolute -right-[18px] bottom-[70px] z-20 flex justify-center items-center cursor-pointer"
              onClick={handleChangeType}
            >
              <span className="h-7 w-7 rounded-full bg-skin-al300 z-30 flex justify-center items-center">
                <IcResize />
              </span>
            </div>
          </div>
        </>
      )}
      {typeNav === 'minimize' && (
        <>
          <div
            className="nav-account w-full flex flex-col gap-6 nav_minimize relative bg-center bg-no-repeat bg-cover  px-2 py-6 "
            style={{
              backgroundImage: `url(${BgMenu})`,
            }}
          >
            <div className="w-full h-8 flex justify-center items-center py-1">
              <IconLogoZo />
            </div>
            <div
              className="flex flex-col"
              onMouseMove={() => dispatch(clientTypeNavSet('hover'))}
            >
              <div className=" h-[calc(100vh-130px)]">
                <PerfectScrollBar>
                  <ul>
                    {renderNavItemIcon(nav1)}
                    <li className="divider"></li>
                    {renderNavItemIcon(nav2)}
                    <li className="divider"></li>
                    {renderNavItemIcon(nav3)}
                  </ul>
                </PerfectScrollBar>
              </div>
              <div className="mt-auto h-[41px] px-4 pb-4">
                <div className="h-px border-t border-skin-weak" />
                <div className="mt-2 text-xs font-medium flex justify-between flex-col items-center">
                  {packageJson && (
                    <span className="text-skin-disable">
                      v.{packageJson.version}
                    </span>
                  )}
                  <IcConnect
                    className={
                      serverStatus === 'on' ? 'fill-green' : 'fill-red'
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="h-9 w-9 bg-skin-navLeft rounded-full absolute -right-[18px] bottom-[60px] z-20 flex justify-center items-center cursor-pointer"
              onClick={() => dispatch(clientTypeNavSet('hidden'))}
            >
              <span className="h-7 w-7 rounded-full bg-skin-al300 z-30 flex justify-center items-center">
                <IcResize />
              </span>
            </div>
          </div>
        </>
      )}
      {typeNav === 'hidden' && (
        <div className="absolute left-4 bottom-[44px] z-10 cursor-pointer w-9 h-9 bg-skin-al300 rounded-full p-2">
          <IcShowNav onClick={handleShowNav} />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  const getToken = makeGetToken();
  const getSocketStatus = makeGetSocketStatus();
  return {
    showNav: state.client.showNav,
    token: getToken(state),
    typeNav: state.client.typeNav,
    serverStatus: getSocketStatus(state),
  };
};

export default connect(mapStateToProps)(React.memo(NavLeft));
