import { AppState } from 'reducers'
import { createSelector } from 'reselect'
import * as _ from 'lodash'

const getSecAllList = (state: AppState) => state.analysis.secListAll
const getNNInday = (state: AppState) => state.analysis.analNNInday
const getAnalPBDongTien = (state: AppState) => state.analysis.analPBDongTien
const getAnalFSRatio = (state: AppState) => {
  const { analFSRatio } = state.analysis
  if (!analFSRatio) return null
  return _.sortBy(analFSRatio, 'ROW_ORDER').reverse()
}
const getAnalRankFSRatio = (state: AppState) => state.analysis.analRankFSRatio
const getAnalSecOverview = (state: AppState) => state.analysis.analSecOverview

const getAnalComIntroduction = (state: AppState) => {
  const { analComIntroduction } = state.analysis

  return analComIntroduction || null
  if (!analComIntroduction?.profile) return null

  const lang = state.client.setting?.lang || 'vi'

  var div = document.createElement('div')
  div.innerHTML =
    lang === 'vi'
      ? analComIntroduction?.profile
      : analComIntroduction?.profileEn

  return div.innerText
}
const getAnalComBasic = (state: AppState) => state.analysis.analComBasic
const getAnalComSameInd = (state: AppState) => state.analysis.analComSameInd
const getAnalComLeader = (state: AppState) => state.analysis.analComLeader
const getAnalComHolder = (state: AppState) => state.analysis.analComHolder
const getAnalComEvents = (state: AppState) => state.analysis.analComEvents
const getAnalComBigTrans = (state: AppState) => state.analysis.analComBigTrans
const getAnalComRelated = (state: AppState) => state.analysis.analComRelated

const getAnalIndexReal = (state: AppState) => state.analysis.analIndReal
const getAnalStockReal = (state: AppState) => state.analysis.analStReal
const getAnalLichSuGia = (state: AppState) => {
  const { analLichSuGia } = state.analysis

  if (analLichSuGia) return analLichSuGia.reverse()

  return null
}
const getAnalSTMatchPrice = (state: AppState) => state.analysis.analStMatchPrice
const getAnalSTMBActive = (state: AppState) => state.analysis.analStMBActive
const getAnalTopMatch = (state: AppState) => state.analysis.analStTopMatch
const getAnalBasicInfo = (state: AppState) => state.analysis.analBasicInfo

const getAnalFilterRange = (state: AppState) => state.analysis.analFilterRange
const getAnalFilterAct = (state: AppState) => state.analysis.analFilterAct
const getAnalUserFilter = (state: AppState) => state.analysis.analUserFilter

const getAnalUserSignal = (state: AppState) => {
  const { analUserSignal } = state.analysis
  return _.filter(analUserSignal?.signals, (o) => o.active === true)
}
/************************************** MAKE GET ****************************************/

export const makeGetSecAllList = () =>
  createSelector([getSecAllList], (secListAll) => secListAll)

export const makeGetAnalIndexReal = () =>
  createSelector([getAnalIndexReal], (analIndReal) => analIndReal)

export const makeGetAnalStockReal = () =>
  createSelector([getAnalStockReal], (analStReal) => analStReal)

export const makeGetAnalSTMatchPrice = () =>
  createSelector([getAnalSTMatchPrice], (analStMatchPrice) => analStMatchPrice)

export const makeGetAnalSTMBActive = () =>
  createSelector([getAnalSTMBActive], (analStMBActive) => analStMBActive)

export const makeGetAnalTopMatch = () =>
  createSelector([getAnalTopMatch], (analStMBActive) => analStMBActive)

export const makeGetNNInday = () =>
  createSelector([getNNInday], (analNNInday) => analNNInday)

export const makeGetAnalPBDongTien = () =>
  createSelector([getAnalPBDongTien], (analPBDongTien) => analPBDongTien)

export const makeGetAnalLichSuGia = () =>
  createSelector([getAnalLichSuGia], (analLichSuGia) => analLichSuGia)

export const makeGetAnalBasicInfo = () =>
  createSelector([getAnalBasicInfo], (analBasicInfo) => analBasicInfo)

export const makeGetAnalFSRatio = () =>
  createSelector([getAnalFSRatio], (analFSRatio) => analFSRatio)

export const makeGetAnalRankFSRatio = () =>
  createSelector([getAnalRankFSRatio], (analRankFSRatio) => analRankFSRatio)

export const makeGetAnalSecOverview = () =>
  createSelector([getAnalSecOverview], (analSecOverview) => analSecOverview)

export const makeGetAnalComIntroduction = () =>
  createSelector(
    [getAnalComIntroduction],
    (analComIntroduction) => analComIntroduction
  )

export const makeGetAnalComBasic = () =>
  createSelector([getAnalComBasic], (analComBasic) => analComBasic)

export const makeGetAnalComSameInd = () =>
  createSelector([getAnalComSameInd], (analComSameInd) => analComSameInd)

export const makeGetAnalComLeader = () =>
  createSelector([getAnalComLeader], (analComLeader) => analComLeader)

export const makeGetAnalComHolder = () =>
  createSelector([getAnalComHolder], (analComHolder) => analComHolder)

export const makeGetAnalComEvents = () =>
  createSelector([getAnalComEvents], (analComEvents) => analComEvents)

export const makeGetAnalComBigTrans = () =>
  createSelector([getAnalComBigTrans], (analComBigTrans) => analComBigTrans)

export const makeGetAnalComRelated = () =>
  createSelector([getAnalComRelated], (analComRelated) => analComRelated)

export const makeGetAnalFilterRange = () =>
  createSelector([getAnalFilterRange], (analFilterRange) => analFilterRange)

export const makeGetAnalFilterAct = () =>
  createSelector([getAnalFilterAct], (analFilterAct) => analFilterAct)

export const makeGetAnalUserFilter = () =>
  createSelector([getAnalUserFilter], (analUserFilter) => analUserFilter)

export const makeGetAnalUserSignal = () =>
  createSelector([getAnalUserSignal], (analUserSignal) => analUserSignal)
