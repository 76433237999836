import { AppState } from 'reducers';
import { createSelector } from 'reselect';

const getAccountInfo = (state: AppState) => state.accInfo.accInfo;
const getAccountInfoUpd = (state: AppState) => state.accInfo.accInfoUpd;
const getAccountCashApprove = (state: AppState) => state.accInfo.accCashAppr;
const getAccountBenUpd = (state: AppState) => state.accInfo.accBenUpd;
const getAccountBenList = (state: AppState) => state.accInfo.accBenList;
const getAccountBenDel = (state: AppState) => state.accInfo.accBenDel;
const getAccountBenDef = (state: AppState) => state.accInfo.accBenDef;
const getAccSaleInfList = (state: AppState) => state.accInfo.accSaleInfor;
const getAccSaleInfSingle = (state: AppState) =>
  state.accInfo.accSaleInforSingle;
const getAccSaleInfUpd = (state: AppState) => state.accInfo.accSaleInforUpd;
const getCreateNickname = (state: AppState) => state.accInfo.createNickname;
const getManLoginDev = (state: AppState) => state.accInfo.manLoginDev;

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetAccountInfo = () =>
  createSelector(getAccountInfo, (accInfo) => accInfo);
export const makeGetAccountInfoUpd = () =>
  createSelector(getAccountInfoUpd, (accInfoUpd) => accInfoUpd);
export const makeGetAccountCashApprove = () =>
  createSelector(getAccountCashApprove, (accCashAppr) => accCashAppr);
export const makeGetAccountBenUpd = () =>
  createSelector(getAccountBenUpd, (accBenUpd) => accBenUpd);
export const makeGetAccountBenList = () =>
  createSelector(getAccountBenList, (accBenList) => accBenList);
export const makeGetAccountBenDel = () =>
  createSelector(getAccountBenDel, (accBenDel) => accBenDel);

export const makeGetAccountBenDef = () =>
  createSelector(getAccountBenDef, (accBenDef) => accBenDef);
export const makeGetAccSaleInfList = () =>
  createSelector(getAccSaleInfList, (accSaleInfor) => accSaleInfor);
export const makeAccSaleInfSingle = () =>
  createSelector(
    getAccSaleInfSingle,
    (accSaleInforSingle) => accSaleInforSingle,
  );
export const makeAccSaleInfUpd = () =>
  createSelector(getAccSaleInfUpd, (accSaleInforUpd) => accSaleInforUpd);

export const makeCreateNickname = () =>
  createSelector(getCreateNickname, (createNickname) => createNickname);

export const makeGetManLoginDev = () =>
  createSelector(getManLoginDev, (manLoginDev) => manLoginDev);
