import { ReactComponent as IcArrowLeft } from 'assets/img/icon/arrow-left.svg';
import * as actionTypes from 'containers/login/actionType';
import i18n from 'i18next';
import { IUserForgot } from 'interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { AppState } from 'reducers';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { BtnSubmit } from 'utils/styledUtils';
import { validateCard, validateEmail, validatePhone } from 'utils/validation';
import _ from 'lodash';

import { setLogin, setToast } from 'containers/client/actions';
import RenderLoginInput from 'helper/input/renderLoginInput';
import { handleApiErrors } from 'lib/api-error';
import { useHistory } from 'react-router';
interface Props {
  setStep: any;
  setAccount: any;
  email?: string;
  isLoading?: boolean;
  errorMsg?: Error | string | null;
  method?: string;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const validate = (values: any) => {
  const { phone, email, card, newPass, confirmPass, method } = values;
  const errors: any = {};

  if (!phone && method === 'phone') {
    errors.phone = i18n.t('valid.requiredPhone');
  }

  if (!email && method === 'email') {
    errors.email = i18n.t('valid.requiredEmail');
  }

  if (!card) {
    errors.card = i18n.t('valid.requiredCard');
  }
  return errors;
};

const apiUrl = process.env.REACT_APP_API_URL;

const FormForgotPass: React.FunctionComponent<
  InjectedFormProps<IUserForgot> & Props
> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const abortController = new AbortController();

  const { handleSubmit, isLoading, method, setStep, setAccount } = props;

  const preMethod = usePrevious(method);

  React.useEffect(() => {
    initForm();

    return () => {
      abortController.abort();
    };
  }, []);

  React.useEffect(() => {
    if (method && !_.isEqual(method, preMethod)) {
      dispatch(change('formForgotPass1', 'phone', ''));
      dispatch(change('formForgotPass1', 'email', ''));
    }
  }, [method]);

  function initForm(): void {
    dispatch(change('formForgotPass1', 'method', 'phone'));
  }

  function submit(values: IUserForgot) {
    var params = new URLSearchParams();
    params.append('username', '123456');
    params.append('ss', '');
    params.append('step', '1');
    params.append('otp', '');
    params.append('p1', method === 'phone' ? values.phone : '');
    params.append('p2', method === 'email' ? values.email : '');
    params.append('p3', values.card);
    params.append('p4', '');

    const request = fetch(`${apiUrl}/ForgetPass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: params.toString(),
      signal: abortController.signal,
    });
    handleRequestCheckAccount(request, values);
  }

  function handleRequestCheckAccount(request: any, values: IUserForgot) {
    return request
      .then(handleApiErrors)
      .then((response: any) => response.json())
      .then((json: any) => {
        if (json.rc > 0) {
          setStep(2);
          const obj = _.cloneDeep(values);
          obj.username = json.data?.account;
          setAccount(obj);
        } else {
          _handleToast(json.rs, 'error');
        }
      })
      .catch((error: any) => {
        _handleToast(error, 'error');
      });
  }

  function handleReturnLogin() {
    history.push('/price/bang-gia/vn30');
    dispatch(setLogin());
  }

  function _handleToast(msg: string, type = 'info') {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: 'Thông báo',
      type,
    };
    dispatch(setToast(toastMsg));
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="grid gap-4 min-h-[381px]">
      <div className="font-briGro flex justify-between items-center">
        <span className="text-[32px] font-semibold">
          {t('forgot.verify-info')}
        </span>
      </div>
      <div>{t('forgot.pass-setup-method')}</div>
      <div className="flex">
        <div className="container-radio">
          <Field name="method" component="input" type="radio" value="phone" />
          <span className="checkmark" />
          {t('forgot.phone')}
        </div>
        {/* <div className="container-radio ml-6">
          <Field name="method" component="input" type="radio" value="email" />
          <span className="checkmark" />
          Email
        </div> */}
      </div>
      {method === 'phone' && (
        <div className="w-full">
          <div className="mb-2">{t('forgot.phone')}</div>
          <Field
            name="phone"
            type="text"
            autoComplete="off"
            placeholder={t('forgot.phone')}
            component={RenderLoginInput}
            validate={[validatePhone]}
          />
        </div>
      )}
      {method === 'email' && (
        <div className="w-full">
          <div className="mb-2">Email</div>
          <Field
            name="email"
            type="text"
            autoComplete="off"
            placeholder="Email"
            component={RenderLoginInput}
            validate={[validateEmail]}
          />
        </div>
      )}
      <div className="w-full">
        <div className="mb-2">{t('forgot.card')}</div>
        <Field
          name="card"
          type="text"
          placeholder={t('forgot.card')}
          component={RenderLoginInput}
          validate={[validateCard]}
        />
      </div>
      <div className="flex items-center justify-between">
        <div
          className="font-semibold flex items-center cursor-pointer"
          onClick={handleReturnLogin}
        >
          <IcArrowLeft className="w-6 h-6 mr-1" />
          {t('forgot.return-login')}
        </div>
        <BtnSubmit type="submit" disabled={isLoading} className="success">
          {t('button.continue')}
        </BtnSubmit>
      </div>
    </form>
  );
};

const _FormForgotPass = reduxForm<IUserForgot, Props>({
  form: 'formForgotPass1',
  enableReinitialize: true,
  validate,
})(FormForgotPass as any);

const selector = formValueSelector('formForgotPass1');

const makeMapStateToProps = () => {
  const mapStateToProps = (state: AppState) => {
    const { phone, email, card, method } = selector(
      state,
      'phone',
      'email',
      'card',
      'method',
    );

    return {
      isLoading: state.isLoading[actionTypes.PAGE_LOGIN],
      errorMsg: state.error[actionTypes.PAGE_LOGIN],

      phone,
      email,
      card,
      method,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormForgotPass);
