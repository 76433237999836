import { AppState } from 'reducers';
import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { categoryDefault } from '../storages';
import { INormalSelect } from 'interface';

const getToken = (state: AppState) => state.client.token;
const getToast = (state: AppState) => state.client.toast;
const getTypeNav = (state: AppState) => state.client.typeNav;
export const getConfig = (state: AppState) => state.client.config;
const getDataSearch = (state: AppState) => state.client.dataSearch;
const getClientSetting = (state: AppState) => state.client.setting;
const getFooterActive = (state: AppState) => state.client.footActive;
const getTypeIndex = (state: AppState) => {
  const { setting } = state.client;
  return (setting && setting.typeIndex) || 1;
};
const getTypePrice = (state: AppState) => {
  const { setting } = state.client;
  return (setting && setting.priceType) || 1;
};
export const makeGetCategory = () =>
  createSelector([getCategory], (category) => category);

export const makeGetCategoryNganh = () =>
  createSelector([getCategoryNganh], (category) => category);

export const makeGetCategoryPortfolio = () =>
  createSelector([getCategoryPortfolio], (category) => category);

const getCatalogSelected = (state: AppState, props: any) => {
  const { categoryId } = props;
  // log(categoryId)
  if (categoryId?.startsWith('nganh-')) {
    // danh muc nganh
    const _categoryNganh = getCategoryNganh(state);
    const _filter = _.find(
      _categoryNganh,
      (o) => o && o.path && o.path.endsWith(categoryId),
    );
    return _filter;
  } else {
    const _category = getCategory(state);
    const _accPortfolio = getCategoryPortfolio(state);

    const _filter =
      _.find(_category, (o) => o && o.path && o.path.endsWith(categoryId)) ||
      _.find(_accPortfolio, (o) => o.id === categoryId);

    return _filter;
  }
};

const getAccountPortfolio = (state: AppState) => state.client.accPortfolio;

const getCategory = (state: AppState) => {
  const { category } = state?.client;
  if (!category || !_.isArray(category)) return categoryDefault;
  return category;
};

const getCategoryNganh = (state: AppState) => {
  // console.log(state.client.category)
  const { categoryNganh } = state?.client;
  const { dmNganh } = state.priceBoard;

  if (!categoryNganh || !_.isArray(categoryNganh)) return dmNganh;

  return categoryNganh;
};

const getCategoryPortfolio = (state: AppState) => {
  const { categoryPortfolio, accPortfolio } = state?.client;
  const { dmNganh } = state.priceBoard;

  if (!categoryPortfolio || !_.isArray(categoryPortfolio)) return accPortfolio;

  return categoryPortfolio;
};

const getUpdatePortfolio = (state: AppState) => state.client.updPortfolio;
const getDeletePortfolio = (state: AppState) => state.client.delPortfolio;
const getDefaultAccount = (state: AppState) => state.client.defAccount;
const getListAccount = (state: AppState) => state.client.listAccount;

const getListAccountSelect = (state: AppState) => {
  const listAcc: INormalSelect[] = [];

  if (!state.client.listAccount) return [];

  state.client.listAccount.forEach((element: any) => {
    listAcc.push({
      value: element.accCode,
      label: element.accCode,
    });
  });

  return listAcc;
};

const getListBankOnline = (state: AppState) => state.client.allBank;
const getListProvince = (state: AppState) => state.client.allProvince;
const getListDistrict = (state: AppState) => state.client.allDistrict;
const getListVillage = (state: AppState) => state.client.allVillage;
const getListBankBranch = (state: AppState) => state.client.bankBranch;

const getDataCCCD = (state: AppState) => state.client.dataCCCD;

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetToken = () => createSelector(getToken, (token) => token);
export const makeGetToast = () => createSelector(getToast, (toast) => toast);
export const makeGetTypeNav = () =>
  createSelector(getTypeNav, (typeNav) => typeNav);
export const makeGetConfig = () =>
  createSelector(getConfig, (config) => config);
export const makeGetDataSearch = () =>
  createSelector(getDataSearch, (dataSearch) => dataSearch);
export const makeGetClientSetting = () =>
  createSelector(getClientSetting, (setting) => setting);
export const makeGetFooterActive = () =>
  createSelector(getFooterActive, (footActive) => footActive);
export const makeGetTypeIndex = () =>
  createSelector([getTypeIndex], (typeIndex) => typeIndex);
export const makeGetTypePrice = () =>
  createSelector([getTypePrice], (typePrice) => typePrice);
export const makeGetCategorySelected = () =>
  createSelector([getCatalogSelected], (symbol) => symbol);
export const makeGetAccountPortfolio = () =>
  createSelector([getAccountPortfolio], (accPortfolio) => accPortfolio);
export const makeGetUpdatePortfolio = () =>
  createSelector([getUpdatePortfolio], (updPortfolio) => updPortfolio);

export const makeGetDeletePortfolio = () =>
  createSelector([getDeletePortfolio], (delPortfolio) => delPortfolio);
export const makeGetDefaultAccount = () =>
  createSelector([getDefaultAccount], (defAccount) => defAccount);
export const makeGetListAccount = () =>
  createSelector([getListAccount], (listAccount) => listAccount);

export const makeGetListAccountSelect = () =>
  createSelector([getListAccountSelect], (listAccount) => listAccount);

export const makeGetListBankOnline = () =>
  createSelector([getListBankOnline], (allBank) => allBank);

export const makeGetListProvince = () =>
  createSelector([getListProvince], (allProvince) => allProvince);

export const makeGetListDistrict = () =>
  createSelector([getListDistrict], (allDistrict) => allDistrict);

export const makeGetListVillage = () =>
  createSelector([getListVillage], (allVillage) => allVillage);

export const makeGetListBankBranch = () =>
  createSelector([getListBankBranch], (bankBranch) => bankBranch);

export const makeGetDataCCCD = () =>
  createSelector(getDataCCCD, (dataCCCD) => dataCCCD);
