import { INormalSelect } from 'interface';
import { AppState } from 'reducers';
import { createSelector } from 'reselect';

const getUserList = (state: AppState) => state.user.userList;
const getUserDetail = (state: AppState) => state.user.userDetail;
const getUserUpd = (state: AppState) => state.user.userUpd;
const getUserDel = (state: AppState) => state.user.userDel;
const getUserAssetDetail = (state: AppState) => state.user.userAssetDetail;
const getUserProfitClose = (state: AppState) => state.user.userProfitClose;
const getUserProfitSymbol = (state: AppState) => state.user.userProfitSymbol;
const getUserAssetChart = (state: AppState) => state.user.userAssetChart;

const getUserRightByAcc = (state: AppState) => {
  const { userRightByAcc } = state.user;

  if (!userRightByAcc || !userRightByAcc.length) return [];

  let _arr: INormalSelect[] = [];

  userRightByAcc.forEach((element: any) => {
    _arr.push({
      value: element.C_CODE,
      label: element.C_NAME,
    });
  });

  return _arr;
};

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetUserList = () =>
  createSelector(getUserList, (userList) => userList);
export const makeGetUserDetail = () =>
  createSelector(getUserDetail, (userDetail) => userDetail);
export const makeGetUserUpd = () =>
  createSelector(getUserUpd, (userUpd) => userUpd);
export const makeGetUserDel = () =>
  createSelector(getUserDel, (userDel) => userDel);

export const makeGetUserRightByAcc = () =>
  createSelector(getUserRightByAcc, (userRightByAcc) => userRightByAcc);

export const makeGetUserAssetDetail = () =>
  createSelector(getUserAssetDetail, (userAssetDetail) => userAssetDetail);
export const makeGetUserProfitClose = () =>
  createSelector(getUserProfitClose, (userProfitClose) => userProfitClose);
export const makeGetUserProfitSymbol = () =>
  createSelector(getUserProfitSymbol, (userProfitSymbol) => userProfitSymbol);
export const makeGetUserAssetChart = () =>
  createSelector(getUserAssetChart, (userAssetChart) => userAssetChart);
