/* eslint-disable react-hooks/exhaustive-deps */
import { connect, useDispatch } from 'react-redux';

import { makeGetConfig } from 'lib/selector';
import { AppState } from 'reducers';
import { useTranslation } from 'react-i18next';
import BgGrid from 'assets/img/bg/authen/bg-grid.svg';
import BgTextForget from 'assets/img/bg/authen/txt-forget.svg';

import FormForgotPass from 'components/login/formForgotPass';

interface Props {
  successful: any;
}

function ForgetPassPage(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="h-full grid grid-cols-3 gap-6">
      <div
        className="col-span-1 font-briGro pt-[206px]"
        style={{
          backgroundImage: `url(${BgGrid})`,
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="text-skin-subdued text-2xl">
          {t('forgot.forgot-pass')}
        </div>
        <img src={BgTextForget} alt="" />
      </div>
      <div className="col-span-2">
        <FormForgotPass />
      </div>
    </div>
  );
}

const makeMapStateToProps = () => {
  const getConfig = makeGetConfig();

  const mapStateToProps = (state: AppState) => {
    return {
      config: getConfig(state),

      successful: state.login.successful,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(ForgetPassPage);
