/** chi tiết khách hàng */
export const ACC_INFO_REQUESTING = 'acc/INFO_REQUESTING';
export interface AccInfoRequestingAction {
  type: typeof ACC_INFO_REQUESTING;
  data: any;
}

export const ACC_INFO_SUCCESS = 'acc/INFO_SUCCESS';
export interface AccInfoSuccessAction {
  type: typeof ACC_INFO_SUCCESS;
  resData: any;
}

export const ACC_INFO_ERROR = 'acc/INFO_ERROR';
export interface AccInfoErrorAction {
  type: typeof ACC_INFO_ERROR;
  error: any;
}
//----- UPD khách hàng -----
// Định nghĩa action cho yêu cầu cập nhật thông tin
export const ACC_UPD_INFO_REQUESTING = 'acc/UPD_INFO_REQUESTING';
export interface AccUpdInfoRequestingAction {
  type: typeof ACC_UPD_INFO_REQUESTING;
  data: any;
}

// Định nghĩa action cho thành công cập nhật thông tin
export const ACC_UPD_INFO_REQUEST_SUCCESS = 'acc/UPD_INFO_REQUEST_SUCCESS';
export interface AccUpdInfoSuccessAction {
  type: typeof ACC_UPD_INFO_REQUEST_SUCCESS;
  resData: any;
}

// Định nghĩa action cho lỗi cập nhật thông tin
export const ACC_UPD_INFO_REQUEST_ERROR = 'acc/UPD_INFO_REQUEST_ERROR';
export interface AccUpdInfoErrorAction {
  type: typeof ACC_UPD_INFO_REQUEST_ERROR;
  error: any;
}

/** duyệt nghiệp vụ */
export const ACC_CASH_APPR_REQUESTING = 'acc/CASH_APPR_REQUESTING';
export interface AccCashApprRequestingAction {
  type: typeof ACC_CASH_APPR_REQUESTING;
  data: any;
}

export const ACC_CASH_APPR_SUCCESS = 'acc/CASH_APPR_SUCCESS';
export interface AccCashApprSuccessAction {
  type: typeof ACC_CASH_APPR_SUCCESS;
  resData: any;
}

export const ACC_CASH_APPR_ERROR = 'acc/CASH_APPR_ERROR';
export interface AccCashApprErrorAction {
  type: typeof ACC_CASH_APPR_ERROR;
  error: any;
}

/** tài khoản thụ hưởng */
export const ACC_BEN_UPD_REQUESTING = 'acc/BEN_UPD_REQUESTING';
export interface AccBenUpdRequestingAction {
  type: typeof ACC_BEN_UPD_REQUESTING;
  data: any;
}

export const ACC_BEN_UPD_SUCCESS = 'acc/BEN_UPD_SUCCESS';
export interface AccBenUpdSuccessAction {
  type: typeof ACC_BEN_UPD_SUCCESS;
  resData: any;
}

export const ACC_BEN_UPD_ERROR = 'acc/BEN_UPD_ERROR';
export interface AccBenUpdErrorAction {
  type: typeof ACC_BEN_UPD_ERROR;
  error: any;
}

/** danh sách tài khoản thụ hưởng */
export const ACC_BEN_LIST = 'acc/BEN_LIST';
export interface AccBenListAction {
  type: typeof ACC_BEN_LIST;
  data: any;
}
export const ACC_BEN_LIST_REQUESTING = 'acc/BEN_LIST_REQUESTING';
export interface AccBenListRequestingAction {
  type: typeof ACC_BEN_LIST_REQUESTING;
  data: any;
}

export const ACC_BEN_LIST_SUCCESS = 'acc/BEN_LIST_SUCCESS';
export interface AccBenListSuccessAction {
  type: typeof ACC_BEN_LIST_SUCCESS;
  resData: any;
}

export const ACC_BEN_LIST_ERROR = 'acc/BEN_LIST_ERROR';
export interface AccBenListErrorAction {
  type: typeof ACC_BEN_LIST_ERROR;
  error: any;
}

/**xóa tài khoản thụ hương */
export const ACC_BEN_DEL_REQUESTING = 'acc/BEN_DEL_REQUESTING';
export interface AccBenDelRequestingAction {
  type: typeof ACC_BEN_DEL_REQUESTING;
  data: any;
}

export const ACC_BEN_DEL_SUCCESS = 'acc/BEN_DEL_SUCCESS';
export interface AccBenDelSuccessAction {
  type: typeof ACC_BEN_DEL_SUCCESS;
  resData: any;
}

export const ACC_BEN_DEL_ERROR = 'acc/BEN_DEL_ERROR';
export interface AccBenDelErrorAction {
  type: typeof ACC_BEN_DEL_ERROR;
  error: any;
}

/**set khoản thụ hưởng mặc định */
export const ACC_BEN_DEF = 'acc/BEN_DEF';
export interface AccBenDefAction {
  type: typeof ACC_BEN_DEF;
  data: any;
}
export const ACC_BEN_DEF_REQUESTING = 'acc/BEN_DEF_REQUESTING';
export interface AccBenDefRequestingAction {
  type: typeof ACC_BEN_DEF_REQUESTING;
  data: any;
}

export const ACC_BEN_DEF_SUCCESS = 'acc/BEN_DEF_SUCCESS';
export interface AccBenDefSuccessAction {
  type: typeof ACC_BEN_DEF_SUCCESS;
  resData: any;
}

export const ACC_BEN_DEF_ERROR = 'acc/BEN_DEF_ERROR';
export interface AccBenDefErrorAction {
  type: typeof ACC_BEN_DEF_ERROR;
  error: any;
}

/** danh sách mô giới */
export const ACC_SALE_INFOR_LIST = 'acc/SALE_INFOR_LIST';
export interface AccSaleInforListAction {
  type: typeof ACC_SALE_INFOR_LIST;
  data: any;
}
export const ACC_SALE_INFOR_LIST_REQUESTING = 'acc/SALE_INFOR_LIST_REQUESTING';
export interface AccSaleInforListRequestingAction {
  type: typeof ACC_SALE_INFOR_LIST_REQUESTING;
  data: any;
}

export const ACC_SALE_INFOR_LIST_SUCCESS = 'acc/SALE_INFOR_LIST_SUCCESS';
export interface AccSaleInforListSuccessAction {
  type: typeof ACC_SALE_INFOR_LIST_SUCCESS;
  resData: any;
}

export const ACC_SALE_INFOR_LIST_ERROR = 'acc/SALE_INFOR_LIST_ERROR';
export interface AccSaleInforListErrorAction {
  type: typeof ACC_SALE_INFOR_LIST_ERROR;
  error: any;
}

/** thông tin mô giới */
export const ACC_SALE_INFOR = 'acc/SALE_INFOR';
export interface AccSaleInforAction {
  type: typeof ACC_SALE_INFOR_LIST;
  data: any;
}
export const ACC_SALE_INFOR_REQUESTING = 'acc/SALE_INFOR_REQUESTING';
export interface AccSaleInforRequestingAction {
  type: typeof ACC_SALE_INFOR_REQUESTING;
  data: any;
}

export const ACC_SALE_INFOR_SUCCESS = 'acc/SALE_INFOR_SUCCESS';
export interface AccSaleInforSuccessAction {
  type: typeof ACC_SALE_INFOR_SUCCESS;
  resData: any;
}

export const ACC_SALE_INFOR_ERROR = 'acc/SALE_INFOR_ERROR';
export interface AccSaleInforErrorAction {
  type: typeof ACC_SALE_INFOR_ERROR;
  error: any;
}

/** thay đổi thông tin mô giới */
export const ACC_SALE_INFOR_UPD = 'acc/SALE_INFOR_UPD';
export interface AccSaleInforUpdAction {
  type: typeof ACC_SALE_INFOR_UPD;
  data: any;
}
export const ACC_SALE_INFOR_UPD_REQUESTING = 'acc/SALE_INFOR_UPD_REQUESTING';
export interface AccSaleInforUpdRequestingAction {
  type: typeof ACC_SALE_INFOR_UPD_REQUESTING;
  data: any;
}

export const ACC_SALE_INFOR_UPD_SUCCESS = 'acc/SALE_INFOR_UPD_SUCCESS';
export interface AccSaleInforUpdSuccessAction {
  type: typeof ACC_SALE_INFOR_UPD_SUCCESS;
  resData: any;
}

export const ACC_SALE_INFOR_UPD_ERROR = 'acc/SALE_INFOR_UPD_ERROR';
export interface AccSaleInforUpdErrorAction {
  type: typeof ACC_SALE_INFOR_UPD_ERROR;
  error: any;
}

export const SHOW_CREATE_NICKNAME = 'SHOW_CREATE_NICKNAME';
export interface SetCreateNicknamAction {
  type: typeof SHOW_CREATE_NICKNAME;
  resData: any;
}

/** Quản lý thiết bị đăng nhập */
export const MAN_LOGIN_DEV = 'acc/LOGIN_DEV';
export interface ManLoginDevAction {
  type: typeof MAN_LOGIN_DEV;
  data: any;
}
export const MAN_LOGIN_DEV_REQUESTING = 'acc/LOGIN_DEV_REQUESTING';
export interface ManLoginDevRequestingAction {
  type: typeof MAN_LOGIN_DEV_REQUESTING;
  data: any;
}

export const MAN_LOGIN_DEV_SUCCESS = 'acc/LOGIN_DEV_SUCCESS';
export interface ManLoginDevSuccessAction {
  type: typeof MAN_LOGIN_DEV_SUCCESS;
  resData: any;
}

export const MAN_LOGIN_DEV_ERROR = 'acc/LOGIN_DEV_ERROR';
export interface ManLoginDevErrorAction {
  type: typeof MAN_LOGIN_DEV_ERROR;
  error: any;
}

export type AccountInfoAction =
  | AccInfoRequestingAction
  | AccInfoSuccessAction
  | AccInfoErrorAction
  | AccUpdInfoRequestingAction
  | AccUpdInfoSuccessAction
  | AccUpdInfoErrorAction
  | AccCashApprRequestingAction
  | AccCashApprSuccessAction
  | AccCashApprErrorAction
  | AccBenUpdRequestingAction
  | AccBenUpdSuccessAction
  | AccBenUpdErrorAction
  | AccBenDelRequestingAction
  | AccBenDelSuccessAction
  | AccBenDelErrorAction
  | AccBenListAction
  | AccBenDefAction
  | AccBenDefRequestingAction
  | AccBenDefSuccessAction
  | AccBenDefErrorAction
  | AccBenListRequestingAction
  | AccBenListSuccessAction
  | AccBenListErrorAction
  | AccSaleInforListAction
  | AccSaleInforListRequestingAction
  | AccSaleInforListSuccessAction
  | AccSaleInforListErrorAction
  | AccBenListErrorAction
  | AccSaleInforAction
  | AccSaleInforRequestingAction
  | AccSaleInforSuccessAction
  | AccSaleInforErrorAction
  | AccSaleInforUpdAction
  | AccSaleInforUpdRequestingAction
  | AccSaleInforUpdSuccessAction
  | AccSaleInforUpdErrorAction
  | SetCreateNicknamAction
  | ManLoginDevAction
  | ManLoginDevRequestingAction
  | ManLoginDevSuccessAction
  | ManLoginDevErrorAction;
