import { all, fork } from 'redux-saga/effects';

import LoginSaga from 'containers/login/saga';
import GlobalSaga from 'containers/global/saga';
import ReportSaga from 'containers/baocao/saga';
import SettingSaga from 'containers/setting/saga';
import UserSaga from 'containers/user/saga';
import ClientSaga from 'containers/client/saga';

import priceBoardSaga from 'containers/banggia/saga';
import stockDetailSaga from 'containers/banggia/stockDetail/saga';
import analysisSaga from 'containers/market/analysis/saga';
import TradingSaga from 'containers/trading/saga';
import PrivDataSaga from 'containers/privData/saga';
import SummarySaga from 'containers/summary/saga';
import RightSaga from 'containers/right/saga';
import RegisterSaga from 'containers/register/saga';
import AccountInfoSaga from 'containers/account-info/saga';
import CashSaga from 'containers/cash/saga';
import NotiSaga from 'containers/notification/saga';

export default function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(GlobalSaga),
    fork(TradingSaga),
    fork(ReportSaga),
    fork(SettingSaga),
    fork(UserSaga),
    fork(priceBoardSaga),
    fork(ClientSaga),
    fork(stockDetailSaga),
    fork(analysisSaga),
    fork(PrivDataSaga),
    fork(SummarySaga),
    fork(RightSaga),
    fork(RegisterSaga),
    fork(AccountInfoSaga),
    fork(CashSaga),
    fork(NotiSaga),
  ]);
}
